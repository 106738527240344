import React from "react";
import {Icon} from "semantic-ui-react";
import './StatusHouse.css'

const StatusHouse = ({status, ...props}) => {
    return (
        <div className={'status-house'}>
            <div className={'line-status'}/>

            <div className={'status-house-content'}>
                <div className={'status-house-container'}>
                    <div className={status === 'new'? 'icon-status-error' : 'icon-status'}>
                        <Icon name={'home'}/>
                    </div>
                    <span className={status === 'new'? 'text-status-error' : 'text-status'}>ยังไม่เชื่อมข้อมูล</span>
                </div>

                <div className={'status-house-container'}>
                    <div className={status === 'incomplete'? 'icon-status-warning' : 'icon-status'}>
                        <Icon name={'home'}/>
                    </div>
                    <span className={status === 'incomplete'? 'text-status-warning' : 'text-status'}>กำลังดำเนินการ</span>
                </div>

                <div className={'status-house-container'}>
                    <div className={status === 'complete'? 'icon-status-now' : 'icon-status'}>
                        <Icon name={'home'}/>
                    </div>
                    <span className={status === 'complete'? 'text-status-now' : 'text-status'}>ดำเนินการเสร็จสิ้น</span>
                </div>

                <div className={'status-house-container'}>
                    <div className={status === 'reject'? 'icon-status-error' : 'icon-status'}>
                        <Icon name={'home'}/>
                    </div>
                    <span className={status === 'reject'? 'text-status-error' : 'text-status'}>ปฏิเสธข้อมูล</span>
                </div>

                <div className={'status-house-container'}>
                    <div className={status === 'approve'? 'icon-status-now' : 'icon-status'}>
                        <Icon name={'home'}/>
                    </div>
                    <span className={status === 'approve'? 'text-status-now' : 'text-status'}>อนุมัติข้อมูล</span>
                </div>


            </div>

        </div>
    )
}

export default StatusHouse
