import React from "react";
import Topic from "./Topic";
import SubTopic from "./SubTopic";
import Answer from "./Answer";

export default ({data, ...props}) => {

    let {
        no20_1,
        no20_2,
        no20_3,
        no21_1,
        no21_2,
        no21_3,
        no22_1,
        no22_2,
        no22_3,
        no22_4,
        no23_1,
        no23_2,
    } = data

    // Check comma number
    const numberWithCommas = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const answer20_2 = () => {
        return (
            <div>
                {
                    (no20_2.no20_2_1.answer === '0' && no20_2.no20_2_2.answer === '0') &&
                    <Answer detail={`มี`} value={''} unit={''}/>
                }
                {
                    no20_2.no20_2_1.answer === '1' &&
                    <Answer detail={`- ไม่มีอาชีพและไม่มีรายได้ จำนวน `}
                            value={no20_2.no20_2_1.amount} unit={'คน'}/>

                }
                {
                    no20_2.no20_2_2.answer === '1' &&
                    <Answer detail={`- ไม่มีอาชีพแต่มีรายได้ จำนวน `}
                            value={no20_2.no20_2_2.amount} unit={'คน'}/>
                }
                {
                    (no20_2.no20_2_1.answer !== '1' && no20_2.no20_2_2.answer !== '1') &&
                    <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                }
            </div>
        )
    }

    const answer21_2 = () => {
        return (
            <div>
                {
                    (no21_2.no21_2_1.answer === '0' && no21_2.no21_2_2.answer === '0') &&
                    <Answer detail={`มี`} value={''} unit={''}/>
                }
                {
                    no21_2.no21_2_1.answer === '1' &&
                    <Answer detail={`- ไม่มีอาชีพและไม่มีรายได้ จำนวน `}
                            value={no21_2.no21_2_1.amount} unit={'คน'}/>

                }
                {
                    no21_2.no21_2_2.answer === '1' &&
                    <Answer detail={`- ไม่มีอาชีพแต่มีรายได้ จำนวน `}
                            value={no21_2.no21_2_2.amount} unit={'คน'}/>
                }
                {
                    (no20_2.no20_2_1.answer !== '1' && no20_2.no20_2_2.answer !== '1') &&
                    <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                }
            </div>
        )
    }

    const answer22_1 = () => {
        return (
            <div>
                {
                    no22_1.no22_1_1.answer === '1' &&
                    <Answer detail={`- รายได้จากอาชีพหลักของทุกคนในครัวเรือน (ต่อปี) จำนวน`}
                            value={numberWithCommas(no22_1.no22_1_1.amount)} unit={'บาท'}/>
                }
                {
                    no22_1.no22_1_2.answer === '1' &&
                    <Answer detail={`- รายได้จากอาชีพรอง/อาชีพเสริมของทุกคนในครัวเรือน (ต่อปี) จำนวน`}
                            value={numberWithCommas(no22_1.no22_1_2.amount)} unit={'บาท'}/>
                }
                {
                    no22_1.no22_1_3.answer === '1' &&
                    <Answer detail={`- รายได้อื่น ๆ ของทุกคนในครัวเรือน (ต่อปี) จำนวน`}
                            value={numberWithCommas(no22_1.no22_1_3.amount)} unit={'บาท'}/>
                }
                {
                    no22_1.no22_1_4.answer === '1' &&
                    <Answer
                        detail={`- รายได้ที่เกิดจากการทำ/การปลูก การเลี้ยง สัตว์และการหาไว้กินเอง แล้วคิดคำนวณเป็นจำนวนเงินทั้งหมด (ต่อปี) จำนวน`}
                        value={numberWithCommas(no22_1.no22_1_4.amount)} unit={'บาท'}/>
                }
                {
                    no22_1.no22_1_5.answer === '1' &&
                    <Answer detail={`- รายได้อื่น ๆ ของทุกคนในครัวเรือน (ต่อปี) จำนวน`}
                            value={numberWithCommas(no22_1.no22_1_5.amount)} unit={'บาท'}/>
                }
                {
                    (no22_1.no22_1_1.answer !== '1' && no22_1.no22_1_2.answer !== '1' && no22_1.no22_1_3.answer !== '1' &&
                        no22_1.no22_1_4.answer !== '1' && no22_1.no22_1_5.answer !== '1') &&
                    <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                }
            </div>
        )
    }

    const answer22_2 = () => {
        return (
            <div>
                {
                    no22_2.no22_2_1.answer === '1' &&
                    <Answer detail={`- รายจ่ายที่เป็นต้นทุนการผลิต (ต่อปี) จำนวน`}
                            value={numberWithCommas(no22_2.no22_2_1.amount)} unit={'บาท'}/>
                }
                {
                    no22_2.no22_2_2.answer === '1' &&
                    <Answer detail={`- รายจ่ายในการอุปโภคบริโภคที่่จำเป็น (ต่อปี) จำนวน`}
                            value={numberWithCommas(no22_2.no22_2_2.amount)} unit={'บาท'}/>
                }
                {
                    no22_2.no22_2_3.answer === '1' &&
                    <Answer detail={`- รายจ่ายในการอุปโภคบริโภคที่ไม่จำเป็น (ต่อปี) จำนวน`}
                            value={numberWithCommas(no22_2.no22_2_3.amount)} unit={'บาท'}/>
                }
                {
                    no22_2.no22_2_4.answer === '1' &&
                    <Answer detail={`- รายจ่ายในการชำระหนี้สิน จำนวน`}
                            value={numberWithCommas(no22_2.no22_2_4.amount)} unit={'บาท'}/>
                }
                {
                    (no22_2.no22_2_1.answer !== '1' && no22_2.no22_2_2.answer !== '1' && no22_2.no22_2_3.answer !== '1' &&
                        no22_2.no22_2_4.answer !== '1') &&
                    <Answer detail={`- ไม่มีข้อมูล`} value={''} unit={''}/>
                }
            </div>
        )
    }

    const answer22_3 = () => {
        return (
            <div>
                {
                    no22_3.no22_3_1.answer === '1' &&
                    (
                        no22_3.no22_3_1.status === '1' ?
                            <Answer detail={`- กลุ่มออมทรัพย์เพื่อการผลิต`} value={'เข้าถึง'} unit={''}/>
                            :
                            <Answer detail={`- กลุ่มออมทรัพย์เพื่อการผลิต`} value={'เข้าไม่ถึง'} unit={''}/>

                    )
                }
                {
                    no22_3.no22_3_2.answer === '1' &&
                    (
                        no22_3.no22_3_2.status === '1' ?
                            <Answer detail={`- สหกรณ์`} value={'เข้าถึง'} unit={''}/>
                            :
                            <Answer detail={`- สหกรณ์`} value={'เข้าไม่ถึง'} unit={''}/>

                    )
                }
                {
                    no22_3.no22_3_3.answer === '1' &&
                    (
                        no22_3.no22_3_3.status === '1' ?
                            <Answer detail={`- ธนาคารเพื่อนการเกษตรและ สหกรณ์`} value={'เข้าถึง'} unit={''}/>
                            :
                            <Answer detail={`- ธนาคารเพื่อนการเกษตรและ สหกรณ์`} value={'เข้าไม่ถึง'} unit={''}/>

                    )
                }
                {
                    no22_3.no22_3_4.answer === '1' &&
                    (
                        no22_3.no22_3_4.status === '1' ?
                            <Answer detail={`- ธนาคารออมสิน / ธนาคารกรุงไทย / ธนาคารอิสลาม / ธนาคาร SME`}
                                    value={'เข้าถึง'} unit={''}/>
                            :
                            <Answer detail={`- ธนาคารออมสิน / ธนาคารกรุงไทย / ธนาคารอิสลาม / ธนาคาร SME`}
                                    value={'เข้าไม่ถึง'} unit={''}/>

                    )
                }
                {
                    no22_3.no22_3_5.answer === '1' &&
                    (
                        no22_3.no22_3_5.status === '1' ?
                            <Answer detail={`- ธนคารพาณิชย์อื่นๆ`} value={'เข้าถึง'} unit={''}/>
                            :
                            <Answer detail={`- ธนคารพาณิชย์อื่นๆ`} value={'เข้าไม่ถึง'} unit={''}/>

                    )
                }
                {
                    no22_3.no22_3_6.answer === '1' &&
                    (
                        no22_3.no22_3_6.status === '1' ?
                            <Answer detail={`- สถาบันการเงินที่ให้การสนับสนุนสินเชื่อภาคอุตสาหกรรม`} value={'เข้าถึง'}
                                    unit={''}/>
                            :
                            <Answer detail={`- สถาบันการเงินที่ให้การสนับสนุนสินเชื่อภาคอุตสาหกรรม`}
                                    value={'เข้าไม่ถึง'} unit={''}/>
                    )
                }
                {
                    no22_3.no22_3_7.answer === '1' &&
                    (
                        no22_3.no22_3_7.status === '1' ?
                            <Answer detail={`- ร้านค้า / พ่อค้า / แม่ค้า /นายทุน`}
                                    value={'เข้าถึง'} unit={''}/>
                            :
                            <Answer detail={`- ร้านค้า / พ่อค้า / แม่ค้า /นายทุน`}
                                    value={'เข้าไม่ถึง'} unit={''}/>

                    )
                }
                {
                    no22_3.no22_3_8.answer === '1' &&
                    (
                        no22_3.no22_3_8.status === '1' ?
                            <Answer detail={`- กองทุนหมุนเวียนจากทางราชการ`}
                                    value={'เข้าถึง'} unit={''}/>
                            :
                            <Answer detail={`- กองทุนหมุนเวียนจากทางราชการ`}
                                    value={'เข้าไม่ถึง'} unit={''}/>

                    )
                }
                {
                    no22_3.no22_3_9.answer === '1' &&
                    (
                        no22_3.no22_3_9.status === '1' ?
                            <Answer detail={`- ${no22_3.no22_3_9.name}`}
                                    value={'เข้าถึง'} unit={''}/>
                            :
                            <Answer detail={`- ${no22_3.no22_3_9.name}`}
                                    value={'เข้าไม่ถึง'} unit={''}/>

                    )
                }
                {
                    (no22_3.no22_3_1.answer === '0' && no22_3.no22_3_2.answer === '0' &&
                        no22_3.no22_3_3.answer === '0' && no22_3.no22_3_4.answer === '0' &&
                        no22_3.no22_3_5.answer === '0' && no22_3.no22_3_6.answer === '0' &&
                        no22_3.no22_3_7.answer === '0' && no22_3.no22_3_8.answer === '0' &&
                        no22_3.no22_3_9.answer === '0') &&
                    <Answer detail={`-`} value={'ไม่ได้ขอเงินกู้'} unit={''}/>

                }
                {
                    (no22_3.no22_3_1.answer !== '1' && no22_3.no22_3_1.answer !== '0') &&
                    (no22_3.no22_3_2.answer !== '1' && no22_3.no22_3_1.answer !== '0') &&
                    (no22_3.no22_3_3.answer !== '1' && no22_3.no22_3_1.answer !== '0') &&
                    (no22_3.no22_3_4.answer !== '1' && no22_3.no22_3_1.answer !== '0') &&
                    (no22_3.no22_3_5.answer !== '1' && no22_3.no22_3_1.answer !== '0') &&
                    (no22_3.no22_3_6.answer !== '1' && no22_3.no22_3_1.answer !== '0') &&
                    (no22_3.no22_3_7.answer !== '1' && no22_3.no22_3_1.answer !== '0') &&
                    (no22_3.no22_3_8.answer !== '1' && no22_3.no22_3_1.answer !== '0') &&
                    (no22_3.no22_3_9.answer !== '1' && no22_3.no22_3_1.answer !== '0') &&
                    <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                }
            </div>
        )
    }

    const answer22_4 = () => {
        return (
            <div>
                {
                    no22_4.no22_4_1.answer === '1' &&
                    <div>
                        <div>- ครัวเรือนนี้ มีหนี้ผ่านสถาบันการเงิน</div>
                        <div style={{marginRight: '20px'}}>
                            <Answer detail={`มี จำนวน`} value={no22_4.no22_4_1.amount} unit={'คน'}/>
                            <Answer detail={`จำนวนหนี้สิน`} value={numberWithCommas(no22_4.no22_4_1.value)} unit={'บาท'}/>
                        </div>
                    </div>
                }
                {
                    (no22_4.no22_4_1.answer === '0' && no22_4.no22_4_1.amount !== '0') &&
                    <div>
                        <div>- ครัวเรือนนี้ มีหนี้ผ่านสถาบันการเงิน</div>
                        <div style={{marginRight: '20px'}}>
                            <Answer detail={`ไม่มี`} value={''} unit={''}/>
                        </div>
                    </div>
                }
                {
                    no22_4.no22_4_2.answer === '1' &&
                    <div>
                        <div>- ครัวเรือนนี้ มีหนี้นอกระบบ</div>
                        <div style={{marginRight: '20px'}}>
                            <Answer detail={`มี จำนวน`} value={no22_4.no22_4_2.amount} unit={'คน'}/>
                            <Answer detail={`จำนวนหนี้สิน`} value={numberWithCommas(no22_4.no22_4_2.value)} unit={'บาท'}/>
                        </div>
                    </div>
                }
                {
                    (no22_4.no22_4_2.answer === '0' && no22_4.no22_4_2.amount !== '0') &&
                    <div>
                        <div>- ครัวเรือนนี้ มีหนี้นอกระบบ</div>
                        <div style={{marginRight: '20px'}}>
                            <Answer detail={`ไม่มี`} value={''} unit={''}/>
                        </div>
                    </div>
                }
                {
                    (no22_4.no22_4_1.answer !== '1' && no22_4.no22_4_1.answer !== '0') &&
                    (no22_4.no22_4_2.answer !== '1' && no22_4.no22_4_2.answer !== '0') &&
                    <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                }
                {
                    (no22_4.no22_4_1.answer === '0' && no22_4.no22_4_1.amount === '0') &&
                    (no22_4.no22_4_2.answer === '0' && no22_4.no22_4_2.amount === '0') &&
                    <Answer detail={`ไม่มี`} value={''} unit={''}/>
                }
            </div>
        )
    }

    const answer23_1 = () => {
        return (
            <div>
                {
                    (no23_1.no23_1_1.answer === '1' || no23_1.no23_1_2.answer === '1' ||
                        no23_1.no23_1_3.answer === '1' || no23_1.no23_1_4.answer === '1' ||
                        no23_1.no23_1_5.answer === '1' || no23_1.no23_1_6.answer === '1' ||
                        no23_1.no23_1_7.answer === '1' || no23_1.no23_1_8.answer === '1' ||
                        no23_1.no23_1_9.answer === '1' || no23_1.no23_1_10.answer === '1') &&
                    <div>
                        <div style={{}}>รูปแบบการออมเงิน</div>
                        <div style={{color: '#555555', marginLeft: '20px'}}>
                            {
                                no23_1.no23_1_1.answer === '1' &&
                                <Answer detail={`- เงินสด`} value={''} unit={''}/>
                            }
                            {
                                no23_1.no23_1_2.answer === '1' &&
                                <Answer detail={`- เงินฝากธนาคาร`} value={''} unit={''}/>
                            }
                            {
                                no23_1.no23_1_3.answer === '1' &&
                                <Answer detail={`- เงินฝากสหกรณ์`} value={''} unit={''}/>
                            }
                            {
                                no23_1.no23_1_4.answer === '1' &&
                                <Answer detail={`- เงินฝากกลุ่มออมทรัพย์ฯ`} value={''} unit={''}/>
                            }
                            {
                                no23_1.no23_1_5.answer === '1' &&
                                <Answer detail={`- เงินฝากกองทุนต่างๆ`} value={''} unit={''}/>
                            }
                            {
                                no23_1.no23_1_6.answer === '1' &&
                                <Answer detail={`- ทำประกันชีวิต`} value={''} unit={''}/>
                            }
                            {
                                no23_1.no23_1_7.answer === '1' &&
                                <Answer detail={`- ซื้อพันธบัตร`} value={''} unit={''}/>
                            }
                            {
                                no23_1.no23_1_8.answer === '1' &&
                                <Answer detail={`- ซื้อทองคำ`} value={''} unit={''}/>
                            }
                            {
                                no23_1.no23_1_9.answer === '1' &&
                                <Answer detail={`- ซื้อบ้านหรือที่ดิน`} value={''} unit={''}/>
                            }
                            {
                                no23_1.no23_1_10.answer === '1' &&
                                <Answer detail={no23_1.no23_1_10.name} value={''} unit={''}/>
                            }
                        </div>
                    </div>
                }
                {
                    (no23_1.no23_1_1.answer === '0' && no23_1.no23_1_2.answer === '0' &&
                        no23_1.no23_1_3.answer === '0' && no23_1.no23_1_4.answer === '0' &&
                        no23_1.no23_1_5.answer === '0' && no23_1.no23_1_6.answer === '0' &&
                        no23_1.no23_1_7.answer === '0' && no23_1.no23_1_8.answer === '0' &&
                        no23_1.no23_1_9.answer === '0' && no23_1.no23_1_10.answer === '0') &&
                    <Answer detail={`ไม่มีการออมเงิน`} value={''} unit={''}/>
                }
                {
                    (no23_1.no23_1_1.answer !== '1' && no23_1.no23_1_2.answer !== '1' &&
                        no23_1.no23_1_3.answer !== '1' && no23_1.no23_1_4.answer !== '1' &&
                        no23_1.no23_1_5.answer !== '1' && no23_1.no23_1_6.answer !== '1' &&
                        no23_1.no23_1_7.answer !== '1' && no23_1.no23_1_8.answer !== '1' &&
                        no23_1.no23_1_9.answer !== '1' && no23_1.no23_1_10.answer !== '1' &&
                        no23_1.no23_1_1.answer !== '0' && no23_1.no23_1_2.answer !== '0' &&
                        no23_1.no23_1_3.answer !== '0' && no23_1.no23_1_4.answer !== '0' &&
                        no23_1.no23_1_5.answer !== '0' && no23_1.no23_1_6.answer !== '0' &&
                        no23_1.no23_1_7.answer !== '0' && no23_1.no23_1_8.answer !== '0' &&
                        no23_1.no23_1_9.answer !== '0' && no23_1.no23_1_10.answer !== '0') &&
                    <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                }
            </div>
        )
    }

    return (
        <div>
            {/*Question 20*/}
            <Topic
                no={20}
                title={"คนอายุ 15 - 59 ปี มีอาชีพและรายได้"}
            >
                {/*Question 20.1*/}
                <SubTopic
                    no={'20.1.'}
                    questionName={"ครัวเรือนนี้ มีคนอายุ 15 - 59 ปี หรือไม่(ไม่นับรวมผู้ที่กำลังศึกษาอย่างเดียว โดยไม่ประกอบอาชีพและคนพิการที่ไม่สามารถช่วยตัวเองได้)"}
                >
                    {
                        (no20_1.answer === '1' || no20_1.answer === '0') ?
                            no20_1.answer === '1' ?
                                <Answer detail={`มี จำนวน `}
                                        value={no20_1.amount} unit={'คน'}/>
                                :
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 20.2*/}
                <SubTopic
                    no={'20.2.'}
                    questionName={"คนอายุ 15 - 59 ปี มีอาชีพและมีรายได้ ทุกคนหรือไม่"}
                >
                    {data ? answer20_2() : `ไม่มีข้อมูล`}
                </SubTopic>

                {/*Question 20.3*/}
                <SubTopic
                    no={'20.3.'}
                    questionName={"คนอายุ 15 - 59 ปี ที่ไม่มีอาชีพ ตามข้อ 20.2 มีความสมัครใจที่จะประกอบอาชีพ กี่คน"}
                >
                    {
                        (no20_3.amount > 0) ?
                            <Answer detail={``} value={no20_3.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

            {/*Question 21*/}
            <Topic
                no={21}
                title={"คนอายุ 60 ปี ขึ้นไปมีอาชีพและรายได้"}
            >
                {/*Question 21.1*/}
                <SubTopic
                    no={'21.1.'}
                    questionName={"ครัวเรือนนี้ มีคนอายุ 60 ปีขึ้นไปหรือไม่(ไม่นับรวมคนพิการที่ไม่สามารถช่วยตัวเองได้)"}
                >
                    {
                        (no21_1.answer === '1' || no21_1.answer === '0') ?
                            no21_1.answer === '1' ?
                                <Answer detail={`มี จำนวน `}
                                        value={no21_1.amount} unit={'คน'}/>
                                :
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 21.2*/}
                <SubTopic
                    no={'21.2.'}
                    questionName={"คนอายุ 60 ปีขึ้นไป มีอาชีพและมีรายได้ ทุกคนหรือไม่"}
                >
                    {data ? answer21_2() : `ไม่มีข้อมูล`}
                </SubTopic>

                {/*Question 21.3*/}
                <SubTopic
                    no={'21.3.'}
                    questionName={"คนอายุ 60 ปี ขึ้นไป ที่ไม่มีอาชีพ ตามข้อ 21.2 มีความสมัครใจที่จะประกอบอาชีพ กี่คน"}
                >
                    {
                        no21_1.amount > 0 ?
                            <Answer detail={``} value={no21_3.amount}
                                    unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

            {/*Question 22*/}
            <Topic
                no={22}
                title={"รายได้เฉลี่ยของครัวเรือนต่อปี"}
            >
                {/*Question 22.1*/}
                <SubTopic
                    no={'22.1.'}
                    questionName={"ในรอบปีที่ผ่านมาครัวเรือนนี้มีรายได้จากข้อใดข้อหนึ่ง หรือหลายข้อดังต่อไปนี้ หรือไม่"}
                >
                    {data ? answer22_1() : `ไม่มีข้อมูล`}
                </SubTopic>

                {/*Question 22.2*/}
                <SubTopic
                    no={'22.2.'}
                    questionName={"รายจ่ายของครัวเรือน ในรอบปีที่ผ่านมา ครัวเรือนมีรายจ่ายในแต่ละด้านดังต่อไปนี้"}
                >
                    {data ? answer22_2() : `ไม่มีข้อมูล`}
                </SubTopic>

                {/*Question 22.3*/}
                <SubTopic
                    no={'22.3.'}
                    questionName={"การเข้าถึงแหล่งเงินทุน"}
                >
                    {data ? answer22_3() : `ไม่มีข้อมูล`}
                </SubTopic>

                {/*Question 22.4*/}
                <SubTopic
                    no={'22.4.'}
                    questionName={"หนี้สินของครัวเรือน"}
                >
                    {data ? answer22_4() : `ไม่มีข้อมูล`}
                </SubTopic>
            </Topic>

            {/*Question 23*/}
            <Topic
                no={23}
                title={"ครัวเรือนมีการเก็บออมเงิน"}
            >
                {/*Question 23.1*/}
                <SubTopic
                    no={'23.1.'}
                    questionName={"ในรอบปีที่ผ่านมา ครัวเรือนมีการเก็บออมเงินในรูปแบบใดบ้าง (เลือกได้มากกว่า 1 ข้อ)"}
                >
                    {data ? answer23_1() : `ไม่มีข้อมูล`}
                </SubTopic>

                {/*Question 23.2*/}
                <SubTopic
                    no={'23.2.'}
                    questionName={"ในรอบปีที่ผ่านมา ครัวเรือน มีการเก็บออมเงินตามข้อ 23.1. ปีละกี่บาท"}
                >
                    {
                        (no23_2.amount > 0) ?
                            <Answer detail={`ปีละ`}
                                    value={numberWithCommas(no23_2.amount)}
                                    unit={'บาท'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

        </div>
    )
}
