import * as React from 'react';
import {Dropdown} from 'semantic-ui-react';
import './Dropdown.css'

export const DropdownDetail = (
    {
        field: {name, value},
        form: {touched, errors, setFieldValue},
        options,
        children: _,
        ...props
    }) => {
    return (
        <div className={'dropdown-profile'}>
            {/* Dropdown */}
            <Dropdown
                className={'dropdown-pattern-profile'}
                selection
                options={options}
                value={value}
                //แก้ต้องนี้แทน
                onChange={(_, {value, ...data}) => {
                    if (name === 'community_id') {
                        const {area} = data.options.find((o) => o.value === value);
                        setFieldValue(name, value);
                        setFieldValue('area_id', area);
                    } else {
                        setFieldValue(name, value);
                    }
                }}
                {...props}
            />
        </div>
    );
};

const ItemAddress = ({address, name}) => {
    let [sub_district, district, province, zip_code] = address.split(' >> ');
    if (district) {
        return (
            <div className={'address-item'}>
                <span className={name === 'sub_district' && 'active'}> {sub_district} </span><span>>></span>
                <span className={name === 'district' && 'active'}> {district} </span><span>>></span>
                <span className={name === 'province' && 'active'}> {province} </span><span>>></span>
                <span className={name === 'zip_code' && 'active'}> {zip_code} </span>
            </div>
        )
    } else {
        return (
            <div className={'address-item'}>
                <span className={'active'}> {sub_district} </span>
            </div>
        )
    }
}

export const DropdownAddress = ({
                                   field: {name, value},
                                   form: {touched, errors, setFieldValue},
                                   options,
                                   children: _,
                                   onChangeAddress,
                                   ...props
                               }) => {
    return (
        <div className={'dropdown-profile'}>
            {/* Dropdown */}
            <Dropdown
                className={'dropdown-pattern-profile'}
                search
                selection
                options={options.map((data) => {
                    return {
                        ...data,
                        content: (
                            <ItemAddress name={name} address={data.value}/>
                        ),
                    }
                })}
                value={value}
                {...props}
                onChange={(_, {value, ...data}) => {
                    // console.log('name: ',name);
                    value = value.split(' >> ');
                    let option_address = value.map((data) => ({
                        key: data,
                        text: data,
                        value: data
                    }))
                    onChangeAddress(option_address)
                    // console.log('value: ', value);

                    setFieldValue('sub_district', value[0]);
                    setFieldValue('district', value[1]);
                    setFieldValue('province', value[2]);
                    setFieldValue('zip_code', value[3]);
                }}
            />
        </div>
    );
};
export default DropdownAddress;

