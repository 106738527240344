import React, {useState} from "react";
import {Button, Header, Icon, Image, Modal} from "semantic-ui-react";
import './ChangePassword.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import axios from "axios";
import Config from "../../../constants/Config";
import swal from "sweetalert2";


export default ({props, open, onClose}) => {

    const [old_password, set_old_password] = useState('')
    const [new_password, set_new_password] = useState('')
    const [confirm_new_password, set_confirm_new_password] = useState('')
    const [error_old_password, set_error_old_password] = useState(false)
    const [error_new_password, set_error_new_password] = useState(false)
    const [error_confirm_new_password, set_error_confirm_new_password] = useState(false)
    const [unmatch_new_password, set_unmatch_new_password] = useState(false)
    const [show_hint, set_show_hint] = useState(false)
    const [hint_length, set_hint_length] = useState(false)
    const [hint_text, set_hint_text] = useState(false)
    const [hint_number, set_hint_number] = useState(false)
    const [hint_vip, set_hint_vip] = useState(false)
    const [see_old_password, set_see_old_password] = useState(false)
    const [see_new_password, set_see_new_password] = useState(false)
    const [see_confirm_new_password, set_see_confirm_new_password] = useState(false)

    // ฟังก์ชันกำหนดค่าเริ่มต้น
    const setInitialState = () => {
        set_old_password('')
        set_new_password('')
        set_confirm_new_password('')
        set_error_old_password(false)
        set_error_new_password(false)
        set_unmatch_new_password(false)
        set_show_hint(false)
        set_hint_length(false)
        set_hint_text(false)
        set_hint_number(false)
        set_hint_vip(false)
    }

    // ฟังก์ชัน onChange
    const handleChange = (name, value) => {
        set_error_old_password(false)
        set_error_new_password(false)
        set_error_confirm_new_password(false)
        if (name === 'old_password') {
            set_old_password(value)
            // console.log(name, value)
        }

        if (name === 'confirm_new_password') {
            set_confirm_new_password(value)
            if (value === '') {
                set_unmatch_new_password(false)
            } else {
                if (new_password === value) {
                    set_unmatch_new_password(false)
                } else if (new_password !== value) {
                    set_unmatch_new_password(true)
                }
            }

            // set_error_confirm_new_password(new_password !== confirm_new_password)
            // this.state.status[name].success = this.state.field.new_password === this.state.field.confirm_new_password && this.state.field.confirm_new_password
            // this.state.status[name].error = this.state.field.new_password !== this.state.field.confirm_new_password
        } else if (name === 'new_password') {

            if (value === '') {
                set_error_new_password(false)
            } else {
                let regx_password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
                let regx_text = /(?=.*[a-z])(?=.*[A-Z])/
                let regx_number = /([0-9])/
                let regx_vip = /([\W]+)/
                set_new_password(value)

                set_hint_length(value.length >= 8)
                set_hint_text(regx_text.test(value))
                set_hint_number(regx_number.test(value))
                set_hint_vip(value ? !regx_vip.test(value) : false)

                if (regx_password.test(value)) {
                    set_error_new_password(false)
                } else {
                    set_error_new_password(true)
                }

            }
        }

    }

    // ฟังก์ชันบันทึกรหัสผ่าน
    const savePassword = async () => {

        if (old_password && new_password && confirm_new_password) {

            if (new_password === confirm_new_password) {
                let passwordForm = {
                    old_password: old_password,
                    new_password: new_password,
                }

                // call api
                await axios.put(`${Config.url}/api/v1/change_password`,
                    passwordForm,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + await localStorage.getItem('access_token'),
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {

                        let status = response.status

                        if (status === 200) {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'เปลี่ยนรหัสผ่านสำเร็จ',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            onClose();
                            setInitialState();
                        } else {
                            swal
                                .fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: 'เกิดข้อผิดพลาด',
                                    text: 'กรุณาลองใหม่อีกครั้ง',
                                    confirmButtonText: 'ตกลง'
                                })
                        }


                    })
                    .catch((error) => {
                        if (error.response) {
                            const message = error.response.data.message;
                            if (message === 'Wrong Password') {
                                swal
                                    .fire({
                                        position: 'center',
                                        icon: 'error',
                                        title: 'รหัสผ่านเดิมไม่ถูกต้อง',
                                        text: 'กรุณากรอกรหัสผ่านเดิมอีกครั้ง',
                                        confirmButtonText: 'ตกลง'
                                    })
                                set_error_old_password(true)
                            } else {
                                swal
                                    .fire({
                                        position: 'center',
                                        icon: 'error',
                                        title: 'เกิดข้อผิดพลาด',
                                        text: message,
                                        confirmButtonText: 'ตกลง'
                                    })
                            }

                        } else {
                            swal
                                .fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: 'เกิดข้อผิดพลาด',
                                    confirmButtonText: 'ตกลง'
                                })
                        }
                    })
            } else {
                set_unmatch_new_password(true)
            }

        } else {
            if (!old_password) {
                set_error_old_password(true)
            }
            if (!new_password) {
                set_error_new_password(true)
            }
            if (!confirm_new_password) {
                set_error_confirm_new_password(true)
            }
        }
    };

    const seePassword = (name) => {
        if (name === 'old_password') {
            set_see_old_password(!see_old_password)
        }
        if (name === 'new_password') {
            set_see_new_password(!see_new_password)
        }
        if (name === 'confirm_new_password') {
            set_see_confirm_new_password(!confirm_new_password)
        }

    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={'change-password-screen'}
        >
            {/*หัวข้อ*/}
            <div className={'header-change-password'}>
                <div className={'header-container'}>
                    <div className={'header-text-container'}>
                        <Icon className={'icon-password'} name='key'/>
                        <span className={'header-text'}>เปลี่ยนรหัสผ่าน</span>
                    </div>


                    {/*ปุ่มปิด Modal*/}
                    <button onClick={onClose} className={'close-modal-button'}>
                        <Icon name={'close'} size={'small'} className={'close-modal'}/>
                    </button>
                </div>

            </div>

            {/*ส่วนการกรอกรหัสผ่าน*/}
            <Modal.Content className={'content-container'}>

                {/*รหัสผ่านเดิม*/}
                <div className={'container-input'}>
                    <label>รหัสผ่านเดิม</label>
                    <div className={'container-input-password'}>
                        <div
                            className={'border-input-password'}>
                            <input
                                className={error_old_password ? 'input-password-error' : 'input-password'}
                                placeholder='กรอกรหัสผ่านเดิม'
                                type={see_old_password ? 'text' : 'password'}
                                name='old_password'
                                value={old_password}
                                onChange={(event) => {
                                    let {name, value} = event.target
                                    handleChange(name, value)
                                }}
                            />
                            <button
                                className={'see-button'}
                                onClick={() => {
                                    set_see_old_password(!see_old_password)
                                }}
                            >
                                <Icon name={see_old_password ? 'eye slash' : 'eye'}/>
                            </button>
                        </div>
                            {error_old_password && <span className={'input-detail-error'}>กรุณากรอกรหัสผ่านเดิม</span>}

                    </div>
                </div>

                {/*รหัสผ่านใหม่*/}
                <div className={'container-input'}>
                    <label>รหัสผ่านใหม่</label>
                    <div className={'container-input-password'}>
                        <div
                            className={error_new_password || unmatch_new_password ? 'border-input-password-error' : 'border-input-password'}>
                            <input
                                className={error_new_password ? 'input-password-error' : 'input-password'}
                                placeholder='กรอกรหัสผ่านใหม่'
                                type={see_new_password ? 'text' : 'password'}
                                name='new_password'
                                value={new_password}
                                onChange={(event) => {
                                    let {name, value} = event.target
                                    handleChange(name, value)
                                }}
                                onFocus={() => {
                                    set_show_hint(true)
                                }}
                                onBlur={() => {
                                    set_show_hint(false)
                                }}
                            />
                            <button
                                className={'see-button'}
                                onClick={() => {
                                    set_see_new_password(!see_new_password)
                                }}
                            >
                                <Icon name={see_new_password ? 'eye slash' : 'eye'}/>
                            </button>
                        </div>
                        {error_new_password && <span className={'input-detail-error'}>กรุณากรอกรหัสผ่านใหม่</span>}
                        {
                            show_hint &&
                            <div style={{backgroundColor: '#EBEBEB', borderRadius: '10px'}}>
                                <div
                                    className={
                                        hint_length ?
                                            'text-success'
                                            :
                                            'text-normal'
                                    }
                                >

                                    {hint_length &&
                                    <Icon
                                        name="check circle outline"
                                        color={'green'}
                                        className={'icon-success'}
                                    />
                                    }
                                    รหัสผ่านมากกว่าหรือเท่ากับ 8 ตัว
                                </div>
                                <div
                                    className={
                                        hint_text ?
                                            'text-success'
                                            :
                                            'text-normal'
                                    }
                                >
                                    {hint_text &&
                                    <Icon
                                        name="check circle outline"
                                        color={'green'}
                                        className={'icon-success'}
                                    />
                                    }
                                    ต้องมีตัวอักษรทั้งตัวพิมเล็กและตัวพิมใหญ่

                                </div>
                                <div
                                    className={
                                        hint_number ?
                                            'text-success'
                                            :
                                            'text-normal'
                                    }
                                >
                                    {hint_number &&
                                    <Icon name="check circle outline"
                                          color={'green'}
                                          className={'icon-success'}
                                    />
                                    }
                                    ต้องมีตัวเลขอย่างน้อย 1 ตัว

                                </div>
                                <div
                                    className={
                                        hint_vip ?
                                            'text-success'
                                            :
                                            'text-normal'
                                    }
                                >
                                    {hint_vip &&
                                    <Icon
                                        name="check circle outline"
                                        color={'green'}
                                        className={'icon-success'}
                                    />
                                    }
                                    ห้ามมีอักขระพิเศษ

                                </div>
                            </div>
                        }

                    </div>
                </div>

                {/*ยืนยันรหัสผ่านใหม่*/}
                <div className={'container-input'}>
                    <label>ยืนยันรหัสผ่านใหม่</label>
                    <div className={'container-input-password'}>
                        <div
                            className={error_confirm_new_password || unmatch_new_password ? 'border-input-password-error' : 'border-input-password'}>
                            <input
                                className={error_confirm_new_password || unmatch_new_password ? 'input-password-error' : 'input-password'}
                                placeholder='กรอกรหัสผ่านใหม่อีกครั้ง'
                                type={see_confirm_new_password ? 'text' : 'password'}
                                name='confirm_new_password'
                                value={confirm_new_password}
                                onChange={(event) => {
                                    let {name, value} = event.target
                                    handleChange(name, value)
                                }}
                            />
                            <button
                                className={'see-button'}
                                onClick={() => {
                                    set_see_confirm_new_password(!see_confirm_new_password)
                                }}
                            >
                                <Icon name={see_confirm_new_password ? 'eye slash' : 'eye'}/>
                            </button>
                        </div>
                        {unmatch_new_password &&
                        <span className={'input-detail-error'}>รหัสผ่านใหม่ไม่ตรงกัน</span>}
                        {error_confirm_new_password &&
                        <span className={'input-detail-error'}>กรุณากรอกยืนยันรหัสผ่านใหม่</span>}

                    </div>
                </div>


                {/*ปุ่มบันทึก / ยกเลิก*/}
                <div className={'button-action'}>

                    {/*ปุ่มยกเลิก*/}
                    <a onClick={() => {
                        onClose()
                        setInitialState()
                    }}>ยกเลิก</a>

                    {/*ปุ่มบันทึก*/}
                    <button
                        onClick={() => {
                            savePassword();
                        }}
                        className={'button-save-password'}
                    >บันทึก
                    </button>

                </div>

            </Modal.Content>
        </Modal>
    )
}


