import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';

export const DropdownAddMember = ({
	field: { name, value },
	form: { touched, errors, setFieldValue },
	options,
	children: _,
	...props
}) => {
	return (
		<div>
			{/* Dropdown */}
			<Dropdown
				selection
				options={options}
				value={value}
				onChange={(_, { value, ...data }) => {
					if (name === 'community_id') {
						const { area } = data.options.find((o) => o.value === value);
						setFieldValue(name, value);
						setFieldValue('area_id', area);
					} else {
						setFieldValue(name, value);
					}
				}}
				{...props}
			/>
		</div>
	);
};
export default DropdownAddMember;
