import React, { useState, useEffect } from "react";
import './DetailMakerHouse.css'
import { Button, Header, Icon, Image, Modal, Grid } from 'semantic-ui-react'
import { Link, withRouter } from "react-router-dom";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import DataHouse from '../../ApproveDataScreen/DataHouse'
import MemberHouse from "../../ApproveDataScreen/MemberHouse";
import ApproveData from "../../ApproveDataScreen/ApproveData"
import axios from "axios";
import Config from "../../../constants/Config";
import FormatContainer from "../../../components/FormatContainer";
import StatusHouse from "../StatusHouse";
import Loader from "../../../components/RouteLoader/Loader";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import SurveyData from "../../ApproveDataScreen/SurveyData";
import Question from "../../ApproveDataScreen/Question";
import LocationHouse from "../../ApproveDataScreen/LocationHouse";
import { useHistory } from "react-router-dom";


const DetailMakerHouse = ({ visible, onClose, houseId, ...props }) => {

    const [house_id, set_house_id] = useState("")
    const [loading, set_loading] = useState(false)
    const [close_modal, set_close_modal] = useState(false)
    const [household, set_household] = useState({})
    const [house_data, set_house_data] = useState({})
    const [member_data, set_member_data] = useState([])
    const [approve_data, set_approve_data] = useState([])
    const [status_house_data, set_status_house_data] = useState([])

    const history = useHistory()


    const connectApi = async (id) => {
        try {
            set_loading(true)
            let response = await axios.get(
                `${Config.url}/api/v1/house_data/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json',
                    }
                }
            )

            set_loading(false)
            if (response.data.value) {
                set_household(response.data.value)
                set_status_house_data(response.data.value.status)
                set_house_data(response.data.value.data)
                set_member_data(response.data.value.data.members)
                set_approve_data(response.data.value.approve_and_reject ? response.data.value.approve_and_reject : [])
                console.log(response)
            } else {
                set_house_data({})
            }
        } catch (e) {
            console.log("error", e)
            Swal.fire({
                title: 'แจ้งเตือน',
                text: 'พบข้อผิดพลาดในการดำเนินการ กรุณาลองใหม่อีกครั้ง',
                icon: 'warning',
                confirmButtonText: 'ตกลง',
            }).then(() => {
                onClose()
            })
        }
    }

    useEffect(() => {
        set_house_id(houseId)
        connectApi(houseId)
    }, [])


    return (
        <Rodal
            className={'modal-slide'}
            visible={visible}
            onClose={onClose}
            enterAnimation={'slideRight'}
            leaveAnimation={'slideRight'}
            customStyles={{
                position: 'absolute',
                width: '75%',
                height: '100%',
                right: '0',
                top: '0',
                bottom: '0',
                left: 'unset',
                padding: '0px',
            }}
            customMaskStyles={{
                cursor: 'pointer',
                background: 'rgba(0, 0, 0, 0.6)',
            }}
        >

            <div className={'detail-maker-house'}>

                <Grid>
                    <Grid.Row>
                        <button
                            className={'button-back'}
                            onClick={onClose}
                        >
                            <Icon className={'button-back-color'} name={'angle left'}/>
                            <span className={'button-back-color'}>ย้อนกลับ</span>
                        </button>
                    </Grid.Row>

                    {loading ?
                        <div style={{ width: '100%' }}>
                            <Loader/>
                        </div>
                        :
                        household.status === "new" ?
                            <Grid.Row>
                                <Grid.Column
                                    mobile={16}
                                    tablet={16}
                                    computer={16}
                                    largeScreen={16}
                                >
                                    <div className={'subcontant-container'}>
                                        <FormatContainer
                                            icon={'marker'}
                                            title={'ตำแหน่งที่ตั้ง'}
                                        >

                                            <button
                                                className={'edit-location'}
                                                onClick={() => {
                                                    history.push(`listhousehold/editmarker`, { household_id: household._id })
                                                }}
                                            >
                                                <Icon
                                                    name={"map marker alternate"}
                                                />
                                                แก้ไขข้อมูลแผนที่
                                            </button>

                                        </FormatContainer>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            :
                            <Grid.Row>

                                {/*ข้อมูลสถานะบ้าน*/}
                                <Grid.Column
                                    mobile={16}
                                    tablet={16}
                                    computer={6}
                                    largeScreen={6}
                                >


                                    <div className={'survey-person-detail'}>
                                        <FormatContainer
                                            icon={'user'}
                                            title={'ข้อมูลผู้สำรวจ'}
                                        >
                                            <SurveyData
                                                item={household}
                                            />
                                        </FormatContainer>
                                    </div>

                                    {/*สถานะบ้าน*/}
                                    <div className={'approve-subcontant-container'}>
                                        <FormatContainer
                                            icon={'home'}
                                            title={'ข้อมูลสถานะบ้าน'}
                                        >

                                            <StatusHouse
                                                status={status_house_data}
                                            />

                                        </FormatContainer>
                                    </div>
                                </Grid.Column>

                                {/*ข้อมูลบ้าน*/}
                                <Grid.Column
                                    mobile={16}
                                    tablet={16}
                                    computer={10}
                                    largeScreen={10}
                                >
                                    <div className={'subcontant-container'}>
                                        <FormatContainer
                                            icon={'home'}
                                            title={'ข้อมูลบ้าน'}
                                            style={{ height: '100%' }}
                                        >

                                            {/*Component ข้อมูลบ้าน*/}
                                            <DataHouse
                                                item={house_data}
                                            />

                                        </FormatContainer>
                                    </div>
                                </Grid.Column>

                                <Grid.Column
                                    mobile={16}
                                    tablet={16}
                                    computer={16}
                                    largeScreen={16}
                                >
                                    <div className={'subcontant-container'}>
                                        <FormatContainer
                                            icon={'marker'}
                                            title={'ตำแหน่งที่ตั้ง'}
                                        >

                                            <button
                                                className={'edit-location'}
                                                onClick={() => {
                                                    history.push(`listhousehold/editmarker`, { household_id: household._id })
                                                }}
                                            >
                                                <Icon
                                                    name={"map marker alternate"}
                                                />
                                                แก้ไขข้อมูลแผนที่
                                            </button>

                                        </FormatContainer>
                                    </div>
                                </Grid.Column>

                                {/*ข้อมูลสมาชิก*/}
                                {member_data.length === 0 &&
                                <Grid.Column
                                    mobile={16}
                                    tablet={16}
                                    computer={16}
                                    largeScreen={16}
                                >
                                    <div className={'subcontant-container'}>
                                        <FormatContainer
                                            icon={'users'}
                                            title={'ข้อมูลสมาชิก'}
                                        >

                                            {/*Component ข้อมูลสมาชิก*/}
                                            <MemberHouse
                                                members={member_data}
                                            />
                                            {member_data.length === 0 &&
                                            <div style={{ textAlign: 'center', marginTop: '-24px' }}>ไม่พบข้อมูล</div>}


                                        </FormatContainer>
                                    </div>
                                </Grid.Column>
                                }

                                {/*ข้อมูลแบบสอบถาม*/}
                                {household.data && household.data.jpt_survey &&
                                <Grid.Column
                                    mobile={16}
                                    tablet={16}
                                    computer={16}
                                    largeScreen={16}
                                >
                                    <div className={'approve-subcontant-container'}>

                                        {/*Component container หัวข้อ*/}
                                        <FormatContainer
                                            icon={'file alternate'}
                                            title={'ข้อมูลแบบสอบถาม'}
                                        >

                                            {/*Component ข้อมูลแบบสอบถาม*/}
                                            <Question data={!!household.data && household.data.jpt_survey}/>
                                        </FormatContainer>
                                    </div>

                                </Grid.Column>}

                                {/*ข้อมูลการอนุมัติ*/}
                                <Grid.Column
                                    mobile={16}
                                    tablet={16}
                                    computer={16}
                                    largeScreen={16}
                                >
                                    <div className={'subcontant-container'}>
                                        <FormatContainer
                                            icon={'check circle'}
                                            title={'ข้อมูลการอนุมัติ'}
                                        >
                                            {/*Component ข้อมูลการอนุมัติ*/}
                                            {
                                                approve_data ?

                                                    <ApproveData
                                                        approveDetail={approve_data}
                                                    />
                                                    :
                                                    <div style={{ textAlign: 'center' }}>รอการตรวจสอบ</div>
                                            }

                                        </FormatContainer>
                                    </div>
                                </Grid.Column>

                                {/*เงื่อนไขแสดงปุ่มหน้าอนุมัติข้อมูล*/}
                                {status_house_data === 'complete' &&

                                // ปุ่มไปยังหน้าอนุมัติข้อมูล
                                <Grid.Column
                                    mobile={16}
                                    tablet={16}
                                    computer={16}
                                    largeScreen={16}
                                >
                                    <div className={'button-subcontant-container'}>

                                        <Link
                                            to={{
                                                pathname: '/administrator/approve/detail',
                                                hash: "#the-hash",
                                                state: { mark_id: house_id }
                                            }}
                                            className={'button-next-page'}
                                            type='button'
                                        >
                                <span className={'icon-button'}>
                                <Icon name={'arrow alternate circle left'}/>
                                </span>
                                            ไปยัง หน้าข้อมูลครัวเรือน
                                        </Link>

                                    </div>
                                </Grid.Column>
                                }
                            </Grid.Row>

                    }


                </Grid>
            </div>

        </Rodal>
    )
}

export default DetailMakerHouse
