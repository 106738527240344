import React from "react";

export default (props) => {
    return(
        <div className={'question-sub-topic'}>
            <div className={'no'}>{props.no}</div>
            <div className={'content'}>
                <div className={'block'}>
                    <div className={'title'}>คำถาม </div>
                    <div className={'question'}>: {props.questionName}</div>
                </div>
                <div className={'block'}>
                    <div className={'title'}>คำตอบ </div>
                    <div style={{marginRight:'5px'}}>:</div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}
