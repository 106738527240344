import React, { Fragment } from 'react';
import { Grid, Input, Icon } from 'semantic-ui-react';
import InputMask from 'react-input-mask';
import './AddMember.css';
import { DropdownAddMember } from './DropdownAddMember';
import { RadioButton } from './RadioAddMember';
import { Field } from 'formik';
import UploadImgAndCrop from './UploadImgAndCrop';
import BirthDateAddMember from './BirthDateAddMember';

/* Options Prefix */
const prefix = [
    {
        key: 'นาย',
        text: 'นาย',
        value: 'นาย'
    },
    {
        key: 'นาง',
        text: 'นาง',
        value: 'นาง'
    },
    {
        key: 'นางสาว',
        text: 'นางสาว',
        value: 'นางสาว'
    }
];

/* Type Permission */
const admin = '5e71d0542e86f6aca55b90ea'
const surveyPerson = '5e71d1d12e86f6aca55b90eb'

const UserDetail = (props) => (
    <Fragment>
        <Grid>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                    {/* Heading */}
                    <div className='heading'>
                        <h3>ข้อมูลผู้ใช้งาน</h3>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>

                {/*CropImage*/}
                <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={5}
                    className='display-center'
                >
                    <div className='set-margin-left-bottom'>
                        <Field name='img_profile' component={UploadImgAndCrop} fluid/>
                    </div>
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={10}>
                    <Grid>
                        {/* prefix */}
                        <Grid.Row className='set-padding-bottom'>
                            <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                <label>คำนำหน้า<span style={{ color: 'red' }}>*</span></label>
                            </Grid.Column>
                            <Grid.Column mobile={10} tablet={10} computer={11}>
                                <Field
                                    placeholder='เลือกคำนำหน้า'
                                    options={prefix}
                                    name='prefix'
                                    component={DropdownAddMember}
                                    fluid
                                    onBlur={props.handleBlur}
                                    className={
                                        props.errors.prefix && props.touched.prefix && props.errors.prefix ? (
                                            'error'
                                        ) : (
                                            props.values.prefix && 'success'
                                        )
                                    }
                                />
                                {props.errors.prefix && props.touched.prefix && props.errors.prefix && (
                                    <span className='set-color-red'>{props.errors.prefix}</span>
                                )}
                            </Grid.Column>
                        </Grid.Row>

                        {/* first_name */}
                        <Grid.Row className='set-padding-bottom'>
                            <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                <label>ชื่อ<span style={{ color: 'red' }}>*</span></label>
                            </Grid.Column>
                            <Grid.Column mobile={10} tablet={10} computer={11}>
                                <Input
                                    values={props.values}
                                    type='text'
                                    name='first_name'
                                    placeholder='กรอกชื่อ'
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.first_name}
                                    className={
                                        props.errors.first_name &&
                                        props.touched.first_name &&
                                        props.errors.first_name ? (
                                            'error'
                                        ) : (
                                            props.values.first_name && 'success'
                                        )
                                    }
                                    fluid
                                />
                                {props.errors.first_name && props.touched.first_name && props.errors.first_name && (
                                    <span className='set-color-red'>{props.errors.first_name}</span>
                                )}
                            </Grid.Column>
                        </Grid.Row>

                        {/* last_name */}
                        <Grid.Row className='set-padding-bottom'>
                            <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                <label>สกุล<span style={{ color: 'red' }}>*</span></label>
                            </Grid.Column>
                            <Grid.Column mobile={10} tablet={10} computer={11}>
                                <Input
                                    values={props.values}
                                    type='text'
                                    name='last_name'
                                    placeholder='กรอกสกุล'
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.last_name}
                                    fluid
                                    className={
                                        props.errors.last_name && props.touched.last_name && props.errors.last_name ? (
                                            'error'
                                        ) : (
                                            props.values.last_name && 'success'
                                        )
                                    }
                                />
                                {
                                    props.errors.last_name && props.touched.last_name && props.errors.last_name &&
                                    <span className='set-color-red'>{props.errors.last_name}</span>
                                }
                            </Grid.Column>
                        </Grid.Row>

                        {/* birth_date_format */}
                        <Grid.Row className='set-padding-bottom'>
                            <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                <label>วัน/ เดือน/ ปี(ค.ศ.) เกิด<span style={{ color: 'red' }}>*</span></label>
                            </Grid.Column>
                            <Grid.Column mobile={10} tablet={10} computer={11}>
                                <Field
                                    component={BirthDateAddMember}
                                    values={props.values}
                                    name='birth_date_format'
                                    value={props.values.birth_date_format}
                                />
                                {props.errors.birth_date_format &&
                                props.touched.birth_date_format &&
                                props.errors.birth_date_format && (
                                    <span className='set-color-red'>{props.errors.birth_date_format}</span>
                                )}
                            </Grid.Column>
                        </Grid.Row>

                        {/* id_card */}
                        <Grid.Row className='set-padding-bottom'>
                            <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                <label>เลขประจำตัวประชาชน<span style={{ color: 'red' }}>*</span></label>
                            </Grid.Column>
                            <Grid.Column mobile={10} tablet={10} computer={11}>
                                <InputMask
                                    placeholder='กรอกเลขประจำตัวประชาชน'
                                    fluid
                                    mask='9-9999-99999-99-9'
                                    values={props.values}
                                    name='id_card'
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.id_card}
                                    className={
                                        props.errors.id_card && props.touched.id_card && props.errors.id_card ? (
                                            'error'
                                        ) : (
                                            props.values.id_card && 'success'
                                        )
                                    }
                                >
                                    {(inputProps) => <Input {...inputProps} type='tel'/>}
                                </InputMask>
                                {props.errors.id_card && props.touched.id_card && props.errors.id_card && (
                                    <span className='set-color-red'>{props.errors.id_card}</span>
                                )}
                            </Grid.Column>
                        </Grid.Row>

                        {/* phone_number */}
                        <Grid.Row className='set-padding-bottom'>
                            <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                <label>หมายเลขโทรศัพท์<span style={{ color: 'red' }}>*</span></label>
                            </Grid.Column>
                            <Grid.Column mobile={10} tablet={10} computer={11}>
                                <Input
                                    maxLength={10}
                                    placeholder='กรอกหมายเลขโทรศัพท์'
                                    values={props.values}
                                    type='text'
                                    name='phone_number'
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.phone_number}
                                    fluid
                                    className={
                                        props.errors.phone_number &&
                                        props.touched.phone_number &&
                                        props.errors.phone_number ? (
                                            'error'
                                        ) : (
                                            props.values.phone_number && 'success'
                                        )
                                    }
                                />
                                {props.errors.phone_number &&
                                props.touched.phone_number &&
                                props.errors.phone_number && (
                                    <span className='set-color-red'>{props.errors.phone_number}</span>
                                )}
                            </Grid.Column>
                        </Grid.Row>

                        {/* permission */}
                        <Grid.Row className='set-padding-bottom'>
                            <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                <label>ประเภทผู้ใช้งาน<span style={{ color: 'red' }}>*</span></label>
                            </Grid.Column>
                            <Grid.Column mobile={10} tablet={10} computer={11}>
                                <Grid>
                                    <Grid.Row>
                                        {props.choicePermission.map((item, index) => {
                                            // console.log('item',item)
                                            return (
                                                <Grid.Column mobile={16} tablet={8} computer={8} key={index}>
                                                    <label
                                                        value={item._id}
                                                        className={
                                                            props.errors.permission_id &&
                                                            props.touched.permission_id &&
                                                            props.errors.permission_id ? (
                                                                'labelRadioError'
                                                            ) : props.values.permission_id === item._id ? (
                                                                'labelRadioSuccess'
                                                            ) : (
                                                                'labelRadio'
                                                            )
                                                        }
                                                    >
                                                        <div style={{ justifyContent: 'flex-start' }}>
                                                            <Field component={RadioButton} name='permission_id'
                                                                   id={item._id}/>
                                                        </div>
                                                        <div style={{
                                                            justifyContent: 'center',
                                                            margin: 'auto',
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: 'center'
                                                        }}>
                                                            <Icon
                                                                name='male'
                                                                size={'huge'}
                                                            />
                                                            <br/>
                                                            <span>{item._id === '5e71d0542e86f6aca55b90ea' ? 'ผู้ดูแลศูนย์ฯ' : 'ผู้สำรวจ'}</span>
                                                        </div>
                                                    </label>
                                                    {props.errors.permission_id &&
                                                    props.touched.permission_id &&
                                                    props.errors.permission_id && (
                                                        <span
                                                            className='set-color-red'>{props.errors.permission_id}</span>
                                                    )}
                                                </Grid.Column>
                                            );
                                        })}
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>

                        {/* Permission Type Admin and surveyPerson */}
                        {props.values.permission_id === admin ? (
                            <Grid.Row className='set-padding-bottom'>
                                <Grid.Column
                                    mobile={6}
                                    tablet={6}
                                    computer={5}
                                    className='displayFlexEnd'
                                >
                                    <label>ศูนย์ฯที่รับผิดชอบ<span style={{ color: 'red' }}>*</span></label>
                                </Grid.Column>

                                <Grid.Column mobile={10} tablet={10} computer={11}>
                                    <Field
                                        placeholder='เลือกศูนย์ที่รับผิดชอบ'
                                        options={
                                            props.choiceArea?.length > 0 ?
                                                props.choiceArea.map((item) => {
                                                    return {
                                                        key: item._id,
                                                        text: item.name,
                                                        value: item._id
                                                    };
                                                })
                                                :
                                                []
                                        }
                                        name='area_id'
                                        component={DropdownAddMember}
                                        fluid
                                        onBlur={props.handleBlur}
                                        className={
                                            props.errors.area_id && props.touched.area_id && props.errors.area_id ? (
                                                'error'
                                            ) : (
                                                props.values.area_id && 'success'
                                            )
                                        }
                                    />
                                    {props.errors.area_id && props.touched.area_id && props.errors.area_id && (
                                        <span className='set-color-red'>{props.errors.area_id}</span>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        ) : props.values.permission_id === surveyPerson ? (
                            <Grid.Row className='set-padding-bottom'>
                                <Grid.Column
                                    mobile={6}
                                    tablet={6}
                                    computer={5}
                                    className='displayFlexEnd'
                                >
                                    <label>ชุมชนที่รับผิดชอบ<span style={{ color: 'red' }}>*</span></label>
                                </Grid.Column>
                                <Grid.Column mobile={10} tablet={10} computer={11}>
                                    <Field
                                        placeholder='เลือกชุมชนที่รับผิดชอบ'
                                        options={
                                            props.choiceCommunity?.length > 0 ?
                                                props.choiceCommunity.map((item) => {
                                                    return {
                                                        key: item._id,
                                                        text: item.name,
                                                        value: item._id,
                                                        area: item.area_id
                                                    };
                                                })
                                                :
                                                []
                                        }
                                        // options={community}
                                        name='community_id'
                                        component={DropdownAddMember}
                                        fluid
                                        onBlur={props.handleBlur}
                                        className={
                                            props.errors.community_id &&
                                            props.touched.community_id &&
                                            props.errors.community_id ? (
                                                'error'
                                            ) : (
                                                props.values.community_id && 'success'
                                            )
                                        }
                                    />
                                    {props.errors.community_id &&
                                    props.touched.community_id &&
                                    props.errors.community_id && (
                                        <span className='set-color-red'>{props.errors.community_id}</span>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        ) : null}
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Fragment>
);
export default UserDetail;
