import React, {useState} from "react"
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import AsideMenu from '../../components/AsideMenu';
import TopBar from '../../components/TopBar';
import RouteLoader from '../../components/RouteLoader';
import Page403 from "../../screen/PageError/Page403";
import Page404 from "../../screen/PageError/Page404";
import EditMember from '../../screen/MemberScreen/EditMember'
import DetailMember from '../../screen/MemberScreen/DetailMember'
import DetailProfile from '../../screen/ProfileScreen/DetailProfile'
import EditProfile from '../../screen/ProfileScreen/EditProfile'
import MemberListScreen from '../../screen/MemberScreen/MemberListScreen';
import AddMember from '../../screen/MemberScreen/AddMember';
import ApproveScreen from '../../screen/ApproveScreen/ApproveScreen';
import ApproveDataScreen from '../../screen/ApproveDataScreen';
import SettingScreen from '../../screen/SettingScreen'
import SettingApp from  "../../screen/SettingScreen/SettingApp"
import MapScreen from "../../screen/MapScreen"
import ListHouseHoldScreen from "../../screen/ListHouseHoldScreen"
import HistoryUserScreen from '../../screen/HistoryUserScreen'
import Logo from "../../assets/img/khonkean.png";
import DashboardScreen from "../../screen/DashboardScreen/index"
import EditMakersScreen from "../../screen/EditMakerScreen"
export default (props) => {
    return (
        <div>
            <AsideMenu
                path={props.match.path}
                menu={[
                    {
                        path:"/home",
                        name:"หน้าแรก",
                        icon:"home"
                    },
                    {
                        path:"/memberList",
                        name:"จัดการผู้ใช้งาน",
                        icon:"users"
                    },

                    {
                        path:"/map",
                        name:"พื้นที่สำรวจ",
                        icon:"map marker alternate"
                    },
                    {
                        path:"/approve",
                        name:"อนุมัติข้อมูลครัวเรือน",
                        icon:"check circle"
                    },
                    {
                        path:"/listhousehold",
                        name:"ข้อมูลครัวเรือนทั้งหมด",
                        icon:"file alternate"
                    },
                    {
                        path:"/history",
                        name:"ประวัติการดำเนินการ",
                        icon:"clock"
                    },
                    {
                        path:"/setting",
                        name:"ตั้งค่าระบบ",
                        icon:"setting"
                    },
                    // {
                    //     path:"/manual",
                    //     name:"คู่มือการใช้งาน",
                    //     icon:"warning circle"
                    // },

                ]}
            />

            <TopBar/>

            <div className={"main-container"}>
                <Switch>
                    <RouteLoader permission={'AdminCentral'} exact path={`${props.match.path}/home`} component={DashboardScreen}/>
                    <RouteLoader permission={'AdminCentral'} exact path={`${props.match.path}/approve`} component={ApproveScreen}/>
                    <RouteLoader permission={'AdminCentral'} exact path={`${props.match.path}/memberList`} component={MemberListScreen}/>
                    <RouteLoader permission={'AdminCentral'} exact path={`${props.match.path}/listhousehold`} component={ListHouseHoldScreen}/>
                    <RouteLoader permission={'AdminCentral'} exact path={`${props.match.path}/listhousehold/editmarker`} component={EditMakersScreen}/>
                    <RouteLoader permission={'AdminCentral'} exact path={`${props.match.path}/history`} component={HistoryUserScreen}/>
                    <RouteLoader permission={'AdminCentral'} exact path={`${props.match.path}/setting`} component={SettingScreen}/>
                    <RouteLoader permission={'AdminCentral'} exact path={`${props.match.path}/manual`} component={Manual}/>
                    <RouteLoader permission={'AdminCentral'} exact path={`${props.match.path}/editprofile`} component={EditProfile}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/profile`} component={DetailProfile}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/approve/detail`} component={ApproveDataScreen}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/approve/listhousehold/editmarker`} component={EditMakersScreen}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/users2`} component={Users}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/users3`} component={Users}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/addMember`} component={AddMember}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/editMember`} component={EditMember}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/map`} component={MapScreen}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/detailMember`} component={DetailMember}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/setting/religion`} component={SettingApp}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/setting/education`} component={SettingApp}/>
                    <RouteLoader permission={'AdminCentral'} path={`${props.match.path}/setting/occupation`} component={SettingApp}/>
                    <Route exact path={`${props.match.path}`} render={(props) => (
                        <Redirect to={`${props.match.path}/home`} />
                    )}/>
                    <Route render={(props) => (
                        <Page403 {...props} mainPath={`${props.match.path}`}/>
                    )}/>
                    <Route render={(props) => (
                        <Page404 {...props} mainPath={`${props.match.path}`}/>
                    )}/>
                </Switch>
            </div>
        </div>
    );
}

function Home() {
    return (
        <div style={{
            height: 'calc(100vh - 180px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        }}>
            <img style={{width: '200px', height: '200px'}} src={Logo}/>
            <h1 style={{
                fontFamily: 'Kanit , sans-serif',
                fontSize: '48px'
            }}>เทศบาลนครขอนแก่น</h1>
            {/*<RouteLoader />*/}
        </div>
    )
}

function About() {
    return <h2>About</h2>;
}

function Users() {
    return <h2>Users</h2>;
}


function Manual() {
    return <h2 style={{margin:'20px'}}>คู่มือการใช้งาน</h2>;
}
