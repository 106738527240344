import React, {Component} from "react";
import {Doughnut, Chart} from "react-chartjs-2";
import {Icon} from "semantic-ui-react";
import "./pieStyle.css";

export default class DonutDash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            backgroundColor: [
                "#5da5da",
                "#faa43a",
                "#60bd68",
                "#f17cb0",
                "#b2912f",
                "#b276b2",
                "#decf3f",
                "#f15854",
                "#1aaaa5",
                "#06467c",
                "#b5bd32",
                "#994f9c",
                "#f26130",
                "#1690c3",
                "#ca3471",
                "#20d3cd",
                "#0860aa",
                "#d7e03b",
                "#c767cb",
                "#ff984c",
                "#00abeb",
                "#ef3e86",
                "#3388bd",
                "#66c2bd",
                "#96d98d",
                "#fedf8b",
                "#fdaf61",
                "#f46c43",
                "#d53e50",
                "#aadc53"

            ],
            hoverBackgroundColor: [
                "#63b2eb",
                "#fab32f",
                "#6ad473",
                "#f571ab",
                "#c7a234",
                "#c983c9",
                "#eddc40",
                "#ff554f",
                "#1dbfba",
                "#06467c",
                "#075391",
                "#994f9c",
                "#b25bb5",
                "#179bd4",
                "#db3779",
                "#22e3dd",
                "#086ec2",
                "#e9f23f",
                "#da6fde",
                "#fc8f42",
                "#02b6f7",
                "#fc3f8b",
                "#3694cf",
                "#6fd6d1",
                "#a2ed98",
                "#f7d67c",
                "#faa755",
                "#fa683c",
                "#ed4256",
                "#b9f059"
            ],
        };
        this.chartReference = React.createRef();
    }

    render() {


        const options = {
            responsive: false,
            legend: {
                display: false,
            },

            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 20,
                    bottom: 0,
                },
            },
            tooltips: {
                enabled: false,
                custom: function (tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table style="width: 100%"></table>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        var titleLines = tooltipModel.title || [];
                        var bodyLines = tooltipModel.body.map(getBody);

                        var innerHtml = '<thead style="display:flex;flex-direction: column; align-items: center;color: #0b4a84">';

                        titleLines.forEach(function (title) {
                            innerHtml += '<tr><th>' + title + '</th></tr>';
                        });
                        innerHtml += '</thead><tbody>';

                        bodyLines.forEach(function (body, i) {
                            var colors = tooltipModel.labelColors[i];
                            var style = 'background:' + colors.backgroundColor;
                            style += '; border-color:' + colors.borderColor;
                            style += '; border-width: 2px; ';
                            var styleTr = 'display:flex;justify-content: center;color: ' + colors.backgroundColor + ';font-size: 18px'
                            var span = '<span style="' + style + '"></span>';
                            innerHtml += '<tr style="' + styleTr + '"><td>' + span + body + '</td></tr>';
                        });
                        innerHtml += '</tbody>';

                        var tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;
                    }

                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.display = 'flex';
                    tooltipEl.style.justifyContent = 'center';
                    tooltipEl.style.alignItems = 'center';
                    tooltipEl.style.left = position.left + 115 + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + 85 + 'px';
                    tooltipEl.style.width = '70px';
                    tooltipEl.style.height = '80px';
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.pointerEvents = 'none';
                },

                callbacks: {
                    label: function (tooltipItem, data) {
                        var dataValue = (data.datasets[0].data[tooltipItem.index])
                        return dataValue
                    },
                    title: function (tooltipItem, data) {
                        var dataLabel = data.labels[tooltipItem[0].index]
                        return dataLabel
                    },
                }
            },
        };

        //Get raw data from props
        const rawData = this.props.data;
        const labels = [];
        const dataAmount = [];
        const backgroundColor = [];
        const hoverBackgroundColor = [];
        for (let i = 0; i < rawData.length; i++) {
            const element = rawData[i];
            labels.push(element.label);
            dataAmount.push(element.data);
            backgroundColor.push(this.state.backgroundColor[i]);
            hoverBackgroundColor.push(this.state.hoverBackgroundColor[i]);
        }

        //raw data to data
        const data =
            () => {
                Chart.pluginService.register({
                    beforeDraw: function (chart) {
                        if (chart.config.options.elements.center) {
                            // Get ctx from string
                            var ctx = chart.chart.ctx;

                            // Get options from the center object in options
                            var centerConfig = chart.config.options.elements.center;
                            var fontStyle = centerConfig.fontStyle || 'Arial';
                            var txt = centerConfig.text;
                            var color = centerConfig.color || '#000';
                            var maxFontSize = centerConfig.maxFontSize || 75;
                            var sidePadding = centerConfig.sidePadding || 20;
                            var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                            // Start with a base font of 30px
                            ctx.font = "30px " + fontStyle;

                            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                            var stringWidth = ctx.measureText(txt).width;
                            var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                            // Find out how much the font can grow in width.
                            var widthRatio = elementWidth / stringWidth;
                            var newFontSize = Math.floor(30 * widthRatio);
                            var elementHeight = (chart.innerRadius * 2);

                            // Pick a new font size so it will not be larger than the height of label.
                            var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                            var minFontSize = centerConfig.minFontSize;
                            var lineHeight = centerConfig.lineHeight || 25;
                            var wrapText = false;

                            if (minFontSize === undefined) {
                                minFontSize = 20;
                            }

                            if (minFontSize && fontSizeToUse < minFontSize) {
                                fontSizeToUse = minFontSize;
                                wrapText = true;
                            }

                            // Set font settings to draw it correctly.
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                            var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                            ctx.font = fontSizeToUse + "px " + fontStyle;
                            ctx.fillStyle = color;

                            if (!wrapText) {
                                ctx.fillText(txt, centerX, centerY);
                                return;
                            }

                            var words = txt.split(' ');
                            var line = '';
                            var lines = [];

                            // Break words up into multiple lines if necessary
                            for (var n = 0; n < words.length; n++) {
                                var testLine = line + words[n] + ' ';
                                var metrics = ctx.measureText(testLine);
                                var testWidth = metrics.width;
                                if (testWidth > elementWidth && n > 0) {
                                    lines.push(line);
                                    line = words[n] + ' ';
                                } else {
                                    line = testLine;
                                }
                            }

                            // Move the center up depending on line height and number of lines
                            centerY -= (lines.length / 2) * lineHeight;

                            for (var n = 0; n < lines.length; n++) {
                                ctx.fillText(lines[n], centerX, centerY);
                                centerY += lineHeight;
                            }


                            //Draw text in center
                            ctx.fillText(line, centerX, centerY);
                        }
                    }
                });
                return {
                    labels: labels,
                    datasets: [
                        {
                            data: dataAmount,
                            backgroundColor: backgroundColor,
                            hoverBackgroundColor: hoverBackgroundColor,
                        },
                    ],
                }

            };

        //find sum of amount in data
        const sumAmount = dataAmount.reduce((x, y) => x + y, 0);

        //map label of data
        const mapData = rawData.map((data) => {

            //find percent
            let perc = ((data.data / sumAmount) * 100);
            perc = perc.toFixed(2)
            return (
                <div className="content-desc" key={data.id}>
                    <div className="text-desc">
                        <div
                            className="point-date"
                            style={{backgroundColor: `${this.state.backgroundColor[data.id]}`}}
                        />
                        <p>{data.label}</p>
                    </div>
                    <div className="text-num">
                        <p>{data.data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {this.props.unit}</p>
                        <p style={{color: `${data.backgroundColor}`, fontWeight: "900"}}>
                            {perc}%
                        </p>
                    </div>

                </div>
            );
        });
        return (
            <div className="donut-container">
                <div className="head-pie">
                    <Icon name={this.props.img} size="big"/>
                    <p>{this.props.header}</p>
                </div>
                <div className={'wrapper-donut'}>

                    <Doughnut id={'PieChart'} data={data} options={options} height={this.props.height}
                              ref={this.chartReference}/>
                </div>
                <div className="data-description">{mapData}</div>
            </div>
        );
    }
}
