import React, {Component} from 'react';
import { Bar } from "react-chartjs-2";
import { Icon } from "semantic-ui-react";
import Smoke from "./smoke.svg"
import PigSaving from "./saving.svg"
import PayMoney from "./money.svg"
import Beer from "./beer.svg"
import './barStyle.css'
class BarAlone extends Component {
constructor(props) {
    super(props);
    this.state = {
        data:{},
        rawData : [],
        rawLabel:[],
        imgIcon:""
    }
}
componentDidMount() {
    let data = {};
    let rawData =[];
    let rawLabel =[];
    let imgIcon;
    if(this.props.data && this.props.labels){
        rawData = this.props.data
        rawLabel = this.props.labels
        data = {
            labels: rawLabel,
            datasets: [
                {
                    backgroundColor: 'rgba(51,136,189,0.8)',
                    borderColor: 'rgba(51,136,189,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(58, 147, 207,0.8)',
                    hoverBorderColor: 'rgba(58, 147, 207,1)',
                    data: rawData
                }
            ]
        };
    }
    if (this.props.img ==="smoking") {
        imgIcon = <img src={Smoke} alt="React Logo" className="baralone-icon"/>
    }
    else if(this.props.img ==="pig-saving"){
        imgIcon = <img src={PigSaving} alt="React Logo" className="baralone-icon"/>
    }
    else if(this.props.img ==="pay-money"){
        imgIcon = <img src={PayMoney} alt="React Logo" className="baralone-icon"/>
    }
    else if(this.props.img ==="icon-beer"){
        imgIcon = <img src={Beer} alt="React Logo" className="baralone-icon"/>
    }
    else {
        imgIcon = <Icon
            className="baralone-icon"
            name={this.props.img}
            size="large"
            style={{ color: `${this.props.color}` }}
        />
    }
    this.setState({
        data : data,
        imgIcon:imgIcon
    })
}

    render() {

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 10,
                    bottom: 0
                }
            }
        };
        return (
            <div className={'baralone-container'}>
                <div className={'header-baralone'}>
                    {/*<Icon className={'header-icon'} name={'money bill alternate'} size={"big"}/>*/}
                    {this.state.imgIcon}
                    <p>{this.props.header}</p>
                </div>
                <div className={'bar-alone-container'}>
                    <Bar
                        data={this.state.data}
                        options={options}
                    />
                </div>
            </div>
        );
    }
}

export default BarAlone;