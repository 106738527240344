import React from "react";

export default (props) => {
    return(
        <div style={{marginBottom:'10px'}}>
            <div style={{display:'flex',alignItems:'center'}}>
                <div className={"topic-group"}>{props.no}.</div>
                <div style={{flex:1}}>{props.title}</div>
            </div>
            {props.children}
        </div>
    )
}
