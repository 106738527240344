import React, { Component } from "react";
import { Icon, Grid } from "semantic-ui-react";
import "./signboardStyle.css";
import Smoke from "./smoke.svg"
import PigSaving from "./saving.svg"
import PayMoney from "./money.svg"
import Beer from "./beer.svg"
export default class SignBoard extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let label="",amount="";

    //get data from prop
     if(this.props.data){
       label = this.props.data.label;
       amount = this.props.data.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
     }

     //check image if not in semantic icon
    let showImg;
    if (this.props.img ==="smoking") {
      showImg = <img src={Smoke} alt="React Logo" className="icon-smoke"/>
    }
    else if(this.props.img ==="pig-saving"){
      showImg = <img src={PigSaving} alt="React Logo" className="icon-saving"/>
    }
    else if(this.props.img ==="pay-money"){
      showImg = <img src={PayMoney} alt="React Logo" className="icon-money"/>
    }
    else if(this.props.img ==="icon-beer"){
      showImg = <img src={Beer} alt="React Logo" className="icon-beer"/>
    }
    else {
      showImg = <Icon
          className="icon-male"
          name={this.props.img}
          size="huge"
          style={{ color: `${this.props.color}` }}
      />
    }

    return (
      <div className="sign-container">
        <Grid celled>
          <Grid.Row>
            <Grid.Column className="sign-sex">
              <p style={{ color: `${this.props.color}` }}>{label}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="bg-sign">
            <Grid.Column className="sign-amount" width={10}>
              <p className="text-amount">{amount}</p>
              <p className="text-unit">{this.props.unit}</p>
            </Grid.Column>
            <Grid.Column width={6} className="grid-img">
              {showImg}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
