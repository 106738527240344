import React, {useEffect, useState} from 'react';
import '../Image/Image.css';
import imgProfile from '../../assets/img/girl_profile.png';

export default ({ className, src, path }) => {
	const [ img_error, set_img_error ] = useState(false);

	// เช็กรูปภาพ
	const imgOnError = () => {
		set_img_error(true);
	};

	useEffect(()=>{
		set_img_error(false)
	},[src,path])

	const setImage = () => {
		if(src){
			return src
		}else{
			return path
		}
	}
	// console.log('er', img_error);

	return (
		<div>
			<img
				className={className}
				onError={imgOnError}
				src={img_error ? imgProfile : setImage()} />
		</div>
	);
};




