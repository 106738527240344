import React, { Component } from 'react';
import "./LocationHouse.css";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Button, Icon } from 'semantic-ui-react';
import Config from "../../../constants/Config";
import { withRouter } from "react-router";

class LocationHouse extends Component {

    render() {

        // เช็กสถานะของหมุดแผนที่
        let source = null
        switch (this.props.status) {
            case 'new':
                source = require('../../../assets/img/Maker/NoanyHouse.png');
                break;
            case 'incomplete':
                source = require('../../../assets/img/Maker/IncompleteHouse.png');
                break;
            case 'complete':
                source = require('../../../assets/img/Maker/CompleteHouse.png');
                break;
            case 'approve':
                source = require('../../../assets/img/Maker/approvehouse.png');
                break;
            case 'reject':
                source = require('../../../assets/img/Maker/rejecthouse.png');
                break;
        }

        return (
            <div className={!this.props.disableContainer && 'location-container'}>

                <button
                    className={'edit-location'}
                    onClick={() => {
                        this.props.history.push(`listhousehold/editmarker`,{household_id:this.props.id})
                    }}
                >
                    <Icon
                        name={"map marker alternate"}
                    />
                    แก้ไขข้อมูลแผนที่
                </button>

                <div className={'map-container'}>

                    <Map
                        google={this.props.google}
                        zoom={15}
                        className={'map-styled'}
                        fluid
                        center={{
                            lat: this.props.item.latitude,
                            lng: this.props.item.longitude,
                        }}
                        initialCenter={{
                            lat: this.props.item.latitude,
                            lng: this.props.item.longitude,
                        }}
                    >

                        <Marker
                            // onClick={this.onMarkerClick}
                            name={'Your position'}
                            position={{
                                lat: this.props.item.latitude,
                                lng: this.props.item.longitude
                            }}
                            icon={{
                                url: source,
                                anchor: new this.props.google.maps.Point(32, 32),
                                scaledSize: new this.props.google.maps.Size(64, 64)
                            }}
                        />
                    </Map>

                </div>
                {
                    !this.props.disableContainer &&
                    <Button fluid className={'btn-location'}>ตำแหน่งที่พักอาศัย</Button>
                }
            </div>
        )

    }
}

const _LocationHouse = withRouter(LocationHouse)

export default GoogleApiWrapper({
    apiKey: (Config.map_key)
})(_LocationHouse)
