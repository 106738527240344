import React, {Component} from "react";
import "./selectCompStyle.css";

export default class SelectComp extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="select-container">

                {/*line between button*/}
                <div className={'link-select'}>
                    <p></p>
                </div>

                {/*menu button group*/}
                <div className={'flex-menu-butt'}>
                    <div
                        className={"menu-select " + `${this.props.setActive[0]}`}
                        value="1"
                        onClick={(e) => this.props.onSelectTab(0)}
                    >
                        <p className="text-group">หมวดที่</p>
                        <p className="text-group">1</p>
                    </div>
                    <p className={"label-but-" + `${this.props.setActive[0]}`}>สุขภาพ</p>
                </div>
                <div className={'flex-menu-butt'}>
                    <div
                        className={"menu-select " + `${this.props.setActive[1]}`}
                        value="1"
                        onClick={(e) => this.props.onSelectTab(1)}
                    >
                        <p className="text-group">หมวดที่</p>
                        <p className="text-group">2</p>
                    </div>
                    <p className={"label-but-" + `${this.props.setActive[1]}`}>สภาพแวดล้อม</p>
                </div>
                <div className={'flex-menu-butt'}>
                    <div
                        className={"menu-select " + `${this.props.setActive[2]}`}
                        value="1"
                        onClick={(e) => this.props.onSelectTab(2)}
                    >
                        <p className="text-group">หมวดที่</p>
                        <p className="text-group">3</p>
                    </div>
                    <p className={"label-but-" + `${this.props.setActive[2]}`}>การศึกษา</p>
                </div>
                <div className={'flex-menu-butt mt'}>
                    <div
                        className={"menu-select " + `${this.props.setActive[3]}`}
                        value="1"
                        onClick={(e) => this.props.onSelectTab(3)}
                    >
                        <p className="text-group">หมวดที่</p>
                        <p className="text-group">4</p>
                    </div>
                    <p className={"label-but-" + `${this.props.setActive[3]}`}>การมีงานทำ</p>
                    <p className={"label-but2-" + `${this.props.setActive[3]}`}>และรายได้</p>
                </div>
                <div className={'flex-menu-butt'}>
                    <div
                        className={"menu-select " + `${this.props.setActive[4]}`}
                        value="1"
                        onClick={(e) => this.props.onSelectTab(4)}
                    >
                        <p className="text-group">หมวดที่</p>
                        <p className="text-group">5</p>
                    </div>
                    <p className={"label-but-" + `${this.props.setActive[4]}`}>ค่านิยม</p>
                </div>
            </div>
        );
    }
}
