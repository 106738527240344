import React, {Fragment} from 'react';
import {Grid, Input, Icon, Button} from 'semantic-ui-react';
import InputMask from 'react-input-mask';
import './EditMember.css';
import {DropdownEditMember} from './DropdownEditMember';
import {RadioEditMember} from './RadioEditMember';
import {Field} from 'formik';
import UploadImgAndCrop from './../EditMember/UploadImgAndCrop';
import BirthDateAddMember from './../AddMember/BirthDateAddMember';
import swal from "sweetalert2";
import axios from "axios";
import Config from "../../../constants/Config";

/* Options Prefix */
const prefix = [
    {
        key: 'นาย',
        text: 'นาย',
        value: 'นาย'
    },
    {
        key: 'นาง',
        text: 'นาง',
        value: 'นาง'
    },
    {
        key: 'นางสาว',
        text: 'นางสาว',
        value: 'นางสาว'
    }
];

/* Type Permission */
const adminCentral = '5e71d0542e86f6aca55b90ea';
const surveyPerson = '5e71d1d12e86f6aca55b90eb';


/* ClickResetPassword */


const EditUserDetail = (props) => {
    const ClickResetPassword = () => {
        swal
            .fire({
                title: 'ยืนยันการรีเซตรหัสผ่านหรือไม่ ?',
                text: 'รหัสผ่านเริ่มต้นคือ เลข 7 ตัวของเลขบัตรประชาชน + Pka',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก'
            })
            .then((result) => {
                // console.log(result.value);

                if (result.value) {
                    //ยิง API
                    // /reset_password/:user_id
                    axios.put(`${Config.url}/api/v1/reset_password/${props.idUser}`,{}, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function(response){
                            swal.fire({
                                title: 'สำเร็จ!',
                                text: 'รีเซตรหัสผ่านสำเร็จ',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500
                            });
                        })
                        .catch((error) =>{
                            swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: 'เกิดข้อผิดพลาด',
                                confirmButtonText: 'ตกลง'
                            })
                        });
                }
            });
    };

    return (
        <Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        {/* Header */}
                        <div className='heading'>
                            <h3>ข้อมูลผู้ใช้งาน</h3>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                {/*CropImage*/}
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={5} textAlign='center' className='displayCenter'>
                        <div className='set_margin_left_bottom'>
                            <Field name='img_profile' component={UploadImgAndCrop} pathImage={props.pathImage} fluid/>
                        </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={10}>
                        <Grid>
                            {/* prefix */}
                            <Grid.Row className='set_padding_bottom'>
                                <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                    <label>คำนำหน้า<span style={{color: 'red'}}>*</span></label>
                                </Grid.Column>
                                <Grid.Column mobile={10} tablet={10} computer={11}>
                                    <Field
                                        placeholder='เลือกคำนำหน้า'
                                        options={prefix}
                                        name='prefix'
                                        component={DropdownEditMember}
                                        fluid
                                        onBlur={props.handleBlur}
                                        className={
                                            props.errors.prefix && props.touched.prefix && props.errors.prefix ? (
                                                'error'
                                            ) : (
                                                props.values.prefix && 'success'
                                            )
                                        }
                                    />
                                    {props.errors.prefix && props.touched.prefix && props.errors.prefix && (
                                        <span className='set_color_red'>{props.errors.prefix}</span>
                                    )}
                                </Grid.Column>
                            </Grid.Row>

                            {/* first_name */}
                            <Grid.Row className='set_padding_bottom'>
                                <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                    <label>ชื่อ<span style={{color: 'red'}}>*</span></label>
                                </Grid.Column>
                                <Grid.Column mobile={10} tablet={10} computer={11}>
                                    <Input
                                        values={props.values}
                                        type='text'
                                        name='first_name'
                                        placeholder='กรอกชื่อ'
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.first_name}
                                        className={
                                            props.errors.first_name &&
                                            props.touched.first_name &&
                                            props.errors.first_name ? (
                                                'error'
                                            ) : (
                                                props.values.first_name && 'success'
                                            )
                                        }
                                        fluid
                                    />
                                    {props.errors.first_name && props.touched.first_name && props.errors.first_name && (
                                        <span className='set_color_red'>{props.errors.first_name}</span>
                                    )}
                                </Grid.Column>
                            </Grid.Row>

                            {/* last_name */}
                            <Grid.Row className='set_padding_bottom'>
                                <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                    <label>สกุล<span style={{color: 'red'}}>*</span></label>
                                </Grid.Column>
                                <Grid.Column mobile={10} tablet={10} computer={11}>
                                    <Input
                                        values={props.values}
                                        type='text'
                                        name='last_name'
                                        placeholder='กรอกสกุล'
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.last_name}
                                        fluid
                                        className={
                                            props.errors.last_name && props.touched.last_name && props.errors.last_name ? (
                                                'error'
                                            ) : (
                                                props.values.last_name && 'success'
                                            )
                                        }
                                    />
                                    {props.errors.last_name && props.touched.last_name && props.errors.last_name && (
                                        <span className='set_color_red'>{props.errors.last_name}</span>
                                    )}
                                </Grid.Column>
                            </Grid.Row>

                            {/* birth_date_format */}
                            <Grid.Row className='set_padding_bottom'>
                                <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                    <label>วัน/ เดือน/ ปี(ค.ศ.) เกิด <span style={{color: 'red'}}>*</span></label>
                                </Grid.Column>
                                <Grid.Column mobile={10} tablet={10} computer={11}>
                                    <Field
                                        component={BirthDateAddMember}
                                        values={props.values}
                                        name='birth_date_format'
                                        value={props.values.birth_date_format}
                                    />
                                    {props.errors.birth_date_format &&
                                    props.touched.birth_date_format &&
                                    props.errors.birth_date_format && (
                                        <span className='set_color_red'>{props.errors.birth_date_format}</span>
                                    )}
                                </Grid.Column>
                            </Grid.Row>

                            {/* id_card */}
                            <Grid.Row className='set_padding_bottom'>
                                <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                    <label>เลขประจำตัวประชาชน<span style={{color: 'red'}}>*</span></label>
                                </Grid.Column>
                                <Grid.Column mobile={10} tablet={10} computer={11}>
                                    <InputMask
                                        placeholder='กรอกเลขประจำตัวประชาชน'
                                        fluid
                                        mask='9-9999-99999-99-9'
                                        values={props.values}
                                        name='id_card'
                                        // value={props.value}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.id_card}
                                        className={
                                            props.errors.id_card && props.touched.id_card && props.errors.id_card ? (
                                                'error'
                                            ) : (
                                                props.values.id_card && 'success'
                                            )
                                        }
                                    >
                                        {(inputProps) => <Input {...inputProps} type='tel'/>}
                                    </InputMask>
                                    {props.errors.id_card && props.touched.id_card && props.errors.id_card && (
                                        <span className='set_color_red'>{props.errors.id_card}</span>
                                    )}
                                </Grid.Column>
                            </Grid.Row>

                            {/* phone_number */}
                            <Grid.Row className='set_padding_bottom'>
                                <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                    <label>หมายเลขโทรศัพท์<span style={{color: 'red'}}>*</span></label>
                                </Grid.Column>
                                <Grid.Column mobile={10} tablet={10} computer={11}>
                                    <Input
                                        maxLength={10}
                                        placeholder='กรอกหมายเลขโทรศัพท์'
                                        values={props.values}
                                        type='text'
                                        name='phone_number'
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.phone_number}
                                        fluid
                                        className={
                                            props.errors.phone_number &&
                                            props.touched.phone_number &&
                                            props.errors.phone_number ? (
                                                'error'
                                            ) : (
                                                props.values.phone_number && 'success'
                                            )
                                        }
                                    />
                                    {props.errors.phone_number &&
                                    props.touched.phone_number &&
                                    props.errors.phone_number && (
                                        <span className='set_color_red'>{props.errors.phone_number}</span>
                                    )}
                                </Grid.Column>
                            </Grid.Row>

                            {/* permission */}
                            <Grid.Row className='set_padding_bottom'>
                                <Grid.Column mobile={6} tablet={6} computer={5} className='flexEnd'>
                                    <label>ประเภทผู้ใช้งาน<span style={{color: 'red'}}>*</span></label>
                                </Grid.Column>
                                <Grid.Column mobile={10} tablet={10} computer={11}>
                                    <Grid>
                                        <Grid.Row>
                                            {props.choicePermission.map((item, index) => {
                                                return (
                                                    <Grid.Column mobile={16} tablet={8} computer={8} key={index}>
                                                        <label
                                                            value={item._id}
                                                            className={
                                                                props.errors.permission_id &&
                                                                props.touched.permission_id &&
                                                                props.errors.permission_id ? (
                                                                    'labelRadioError'
                                                                ) : props.values.permission_id === item._id ? (
                                                                    'labelRadioSuccess'
                                                                ) : (
                                                                    'labelRadio'
                                                                )
                                                            }
                                                        >
                                                            <div style={{justifyContent: 'flex-start'}}>
                                                                <Field
                                                                    component={RadioEditMember}
                                                                    name='permission_id'
                                                                    id={item._id}
                                                                />
                                                            </div>
                                                            <div style={{justifyContent: 'center', margin: 'auto'}}>
                                                                <Icon name='male' size={'huge'}/>
                                                                <br/>
                                                                <span>{item.name_th}</span>
                                                            </div>
                                                        </label>
                                                        {props.errors.permission_id &&
                                                        props.touched.permission_id &&
                                                        props.errors.permission_id && (
                                                            <span className='set_color_red'>
															{props.errors.permission_id}
														</span>
                                                        )}
                                                    </Grid.Column>
                                                );
                                            })}
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>

                            {/* Permission Type AdminCentral and surveyPerson */}
                            {props.values.permission_id === adminCentral ? (
                                <Grid.Row className='set_padding_bottom'>
                                    <Grid.Column
                                        mobile={6}
                                        tablet={6}
                                        computer={5}
                                        className='displayFlexEnd'
                                    >
                                        <label>ศูนย์ฯที่รับผิดชอบ<span style={{color: 'red'}}>*</span></label>
                                    </Grid.Column>

                                    <Grid.Column mobile={10} tablet={10} computer={11}>
                                        <Field
                                            placeholder='เลือกศูนย์ฯที่รับผิดชอบ'
                                            options={props.choiceArea.map((item) => {
                                                return {
                                                    key: item._id,
                                                    text: item.name,
                                                    value: item._id
                                                };
                                            })}
                                            name='area_id'
                                            component={DropdownEditMember}
                                            fluid
                                            onBlur={props.handleBlur}
                                            className={
                                                props.errors.area_id && props.touched.area_id && props.errors.area_id ? (
                                                    'error'
                                                ) : (
                                                    props.values.area_id && 'success'
                                                )
                                            }
                                        />
                                        {props.errors.area_id && props.touched.area_id && props.errors.area_id && (
                                            <span className='set_color_red'>{props.errors.area_id}</span>
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                            ) : props.values.permission_id === surveyPerson ? (
                                <Grid.Row className='set_padding_bottom'>
                                    <Grid.Column
                                        mobile={6}
                                        tablet={6}
                                        computer={5}
                                        className='displayFlexEnd'
                                    >
                                        <label>ชุมชนที่ที่รับผิดชอบ<span style={{color: 'red'}}>*</span></label>
                                    </Grid.Column>
                                    <Grid.Column mobile={10} tablet={10} computer={11}>
                                        <Field
                                            placeholder='เลือกชุมชนที่ที่รับผิดชอบ'
                                            options={props.choiceCommunity.map((item) => {
                                                // console.log('choiceCommunity: ', item);
                                                return {
                                                    key: item._id,
                                                    text: item.name,
                                                    value: item._id,
                                                    area: item.area_id
                                                };
                                            })}
                                            name='community_id'
                                            component={DropdownEditMember}
                                            fluid
                                            onBlur={props.handleBlur}
                                            className={
                                                props.errors.community_id &&
                                                props.touched.community_id &&
                                                props.errors.community_id ? (
                                                    'error'
                                                ) : (
                                                    props.values.community_id && 'success'
                                                )
                                            }
                                        />
                                        {props.errors.community_id &&
                                        props.touched.community_id &&
                                        props.errors.community_id && (
                                            <span className='set_color_red'>{props.errors.community_id}</span>
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                            ) : null}
                            <Grid.Row>
                                {/* ปุ่มรีเซตรหัสผ่าน */}
                                <Grid.Column
                                    mobile={6}
                                    tablet={6}
                                    computer={5}
                                    className='displayFlexEnd'
                                >
                                    <label>รีเซตรหัสผ่าน</label>
                                </Grid.Column>
                                <Grid.Column mobile={10} tablet={10} computer={11}>
                                    <Button
                                        className='button-reset-password'
                                        type='submit'
                                        // onClick={() => ClickResetPassword(detailUser._id)}
                                    >
                                        <Icon circular name='key' className='set-icon-blue'/>
                                        รีเซตรหัสผ่าน
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    )
};
export default EditUserDetail;
