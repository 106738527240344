import React, {Component} from "react";
import {Card, Icon, Container, Grid, Image, Label} from "semantic-ui-react";
import "./pieStyle.css";
import {Pie} from "react-chartjs-2";

export default class SignBoard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            options: {},
            header: "",
            img: "",
            backgroundColor: [
                "#5da5da",
                "#faa43a",
                "#60bd68",
                "#f17cb0",
                "#b2912f",
                "#b276b2",
                "#decf3f",
                "#f15854",
                "#1d699d",
                "#564d65",
                "#c767cb",
                "#ff984c",
                "#00abeb",
                "#ef3e86",
                "#3388bd",
            ],
            hoverBackgroundColor: [
                "#63b2eb",
                "#fab32f",
                "#6ad473",
                "#f571ab",
                "#c7a234",
                "#c983c9",
                "#eddc40",
                "#ff554f",
                "#1f7bb8",
                "#675c7a",
                "#da6fde",
                "#fc8f42",
                "#02b6f7",
                "#fc3f8b",
                "#3694cf",
            ],
        };
    }

    componentDidMount() {
        let rawData = this.props.data || [], data = [], mapLegends = []

        //Init variable
        const labels = [];
        const dataAmount = [];
        const backgroundColor = [];
        const hoverBackgroundColor = [];

        //Set Value
        for (let i = 0; i < rawData.length; i++) {
            const element = rawData[i];
            labels.push(element.labels);
            dataAmount.push(element.data);
            backgroundColor.push(this.state.backgroundColor[i]);
            hoverBackgroundColor.push(this.state.hoverBackgroundColor[i]);
        }

        //Raw data to data
        data = {
            labels: labels,
            datasets: [
                {
                    data: dataAmount,
                    backgroundColor: backgroundColor,
                    hoverBackgroundColor: hoverBackgroundColor,
                },
            ],
        };

        this.setState({data: data})
    }


    render() {
        // pie bar option
        const options = {
            responsive: false,
            maintainAspectRatio: true,
            legend: {
                display: false,
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 10,
                    bottom: 20,
                },
            },

        };
        return (
            <div className="pie-container">

                {/*Header*/}
                <div className="head-pie">
                    <Icon name={this.props.img} size="big"/>
                    <p>{this.props.header}</p>
                </div>

                {/*Chart*/}
                <Pie data={this.state.data} options={options} height={this.props.height}/>

                {/*List lable*/}
                <div className={'legend-container'}>
                    {
                        this.props.data.map((data, index= 0) => {
                            return (
                                <div className={'data-label'} key={index + Math.random()}>
                                    <div className={'box-color'}
                                         style={{backgroundColor: `${this.state.backgroundColor[data.id]}`}}/>
                                    <p className={'text-label'}>{data.labels}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}
