import React from "react";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import './TopBar.css'
import {
    Link,
    withRouter
} from "react-router-dom";
import {connect} from "react-redux";
import Image from "../../components/Image";
import {Icon, Dropdown, Header} from "semantic-ui-react";
import Config from "../../constants/Config";

const Topbar = ({history, ...props}) => {
    const logout = () => {
        Swal.fire({
            title: 'แจ้งเตือน',
            text: "ยืนยันการออกจากระบบ",
            icon: 'warning',
            showCancelButton: true,
            customClass: {
                cancelButton: 'btn cancel'
            },
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก'
        }).then((result) => {
            if (result.value) {
                localStorage.clear();
                history.replace('/')
                Swal.fire(
                    'แจ้งเตือน',
                    'ออกจากระบบสำเร็จ',
                    'success'
                )
            }
        })
    }
    return (
        <div className={'top-bar'}>
            <Dropdown
                trigger={(
                    <div className={'account-menu'}>
                        {
                            props.user.permission_id === "5e4a59cec479a2e3b6190e09" &&
                            <div className={'account-info'}>
                                <div className={'permission'}>{`ผู้ดูแลระบบกลาง`}</div>
                                <div className={'topic'}>{`คุณ ${props.user.full_name}`}</div>
                            </div>
                        }
                        {
                            props.user.permission_id === "5e71d0542e86f6aca55b90ea" &&
                            <div className={'account-info'}>
                                <div className={'permission'}>{`ผู้ดูแลศูนย์`}</div>
                                <div className={'topic'}>{`คุณ ${props.user.full_name}`}</div>
                                <div className={'permission'}>{`${props.user.area}`}</div>
                            </div>
                        }
                        <Image
                            className={'account-pic-profile'}
                            src={`${Config.url}/api/v1${props.user.image.path}`}
                            path={props.user.image.path}
                        />
                    </div>
                )}
                direction={'left'}
                floating
                labeled
                className='icon'
            >
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <Link style={{color: '#4a4b4c'}} to={props.user.permission_id === "5e4a59cec479a2e3b6190e09" ? '/administrator/profile' : '/centeradministrator/profile'}>
                            <Icon size={'small'} name={'user'} style={{margin: '0 11px 0 0'}}/>ข้อมูลผู้ใช้งาน
                        </Link>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout}>
                        <Icon size={'small'} name={'sign-out'}/>
                        ออกจากระบบ
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
};

export default connect(mapStateToProps, null) (withRouter(Topbar))
