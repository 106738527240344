import {store} from '../index'

export default (dispatch) => {
    return ({
        userAction: {
            show: async (data) => {
                try {
                    dispatch({
                        type: 'USER_SHOW',
                        payload: data
                    });
                    return Promise.resolve();
                } catch (e) {
                    return Promise.reject(e);
                }
            },
            edit: async (data) => {
                try {
                    dispatch({
                        type: 'USER_EDIT',
                        payload: data
                    });
                    return Promise.resolve();
                } catch (e) {
                    return Promise.reject(e);
                }
            },
        }
    })
}



