import React from 'react'
import {Link, Route} from "react-router-dom";
import {Icon} from "semantic-ui-react";

export default ({to, name, icon = 'home', ...rest}) => {
    return (
        <Route
            exact
            path={to}
            children={({match,...router}) => {
                return(
                    <li className={`menu-item ${router.location.pathname.search(to) !== -1 ? "active" : ""}`}>
                        <Link to={to} {...rest} >
                            <Icon size={'large'} name={icon} style={{color: router.location.pathname.search(to) !== -1 ? "var(--main-color)" : "#FFF"}}/>
                            <span className={'menu-text'}>{name}</span>
                        </Link>
                    </li>
                )
            }}
        />
    );
}
