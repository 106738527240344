import React, {useEffect, useState} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Modal, Button, Icon} from 'semantic-ui-react';
import Config from "../../../constants/Config";
import './UpdateImageProfile.css'
import Image from '../../Image'

export const UpdateImageProfile = ({field: {name}, form: {setFieldValue}, ...props}) => {

    /* Set State */
    const [img_src, set_img_src] = useState('');
    const [crop, set_crop] = useState({
        unit: '%',
        width: 30,
        aspect: 9 / 9
    });
    const [crop_image_url, set_crop_image_url] = useState('');
    const [modal_img, set_modal_img] = useState(false);
    const [img_ref, set_img_ref] = useState('');
    const [file_url, set_file_url] = useState('');
    const [file_name, set_file_name] = useState('');
    const [cancel_img, set_cancel_img] = useState(false);

    /* func onSelectFile */
    const onSelectFile = (e) => {
        set_crop_image_url('');
        if (e.target.files && e.target.files.length > 0) {
            set_file_name(e.target.files[0].name);
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                set_img_src(reader.result);
                set_modal_img(true);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    /* func onImageLoaded */
    const onImageLoaded = (image) => {
        set_img_ref(image);
    };

    /* func onCropComplete */
    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    /* func onCropChange */
    const onCropChange = (crop, percentCrop) => {
        set_crop(crop);
    };

    /* func makeClientCrop */
    const makeClientCrop = async (crop) => {
        if (img_ref && crop.width && crop.height) {
            const {file_url, blob} = await getCroppedImg(img_ref, crop, file_name);
            set_crop_image_url({crop_image_url: file_url, blob});
        }
    };

    /* func getCroppedImg */
    const getCroppedImg = (image, crop, file_name) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = file_name;
                window.URL.revokeObjectURL(file_url);
                set_file_url(window.URL.createObjectURL(blob));
                resolve({file_url, blob});
            }, 'image/jpeg');
        });
    };

    /* func onClickCancel */
    const onClickCancel = () => {
        set_modal_img(false);
        set_img_src('');
        set_crop_image_url('');
        document.getElementById('upload').value = null;
        set_crop({
            unit: '%',
            width: 30,
            aspect: 9 / 9
        });
        set_file_url('');
        set_img_ref('');
    };

    /* func onClickChoose */
    const onClickChoose = () => {
        set_cancel_img(true);
        set_modal_img(false);
        set_img_src(file_url);
        var file = new File([crop_image_url.blob], file_name, {type: crop_image_url.blob.type});
        setFieldValue(name, file);
    };

    const clickCancel = () => {
        set_cancel_img(false);

        // เรียก path รูปเดิมในระบบ
        set_img_src('');
    };


    return (
        <div className={'update-image-profile'}>

            {/*อัปเดตรูปโปรไฟล์*/}
            <div className={'img-profile-content'}>

                {/*ปุ่มยกเลิก Upload รูปโปรไฟล์*/}
                {cancel_img &&
                <button
                    className={'icon-cancelImg'}
                    onClick={clickCancel}
                >
                    <Icon
                        name={'times'}
                        size={'small'}
                    />
                </button>
                }

                <Image
                    className={'img-profile'}
                    src={img_src}
                    path={`${Config.url}/api/v1${props.pathImage}`}

                />

                {/*ปุ่ม Upload รูปโปรไฟล์*/}
                <label
                    className={'icon-uploadImg'}
                >
                    <Icon
                        name={'camera'}
                        size={'small'}
                    />
                    <input
                        type='file'
                        id='upload'
                        accept='image/*'
                        onChange={onSelectFile}
                        style={{display: 'none'}}
                    />
                </label>

                {/* Modal Crop Img */}
                <Modal open={modal_img}>
                    <Modal.Header>
                        <label>สร้างรูปโปร์ไฟล์</label>
                    </Modal.Header>

                    {/* Modal Content */}
                    <Modal.Content>
                        <center>
                            <div style={{maxWidth: '30%', maxHeight: '30%'}}>
                                <ReactCrop
                                    src={img_src}
                                    crop={crop}
                                    ruleOfThirds
                                    onImageLoaded={onImageLoaded}
                                    onComplete={onCropComplete}
                                    onChange={onCropChange}
                                />
                            </div>
                            <div style={{width: '30%', height: '30%'}}>
                                {crop_image_url && <img alt='Crop' src={file_url}/>}
                            </div>
                        </center>
                    </Modal.Content>

                    {/* Modal Actions */}
                    <Modal.Actions>
                        {crop_image_url && (
                            <div>
                                <Button basic color='red' content='ยกเลิก' onClick={onClickCancel}/>
                                <Button primary className='buttonSave' content=' เลือก ' onClick={onClickChoose}/>
                            </div>
                        )}
                    </Modal.Actions>
                </Modal>

            </div>

        </div>
    );
};
export default UpdateImageProfile;
