import React, { Component } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { Icon, Container, Grid, Image, Label } from "semantic-ui-react";
import "./barStyle.css";
export default class BarDash extends Component {
  constructor(props) {
    super(props);
  }
  render() {

    //Bar option
    const options = {
      responsive:true,
      maintainAspectRatio:false,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              padding: 10,
            },
          },
        ],
      },
    };

    return (
      <div className="horizon-bar-container">
        <div className="head-bar">
          <Icon name={this.props.img} size="big" />
          <p>{this.props.header}</p>
        </div>
        <div className={'horizon'}>
          <HorizontalBar
              data={this.props.data}
              options={options}
          />
        </div>
      </div>
    );
  }
}
