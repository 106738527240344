import React from "react";

export default ({no,title,...props}) => {
    return(
        <div className={'question-answer'} style={{padding:'5px 0'}} >
            <div className={'no'}>{no}</div>
            <div className={'content'}>
                <div className={'block'}>
                    <div className={'title'}>คำถาม </div>
                    <div className={'question'}>: {title}</div>
                </div>
                <div className={'block'}>
                    <div className={'title'}>คำตอบ </div>
                    <div style={{marginRight:'5px'}}>:</div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}
