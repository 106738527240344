import React, { Component } from "react";
import { Menu, Icon, Grid, Segment } from "semantic-ui-react";
import "./barStyle.css";
import { Bar } from "react-chartjs-2";
export default class Bars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //ตั้งค่าเริ่มต้นของ tap bar
      activeItem: "อาชีพ",
    };
  }
  //ฟังก์ชันการคลิกเพื่อเลือก tap bar
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  render() {
    //Bar Option
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    };

    //Tapbar Menu
    const heightBar = 285
    const tapOccu = (
      <div>
        <Bar
          className="test-bar"
          data={this.props.data.occupation}
          options={options}
          height={heightBar}
        />
      </div>
    );
    const tapEduca = (
      <div>
        <Bar
          className="test-bar"
          data={this.props.data.education}
          options={options}
          height={heightBar}
        />
      </div>
    );
    const tapReli = (
      <div>
        <Bar
          className="test-bar"
          data={this.props.data.religion}
          options={options}
          height={heightBar}
        />
      </div>
    );

    //Check current Tab
    const activeItem = this.state.activeItem;
    let tapActive;
    if (activeItem === "อาชีพ") {
      tapActive = tapOccu;
    } else if (activeItem === "การศึกษา") {
      tapActive = tapEduca;
    } else {
      tapActive = tapReli;
    }


    return (
      <div className="bar-container">

        {/*Menu Item*/}
        <Menu id="sssd" icon="labeled" attached="top">
          <Menu.Item
            name="อาชีพ"
            active={this.state.activeItem === "อาชีพ"}
            onClick={this.handleItemClick}
            color={this.state.menuColor}
          />
          <Menu.Item
            name="การศึกษา"
            active={this.state.activeItem === "การศึกษา"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="ศาสนา"
            active={this.state.activeItem === "ศาสนา"}
            onClick={this.handleItemClick}
          />
        </Menu>

        {/*show graph*/}
        <Segment
          className="tesst-bar2 "
          style={{
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
          attached="bottom"
        >
          {tapActive}
        </Segment>


      </div>
    );
  }
}
