import * as React from 'react';
import { Input } from 'semantic-ui-react';

export const RadioButton = ({
	field: { name, value, onChange, onBlur },
	form: { touched, errors, setFieldValue },
	id,
	label,
	className,
	...props
}) => {
	return (
		<div>
			{/* Input */}
			<Input
				name={name}
				id={id}
				type='radio'
				value={id} // could be something else for output?
				checked={id === value}
				onChange={(e) => {
					setFieldValue('community_id', '');
					setFieldValue('area_id', '');
					onChange(e);
				}}
				onBlur={onBlur}
				{...props}
			/>
			<span>{label}</span>
		</div>
	);
};
export default RadioButton;
