import React from 'react';
import axios from 'axios';
import './LoginScrees.css'
import {Image, Icon, Loader, Message} from 'semantic-ui-react'
import Logo from '../../assets/img/OGS-logo-white.png'
import LogoBlack from '../../assets/img/OGS-black.png'
import Application from '../../assets/img/application.png'
import QrAppStore from '../../assets/img/QrAppStore.png'
import QrPlayStore from '../../assets/img/QrGooglePlay.png'
import Config from "../../constants/Config";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default class LoginScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            input_null: false,
            username_null: false,
            password_null: false,
            login_invalid: false,
            input_invalid: false,
            loading_button: false,
            play_store: 'https://play.google.com/store/apps/details?id=th.co.ogs.muni.localsurvey&hl=th',
            app_store: 'https://apps.apple.com/us/app/local-survey/id1550701033',
        }
    }

    componentDidMount() {
        this.checkToken()
    }

    checkToken = async () => {
        try {
            let response = await axios.get(
                `${Config.url}/api/v1/verify_token`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + await localStorage.getItem('access_token')
                    }
                }
            )
            if (response.data.value.permission_id === "5e4a59cec479a2e3b6190e09") {
                this.props.history.replace('/administrator')
            }else{
                this.props.history.replace('/centeradministrator')
            }
        } catch (e) {

        }

    };

    handleSubmit = async () => {

        if (this.state.username && this.state.password) {

            this.state.loading_button = true;

            try {
                let response = await axios.post(
                    `${Config.url}/api/v1/login_web`,
                    {
                        username: this.state.username,
                        password: this.state.password
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                localStorage.setItem('access_token', response.data.value.access_token);
                if (response.data.value.permission === "AdminCentral") {
                    this.props.history.replace('/administrator')
                } else if (response.data.value.permission === "Admin") {
                    this.props.history.replace('/centeradministrator')
                } else {
                }

                this.state.loading_button = false;

            } catch (e) {
                if (e.response) {
                    if (e.response.status === 401 || e.response.status === 400) {
                        this.setState({login_invalid: true, input_invalid: true, loading_button: false});
                        console.log(' Username or Password invalid');
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'เกิดข้อผิดหลาด',
                            text: 'ไม่สามารถติดต่อเซิร์ฟเวอร์ได้ กรุณาลองใหม่',
                        })
                    }
                }
            }

        } else {
            if (!this.state.username) {
                this.setState({username_null: true});
                console.log('user invalid')
            }
            if (!this.state.password) {
                this.setState({password_null: true});
            }
            console.log('Login fail', this.state.username_null);

        }
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            login_invalid: false,
            [`${e.target.name}_null`]: false
        });
    }

    forgotPassword = () => {
        Swal.fire({
            icon: 'warning',
            title: 'ลืมรหัสผ่าน?',
            text: 'กรุณาติดต่อผู้ดูแลศูนย์ฯ หรือ ผู้ดูแลระบบ ด้วยตนเอง',
        })
    }

    render() {
        return (
            <div className={'login-screen'}>

                {/*ภาพพื้นหลัง*/}
                <div className={'background-login'}>
                    <div className={'block-top-right'}/>
                    <div className={'block-bottom-right'}/>
                </div>

                <div className={'container'}>

                    {/*โลโก้เทศบาล*/}
                    <div className={'logo-container-login'}>
                        <Image className={'logo-header-login'} src={Logo}/>
                        <div>
                            <div className={"logo-text-login"}>Local Survey</div>
                            <div className={"logo-text-sub-login"}>จัดเก็บข้อมูล จปฐ.</div>
                        </div>
                    </div>

                    <div className={'body-container'}>
                        {/*โปรโมทแอปพลิเคชั่น*/}
                        <div className={'app-container'}>

                            {/*ภาพตัวอย่างแอบพลิเคชั่น*/}
                            <div className={'app-img'}>
                                <img src={Application}/>
                            </div>

                            {/*ดาวน์โหลดแอปพลิเคชัน*/}
                            <div className={'download-here'}>
                                <div className={'download-text'}>ดาวน์โหลดที่นี่</div>
                                <div className={'download-container'}>
                                    <a style={{cursor: 'pointer'}} target={"_blank"} href={this.state.app_store}>
                                        <img className={'download-img'} src={QrAppStore}/>
                                    </a>
                                    <a style={{cursor: 'pointer'}} target={"_blank"} href={this.state.play_store}>
                                        <img className={'download-img'} src={QrPlayStore}/>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className={'app-img-mobile'}>
                            <img src={Application}/>
                        </div>

                        {/*เข้าสู่ระบบ*/}
                        <div className={'container-login'}>
                            <img className={'phone-logo'} src={Application}/>
                            <img className={'logo-login'} src={LogoBlack} style={{height: '150px', width: '150px'}}/>
                            <div className={'welcome-text'}>ยินดีต้อนรับ</div>
                            <div className={'system-text'}>เข้าสู่ระบบจัดเก็บข้อมูลประชากร</div>


                            {/*กรอกชื่อผู้ใช้งาน*/}
                            <div
                                className={this.state.login_invalid || this.state.username_null ? 'invalid-border-input-login' : 'border-input-login'}>
                                <Icon name='user'/>
                                <input
                                    type={'text'}
                                    placeholder='ชื่อผู้ใช้งาน'
                                    className={'input-login'}
                                    name={'username'}
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                />
                            </div>
                            {this.state.username_null && <div className={'invalid-text'}>กรุณากรอกชื่อผู้ใช้งาน</div>}

                            {/*รหัสผ่าน*/}
                            <div
                                className={this.state.login_invalid || this.state.password_null ? 'invalid-border-input-login' : 'border-input-login'}>
                                <Icon name='key'/>
                                <input
                                    type={'password'}
                                    placeholder='รหัสผ่าน'
                                    className={'input-login'}
                                    name={'password'}
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                />
                            </div>
                            {this.state.password_null && <div className={'invalid-text'}>กรุณากรอกรหัสผ่าน</div>}


                            {/*ข้อความแสดงข้อผิดพลาด*/}
                            {this.state.login_invalid &&
                            <Message className={'message-error'} color='red'>
                                <Icon name={'times circle'}/>
                                <span>ชื่อผู้ใช้งาน หรือ รหัสผ่าน ไม่ถูกต้อง</span>
                            </Message>
                            }

                            <a href={'#'} onClick={this.forgotPassword} className={'forgot-password'}>ลืมรหัสผ่าน?</a>


                            {/*<div className={'invalid-text'}>กรุณากรอกชื่อและรหัสผ่านให้ครบถ้วน</div>*/}

                            {this.state.loading_button ?
                                <button className={'button-loading'} disabled>
                                    <Loader active inline size='tiny' style={{marginRight: '10px'}}/>
                                    กำลังโหลด...
                                </button>
                                : <button className={'button-login'} onClick={this.handleSubmit}>เข้าสู่ระบบ</button>}

                            {/*<button className={'button-login'} onClick={this.handleSubmit}>เข้าสู่ระบบ</button>*/}
                        </div>
                    </div>

                </div>
            </div>

        )
    }
}
