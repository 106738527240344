import React from "react";
import Topic from "./Topic";
import SubTopic from "./SubTopic";
import Answer from './Answer'
import SubAnswer from "./SubAnswer";
import DetailTopic from "./DetailTopic";

export default ({data, ...props}) => {
    let {
        no8_1,
        no8_2,
        no9_1,
        no10_1,
        no11_1,
        no11_2,
        no11_3,
        no11_4,
        no11_5,
        no11_6,
        no11_7,
        no11_8,
        no12_1,
        no12_2,
        no12_3,
        no12_4,
        no12_5,
        no12_6,
        no12_7,
        no12_8,
        no13_1,
        no13_2,
        no13_3,
        no14_1,
        no14_2,
        no14_3,
        no14_4,
        no14_5
    } = data

    const answer11_5 = () => {
        return (
            <div style={{display:'flex',flexDirection:'column',flex:'1'}}>

                <SubAnswer
                    no={'1.'}
                    title={'มีอุปกรณ์ อาทิ ไม้กวาด ถุงใส่ขยะ ถังขยะ'}
                >
                    <Answer detail={`${no11_5.no11_5_1.answer === '1' ? 'มี' : 'ไม่มี'}`} unit={''} value={''}/>
                </SubAnswer>

                <SubAnswer
                    no={'2.'}
                    title={'มีการคัดแยกขยะประเภท ของเสีย ขยะรีไซเคิล เศษอาหาร ขยะอื่นๆ'}
                >
                    <Answer detail={`${no11_5.no11_5_2.answer === '1' ? 'มี' : 'ไม่มี'}`} unit={''} value={''}/>
                </SubAnswer>

                <SubAnswer
                    no={'3.'}
                    title={'มีการกำจัดขยะ เช่น ส่งให้ อปท. กำจัด หรือการ ฝังหลุม'}
                >
                    <Answer detail={`${no11_5.no11_5_3.answer === '1' ? 'มี' : 'ไม่มี'}`} unit={''} value={''}/>
                </SubAnswer>

            </div>
        )
    }

    const answer13_1 = () => {
        return (
            <div style={{display:'flex',flexDirection:'column',flex:'1'}}>

                <SubAnswer
                    no={'1.'}
                    title={'มีการป้องกันอุบัติภัยเมื่อขับขี่ยานพาหนะ'}
                >
                    <Answer detail={`${no13_1.no13_1_1.answer === '1' ? 'มี' : 'ไม่มี'}`} unit={''} value={''}/>
                </SubAnswer>

                <SubAnswer
                    no={'2.'}
                    title={'มีการป้องกันอุบัติภัยเมื่อใช้เครื่องใช้ไฟฟ้า'}
                >
                    <Answer detail={`${no13_1.no13_1_2.answer === '1' ? 'มี' : 'ไม่มี'}`} unit={''} value={''}/>
                </SubAnswer>

                <SubAnswer
                    no={'3.'}
                    title={'มีการป้องกันอันตรายหรืออุบัติเหตุจากการประกอบอาชีพ เช่น ปฏิบัติตามคำแนะนำในการใช้สารเคมี การใช้อุปกรณ์คุ้มครองความปลอดภัยส่วนบุคคล (การสวมแว่นตา หน้ากากหรือผ้าปิดจมูก ถุงมือยาง ฯลฯ)'}
                >
                    <Answer detail={`${no13_1.no13_1_3.answer === '1' ? 'มี' : 'ไม่มี'}`} unit={''} value={''}/>
                </SubAnswer>

                <SubAnswer
                    no={'4.'}
                    title={'มีการเตรียมความพร้อมเพื่อรับมือกับภัยธรรมชาติ'}
                >
                    <Answer detail={`${no13_1.no13_1_4.answer === '1' ? 'มี' : 'ไม่มี'}`} unit={''} value={''}/>
                </SubAnswer>

            </div>
        )
    }

    return (
        <div>

            {/*Question 8*/}
            <Topic
                no={8}
                title={"ครัวเรือนมีความมั่นคงในที่อยู่อาศัยและบ้านมีสภาพคงทนถาวร"}
            >
                {/*Question 8.1*/}
                <SubTopic
                    no={'8.1.'}
                    questionName={"ครัวเรือนมีความมั่นคงในที่อยู่อาศัย หรือไม่"}
                >
                    {
                        (no8_1.answer === '1' || no8_1.answer === '0') ?
                            no8_1.answer === '1' ?
                                `มี`
                                : 'ไม่มี'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question 8.2*/}
                <SubTopic
                    no={'8.2'}
                    questionName={"ครัวเรือนมีบ้านที่มีสภาพคงทนถาวร หรือไม่"}
                >
                    {
                        (no8_2.answer === '1' || no8_2.answer === '0') ?
                            no8_2.answer === '1' ?
                                `มี`
                                : 'ไม่มี'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>
            </Topic>

            {/*Question 9*/}
            <Topic
                no={9}
                title={"ครัวเรือนมีน้ำสะอาดสำหรับดื่มและบริโภคเพียงพอตลอดปีอย่างน้อยคนละ 5 ลิตรต่อวัน"}
            >
                {/*Question 9.1*/}
                <SubTopic
                    no={'9.1.'}
                    questionName={"ครัวเรือนมีน้ำสะอาดสำหรับดื่มและบริโภคเพียงพอตลอดปี อย่างน้อยคนละ 5 ลิตรต่อวันหรือไม่"}
                >
                    {
                        (no9_1.answer === '1' || no9_1.answer === '0') ?
                            no9_1.answer === '1' ?
                                `เพียงพอ`
                                : 'ไม่เพียงพอ'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>
            </Topic>

            {/*Question 10*/}
            <Topic
                no={10}
                title={"ครัวเรือนมีน้ำใช้เพียงพอตลอดปี อย่างน้อยคนละ 45 ลิตรต่อวัน"}
            >
                {/*Question */}
                <SubTopic
                    no={'10.1'}
                    questionName={"ครัวเรือนมีน้ำใช้เพียงพอตลอดปี อย่างน้อยคนละ 45 ลิตรต่อวัน (ประมาณ 2 ปี๊บ) หรือไม่"}
                >
                    {
                        (no10_1.answer === '1' || no10_1.answer === '0') ?
                            no10_1.answer === '1' ?
                                `เพียงพอ`
                                : 'ไม่เพียงพอ'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>
            </Topic>

            {/*Question 11*/}
            <Topic
                no={11}
                title={"ครัวเรือนมีการจัดบ้านเรือนเป็นระเบียบเรียบร้อย สะอาดและถูกสุขลักษณะ"}
            >
                {/*Question */}
                <SubTopic
                    no={'11.1'}
                    questionName={"มีสภาพในบ้านสะอาด ห้องนอนลมพัดผ่านสะดวกไม่มีฝุ่นละอองและกลิ่นเหม็นอับชื้น ที่หลับนอน ข้าวของเครื่องใช้สะอาดมีการจัดเก็บเป็นระเบียบ ไม่รกรุงรัง"}
                >
                    {
                        (no11_1.answer === '1' || no11_1.answer === '0') ?
                            no11_1.answer === '1' ?
                                `มี`
                                : 'ไม่มี'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question */}
                <SubTopic
                    no={'11.2'}
                    questionName={"มีที่ประกอบอาหารสะอาดเป็นระเบียบมีตู้กับข้าวหรือฝาชี มีอุปกรณ์ล้างมือที่ใช้งานได้ดี (เช่น สบู่หรือน้ำยาล้างจาน)และมีการกำจัดไขมันก่อนล้าง"}
                >
                    {
                        (no11_2.answer === '1' || no11_2.answer === '0') ?
                            no11_2.answer === '1' ?
                                `มี`
                                : 'ไม่มี'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question */}
                <SubTopic
                    no={'11.3'}
                    questionName={"มีที่เก็บน้ำสะอาดเพื่อการอุปโภคบริโภคสภาพดีมีฝาปิด"}
                >
                    {
                        (no11_3.answer === '1' || no11_3.answer === '0') ?
                            no11_3.answer === '1' ?
                                `มี`
                                : 'ไม่มี'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question */}
                <SubTopic
                    no={'11.4'}
                    questionName={"มีการกำจัดสัตว์ แมลงที่เป็นพาหะนำโรค ได้แก่ยุงลาย หนู แมลงวัน แมลงสาบ ไม่พบแหล่งเพาะพันธุ์และที่หลบซ่อนอาศัยภายในบ้านหรือบริเวณบ้าน"}
                >
                    {
                        (no11_4.answer === '1' || no11_4.answer === '0') ?
                            no11_4.answer === '1' ?
                                `มี`
                                : 'ไม่มี'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question */}
                <DetailTopic
                    no={'11.5'}
                    questionName={"มีการจัดการขยะ ดังนี้"}
                >
                    {data ? answer11_5() : <div>ไม่มีข้อมูล</div>}
                </DetailTopic>

                {/*Question */}
                <SubTopic
                    no={'11.6'}
                    questionName={"มีร่องระบายน้ำอยู่ในสภาพดีไม่มีแหล่งน้ำขังในบ้าน และไม่มีการปล่อยน้ำเสียลงแหล่งน้ำสาธารณะ"}
                >
                    {
                        (no11_6.answer === '1' || no11_6.answer === '0') ?
                            no11_6.answer === '1' ?
                                `มี`
                                : 'ไม่มี'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question */}
                <SubTopic
                    no={'11.7'}
                    questionName={"มีส้วมใช้ที่มีสภาพแข็งแรงใช้งานได้สะอาดมีการระบายอากาศที่ดี ไม่มีการแตกร้าวที่หัวส้วม พื้นที่ถังส้วม และฝาปิดและมีอุปกรณ์ทำความสะอาด"}
                >
                    {
                        (no11_7.answer === '1' || no11_7.answer === '0') ?
                            no11_7.answer === '1' ?
                                `มี`
                                : 'ไม่มี'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question */}
                <SubTopic
                    no={'11.8'}
                    questionName={"มีการจัดเก็บ และแยกสารเคมีที่เป็นอันตรายออกจากเครื่องใช้อื่น ๆ โดยจัดให้เป็นระเบียบ และวางให้พ้นมือเด็ก"}
                >
                    {
                        (no11_8.answer === '1' || no11_8.answer === '0') ?
                            no11_8.answer === '1' ?
                                `มี`
                                : 'ไม่มี'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>
            </Topic>

            {/*Question 12*/}
            <Topic
                no={12}
                title={"ครัวเรือนไม่ถูกรบกวนจากมลพิษ"}
            >

                {/*Question 12.1*/}
                <SubTopic
                    no={'12.1.'}
                    questionName={"มีเสียงดัง อาจสังเกตจากคนที่ยืนห่างกัน 1 เมตร พูดคุยกันแล้วคนฝ่ายหนึ่งไม่ได้ยินว่าอีกฝ่ายพูดอะไรหรือความรู้สึกของบุคคลว่ามีเสียงดังเกิดขึ้นจากสภาพปกติของพื้นที่นั้น ๆ"}
                >
                    {
                        (no12_1.answer === '1' || no12_1.answer === '0') ?
                            no12_1.answer === '1' ?
                                `ถูกรบกวน`
                                : 'ไม่ถูกรบกวน'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question 12.2*/}
                <SubTopic
                    no={'12.2.'}
                    questionName={"ความรู้สึกของบุคคลว่ามีเสียงดังเกิดขึ้นจากสภาพปกติของพื้นที่นั้นๆ"}
                >
                    {
                        (no12_2.answer === '1' || no12_2.answer === '0') ?
                            no12_2.answer === '1' ?
                                `ถูกรบกวน`
                                : 'ไม่ถูกรบกวน'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question 12.3*/}
                <SubTopic
                    no={'12.3.'}
                    questionName={"มีความสั่นสะเทือนโดยจากความรู้สึกหรือการสั่นไหวของวัสดุ หรือภาชนะ สิ่งของต่าง ๆ ในครัวเรือน"}
                >
                    {
                        (no12_3.answer === '1' || no12_3.answer === '0') ?
                            no12_3.answer === '1' ?
                                `ถูกรบกวน`
                                : 'ไม่ถูกรบกวน'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question 12.4*/}
                <SubTopic
                    no={'12.4.'}
                    questionName={"มีฝุ่นละออง (1) ฝุ่นขนาดใหญ่โดยการมองด้วยตาเปล่า (2) ฝุ่นขนาดเล็ก สังเกตจากการสะสมของฝุ่นบนพื้นผิวหน้าของภาชนะอุปกรณ์ เครื่องใช้ภายในบ้าน"}
                >
                    {
                        (no12_4.answer === '1' || no12_4.answer === '0') ?
                            no12_4.answer === '1' ?
                                `ถูกรบกวน`
                                : 'ไม่ถูกรบกวน'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question 12.5*/}
                <SubTopic
                    no={'12.5.'}
                    questionName={"มีกลิ่นเหม็นที่รู้สึกไม่สบาย(โดยสอบถามจากความรู้สึกสัมผัสของบุคคลตั้งแต่ 3 คนขึ้นไป) เช่น วิงเวียนศีรษะ คลื่นไส้ทำให้เกิดความวิตกกังวล รู้สึกอึดอัด เครียด เป็นต้น"}
                >
                    {
                        (no12_5.answer === '1' || no12_5.answer === '0') ?
                            no12_5.answer === '1' ?
                                `ถูกรบกวน`
                                : 'ไม่ถูกรบกวน'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question 12.6*/}
                <SubTopic
                    no={'12.6.'}
                    questionName={"มีน้ำเสียโดยการสังเกตจากกลิ่นและสีที่ผิดไปจากธรรมชาติ"}
                >
                    {
                        (no12_6.answer === '1' || no12_6.answer === '0') ?
                            no12_6.answer === '1' ?
                                `ถูกรบกวน`
                                : 'ไม่ถูกรบกวน'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question 12.7*/}
                <SubTopic
                    no={'12.7.'}
                    questionName={"มีขยะหรือของเสียอันตราย (หลอดไฟ กระป๋องสเปรย์ ถ่านไฟฉาย ฯลฯ) โดยการมองเห็นหรือสังเกต เช่น ปริมาณขยะล้นจากภาชนะรองรับขยะกลิ่นเหม็นจากขยะ ของเสียอันตรายไม่มีการแยกทิ้งอย่างถูกต้อง"}
                >
                    {
                        no12_7.Answer ?
                            no12_7.Answer
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

            </Topic>

            {/*Question 13*/}
            <Topic
                no={13}
                title={"ครัวเรือนมีการป้องกันอุบัติภัยและภัยธรรมชาติอย่างถูกวิธี"}
            >
                {/*Question 13.1*/}
                <DetailTopic
                    no={'13.1.'}
                    questionName={"ครัวเรือนมีการป้องกันอุบัติภัยและภัยธรรมชาติอย่างถูกวิธี หรือไม่"}
                >
                    {data ? answer13_1() : <div>ไม่มีข้อมูล</div>}
                </DetailTopic>

                {/*Question 13.2*/}
                <SubTopic
                    no={'13.2.'}
                    questionName={"ในรอบปีที่ผ่านมา ครัวเรือนประสบภัยธรรมชาติหรือไม่"}
                >
                    {
                        (no13_2.answer === '1' || no13_2.answer === '0') ?
                            no13_2.answer === '1' ?
                                <Answer detail={`ไม่ประสบภัย`} value={''} unit={''}/>
                                :
                                <Answer detail={`ประสบภัย`} value={no13_2.name} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 13.3*/}
                <SubTopic
                    no={'13.3.'}
                    questionName={"ในรอบปีที่ผ่านมา มีคนในครัวเรือนที่ได้รับความเจ็บป่วยจากการทำงาน จนเป็นเหตุให้ต้องหยุดงาน หรือไม่"}
                >
                    {
                        (no13_3.answer === '1' || no13_3.answer === '0') ?
                            no13_3.answer === '1' ?
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                                :
                                <Answer detail={`มี จำนวน`} value={no13_3.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

            {/*Question 14*/}
            <Topic
                no={14}
                title={"ครัวเรือนมีความปลอดภัยในชีวิตและทรัพย์สิน"}
            >
                {/*Question 14.1*/}
                <SubTopic
                    no={'14.1.'}
                    questionName={"ในรอบปีที่ผ่านมา ครัวเรือนนี้ มีคนถูกฆ่าตายหรือไม่"}
                >
                    {
                        (no14_1.answer === '1' || no14_1.answer === '0') ?
                            no14_1.answer !== '1' ?
                                `มี`
                                : `ไม่มี`
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question 14.1*/}
                <SubTopic
                    no={'14.2.'}
                    questionName={"ในรอบปีที่ผ่านมา ครัวเรือนนี้มีคนถูกทำร้ายร่างกาย กระทำอนาจาร ข่มขืน กระทำชำเรา หรือไม่"}
                >
                    {
                        (no14_2.answer === '1' || no14_2.answer === '0') ?
                            no14_2.answer !== '1' ?
                                `มี`
                                : `ไม่มี`
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question 14.1*/}
                <SubTopic
                    no={'14.3.'}
                    questionName={"ในรอบปีที่ผ่านมา ครัวเรือนนี้มีคนถูกประทุษร้ายต่อทรัพย์ (ลักทรัพย์ วิ่งราวทรัพย์ ปล้นทรัพย์ หลอกลวงให้เสียทรัพย์)หรือไม่"}
                >
                    {
                        (no14_3.answer === '1' || no14_3.answer === '0') ?
                            no14_3.answer === '1' ?
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                                :
                                <Answer detail={`มี มูลค่าทรัพย์ที่เสียไป`} value={no14_3.amount} unit={'บาท'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 14.1*/}
                <SubTopic
                    no={'14.4.'}
                    questionName={"ในรอบปีที่ผ่านมา ครัวเรือนนี้ถูกบุกรุกที่อยู่อาศัย หรือไม่"}
                >
                    {
                        (no14_4.answer === '1' || no14_4.answer === '0') ?
                            no14_4.answer !== '1' ?
                                `มี`
                                : `ไม่มี`
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question 14.1*/}
                <SubTopic
                    no={'14.5.'}
                    questionName={"ในรอบปีที่ผ่านมา ครัวเรือนนี้ มีอาชญากรรมอื่น ๆ ที่เกี่ยวกับชีวิตและทรัพย์สิน อย่างใดอย่างหนึ่งหรือหลายอย่าง หรือไม่"}
                >
                    {
                        (no14_5.answer === '1' || no14_5.answer === '0') ?
                            no14_5.answer !== '1' ?
                                `มี`
                                : `ไม่มี`
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>
            </Topic>

        </div>
    )
}
