import React, {useEffect, useState} from "react";
import './SettingTable.css'
import FormatContiner from "../../../components/FormatContainer";
import {Breadcrumb, Icon, Table, Input, Button} from "semantic-ui-react";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default ({index, item, data, handleDelete, handleSummit, ...props}) => {

    const [edit_detail, set_edit_detail] = useState(false);
    const [detail_text, set_detail_text] = useState(item.name);

    return (

        <Table.Row>
            <Table.Cell>{index + 1}</Table.Cell>

            <Table.Cell colSpan={'2'}>

                {edit_detail ?
                    <div className={'block-table'}>
                        <div className={'edit-container'}>
                            <input
                                value={detail_text}
                                onChange={(event)=> {
                                    set_detail_text(event.target.value)
                                }
                                }
                            />
                            <a>
                                <Icon name={'close'}/>
                            </a>
                        </div>
                        <button
                            className={'save-button'}
                            onClick={()=> {
                                handleSummit(detail_text,item._id)
                                set_edit_detail(false)
                            }
                            }
                        >
                            <span>บันทึก</span>
                        </button>
                        <a className={'close-button'} onClick={() => set_edit_detail(false)}>
                            <span>ยกเลิก</span>
                        </a>
                    </div>
                    :
                    <div className={'block-table'}>

                        <div className={'detail-table'}>{item.name}</div>
                        <button
                            className={'edit-button'}
                            onClick={() =>
                                set_edit_detail(true)

                            }
                        >
                            <Icon name={'pencil alternate'}/>
                        </button>
                        <button
                            className={'del-button'}
                            onClick={() => {
                                handleDelete(item._id,data)
                            }
                            }
                        >
                            <Icon name={'trash alternate'}/>
                        </button>
                    </div>
                }
            </Table.Cell>
        </Table.Row>

    )
}
