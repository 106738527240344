import React, {Component} from "react";
import {Grid, Dropdown, Modal, Icon,} from "semantic-ui-react";
import axios from "axios"
import PageLoader from "../../../../components/RouteLoader/Loader";
import Slider from "react-slick";
import Config from "../../../../constants/Config"
import ProgressDash from "../dashComponents/ProgressBar/progressDash";
import GraphDash from "../dashComponents/GraphBar/GraphDash";
import BarDash from "../dashComponents/Bars/Bars";
import SignBoard from "../dashComponents/SignBoard/SignBoard";
import PieDash from "../dashComponents/Pies/PieDash";
import HorizonBarDash from "../dashComponents/HorizonBars/BarDash";
import DonutDash from "../dashComponents/Pies/DonutDash";
import ProgressOnce from "../dashComponents/ProgressBar/ProgressOnce";
import SelectComp from "../dashComponents/selectComponent/SelectComp";
import BarAlone from "../dashComponents/Bars/BarAlone";
import ButtonExport from "../dashComponents/ExportComponent/ButtonExport";
import "./mainStyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles/fiveStyle.css"
import "./Styles/fourStyle.css"
import "./Styles/threeStyle.css"
import "./Styles/twoStyle.css"
import "./Styles/oneStyle.css"
import "./Styles/midStyle.css"
import "./Styles/topStyle.css"
import "../../../ApproveScreen/ApproveScreen.css"
export default class MainComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            selectActive: ["actives", "false", "false", "false", "false"],
            areasName: [],
            areaModal: false,
            communitiesName: [],
            communitiesSelect: "",
            communitiesData: [],
            areaID_modal: "",
            communitiesID_modal: [],
            area_option: [],
            showArea: "",
            showCommunities: "",
            communitiesID: [],
            dataLandLive: [],
            dataLandWork: [],
            dataNumPopulationBar: {},
            dataAllHouse: {},
            dataTapbar: {},
            dataPersonAddress: {},
            dataGender: {},
            dataPhyStatus: [],
            dataAge: {},
            dataPopulation: {},
            dataHealth: {},
            dataCongenital: {},
            dataExercise: {},
            dataMedicalRight: [],
            dataHouse: {},
            dataPollution: [],
            dataDiaster: {},
            dataHouseStable: {},
            dataHouseDurable: {},
            dataWaterConsumption: {},
            dataWaterComsume: {},
            dataHouseTrespass: {},
            dataInvest2: [],
            dataSaving2: [],
            dataIncome: {},
            dataTotalIncome: {},
            dataDeposit: {},
            dataExpenditure: {},
            dataDepositType: {},
            dataAlcoholPerWeek: {},
            dataSmokePerDay: {},
            dataSmokeRange: [],
            dataDrinkRange: [],
            dataReligion: {},
            dataWarmth: {},
            dataCanReadWring: {},
            isLoading: false,
            HomeIsSuccess: false,
            PopulationIsSuccess: false,
            Answer1IsSuccess: false,
            Answer2IsSuccess: false,
            Answer3IsSuccess: false,
            Answer4IsSuccess: false,
            Answer5IsSuccess: false
        };
    }

    componentDidMount() {
        this.fecthData()
        this.getFilter()
    }

    applyFilter = () => {
        this.setState({
            modalIsOpen: false,
            showArea: this.state.area_option.name,
            showCommunities: this.state.communitiesSelect,
            PopulationIsSuccess: false,
            HomeIsSuccess: false,
            Answer1IsSuccess: false,
            Answer2IsSuccess: false,
            Answer3IsSuccess: false,
            Answer4IsSuccess: false,
            Answer5IsSuccess: false
        })
        this.fecthData(this.state.areaID_modal, this.state.communitiesID_modal)
    }

    // fetch data function
    fecthData(area, communities) {
        let commuID = ""
        let dashData = ""
        let dashHouse = ""
        let dashAnswer1 = ""
        let dashAnswer2 = ""
        let dashAnswer5 = ""
        let dashAnswer4 = ""
        let dashAnswer3 = ""
        let config = Config.url

        //check value from filter applyFilter()
        if (area || communities) {
            commuID = communities.join('.')
            dashData = `${config}/api/v1/dashboard_population?a_id=${area}&c_id=${commuID}`
            dashHouse = `${config}/api/v1/dashboard_house?a_id=${area}&c_id=${commuID}`
            dashAnswer1 = `${config}/api/v1/dashboard_group_answer/1?a_id=${area}&c_id=${commuID}`
            dashAnswer2 = `${config}/api/v1/dashboard_group_answer/2?a_id=${area}&c_id=${commuID}`
            dashAnswer5 = `${config}/api/v1/dashboard_group_answer/5?a_id=${area}&c_id=${commuID}`
            dashAnswer4 = `${config}/api/v1/dashboard_group_answer/4?a_id=${area}&c_id=${commuID}`
            dashAnswer3 = `${config}/api/v1/dashboard_group_answer/3?a_id=${area}&c_id=${commuID}`
        } else {
            dashData = config + "/api/v1/dashboard_population"
            dashHouse = config + "/api/v1/dashboard_house"
            dashAnswer1 = config + "/api/v1/dashboard_group_answer/1"
            dashAnswer2 = config + "/api/v1/dashboard_group_answer/2"
            dashAnswer5 = config + "/api/v1/dashboard_group_answer/5"
            dashAnswer4 = config + "/api/v1/dashboard_group_answer/4"
            dashAnswer3 = config + "/api/v1/dashboard_group_answer/3"
        }
        //get token
        const AuthStr = 'Bearer '.concat(localStorage.getItem('access_token'))

        //Get Population data from api
        axios.get(dashData, {headers: {Authorization: AuthStr}})
            .then(response => {
                let data = response.data.value.dashboard;
                var dataGender = {}, dataAddress = [], dataPatient = [], dataAge = [], dataTabBar = {};

                //Set Male and Female data
                dataGender["male"] = {label: "เพศชาย", amount: data.gender.male || 0};
                dataGender["female"] = ({label: "เพศหญิง", amount: data.gender.female || 0});

                //Set physical status data
                dataPatient.push({
                    id: 0,
                    labels: "ปกติ",
                    data: data.physical_status["normal"],
                    backgroundColor: "#DFBB80",
                    hoverBackgroundColor: "#DFBC80"
                });
                dataPatient.push({
                    id: 1,
                    labels: "ผู้พิการ",
                    data: data.physical_status["disabled"],
                    backgroundColor: "#D17958",
                    hoverBackgroundColor: "#D17A58"
                });
                dataPatient.push({
                    id: 2,
                    labels: "ผู้ป่วยเรื้อรัง",
                    data: data.physical_status["chronic"],
                    backgroundColor: "#535A4B",
                    hoverBackgroundColor: "#535B4B"
                });

                //Set Adrees in and out
                dataAddress["in"] = {label: "บุคคลในพื้นที่", amount: data.address.in};
                dataAddress["out"] = {label: "บุคคลนอกพื้นที่", amount: data.address.out};

                //Set age data
                var i = 0;
                let sumAge = 0, ageData = [];
                for (var key in data.age) {
                    sumAge += data.age[key]

                    // if (key === "age_0_to_14") {
                    //     ageData.push({id: i, textDesc: "เด็ก(อายุระหว่าง 0-14 ปี)", amount: data.age[key]});
                    // } else if (key === "age_15_to_59") {
                    //     ageData.push({id: i, textDesc: "วัยแรงงาน(อายุระหว่าง 15-59 ปี)", amount: data.age[key]});
                    // } else {
                    //     ageData.push({id: i, textDesc: "ผู้สูงอายุ(อายุ 60 ปีขึ้นไป)", amount: data.age[key]});
                    // }

                    if (key === "age_0_to_6") {
                        ageData.push({id: 1, textDesc: "ช่วงอายุระหว่าง 0-6 ปี", amount: data.age[key]});
                    } else if (key === "age_7_to_15") {
                        ageData.push({id: 2, textDesc: "ช่วงอายุระหว่าง 7-15 ปี", amount: data.age[key]});
                    } else if (key === "age_15_to_25") {
                        ageData.push({id: 3, textDesc: "ช่วงอายุระหว่าง 16-25 ปี", amount: data.age[key]});
                    } else if (key === "age_16_to_25") {
                        ageData.push({id: 4, textDesc: "ช่วงอายุระหว่าง 16-25 ปี", amount: data.age[key]});
                    } else if (key === "age_26_to_40") {
                        ageData.push({id: 5, textDesc: "ช่วงอายุระหว่าง 26-40 ปี", amount: data.age[key]});
                    } else if (key === "age_41_to_59") {
                        ageData.push({id: 6, textDesc: "ช่วงอายุระหว่าง 41-59 ปี", amount: data.age[key]});
                    } else if (key === "age_60_to_upper"){
                        ageData.push({id: 7, textDesc: "ช่วงอายุระหว่าง 60 ปีขึ้นไป", amount: data.age[key]});
                    }

                    i++
                }

                ageData.sort(function(a, b){return a.id - b.id})

                //Set tabbar data
                let occuLabel = [], occuData = [], eduLabel = [], eduData = [], reliLabel = [], reliData = []

                //Occupation
                for (var key in data.occupation) {
                    occuLabel.push(key);
                    occuData.push(data.occupation[key])
                }
                // Education
                for (var key in data.education) {
                    eduLabel.push(key);
                    eduData.push(data.education[key])
                }
                // Religion
                for (var key in data.religion) {
                    reliLabel.push(key);
                    reliData.push(data.religion[key])
                }

                //Convert array data to complete form
                dataAge = {
                    allPopulate: sumAge,
                    datasets: ageData,
                    header: "ช่วงอายุประชากร",
                    img: "chart bar",
                }
                dataTabBar = {
                    religion: {
                        labels: reliLabel,
                        datasets: [
                            {
                                backgroundColor: "rgba(214,123,92,.8)",
                                borderColor: "rgba(214,123,102,1)",
                                borderWidth: 1,
                                hoverBackgroundColor: "rgba(214,123,92,1)",
                                hoverBorderColor: "rgba(214,123,102,1)",
                                data: reliData,
                            },
                        ],
                    },
                    education: {
                        labels: eduLabel,
                        datasets: [
                            {
                                backgroundColor: "rgba(193,123,14,.8)",
                                borderColor: "rgba(193,123,24,1)",
                                borderWidth: 1,
                                hoverBackgroundColor: "rgba(193,123,14,1)",
                                hoverBorderColor: "rgba(193,123,24,1)",
                                data: eduData,
                            },
                        ],
                    },
                    occupation: {
                        labels: occuLabel,
                        datasets: [
                            {

                                backgroundColor: "rgba(210,160,54,.8)",
                                borderColor: "rgba(210,160,84,1)",
                                borderWidth: 1,
                                hoverBackgroundColor: "rgba(210,160,54,1)",
                                hoverBorderColor: "rgba(210,160,84,1)",
                                data: occuData,
                            },
                        ],
                    },
                }

                // set complete form to state
                this.setState({
                    dataTapbar: dataTabBar,
                    dataPersonAddress: dataAddress,
                    dataGender: dataGender,
                    dataAge: dataAge,
                    dataPopulation: data.population,
                    dataPhyStatus: dataPatient
                })
            }).then(() => {
            this.setState({
                PopulationIsSuccess: true
            })
        })

        //get house data from api
        axios.get(dashHouse, {headers: {Authorization: AuthStr}})
            .then(response => {
                let data = response.data.value.dashboard;
                let numHouse = [], dataLandLive = [], dataLandWork = [], dataNumPopulation = {}, numPopulateLabel = [],
                    numPopulateData = []

                // Set house no. status
                numHouse["have"] = ({label: "มีบ้านเลขที่", amount: data.house_no.have});
                numHouse["not_have"] = ({label: "ไม่มีเลขที่", amount: data.house_no.not_have});

                // Set land live data
                var i = 0;
                for (var key in data.land_live_document_type) {
                    dataLandLive.push({
                        id: i,
                        labels: key,
                        data: data.land_live_document_type[key],
                        backgroundColor: "#FF6384",
                        hoverBackgroundColor: "#36A2EB"
                    });
                    i++
                }
                // Set live work data
                i = 0;
                for (var key in data.land_work_document_type) {
                    dataLandWork.push({
                        id: i,
                        labels: key,
                        data: data.land_work_document_type[key],
                        backgroundColor: "#FF6384",
                        hoverBackgroundColor: "#36A2EB"
                    });
                    i++
                }

                // Set number of population per zone
                for (var key in data.center) {
                    numPopulateLabel.push(key);
                    numPopulateData.push(data.center[key])

                }

                // convert array to complete form
                dataNumPopulation = {
                    labels: numPopulateLabel,
                    datasets: [
                        {
                            backgroundColor: "rgba(225,158,87,.8)",
                            borderColor: "rgba(225,158,107,1)",
                            borderWidth: 1,
                            hoverBackgroundColor: "rgba(225,158,87,1)",
                            hoverBorderColor: "rgba(225,158,107,1)",
                            data: numPopulateData,
                        },
                    ],
                }

                //set complete form to state
                this.setState({
                    dataHouse: numHouse,
                    dataLandLive: dataLandLive,
                    dataLandWork: dataLandWork,
                    dataNumPopulationBar: dataNumPopulation
                })
            }).then(() => {
            this.setState({
                HomeIsSuccess: true
            })
        })

        //Get answer 1 data from api
        axios.get(dashAnswer1, {headers: {Authorization: AuthStr}})
            .then(response => {
                let data = response.data.value.dashboard;
                let dataHealth = {}

                // Find number of people heath check
                const allPerson = data.health_over_35.check + data.health_over_35.not_check
                dataHealth = {
                    amount: allPerson,
                    numPos: data.health_over_35.check,
                    header: "ตรวจสุขภาพประจำปี",
                    img: "doctor"
                }

                // Set congenital data
                let i = 0
                let sumCongenital = 0, congenitalData = [];
                for (var key in data.congenital_disease) {

                    //Find sum of people congenital
                    sumCongenital += data.congenital_disease[key]
                    congenitalData.push({
                        id: i,
                        textDesc: key,
                        amount: data.congenital_disease[key]
                    });
                    i++
                }

                // Set exercise data
                i = 0
                let sumExercise = 0, exerciseData = [];
                for (var key in data.exercise) {

                    // Find sum of people exercise
                    sumExercise += data.exercise[key]
                    exerciseData.push({
                        id: i,
                        textDesc: key,
                        amount: data.exercise[key]
                    });
                    i++
                }

                // Set medical right data
                i = 0
                let medicalData = [];
                for (var key in data.medical_rights) {
                    medicalData.push({
                        id: i,
                        label: key,
                        data: data.medical_rights[key]
                    });
                    i++
                }

                // Set complete form to state
                this.setState({
                    dataHealth: dataHealth,
                    dataCongenital: {
                        allPopulate: sumCongenital,
                        datasets: congenitalData,
                        header: "โรคประจำตัว",
                        img: "wheelchair"
                    },
                    dataExercise: {
                        allPopulate: sumExercise,
                        datasets: exerciseData,
                        header: "การออกกำลังกาย",
                        img: "child"
                    },
                    dataMedicalRight: medicalData
                })
            })
            .then(() => {
                this.setState({
                    Answer1IsSuccess: true
                })
            })

        //Get answer 2 data from api
        axios.get(dashAnswer2, {headers: {Authorization: AuthStr}})
            .then(response => {
                let data = response.data.value.dashboard;
                let dataHouse = {}, dataPollution = [], dataDiaster = [], dataHouseStable = [], dataHouseDurable = [],
                    dataHouseTrespass = [],
                    dataWaterConsumption = [], dataWaterComsume = []

                // Set sum if house
                dataHouse = {
                    label: "จำนวนครัวเรือนทั้งหมด",
                    amount: data.house
                }

                // Set pollution data
                let i = 0
                for (var key in data.pollution) {
                    dataPollution.push({
                        id: i,
                        label: key,
                        data: data.pollution[key]
                    });
                    i++
                }

                // Set natural diaster data
                const allDiaster = data.natural_disaster.have + data.natural_disaster.no
                dataDiaster = {
                    amount: allDiaster,
                    numPos: data.natural_disaster.have,
                    header: "จำนวนครัวเรือนที่ประสบภัยธรรมชาติ",
                    img: "cloud"
                }

                // Set house stable data
                const allStable = data.house_stable.have + data.house_stable.no
                dataHouseStable = {
                    amount: allStable,
                    numPos: data.house_stable.have,
                    header: "ความมั่นคงของครัวเรือน",
                    img: "home"
                }

                // Set house darable data
                const allDurable = data.house_durable.have + data.house_durable.no
                dataHouseDurable = {
                    amount: allDurable,
                    numPos: data.house_durable.have,
                    header: "ความคงทนของครัวเรือน",
                    img: "home"
                }

                // Set house trespass data
                const allTrespass = data.house_trespass.have + data.house_trespass.no
                dataHouseTrespass = {
                    amount: allTrespass,
                    numPos: data.house_trespass.have,
                    header: "จำนวนครัวเรือนที่ถูกบุกรุกอาศัย",
                    img: "truck"
                }

                // Set water comsumption data
                const sumComsumption = data.water_user.have + data.water_user.no
                dataWaterConsumption = {
                    amount: sumComsumption,
                    numPos: data.water_user.have,
                    header: "จำนวนครัวเรือนที่ใช้น้ำอุปโภค",
                    img: "tint"
                }

                // Set water consume data
                const sumComsume = data.water_drink.have + data.water_drink.no
                dataWaterComsume = {
                    amount: sumComsume,
                    numPos: data.water_drink.have,
                    header: "จำนวนครัวเรือนที่ใช้น้ำบริโภค",
                    img: "tint"
                }

                // Set complete form to state
                this.setState({
                    dataAllHouse: dataHouse,
                    dataPollution: dataPollution,
                    dataDiaster: dataDiaster,
                    dataHouseTrespass: dataHouseTrespass,
                    dataHouseDurable: dataHouseDurable,
                    dataHouseStable: dataHouseStable,
                    dataWaterConsumption: dataWaterConsumption,
                    dataWaterComsume: dataWaterComsume
                })
            }).then(() => {
            this.setState({
                Answer2IsSuccess: true
            })
        })

        // Get Answer2 data form api
        axios.get(dashAnswer3, {headers: {Authorization: AuthStr}})
            .then(response => {
                let data = response.data.value.dashboard;
                let rawCanRead = [];
                let i = 0;
                let sumPerson = 0, personData = [];

                // Set read and write data
                for (var key in data.read_write) {
                    if (key === "จำนวนคนอายุ 15 - 59") {
                        continue
                    } else {
                        personData.push({id: i, textDesc: key, amount: data.read_write[key]});
                        sumPerson += data.read_write[key]
                    }
                    i++
                }
                rawCanRead = {
                    allPopulate: sumPerson,
                    datasets: personData,
                    header: "จำนวนประชากรที่อ่านออกเขียนได้",
                    img: "language",
                }

                // Set complete form to state
                this.setState({
                    dataCanReadWring: rawCanRead
                })
            }).then(() => {
            this.setState({
                Answer3IsSuccess: true
            })
        })

        // Get answer 4 data form api
        axios.get(dashAnswer4, {headers: {Authorization: AuthStr}})
            .then(response => {
                let data = response.data.value.dashboard;
                let rawInvest = [], rawSaving = [], rawIncomeLabel = [], rawIncomeData = [], rawExpenditureLabel = [],
                    rawExpenditureData = [],
                    rawTotalIncomeData = [], rawTotalIncomeLabel = [], rawDepositData = [], rawDepositLabel = []

                // Set investment data
                var i = 0;
                for (var key in data.capital_type) {
                    rawInvest.push({
                        id: i,
                        labels: key,
                        data: data.capital_type[key],

                    });
                    i++
                }

                // Set bank saving data
                i = 0;
                for (var key in data.deposit_type) {
                    rawSaving.push({
                        id: i,
                        labels: key,
                        data: data.deposit_type[key],
                    });

                    i++
                }

                // Set income data
                for (var key in data.income) {
                    rawIncomeLabel.push(
                        key
                    )
                    rawIncomeData.push(
                        data.income[key]
                    )
                }

                // Set expense data
                for (var key in data.expenditure) {

                    rawExpenditureLabel.push(
                        key
                    )
                    rawExpenditureData.push(
                        data.expenditure[key]
                    )
                }

                // Set total income data
                for (var key in data.total_income) {
                    rawTotalIncomeLabel.push(
                        key
                    )
                    rawTotalIncomeData.push(
                        data.total_income[key]
                    )
                }

                // Set deposit data
                for (var key in data.deposit) {
                    rawDepositLabel.push(
                        key
                    )
                    rawDepositData.push(
                        data.deposit[key]
                    )
                }

                // Set complete form to state
                this.setState({
                    dataInvest2: rawInvest,
                    dataSaving2: rawSaving,
                    dataIncome: {
                        labels: rawIncomeLabel,
                        data: rawIncomeData
                    },
                    dataExpenditure: {
                        labels: rawExpenditureLabel,
                        data: rawExpenditureData
                    }
                    ,
                    dataTotalIncome: {
                        labels: rawTotalIncomeLabel,
                        data: rawTotalIncomeData
                    }
                    ,
                    dataDeposit: {
                        labels: rawDepositLabel,
                        data: rawDepositData
                    }
                })
            }).then(() => {
            this.setState({
                Answer4IsSuccess: true
            })
        })

        // Get answer5 data form api
        axios.get(dashAnswer5, {headers: {Authorization: AuthStr}})
            .then(response => {
                let data = response.data.value.dashboard;
                let rawAlcoholData = [], rawAlcohoLabel = [], rawSmokeData = [], rawSmokeLabel = [], rawSmokeRange = [],
                    rawDrinkRange = [],
                    rawWarmthData = [], rawReligionData = {}


                // Set drinking per week data
                for (var key in data.alcohol_per_week) {
                    rawAlcohoLabel.push(
                        key
                    )
                    rawAlcoholData.push(
                        data.alcohol_per_week[key]
                    )
                }

                // Set smoking per day data
                for (var key in data.smoke_per_day) {
                    rawSmokeLabel.push(
                        key
                    )
                    rawSmokeData.push(
                        data.smoke_per_day[key]
                    )
                }

                // Set drinking age range data
                var i = 0;
                for (var key in data.age_range_drink_alcohol) {
                    rawDrinkRange.push({
                        id: i,
                        labels: key,
                        data: data.age_range_drink_alcohol[key],
                    });
                    i++
                }

                // Set smoking age range data
                i = 0;
                for (var key in data.age_range_smoke) {
                    rawSmokeRange.push({
                        id: i,
                        labels: key,
                        data: data.age_range_smoke[key],
                    });
                    i++
                }

                // Set warmth data
                i = 0
                let sumPerson = [], personData = [];
                for (var key in data.warmth_of_house) {
                    sumPerson.push(data.warmth_of_house[key].total);
                    if (key === "alone") {
                        personData.push({id: i, textDesc: "กรณีอยู่คนเดียว", amount: data.warmth_of_house[key].have});
                    } else {
                        personData.push({
                            id: i,
                            textDesc: "กรณีอยู่ 2 คนขึ้นไป",
                            amount: data.warmth_of_house[key].have
                        });
                    }
                    i++
                }
                rawWarmthData = {
                    allPopulate: sumPerson,
                    datasets: personData,
                    header: "ความเฉลี่ยความอบอุ่น",
                    img: "users",
                }

                // Set religion activities data
                const allPerson = data.religion.do + data.religion.do_not
                rawReligionData = {
                    amount: allPerson,
                    numPos: data.religion.do,
                    header: "จำนวนประชากรที่ร่วมกิจกรรมศาสนา",
                    img: "university"
                }

                // Set complete form to state
                this.setState({
                    dataAlcoholPerWeek: {
                        labels: rawAlcohoLabel,
                        data: rawAlcoholData
                    },
                    dataSmokePerDay: {
                        labels: rawSmokeLabel,
                        data: rawSmokeData
                    },
                    dataSmokeRange: rawSmokeRange,
                    dataDrinkRange: rawDrinkRange,
                    dataReligion: rawReligionData,
                    dataWarmth: rawWarmthData
                })
            }).then(() => {
            this.setState({
                Answer5IsSuccess: true
            })
        })
    }

    //get data to filter component
    getFilter = () => {
        const AuthStr = 'Bearer '.concat(localStorage.getItem('access_token'))
        let config = Config.url
        const areas_communities = config + "/api/v1/areas_communities"
        axios.get(areas_communities, {headers: {Authorization: AuthStr}})
            .then(response => {
                let communityArr = [];
                if(response.data.value){
                    for (var i = 0; i < response.data.value.length; i++) {
                        communityArr.push(response.data.value[i].name)
                    }
                }
                this.setState({
                    areasName: response.data.value,
                    communitiesName: communityArr
                })
            })
    }

    //Selete slider tab function
    onSelectTab = (e) => {
        this.slider.slickGoTo(e, false);
        const curAct = ["false", "false", "false", "false", "false"];
        for (let i = 0; i <= e; i++) {
            if (i === e) {
                curAct[i] = "actives";
            }
        }
        this.setState({
            selectActive: curAct,
        });
    }

    // Toggle Modal function
    activeModal = (status) => {
        this.setState({
            modalIsOpen: status
        })
    }

    render() {
        //slider setting
        const settings = {
            dots: true,
            lazyLoad: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
        }
        return (
            <div className={'main-layout-container'}>

                {/*Population Components*/}
                <div>
                    <Grid container doubling columns={2} divided className={'main-dash-container'}>
                        <Grid.Row stretched>
                            <Grid.Column width={16}>
                                <div className={'header-dash-container'}>
                                    <div className={'left-header-container'}>
                                        <p className={'header-dash'}>หน้าแรก</p>
                                        <p className={'header-dash-text'}>ข้อมูลประจำปี 2564</p>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>

                        {/*show area and communities name*/}
                        <Grid.Row stretched className={'main-show-area-container'}>
                            <Grid.Column className={'show-area-container'} width={16}>
                                <div className={'title-area-container'}>
                                    <div className={'title-area-icon'}>
                                        <Icon className={'icon-area'} name={'home'} size={'large'}/>
                                    </div>

                                    {/*Show area and communities label*/}
                                    <span
                                        className={this.state.showCommunities?.length > 0 ? '' : 'border-radius-bot-right'}><p>{this.state.showArea ? this.state.showArea : "ศูนย์ทั้งหมด"}</p>
                                    </span>

                                    {/*Map communities label*/}
                                    {this.state.showCommunities?.length > 0 ?
                                        <div className={'area-bar scale-up-hor-left'}>
                                            <div className={'area-text'}>
                                                {
                                                    this.state.showCommunities.map(item => {
                                                        return <div className={'all-commu-name slide-right '}>
                                                            <p>{item}</p>
                                                            <p className={'community-name-space'}>|</p>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    {/* Check loader */}
                    {this.state.PopulationIsSuccess ? (

                            // Population Data
                            <Grid container doubling columns={2} divided className={'main-dash-container'}>
                                <Grid.Row stretched className={'bg-top-container'}>
                                    {/*Topic population information*/}
                                    <Grid.Column width={16}>
                                        <div className={'main-title-dash'}>
                                            <div className={'left-header-container'}>
                                                <p className={'header-population-text'}>ข้อมูลประชากร</p>
                                            </div>

                                            {/*filter area and communities and export components*/}
                                            <div className={'right-header-container'}>

                                                {/*filter component*/}
                                                <button className={'filter-button'} onClick={() => this.activeModal(true)}>
                                                    <Icon
                                                        name={"filter"}/> <p>ตัวกรอง</p>
                                                </button>

                                                {/*Export component*/}
                                                {/*<ButtonExport areaID={this.state.areaID_modal} commuID={this.state.communitiesID_modal}/>*/}
                                            </div>

                                            {/*Modal component*/}
                                            <Modal
                                                size={"large"}
                                                open={this.state.modalIsOpen}
                                                className={'filter-modal-screen'}
                                            >
                                                <div className={'modal-header'}>
                                                    <div className={'left-modal-header'}>
                                                        <Icon name={'filter'}/>
                                                        <p>ตัวกรอง</p>
                                                    </div>
                                                    <button onClick={() => this.activeModal(false)}
                                                            className={'close-modal-button'}>
                                                        <Icon name={'close'} size={'small'} className={'close-modal'}/>
                                                    </button>
                                                </div>
                                                <div className={'modal-content'}>

                                                    <div className={'modal-detail'}>

                                                        {/*Area dropdown*/}
                                                        <Dropdown
                                                            placeholder='เลือกศูนย์ฯที่รับผิดชอบ'
                                                            search
                                                            fluid
                                                            clearable
                                                            selection
                                                            value={this.state.areaID_modal}
                                                            options={
                                                                this.state.areasName?.length > 0 ?
                                                                    this.state.areasName.map((item) => {
                                                                        return (
                                                                            {
                                                                                key: item._id,
                                                                                text: item.name,
                                                                                value: item._id
                                                                            }
                                                                        )
                                                                    })
                                                                    :
                                                                    []
                                                            }
                                                            onChange={((event, data) => {
                                                                let area = this.state.areasName.find((item) => {
                                                                    return item._id === data.value
                                                                })

                                                                if (area) {

                                                                    this.setState({
                                                                        areaModal: true,
                                                                        communitiesData: area.community,
                                                                        areaID_modal: data.value,
                                                                        area_option: {id: area._id, name: area.name}
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        areaModal: false,
                                                                        communitiesData: [],
                                                                        communitiesSelect: "",
                                                                        areaID_modal: "",
                                                                        area_option: "",
                                                                        communitiesID_modal: [],
                                                                    })
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                    <div className={'modal-detail'}>

                                                        {/*Communities dropdown*/}
                                                        <Dropdown
                                                            placeholder='เลือกเขตชุมชนที่รับผิดชอบ'
                                                            search
                                                            fluid
                                                            clearable
                                                            multiple
                                                            selection
                                                            disabled={!this.state.areaModal}
                                                            value={this.state.communitiesID_modal}
                                                            options={
                                                                this.state.communitiesData?.length > 0 ?
                                                                    this.state.communitiesData.map((item) => {
                                                                        return (
                                                                            {
                                                                                key: item._id,
                                                                                text: item.name,
                                                                                value: item._id
                                                                            }
                                                                        )
                                                                    })
                                                                    :
                                                                    []
                                                            }
                                                            onChange={((event, data) => {
                                                                let communityName = [];
                                                                for (var i = 0; i < data.value.length; i++) {
                                                                    for (var j = 0; j < data.options.length; j++) {
                                                                        if (data.value[i] === data.options[j].key) {
                                                                            communityName.push(data.options[j].text)
                                                                        }
                                                                    }
                                                                }
                                                                this.setState({
                                                                    communitiesID_modal: data.value,
                                                                    communitiesSelect: communityName
                                                                })
                                                            })}

                                                        />
                                                    </div>
                                                    <div className={'button-filter-modal'}>
                                                        <a onClick={() => this.setState({
                                                            communitiesData: [],
                                                            communitiesSelect: "",
                                                            areaID: [],
                                                            areaID_modal: "",
                                                            area_option: "",
                                                            communitiesID_modal: []
                                                        })}>
                                                            ล้างข้อมูลทั้งหมด
                                                        </a>
                                                        <button className={'button-save-filter-modal'}
                                                                onClick={() => this.applyFilter()}>
                                                            กรองข้อมูล
                                                        </button>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>
                                    </Grid.Column>

                                    {/*Component population information */}
                                    <Grid.Column className={'main-left-population-container'}>
                                        <div className={'flex-pop-info'}>

                                            {/*All population component*/}
                                            <div className="graph-top">
                                                <GraphDash data={this.state.dataPopulation}/>
                                            </div>

                                            {/*Tabbar component*/}
                                            <BarDash
                                                data={this.state.dataTapbar}
                                                img="chart bar outline"
                                            />
                                        </div>
                                    </Grid.Column>

                                    {/*Population*/}
                                    <Grid.Column>
                                        <div className="population-right-container">
                                            <div className="child">
                                                <div className="child-col1">

                                                    {/* Component จำนวนเพศชาย */}
                                                    <SignBoard
                                                        data={this.state.dataGender.male}
                                                        unit="คน"
                                                        img="male"
                                                        color="#72A7D8"
                                                    />
                                                </div>
                                                <div className="child-col2">

                                                    {/* Component จำนวนเพศหญิง*/}
                                                    <SignBoard
                                                        data={this.state.dataGender.female}
                                                        unit="คน"
                                                        img="female"
                                                        color="#E2668D"
                                                    />
                                                </div>
                                            </div>
                                            <div className="child-prog">

                                                {/* Component ช่วงอายุประชากร*/}
                                                <ProgressDash
                                                    datasets={this.state.dataAge.datasets}
                                                    header={this.state.dataAge.header}
                                                    img={this.state.dataAge.img}
                                                    allPopulate={this.state.dataAge.allPopulate}
                                                    showIcon={false}
                                                    unit="คน"
                                                />
                                            </div>
                                            <div className="child">
                                                <div className="child-col1">

                                                    {/* Wetget บุคคลในพื้นที่ */}
                                                    <SignBoard
                                                        data={this.state.dataPersonAddress.in}
                                                        sex="บุคคลในพื้นที่"
                                                        amount="10000"
                                                        unit="คน"
                                                        img="male"
                                                        color="#72A7D8"
                                                    />
                                                </div>
                                                <div className="child-col2">

                                                    {/* Wetget บุคคลนอกพื้นที่ */}
                                                    <SignBoard
                                                        data={this.state.dataPersonAddress.out}
                                                        sex="บุคคลนอกพื้นที่"
                                                        amount="10000"
                                                        unit="คน"
                                                        img="male"
                                                        color="#72A7D8"
                                                    />
                                                </div>
                                            </div>
                                            <div className="child-pie">

                                                {/* Component สถานะภาพร่างกาย*/}
                                                <PieDash
                                                    data={this.state.dataPhyStatus}
                                                    img="bed"
                                                    header="สถานะภาพร่างกาย"
                                                    height={175}
                                                />
                                            </div>
                                        </div>
                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>
                        ) :
                        <Grid.Row stretched className={'loader-container'}>
                            <div style={{width: '100%'}}><PageLoader/></div>
                        </Grid.Row>
                    }

                    {/* Check Loader */}
                    {this.state.HomeIsSuccess ? (
                            <Grid container doubling columns={2} divided className={'main-dash-container'}>
                                <Grid.Row stretched className={'bg-top-container2'}>
                                    {/*Topic family infomation*/}
                                    <Grid.Column width={16}>
                                        <p className={'header-home-text'}>ข้อมูลครัวเรือน</p>
                                    </Grid.Column>


                                    {/*Component location information*/}
                                    <Grid.Column>
                                        <div className="main-mid-left-container">
                                            <div className="child-sign">
                                                <div className="child-sign-col1">

                                                    {/* Component จำนวนคนที่มีบ้านเลขที่ */}
                                                    <SignBoard
                                                        data={this.state.dataHouse.have}
                                                        sex="มีบ้านเลขที่"
                                                        amount="10000"
                                                        unit="หลัง"
                                                        img="home"
                                                        color="#E2668D"
                                                    />
                                                </div>
                                                <div className="child-sign-col2">
                                                    {/* Component จำนวนคนที่ไม่มีบ้านเลขที่ */}
                                                    <SignBoard
                                                        data={this.state.dataHouse.not_have}
                                                        sex="ไม่มีบ้านเลขที่"
                                                        amount="10000"
                                                        unit="หลัง"
                                                        img="home"
                                                        color="#E2668D"
                                                    />
                                                </div>
                                            </div>
                                            <div className="child-pie">
                                                <div className="child-pie-col">

                                                    {/* Component  ประเภทที่ตั้งพักอาศัย */}
                                                    <PieDash
                                                        data={this.state.dataLandLive}
                                                        img="home"
                                                        header="ที่ตั้งที่พักอาศัย"
                                                    />
                                                </div>
                                                <div className="child-pie-col">

                                                    {/* Component  ที่ดินประกอบอาชีพ */}
                                                    <PieDash
                                                        data={this.state.dataLandWork}
                                                        img="map"
                                                        header="ที่ดินประกอบอาชีพ"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Grid.Column>

                                    {/*Component number of family information*/}
                                    <Grid.Column>
                                        <div className={'mid-right-container'}>

                                            {/* Component  จำนวนครัวเรือนของประชากร */}
                                            <HorizonBarDash
                                                data={this.state.dataNumPopulationBar}
                                                img="chart bar"
                                                header="จำนวนครัวเรือนของประชากร"
                                            />
                                        </div>
                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>
                        ) :
                        <Grid.Row stretched className={'loader-container'}>
                            <div style={{width: '100%'}}><PageLoader/></div>
                        </Grid.Row>
                    }

                    {/* Select Component */}

                    <div className="container-select">
                        <Grid.Row stretched>

                            {/*Select Group Components*/}
                            <Grid.Column width={16}>
                                <SelectComp
                                    onSelectTab={this.onSelectTab}
                                    setActive={this.state.selectActive}
                                />
                            </Grid.Column>

                        </Grid.Row>
                        <Grid.Row stretched>
                            {/*Slider Container*/}
                            <Slider {...settings} ref={(c) => (this.slider = c)}
                                    afterChange={(e) => {
                                        this.onSelectTab(e);
                                    }}>
                                {/*Group 1*/}
                                {this.state.Answer1IsSuccess ? (
                                        <Grid container stackable columns={3} className={'fix-slider-container'}>
                                            <Grid.Row stretched>

                                                {/*Annual health check Component*/}
                                                <Grid.Column width={16} className={'fix-container'}>
                                                    <div className="main-g1-top-container">

                                                        {/* Component  จำนวนคนที่ตรวจสุขภาพประจำปี */}
                                                        <ProgressOnce
                                                            header={this.state.dataHealth.header}
                                                            img={this.state.dataHealth.img}
                                                            amount={this.state.dataHealth.amount}
                                                            numPos={this.state.dataHealth.numPos}
                                                            unit="คน"
                                                            textCheck={["มี", "ไม่มี"]}
                                                        />
                                                    </div>
                                                </Grid.Column>

                                                {/*Congenit disease Components*/}
                                                <Grid.Column width={8} className={'fix-container-left'}>
                                                    <div className="main-g1-left-container">
                                                        <div className="child-g1-row">

                                                            {/* Component  โรคประจำตัว */}
                                                            <ProgressDash
                                                                datasets={this.state.dataCongenital.datasets}
                                                                header={this.state.dataCongenital.header}
                                                                img={this.state.dataCongenital.img}
                                                                allPopulate={this.state.dataCongenital.allPopulate}
                                                                showIcon={false}
                                                                unit="คน"
                                                            />
                                                        </div>
                                                        <div className="child-g1-row">

                                                            {/* Component  การออกกำลังกาย */}
                                                            <ProgressDash
                                                                datasets={this.state.dataExercise.datasets}
                                                                header={this.state.dataExercise.header}
                                                                img={this.state.dataExercise.img}
                                                                allPopulate={this.state.dataExercise.allPopulate}
                                                                showIcon={false}
                                                                unit="คน"
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid.Column>

                                                {/*Medical Right Component*/}
                                                <Grid.Column width={8} className={'fix-container-right'}>
                                                    <div className="main-g1-right-container">

                                                        {/* Component  สิทธิการรักษาพยาบาล */}
                                                        <DonutDash data={this.state.dataMedicalRight}
                                                                   header="สิทธิการรักษาพยาบาล"
                                                                   img="hospital" height={230}
                                                                   unit="คน"
                                                        />
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    ) :
                                    <Grid.Row stretched className={'loader-container'}>
                                        <PageLoader/>
                                    </Grid.Row>}

                                {/*Group 2*/}
                                {this.state.Answer2IsSuccess ? (
                                        <Grid container stackable columns={2} className={'fix-slider-container'}>
                                            <Grid.Row stretched>
                                                <Grid.Column className={'fix-container-left'}>
                                                    <div className="main-g2-left-container">

                                                        {/*Sum family Component*/}
                                                        <div className="child-g2-top">

                                                            {/* Component  จำนวนครัวเรือนทั้งหมด */}
                                                            <SignBoard
                                                                data={this.state.dataAllHouse}
                                                                sex="จำนวนครัวเรือนทั้งหมด"
                                                                amount="31010"
                                                                unit="หลัง"
                                                                img="home"
                                                                color="#EB5757"
                                                            />
                                                        </div>

                                                        {/*Number of pollution*/}
                                                        <div className="child-g2">

                                                            {/* Component  จำนวนครัวเรือนที่ได้รับผลกระทบจากมลพิษ */}
                                                            <DonutDash
                                                                data={this.state.dataPollution}
                                                                header="จำนวนครัวเรือนที่ได้รับผลกระทบจากมลพิษ"
                                                                img="industry"
                                                                unit="หลัง"
                                                                height={230}
                                                            />
                                                        </div>
                                                    </div>

                                                </Grid.Column>
                                                <Grid.Column className={'fix-container-right'}>
                                                    <div className="main-g2-right-container">
                                                        <div className="child-g2-bot">

                                                            {/* Component  จำนวนครัวเรือนที่ประสบภัยธรรมชาติ */}
                                                            <ProgressOnce
                                                                header={this.state.dataDiaster.header}
                                                                img={this.state.dataDiaster.img}
                                                                amount={this.state.dataDiaster.amount}
                                                                numPos={this.state.dataDiaster.numPos}
                                                                unit="หลัง"
                                                                textCheck={["มี", "ไม่มี"]}
                                                            />
                                                        </div>

                                                        {/*family stability Component*/}
                                                        <div className="child-g2-col">

                                                            {/* Component  ความมั่นคงของครัวเรือน */}
                                                            <ProgressOnce
                                                                header={this.state.dataHouseStable.header}
                                                                img={this.state.dataHouseStable.img}
                                                                amount={this.state.dataHouseStable.amount}
                                                                numPos={this.state.dataHouseStable.numPos}
                                                                unit="หลัง"
                                                                textCheck={["มี", "ไม่มี"]}
                                                            />
                                                        </div>

                                                        {/*family durability Component*/}
                                                        <div className="child-g2-col">

                                                            {/* Component  ความคงทนของครัวเรือน */}
                                                            <ProgressOnce
                                                                header={this.state.dataHouseDurable.header}
                                                                img={this.state.dataHouseDurable.img}
                                                                amount={this.state.dataHouseDurable.amount}
                                                                numPos={this.state.dataHouseDurable.numPos}
                                                                unit="หลัง"
                                                                textCheck={["มี", "ไม่มี"]}
                                                            />
                                                        </div>

                                                        {/*number of family invasion Component*/}
                                                        <div className="child-g2-col">

                                                            {/* Component  จำนวนครัวเรือนที่ถูกบุกรุกที่อยู่อาศัย */}
                                                            <ProgressOnce
                                                                header={this.state.dataHouseTrespass.header}
                                                                img={this.state.dataHouseTrespass.img}
                                                                amount={this.state.dataHouseTrespass.amount}
                                                                numPos={this.state.dataHouseTrespass.numPos}
                                                                unit="หลัง"
                                                                textCheck={["มี", "ไม่มี"]}
                                                            />
                                                        </div>

                                                        {/*number of water consumption component*/}
                                                        <div className="child-g2-col">

                                                            {/* Component  การบริโภคน้ำ */}
                                                            <ProgressOnce
                                                                header={this.state.dataWaterConsumption.header}
                                                                img={this.state.dataWaterConsumption.img}
                                                                amount={this.state.dataWaterConsumption.amount}
                                                                numPos={this.state.dataWaterConsumption.numPos}
                                                                unit="หลัง"
                                                                textCheck={["มี", "ไม่มี"]}
                                                            />
                                                        </div>

                                                        {/*number of water consumer component*/}
                                                        <div className="child-g2-col">

                                                            {/* Component  การอุปโภคน้ำ */}
                                                            <ProgressOnce
                                                                header={this.state.dataWaterComsume.header}
                                                                img={this.state.dataWaterComsume.img}
                                                                amount={this.state.dataWaterComsume.amount}
                                                                numPos={this.state.dataWaterComsume.numPos}
                                                                unit="หลัง"
                                                                textCheck={["มี", "ไม่มี"]}
                                                            />
                                                        </div>

                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    ) :
                                    <Grid.Row stretched className={'loader-container'}>
                                        <PageLoader/>
                                    </Grid.Row>}

                                {/*Group 3*/}
                                {this.state.Answer3IsSuccess ? (
                                        <Grid container columns={2} className={'main-g3 fix-slider3-container'}>
                                            <Grid.Row stretched className={'fix-padding'}>

                                                {/*Number of Educated component*/}
                                                <Grid.Column width={16} className={'fix-container'}>

                                                    {/* Component  จำนวนประชากรที่อ่านออก เขียนได้ */}
                                                    <ProgressDash
                                                        datasets={this.state.dataCanReadWring.datasets}
                                                        header={this.state.dataCanReadWring.header}
                                                        img={this.state.dataCanReadWring.img}
                                                        allPopulate={this.state.dataCanReadWring.allPopulate}
                                                        showIcon={false}
                                                        unit="คน"
                                                    />

                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    ) :
                                    <Grid.Row stretched className={'loader-container'}>
                                        <PageLoader/>
                                    </Grid.Row>}

                                {/*Group 4*/}
                                {this.state.Answer4IsSuccess ? (
                                        <Grid container stackable columns={2} className={'main-g4 fix-slider-container'}>
                                            <Grid.Row stretched>
                                                <Grid.Column width={16} className={'fix-container'}>
                                                    <div className={'main-g4-bar-container'}>
                                                        <div className={'child-bar'}>

                                                            {/* Component  รายได้ต่อปีของครัวเรือน */}
                                                            <BarAlone data={this.state.dataIncome.data}
                                                                      labels={this.state.dataIncome.labels}
                                                                      header="รายได้ต่อปี"
                                                                      img="money bill alternate"/>

                                                        </div>
                                                        <div className={'child-bar'}>

                                                            {/* Component  รายจ่ายต่อปีของครัวเรือน */}
                                                            <BarAlone data={this.state.dataExpenditure.data}
                                                                      labels={this.state.dataExpenditure.labels}
                                                                      header="รายจ่ายต่อปี"
                                                                      img="pay-money"/>

                                                        </div>
                                                        <div className={'child-bar'}>

                                                            {/* Component  รายได้หลังหักค่าใช้จ่ายต่อปี */}
                                                            <BarAlone data={this.state.dataTotalIncome.data}
                                                                      labels={this.state.dataTotalIncome.labels}
                                                                      header="รายได้หลังหักค่าใช้จ่ายต่อปี"
                                                                      img="money bill alternate"
                                                            />

                                                        </div>
                                                        <div className={'child-bar'}>

                                                            {/* Component  เงินฝากต่อปี */}
                                                            <BarAlone data={this.state.dataDeposit.data}
                                                                      labels={this.state.dataDeposit.labels}
                                                                      header="เงินออมต่อปี"
                                                                      img="pig-saving"
                                                            />

                                                        </div>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column width={8} className={'fix-container-left'}>

                                                    {/* Component  ครัวเรือนที่เข้าถึงแหล่งเงินทุน */}
                                                    <PieDash
                                                        data={this.state.dataInvest2}
                                                        img="pie chart"
                                                        header="จำนวนครัวเรือนที่เข้าถึงแหล่งเงินทุน"
                                                        height={200}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={8} className={'fix-container-right'}>

                                                    {/* Component  จำนวนครัวเรือนต่อประเภทการออมเงิน */}
                                                    <PieDash
                                                        data={this.state.dataSaving2}
                                                        img="pie chart"
                                                        header="จำนวนครัวเรือนต่อประเภทการออมเงิน"
                                                        height={200}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>

                                        </Grid>
                                    ) :
                                    <Grid.Row stretched className={'loader-container'}>
                                        <PageLoader/>
                                    </Grid.Row>}

                                {/*Group 5*/}
                                {this.state.Answer5IsSuccess ? (
                                        <Grid container stackable columns={2} className={'fix-slider-container'}>
                                            <Grid.Row stretched>
                                                <Grid.Column width={16} className={'fix-container'}>
                                                    <div className={'main-g5-top-container'}>
                                                        <div className={'child1'}>

                                                            {/* Component  ความถี่ในการดื่มสุรา */}
                                                            <BarAlone data={this.state.dataAlcoholPerWeek.data}
                                                                      labels={this.state.dataAlcoholPerWeek.labels}
                                                                      img="icon-beer"
                                                                      header="ความถี่ในการดื่มสุรา(ต่อสัปดาห์)"/>
                                                        </div>

                                                        <div className={'child2'}>

                                                            {/* Component  ความถี่ในการสูบบุหรี่ */}
                                                            <BarAlone data={this.state.dataSmokePerDay.data}
                                                                      labels={this.state.dataSmokePerDay.labels}
                                                                      header="ความถี่ในการสูบบุหรี่(ต่อวัน)"
                                                                      img="smoking"
                                                            />

                                                        </div>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column className={'fix-container-left'}>
                                                    <div className="main-g5-left-container">
                                                        <div className="child-g5-col">
                                                            {/*avg drinkking Component*/}
                                                            <div className="child-1">

                                                                {/* Component  จำนวนประชากรที่สูบบุหรี่ต่อช่วงอายุ */}
                                                                <PieDash
                                                                    data={this.state.dataSmokeRange}
                                                                    img="pie chart"
                                                                    header="จำนวนประชากรที่สูบบุหรี่ต่อช่วงอายุ"
                                                                />
                                                            </div>

                                                            {/*avg smoking Component*/}
                                                            <div className="child-2">

                                                                {/* Component  จำนวนประชากรที่ดื่มสุราต่อช่วงอายุ */}
                                                                <PieDash
                                                                    data={this.state.dataDrinkRange}
                                                                    img="pie chart"
                                                                    header="จำนวนประชากรที่ดื่มสุราต่อช่วงอายุ"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column className={'fix-container-right'}>
                                                    <div className="main-g5-right-container">
                                                        <div className="child1">

                                                            {/* Component  ความอบอุ่นของครอบครัว */}
                                                            <ProgressDash
                                                                datasets={this.state.dataWarmth.datasets}
                                                                header={this.state.dataWarmth.header}
                                                                img={this.state.dataWarmth.img}
                                                                allPopulate={this.state.dataWarmth.allPopulate}
                                                                showIcon={true}
                                                                unit="คน"
                                                            />
                                                        </div>
                                                        <div className="child2">

                                                            {/* Component  จำนวนประชากรที่ร่วมกิจกรรมทางศาสนา */}
                                                            <ProgressOnce
                                                                header={this.state.dataReligion.header}
                                                                img={this.state.dataReligion.img}
                                                                amount={this.state.dataReligion.amount}
                                                                numPos={this.state.dataReligion.numPos}
                                                                unit="คน"
                                                                textCheck={["ร่วม", "ไม่ร่วม"]}
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    ) :
                                    <Grid.Row stretched className={'loader-container'}>
                                        <PageLoader/>
                                    </Grid.Row>}
                            </Slider>
                        </Grid.Row>
                    </div>
                </div>
            </div>
        );
    }
}
