import React, {useState} from "react";
import {Icon, Header, Divider} from "semantic-ui-react";
import './DetailProfile.css'
import imgProfile from '../../../assets/img/girl_profile.png'
import ChangePassword from '../ChangePassword'
import {connect} from "react-redux";
import Config from "../../../constants/Config";
import Image from "../../../components/Image";


const DetailProfile = (props) => {

    const [change_password, set_change_password] = useState(false);

    return (
        <div className={'detail-profile-screen'}>

            {/*ข้อมูลส่วนตัว*/}
            <div className={'profile-user'}>

                {/*หัวข้อข้อมูลส่วนตัว*/}
                <div className={'container-subheader'}>

                    {/*หัวข้อ*/}
                    <div className={'subheader'}>
                        <h1 className={'header-profile'}>ข้อมูลผู้ใช้งาน</h1>
                    </div>

                    {/*ปุ่มแก้ไขข้อมูล*/}
                    <div className={'container-button-action'}>

                        <button
                            className={'button-action'}
                            onClick={() => props.history.push(props.user.permission_id === "5e4a59cec479a2e3b6190e09" ? '/administrator/editprofile' : '/centeradministrator/editprofile')}
                                style={{backgroundImage: 'linear-gradient(#EBBB5E, #E6A935)'}}>
                            <Icon className={'icon-edit'} color={'#E6A935'} name='pencil'/>
                            <span>แก้ไขข้อมูล</span>
                        </button>

                    </div>


                </div>

                {/*แสดงข้อมูลส่วนตัว*/}
                <div className={'container-content'}>

                    {/*รูปโปรไฟล์*/}
                    <Image
                        className={'img-profile'}
                        src={`${Config.url}/api/v1${props.user.image.path}`}
                        path={props.user.image.path}
                    />

                    <div className={'divider-profile'}/>

                    <div className={'container-content-input'}>

                        {/*ชื่อ-สกุล*/}
                        <div className={'container-information'}>
                            <div className={'container-icon-topic'}>
                                <Icon className={'icon-topic'} name='user'/>
                            </div>
                            <div className={'detail-topic'}>
                                <span className={'detail-sub-topic'}>ชื่อ-สกุล</span>
                                <span>{`${props.user.prefix}${props.user.full_name}`}</span>
                            </div>
                        </div>

                        {/*เลขประจำตัวประชาชน*/}
                        <div className={'container-information'}>
                            <div className={'container-icon-topic'}>
                                <Icon className={'icon-topic'} name='id card'/>
                            </div>
                            <div className={'detail-topic'}>
                                <span className={'detail-sub-topic'}>เลขประจำตัวประชาชน</span>
                                <span>{props.user.id_card_number ? props.user.id_card_number.replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5') : "ไม่พบข้อมูล"}</span>
                            </div>
                        </div>

                        {/*หมายเลขโทรศัพท์*/}
                        <div className={'container-information'}>
                            <div className={'container-icon-topic'}>
                                <Icon className={'icon-topic'} name='phone'/>
                            </div>
                            <div className={'detail-topic'}>
                                <span className={'detail-sub-topic'}>หมายเลขโทรศัพท์</span>
                                <span>{props.user.phone_number ? props.user.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2$3') : "ไม่พบข้อมูล"}</span>
                            </div>
                        </div>

                        {/*ประเภทผู้ใช้งาน*/}
                        <div className={'container-information'}>
                            <div className={'container-icon-topic'}>
                                <Icon className={'icon-topic'} name='user doctor'/>
                            </div>
                            <div className={'detail-topic'}>
                                <span className={'detail-sub-topic'}>ประเภทผู้ใช้งาน</span>
                                <span>{props.user.permission_name}</span>
                            </div>
                        </div>

                        {/*พื้นที่ที่รับผิดชอบ*/}
                        <div className={'container-information'}>
                            <div className={'container-icon-topic'}>
                                <Icon className={'icon-topic'} name='map marker alternate'/>
                            </div>
                            <div className={'detail-topic'}>
                                <span className={'detail-sub-topic'}>พื้นที่รับผิดชอบ</span>
                                <span>{props.user.area_name}</span>
                            </div>
                        </div>

                        {/*ที่อยู่*/}
                        <div className={'container-information'} style={{alignItems: 'flex-start'}}>
                            <div className={'container-icon-topic'}>
                                <Icon className={'icon-topic'} name='home'/>
                            </div>
                            <div className={'detail-topic'}>
                                <span className={'detail-sub-topic'}>ที่อยู่</span>
                                <span>{`บ้านเลขที่ ${props.user.address.house_no}`}</span>
                                <span>{`หมู่ที่ ${props.user.address.village_no}  หมู่บ้าน${props.user.address.village_name}`}</span>
                                <span>{`ซอย ${props.user.address.alley} ถนน${props.user.address.road}`}</span>
                                <span>{`ต.${props.user.address.sub_district} อ.${props.user.address.district} จ.${props.user.address.province} ${props.user.address.zip_code}`}</span>
                            </div>

                        </div>

                        {/*เปลี่ยนรหัสผ่าน*/}
                        <div className={'container-button-action'}>

                            {/*Modal เปลี่ยนรหัสผ่าน*/}
                            <ChangePassword
                                props={props}
                                open={change_password}
                                onClose={() => {
                                    set_change_password(false)
                                }}
                            />

                            {/*ปุ่มเปลี่ยนรหัสผ่าน*/}
                            <button
                                className={'button-action'}
                                style={{backgroundImage: 'linear-gradient(#4786BE, #1967AD)'}}
                                onClick={() => {
                                    set_change_password(true)
                                }}
                            >
                                <Icon className={'icon-password'} color={'#1967AD'} name='key'/>
                                <span>เปลี่ยนรหัสผ่าน</span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
};


const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
};

export default connect(mapStateToProps, null)(DetailProfile)

