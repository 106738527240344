import React from "react";
import './PageError.css';


export default (props) => {
    console.log(props)
    return (
        <div className={'page-error'}>
            <div className={'container-text'}>
                <h1>Page not found!</h1>
                <div className={'detail'}>
                    <div>อุ๊ย! เราพบข้อผิดพลาดบางอย่าง </div>
                    <div>ไม่พบหน้าที่ต้องการ</div>
                </div>
                <div className={'ex-detail'}>
                    <div>หากต้องการกลับสู่หน้าหลัก</div>
                    <button onClick={()=>{
                        props.history.replace(props.mainPath)
                    }}>กลับสู่หน้าหลัก</button>
                </div>
            </div>
            <div className={'container-img'}>
                <img src={require('../../assets/img/404.png')}/>
            </div>
        </div>
    )
}
