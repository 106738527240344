import React from "react";

export default ({detail,value,unit}) => {
    return(
        <div>
            <span>{`${detail}`}</span>
            <span style={{color:'green'}}>{` ${value} `}</span>
            <span>{`${unit}`}</span>
        </div>
    )
}
