import React, {useEffect, useState} from 'react';
import {Dropdown, Input} from 'semantic-ui-react';

const ItemAddress = ({address, name}) => {
    let [sub_district, district, province, zip_code] = address.split(' >> ');
    if (district) {
        return (
            <div className={'address-item'}>
                <span className={name === 'sub_district' && 'active'}> {sub_district} </span><span>>></span>
                <span className={name === 'district' && 'active'}> {district} </span><span>>></span>
                <span className={name === 'province' && 'active'}> {province} </span><span>>></span>
                <span className={name === 'zip_code' && 'active'}> {zip_code} </span>
            </div>
        )
    } else {
        return (
            <div className={'address-item'}>
                <span className={'active'}> {sub_district} </span>
            </div>
        )
    }
}

export const DropdownSearch = ({
                                   field: {name, value},
                                   form: {touched, errors, setFieldValue},
                                   options,
                                   children: _,
                                   onChangeAddress,
                                   ...props
                               }) => {
    return (
        <div>
            {/* Dropdown */}
            <Dropdown
                placeholder={"ค้นหา"}
                search
                selection
                options={options.length > 0 ? options.map((data) => {
                    return {
                        ...data,
                        content: (
                            <ItemAddress name={name} address={data.value}/>
                        ),
                    }
                }):[
                    {
                        key: "search",
                        text: "กรอกข้อมูลเพื่อค้นหา",
                        value: "พิมเพื่อค้นหา",
                        disabled: true
                    }
                ]
                }
                value={value}
                {...props}
                onChange={(_, {value, ...data}) => {
                    // console.log('name: ',name);
                    value = value.split(' >> ');
                    let option_address = value.map((data) => ({
                        key: data,
                        text: data,
                        value: data
                    }))
                    onChangeAddress(option_address)
                    // console.log('value: ', value);

                    setFieldValue('sub_district', value[0]);
                    setFieldValue('district', value[1]);
                    setFieldValue('province', value[2]);
                    setFieldValue('zip_code', value[3]);
                }}
            />
        </div>
    );
};
export default DropdownSearch;
