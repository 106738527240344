import React from "react";
import './MemberHouse.css'
import {Grid} from 'semantic-ui-react'
import MemberFormat from '../MemberHouse/MemberFormat'

const MemberHouse = ({members, ...props}) => {

    return (
        <div className={'member-house-content'}>
            {/*ข้อมูลสมาชิก*/}
            <Grid style={{flex:'1',justifyItems:'center'}}>
                <Grid.Row>
                    {
                        members.map((item,index) => {
                            return (
                                <Grid.Column
                                    mobile={16}
                                    tablet={8}
                                    computer={4}
                                    largeScreen={5}

                                >
                                    <MemberFormat
                                        key={index}
                                        number={index+1}
                                        item={item}
                                    />
                                </Grid.Column>


                            )
                        })
                    }
                </Grid.Row>
            </Grid>


        </div>
    )
}

export default MemberHouse;
