const Format = {
  phone_number: {
    regex: /^([\d]{10})$/i,
  },
  name: {
    regex: /^[ก-๛]+$/i,
  },
  zip_code: {
    regex: /^([\d]{5})$/i,
  }
};
export default Format;