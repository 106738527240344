import React, {useState} from 'react';
import ListItemLink from './ListItemLink'
import "./AsideMenu.css"
import Logo from "../../assets/img/OGS-logo-white.png";
import {Icon} from "semantic-ui-react";
import ReactTooltip from 'react-tooltip'

const checkResponse = () => {
    const {innerWidth: width} = window;
    return width < 850
}

export default ({menu = [], ...props}) => {
    const [toggle, setToggle] = useState(checkResponse)
    return (
        <nav className={`nav ${toggle ? "toggle" : ''}`}>
            <Icon onClick={() => setToggle(!toggle)} className={'toggle-menu'} size={'large'} name={'outdent'}/>
            <img className={'logo'} src={Logo}/>
            <h1>Local Survey</h1>
            <h3>ระบบจัดเก็บข้อมูลประชากร</h3>

            {/*<h3>เทศบาลนครขอนแก่น</h3>*/}
            <ul>
                {
                    menu.map((item,index) => (
                        <ListItemLink
                            key={index.toString()}
                            onClick={()=>{
                                if(checkResponse()){
                                    setToggle(true)
                                }
                            }}
                            data-tip={item.name}
                            to={`${props.path}${item.path}`}
                            name={item.name}
                            icon={item.icon}
                        />
                    ))
                }
                <li className={"slide"}>
                    <img className={"top"} src={require('../../assets/curve-top.png')}/>
                    <img className={"bottom"} src={require('../../assets/curve-bottom.png')}/>
                </li>
            </ul>
            <ReactTooltip place='right' disable={!toggle}/>
        </nav>
    )
}
