import React from "react";

export default ({no,questionName,...props}) => {
    return(
        <div className={'question-sub-topic'}>
            <div className={'no'}>{no}</div>
            <div className={'content'}>
                <div className={'block'}>
                    <div className={'question'}>: {questionName}</div>
                </div>
                <div className={'block'}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}
