import React, {useEffect, useState} from "react";
import './SettingScreen.css'
import {Icon} from "semantic-ui-react";
import HeaderContainer from '../../components/HeaderContainer'
import Config from "../../constants/Config";
import axios from "axios"

export default (props) => {


    return (
        <div className={'setting-screen'}>
            <h1>ตั้งค่าระบบ</h1>

            {/*ตั้งค่าอปพลิเคชัน*/}
            <HeaderContainer
                icon={'mobile alternate'}
                title={'ตั้งค่าแอปพลิเคชัน'}
            >
                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    {/*ปุ่มตั้งค่าอาชีพ*/}
                    <button
                        className={'button-setting-app-container'}
                        onClick={() => {
                            props.history.push({
                                pathname: 'setting/occupation',
                                state: {
                                    title: 'ตั้งค่าข้อมูลอาชีพ',
                                    topic: 'อาชีพ',
                                    icon: 'setting',
                                }
                            })
                        }
                        }
                    >
                        <div className={'icon-setting-app'}>
                            <Icon name={'user md'}/>
                        </div>
                        <div className={'header-setting-container'}>
                            <div className={'header-setting-text'}>ตั้งค่า</div>
                            <div className={'subheader-setting-text'}>ข้อมูลอาชีพ</div>
                        </div>

                    </button>

                    {/*ปุ่มตั้งค่าการศึกษา*/}
                    <button
                        className={'button-setting-app-container'}
                        onClick={() => {
                            props.history.push({
                                pathname: 'setting/education',
                                state: {
                                    title: 'ตั้งค่าข้อมูลการศึกษา',
                                    topic: 'วุฒิการศึกษา',
                                    icon: 'setting',
                                }
                            })
                        }
                        }
                    >
                        <div className={'icon-setting-app'}>
                            <Icon name={'book'}/>
                        </div>
                        <div className={'header-setting-container'}>
                            <div className={'header-setting-text'}>ตั้งค่า</div>
                            <div className={'subheader-setting-text'}>ข้อมูลการศึกษา</div>
                        </div>

                    </button>

                    {/*ปุ่มตั้งค่าศาสนา*/}
                    <button
                        className={'button-setting-app-container'}
                        onClick={() => {
                            props.history.push({
                                    pathname: 'setting/religion',
                                    state: {
                                        title: 'ตั้งค่าข้อมูลศาสนา',
                                        topic: 'ศาสนา',
                                        icon: 'setting',
                                    }
                                }
                            )
                        }

                        }
                    >
                        <div className={'icon-setting-app'}>
                            <Icon name={'chess'}/>
                        </div>
                        <div className={'header-setting-container'}>
                            <div className={'header-setting-text'}>ตั้งค่า</div>
                            <div className={'subheader-setting-text'}>ข้อมูลศาสนา</div>
                        </div>

                    </button>
                </div>
            </HeaderContainer>

        </div>
    )
}
