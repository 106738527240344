import React, { Component } from "react";
import { Progress, Icon } from "semantic-ui-react";
import "./styleProg.css";
export default class progressDash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allPopulate: 0,
      datasets: [],
      header: "",
      img: "",
      iconSmile: { nameIcon: "smile", color: "green" },
      iconSad: { nameIcon: "frown", color: "red" },
      progressColor: ["green", "yellow", "pink", "purple", "blue","olive","teal","brown"],
      textColor: ["#21ba45", "#fbbd08", "#e03997", "#a333c8", "#2185d0"],
      showIcon: false,
    };
  }

  componentDidMount() {
  }
    render() {
    let mapProgress=""
    if(this.props.datasets){

      //get data from prop
      const allPopu = this.props.allPopulate;
      const datasets = this.props.datasets;

      let iconProg = {}
        let perc = 0
      //map data to progress bar
       mapProgress = datasets.map((data) => {
           //find percent of data
        if(allPopu.length>1){
                perc = ((data.amount / allPopu[data.id]) * 100);
        }
        else{
            perc = ((data.amount / allPopu) * 100);
        }
           perc = perc.toFixed(2)
        //show happy or sad icon from percent
        if (perc > 50) {
          iconProg = this.state.iconSmile;
        } else {
          iconProg = this.state.iconSad;
        }

        if (data) {
          return (
              <div key={data.id}>
                <div className="progbar-container" >
                  <div className="prog-top-bar">
                    <div className="desc-bar">
                      <p>{data.textDesc}</p>
                      <p style={{ color: `${this.state.textColor[data.id]}` }}>
                        {data.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {this.props.unit} ({perc}%)
                      </p>
                    </div>
                    <Progress
                        percent={perc}
                        color={this.state.progressColor[data.id]}
                        size="small"
                    />
                  </div>
                  <div className="cont-progress">
                    {this.props.showIcon ? (
                        <Icon
                            className="icon-prog"
                            name={iconProg.nameIcon}
                            size="big"
                            color={iconProg.color}
                        />
                    ) : null}
                  </div>
                </div>
              </div>
          );
        }
      });
    }
    return (
      <div className="main-prog-container">
        <div className="header">
          <Icon className={'title-icon'} name={this.props.img} size="big" />
          <p>{this.props.header}</p>
        </div>
        {mapProgress}
      </div>
    );
  }
}
