import React from "react";
import Topic from "./Topic";
import SubTopic from "./SubTopic";
import Answer from './Answer'

export default ({data, ...props}) => {
    let {
        no1_1,
        no1_2,
        no2_1,
        no2_2,
        no2_3,
        no2_4,
        no3_1,
        no3_2,
        no3_3,
        no4_1,
        no4_2,
        no4_3,
        no4_4,
        no5_1,
        no5_2,
        no5_3,
        no5_4,
        no6_1,
        no6_2,
        no6_3,
        no6_4,
        no6_5,
        no6_6,
        no6_7,
        no7_1
    } = data;


    const checkDateฺBreastCancer = (value) => {
        if(value.day && value.month && value.year){
            return value.day/value.month/value.year
        }
        else if (value.month && value.year) {
            return value.month/value.year
        }
        else if (value.year) {
            return value.year
        }
        else {
            return 'ไม่ระบุวันตรวจ'
        }
    }

    const answer3_3 = () => {
        return (
            <div>
                {
                    no3_3.no3_3_1.answer === '1' &&
                    <Answer
                        detail={`- แรกเกิด จำนวน`}
                        value={no3_3.no3_3_1.amount}
                        unit={'คน'}/>

                }
                {
                    no3_3.no3_3_2.answer === '1' &&
                    <Answer
                        detail={`- 1 เดือน จำนวน`}
                        value={no3_3.no3_3_2.amount}
                        unit={'คน'}/>

                }
                {
                    no3_3.no3_3_3.answer === '1' &&
                    <Answer
                        detail={`- 2 เดือน จำนวน`}
                        value={no3_3.no3_3_3.amount}
                        unit={'คน'}/>

                }
                {
                    no3_3.no3_3_4.answer === '1' &&
                    <Answer
                        detail={`- 4 เดือน จำนวน`}
                        value={no3_3.no3_3_4.amount}
                        unit={'คน'}/>

                }
                {
                    no3_3.no3_3_5.answer === '1' &&
                    <Answer
                        detail={`- 6 เดือน จำนวน`}
                        value={no3_3.no3_3_5.amount}
                        unit={'คน'}/>

                }
                {
                    no3_3.no3_3_6.answer === '1' &&
                    <Answer
                        detail={`- 9-12 เดือน จำนวน`}
                        value={no3_3.no3_3_6.amount}
                        unit={'คน'}/>

                }
                {
                    no3_3.no3_3_7.answer === '1' &&
                    <Answer
                        detail={`- 1 ปี จำนวน`}
                        value={no3_3.no3_3_7.amount}
                        unit={'คน'}/>

                }
                {
                    no3_3.no3_3_8.answer === '1' &&
                    <Answer
                        detail={`- 1 ปี 6 เดือน จำนวน`}
                        value={no3_3.no3_3_8.amount}
                        unit={'คน'}/>

                }
                {
                    no3_3.no3_3_9.answer === '1' &&
                    <Answer
                        detail={`- 2 ปี 6 เดือน จำนวน`}
                        value={no3_3.no3_3_9.amount}
                        unit={'คน'}/>

                }
                {
                    no3_3.no3_3_10.answer === '1' &&
                    <Answer
                        detail={`- 4 ปี จำนวน`}
                        value={no3_3.no3_3_10.amount}
                        unit={'คน'}/>

                }
                {
                    no3_3.no3_3_11.answer === '1' &&
                    <Answer
                        detail={`- 7 ปี จำนวน`}
                        value={no3_3.no3_3_11.amount}
                        unit={'คน'}/>

                }
                {
                    no3_3.no3_3_12.answer === '1' &&
                    <Answer
                        detail={`- 12 ปี จำนวน`}
                        value={no3_3.no3_3_12.amount}
                        unit={'คน'}/>

                }
                {
                    (
                        no3_3.no3_3_1.answer !== '1' &&
                        no3_3.no3_3_2.answer !== '1' &&
                        no3_3.no3_3_3.answer !== '1' &&
                        no3_3.no3_3_4.answer !== '1' &&
                        no3_3.no3_3_5.answer !== '1' &&
                        no3_3.no3_3_6.answer !== '1' &&
                        no3_3.no3_3_7.answer !== '1' &&
                        no3_3.no3_3_8.answer !== '1' &&
                        no3_3.no3_3_9.answer !== '1' &&
                        no3_3.no3_3_10.answer !== '1' &&
                        no3_3.no3_3_11.answer !== '1' &&
                        no3_3.no3_3_12.answer !== '1') &&
                    <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                }


            </div>
        )

    }

    const answer6_1 = () => {
        return (
            <div>
                {
                    no6_1.no6_1_1.answer === '1' &&
                    <Answer detail={"- ประกันสุขภาพเอกชน จำนวน"} value={no6_1.no6_1_1.amount}
                            unit={"คน"}/>
                }
                {
                    no6_1.no6_1_2.answer === '1' &&
                    <Answer detail={"- สิทธิข้าราชการ จำนวน"} value={no6_1.no6_1_2.amount}
                            unit={"คน"}/>
                }
                {
                    no6_1.no6_1_3.answer === '1' &&
                    <Answer detail={"- สิทธิประกันสังคม จำนวน"} value={no6_1.no6_1_3.amount}
                            unit={"คน"}/>
                }
                {
                    no6_1.no6_1_4.answer === '1' &&
                    <Answer detail={"- สิทธิหลักประกันสุขภาพ จำนวน"}
                            value={no6_1.no6_1_4.amount} unit={"คน"}/>
                }
                {
                    no6_1.no6_1_5.answer === '1' &&
                    <Answer detail={`- ${no6_1.no6_1_5.name} จำนวน`}
                            value={no6_1.no6_1_5.amount} unit={"คน"}/>
                }
                {
                    (no6_1.no6_1_1.answer !== '1' && no6_1.no6_1_2.answer !== '1' && no6_1.no6_1_3.answer !== '1' &&
                        no6_1.no6_1_4.answer !== '1' && no6_1.no6_1_5.answer !== '1') &&
                    <Answer detail={`- ไม่มีข้อมูล`} value={''} unit={""}/>
                }
            </div>
        )
    }

    const answer6_2 = () => {
        return (
            <div>
                {
                    no6_2.no6_2_1.answer === '1' &&
                    <Answer detail={"- สถานีอนามัย/โรงพยาบาลส่งเสริมสุขภาพ จำนวน"}
                            value={no6_2.no6_2_1.amount} unit={"คน"}/>
                }
                {
                    no6_2.no6_2_2.answer === '1' &&
                    <Answer detail={"- โรงพยาบาลรัฐ จำนวน"} value={no6_2.no6_2_2.amount}
                            unit={"คน"}/>
                }
                {
                    no6_2.no6_2_3.answer === '1' &&
                    <Answer detail={"- โรงพยาบาลเอกชน จำนวน"} value={no6_2.no6_2_3.amount}
                            unit={"คน"}/>
                }
                {
                    no6_2.no6_2_4.answer === '1' &&
                    <Answer detail={"- คลินิก จำนวน"} value={no6_2.no6_2_4.amount}
                            unit={"คน"}/>
                }
                {
                    no6_2.no6_2_5.answer === '1' &&
                    <Answer detail={`- ${no6_2.no6_2_5.name} จำนวน`}
                            value={no6_2.no6_2_5.amount} unit={"คน"}/>
                }
                {
                    (no6_2.no6_2_1.answer !== '1' && no6_2.no6_2_2.answer !== '1' && no6_2.no6_2_3.answer !== '1' &&
                        no6_2.no6_2_4.answer !== '1' && no6_2.no6_2_5.answer !== '1') &&
                    <Answer detail={`- ไม่มีข้อมูล`} value={''} unit={""}/>
                }
            </div>
        )
    }

    const answer6_4 = () => {
        if (no6_4.length > 0) {
            return (
                <div>
                    {no6_4.map((data, index) => {
                        return (
                            <div>
                                {
                                    data.health_check === "0" ?
                                        <div>
                                            <div>{`- ${data.prefix}${data.first_name} ${data.last_name}`}</div>
                                            <div style={{
                                                color: "#808080",
                                                marginLeft: '20px'
                                            }}>ไม่ได้รับการตรวจสุขภาพประจำปี
                                            </div>

                                        </div>
                                        :
                                        <div>
                                            <div>{`- ${data.prefix}${data.first_name} ${data.last_name}`}</div>
                                            <div style={{marginLeft: '20px', color: "#797979"}}>
                                                <Answer detail={"ความดันโลหิต"} value={data.value.blood_pressure}
                                                        unit={"(mmHg)"}/>

                                                <Answer detail={"ชีพจร"} value={data.value.pulse}
                                                        unit={"(ครั้ง / นาที)"}/>

                                                <Answer detail={"น้ำหนัก"} value={data.value.weight}
                                                        unit={"(กิโลกรัม)"}/>

                                                <Answer detail={"ส่วนสูง"} value={data.value.height}
                                                        unit={"(เซนติเมตร)"}/>

                                                <Answer detail={"รอบเอว"} value={data.value.waistline} unit={"(นิ้ว)"}/>

                                                <Answer detail={"ระดับน้ำตาลในเลือด"}
                                                        value={data.value.blood_sugar_levels}
                                                        unit={"(mg/dL)"}/>

                                                <Answer detail={"โรคประจำตัว"}
                                                        value={data.value.congenital_disease.name}
                                                        unit={""}/>
                                            </div>
                                        </div>

                                }
                            </div>
                        )

                    })}
                </div>
            )
        } else {
            return (
                <div>
                    ไม่มีข้อมูล
                </div>
            )
        }
    }

    const answer6_6 = () => {
        if (no6_6.length > 0) {
            return (
                <div>
                    {
                        no6_6.map((data, index) => {
                                return (
                                    <div>
                                        <div>- {data.prefix} {data.first_name} {data.last_name}</div>
                                        <div style={{marginLeft: '20px', color: "#797979"}}> การตรวจมะเร็งเต้านม</div>
                                        {
                                            data.breast_cancer.answer === '1' &&
                                            <div style={{marginLeft: '40px',}}>
                                                <div style={{color: "#555555"}}> ตรวจเมื่อ
                                                    : {checkDateฺBreastCancer(data.breast_cancer.date_skip)}</div>
                                                <div style={{color: "#555555"}}> ผลการตรวจ
                                                    : {data.breast_cancer.status === '1' ?
                                                        <span style={{color: "green"}}>ปกติ</span>
                                                        : <span style={{color: "red"}}>ผิดปกติ</span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            data.breast_cancer.answer === '0' &&
                                            <div style={{marginLeft: '40px', color: "red"}}>ไม่ได้รับการตรวจ</div>
                                        }
                                        <div style={{marginLeft: '20px', color: "#797979"}}> การตรวจมะเร็งปากมดลูก</div>
                                        {
                                            data.cervical_cancer.answer === '1' &&
                                            (
                                                <div style={{marginLeft: '40px',}}>
                                                    <div style={{color: "#555555"}}> ตรวจเมื่อ
                                                        : {checkDateฺBreastCancer(data.breast_cancer.date_skip)}</div>
                                                    <div style={{color: "#555555"}}> ผลการตรวจ
                                                        : {data.cervical_cancer.status === '1' ?
                                                            <span style={{color: "green"}}>ปกติ</span>
                                                            :
                                                            <span style={{color: "red"}}>ผิดปกติ</span>
                                                        }</div>
                                                </div>
                                            )

                                        }
                                        {
                                            data.cervical_cancer.answer === '0' &&
                                            <div style={{marginLeft: '40px', color: "red"}}> ไม่ได้รับการตรวจ</div>
                                        }


                                    </div>
                                )
                            }
                        )
                    }
                </div>
            )

        } else {
            return (
                <div>
                    ไม่มีข้อมูล
                </div>
            )
        }
    }

    const answer6_7 = () => {
        if (no6_7[0]._id !== '') {
            return (
                <div>
                    {
                        no6_7.map((data) => {
                                return (
                                    <div key={data._id}>
                                        <div>- {data.prefix} {data.first_name} {data.last_name}</div>
                                        <div style={{color: "#797979", marginLeft: '20px'}}>{data.value}</div>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            )
        } else {
            return (
                <div style={{color: "#797979"}}>ไม่มีข้อมูล</div>
            )
        }
    }

    const answer7_1 = () => {
        return (
            <div>
                {
                    no7_1.no7_1_1.answer === '1' &&
                    <div>
                        <Answer detail={"- ได้ออกกำลังกาย จำนวน"}
                                value={no7_1.no7_1_1.exercise} unit={'คน'}/>

                        <Answer detail={"- ได้ออกแรง/ออกกำลัง จำนวน"}
                                value={no7_1.no7_1_1.exert} unit={'คน'}/>
                    </div>
                }
                {
                    no7_1.no7_1_2.answer === '1' &&
                    <Answer detail={"ไม่ได้ออกแรง/ออกกำลัง ไม่ได้ออกกำลังกาย จำนวน"}
                            value={no7_1.no7_1_2.amount} unit={'คน'}/>
                }
            </div>
        )
    }

    return (
        <div>
            {/*Question 1*/}
            <Topic
                no={1}
                title={"น้ำหนักแรกเกิดไม่น้อยกว่า 2,500 กรัม ให้ใช้กับเด็กแรกเกิดทุกคน รวมทั้งเด็กฝาแฝด เนื่องจากอัตราการเกิดและมีชีวิตรอดจะสูงกว่า และยังมีผลต่อการพัฒนาทั้งทางร่างกายและสมองของเด็กด้วย"}
            >
                {/*Question 1.1*/}
                <SubTopic
                    no={'1.1.'}
                    questionName={"ครัวเรือนนี้มีเด็กอายุไม่เกิน 1 ปี หรือไม่"}
                >
                    {
                        data ?
                            no1_1.answer === '1' || no1_1.answer === '0' ?
                                no1_1.answer === '1' ?
                                    <Answer
                                        detail={`มี จำนวน`}
                                        value={no1_1.amount}
                                        unit={'คน'}/>
                                    :
                                    <Answer detail={'ไม่มี'} value={''} unit={''}/>
                                :
                                <Answer detail={'ไม่มีข้อมูล'} value={''} unit={''}/>
                            :
                            <div>ไม่มีข้อมูล</div>

                    }

                </SubTopic>

                {/*Question 1.2*/}
                <SubTopic
                    no={'1.2.'}
                    questionName={"เด็กอายุไม่เกิน 1 ปี มีน้ำหนักแรกเกิด ไม่น้อยกว่า 2,500 กรัม ทุกคน หรือไม่"}
                >
                    {
                        data ?
                            no1_2.answer === '1' || no1_2.answer === '0' ?
                                no1_2.answer === '1' ?
                                    <Answer
                                        detail={`มี จำนวน`}
                                        value={no1_2.amount}
                                        unit={'คน'}/>
                                    :
                                    <Answer detail={'ไม่มี'} value={''} unit={''}/>
                                :
                                <Answer detail={'ไม่มีข้อมูล'} value={''} unit={''}/>
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>
            </Topic>


            {/*Question 2*/}
            <Topic
                no={2}
                title={"เด็กแรกเกิดได้กินนมแม่อย่างเดียวอย่างน้อย 6 เดือนแรกติดต่อกัน"}
            >
                {/*Question 2.1*/}
                <SubTopic
                    no={'2.1.'}
                    questionName={"ครัวเรือนนี้มีเด็กอายุน้อยกว่า 6 เดือน หรือไม่"}
                >
                    {
                        data ?
                            no2_1.answer === '1' || no2_1.answer === '0' ?
                                no2_1.answer === '1' ?
                                    <Answer
                                        detail={`มี จำนวน`}
                                        value={no2_1.amount}
                                        unit={'คน'}/>
                                    :
                                    <Answer detail={'ไม่มี'} value={''} unit={''}/>
                                :
                                <Answer detail={'ไม่มีข้อมูล'} value={''} unit={''}/>
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 2.2*/}
                <SubTopic
                    no={'2.2.'}
                    questionName={"เด็กอายุน้อยกว่า 6 เดือน ได้กินนมแม่อย่างเดียวตั้งแต่แรกเกิดเป็นเวลาติดต่อกันจนถึงวันที่สํารวจ ทุกคน หรือไม่"}
                >
                    {
                        data ?
                            no2_2.answer === '1' || no2_2.answer === '0' ?
                                no2_2.answer === '1' ?
                                    <Answer
                                        detail={`มี จำนวน`}
                                        value={no2_2.amount}
                                        unit={'คน'}/>
                                    :
                                    <Answer detail={'ไม่มี'} value={''} unit={''}/>
                                :
                                <Answer detail={'ไม่มีข้อมูล'} value={''} unit={''}/>
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 2.3*/}
                <SubTopic
                    no={'2.3.'}
                    questionName={"ครัวเรือนนี้มีเด็กอายุตั้งแต่ 6 เดือน ถึง 1 ปี หรือไม่"}
                >
                    {
                        data ?
                            no2_3.answer === '1' || no2_3.answer === '0' ?
                                no2_3.answer === '1' ?
                                    <Answer
                                        detail={`มี จำนวน`}
                                        value={no2_3.amount}
                                        unit={'คน'}/>
                                    :
                                    <Answer detail={'ไม่มี'} value={''} unit={''}/>
                                :
                                <Answer detail={'ไม่มีข้อมูล'} value={''} unit={''}/>
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 2.3*/}
                <SubTopic
                    no={'2.4.'}
                    questionName={"เด็กอายุตั้งแต่ 6 เดือนถึง 1 ปีได้กินนมแม่อย่างเดียวเป็นระยะเวลา 6 เดือนแรกติดต่อกัน ทุกคน หรือไม่"}
                >
                    {
                        data ?
                            no2_4.answer === '1' || no2_4.answer === '0' ?
                                no2_4.answer === '1' ?
                                    <Answer
                                        detail={`มี จำนวน`}
                                        value={no2_4.amount}
                                        unit={'คน'}/>
                                    :
                                    <Answer detail={'ไม่มี'} value={''} unit={''}/>
                                :
                                <Answer detail={'ไม่มีข้อมูล'} value={''} unit={''}/>
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>
            </Topic>


            {/*Question 3*/}
            <Topic
                no={3}
                title={"เด็กแรกเกิดถึง 12 ปี ได้รับวัคซีนป้องกันโรคครบตามตารางสร้างเสริมภูมิคุ้มกันโรค"}
            >
                {/*Question 3.1*/}
                <SubTopic
                    no={'3.1'}
                    questionName={"ครัวเรือนนี้ มีเด็กแรกเกิดถึง 12 ปี หรือไม่"}
                >
                    {
                        data ?
                            no3_1.answer === '1' || no3_1.answer === '0' ?
                                no3_1.answer === '1' ?
                                    <Answer
                                        detail={`มี จำนวน`}
                                        value={no3_1.amount}
                                        unit={'คน'}/>
                                    :
                                    <Answer detail={'ไม่มี'} value={''} unit={''}/>
                                :
                                <Answer detail={'ไม่มีข้อมูล'} value={''} unit={''}/>
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 3.2*/}
                <SubTopic
                    no={'3.2.'}
                    questionName={"เด็กแรกเกิดถึง 12 ปี ได้รับวัคซีนป้องกันโรคครบตามตารางสร้างเสริมภูมิคุ้มกันโรค (ที่อยู่ด้านล่าง) ทุกคน หรือไม่"}
                >
                    {
                        data ?
                            no3_2.answer === '1' || no3_2.answer === '0' ?
                                no3_2.answer === '1' ?
                                    <Answer
                                        detail={`มี จำนวน`}
                                        value={no3_2.amount}
                                        unit={'คน'}/>
                                    :
                                    <Answer detail={'ไม่มี'} value={''} unit={''}/>
                                :
                                <Answer detail={'ไม่มีข้อมูล'} value={''} unit={''}/>
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 3.3*/}
                <SubTopic
                    no={'3.3.'}
                    questionName={"ให้ระบุจำนวนเด็กที่ไม่ได้รับวัคซีนในตารางข้างล่างนี้"}
                >
                    {data ? answer3_3() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>
            </Topic>


            {/*Question 4*/}
            <Topic
                no={4}
                title={"ครัวเรือนกินอาหารถูกสุขลักษณะ ปลอดภัย และได้มาตรฐาน"}
            >
                {/*Question 4.1*/}
                <SubTopic
                    no={'4.1'}
                    questionName={"ถ้ากินอาหารบรรจุสำเร็จ ต้องมีเครื่องหมาย อย. เช่นเกลือเสริมไอโอดีน น้ำปลา น้ำส้มสายชู อาหารกระป๋อง นม อาหารกล่อง เป็นต้น"}
                >
                    {
                        data ?
                            no4_1.answer === '1' || no4_1.answer === '0' ?
                                no4_1.answer === '1' ?
                                    `ใช่`
                                    : 'ไม่ใช่'
                                : 'ไม่มีข้อมูล'
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 4.2*/}
                <SubTopic
                    no={'4.2'}
                    questionName={"ถ้ากินเนื้อสัตว์ต้องทำให้สุกด้วยความร้อน"}
                >
                    {
                        data ?
                            no4_2.answer === '1' || no4_2.answer === '0' ?
                                no4_2.answer === '1' ?
                                    `ใช่`
                                    : 'ไม่ใช่'
                                : 'ไม่มีข้อมูล'
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 4.3*/}
                <SubTopic
                    no={'4.3'}
                    questionName={"ถ้ากินผักต้องเป็นผักปลอดสารพิษหรือได้ทำการแช่ด้วยน้ำผสมด่างทับทิมหรือน้ำยาล้างผักแล้วล้างด้วย น้ำสะอาดหลาย ๆ ครั้ง"}
                >
                    {
                        data ?
                            no4_3.answer === '1' || no4_3.answer === '0' ?
                                no4_3.answer === '1' ?
                                    `ใช่`
                                    : 'ไม่ใช่'
                                : 'ไม่มีข้อมูล'
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 4.4*/}
                <SubTopic
                    no={'4.4'}
                    questionName={"ก่อนกินอาหารต้องล้างมือทุกครั้งและใช้ช้อนกลาง"}
                >
                    {
                        data ?
                            no4_4.answer === '1' || no4_4.answer === '0' ?
                                no4_4.answer === '1' ?
                                    `ใช่`
                                    : 'ไม่ใช่'
                                : 'ไม่มีข้อมูล'
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>
            </Topic>

            {/*Question 5*/}
            <Topic
                no={5}
                title={"ครัวเรือนมีการใช้ยาเพื่อบำบัดบรรเทาอาการเจ็บป่วยเบื้องต้นอย่างเหมาะสม"}
            >
                {/*Question 5.1*/}
                <SubTopic
                    no={'5.1.'}
                    questionName={"ใช้ยาสามัญประจำบ้าน"}
                >
                    {
                        data ?
                            no5_1.answer === '1' || no5_1.answer === '0' ?
                                no5_1.answer === '1' ?
                                    `ใช่`
                                    : 'ไม่ใช่'
                                : 'ไม่มีข้อมูล'
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 5.2*/}
                <SubTopic
                    no={'5.2.'}
                    questionName={"ไม่กินยาชุดที่ซื้อจากร้านขายของชำ"}
                >
                    {
                        data ?
                            no5_2.answer === '1' || no5_2.answer === '0' ?
                                no5_2.answer === '1' ?
                                    `ใช่`
                                    : 'ไม่ใช่'
                                : 'ไม่มีข้อมูล'
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 5.3*/}
                <SubTopic
                    no={'5.3.'}
                    questionName={"ไม่กินยาสมุนไพรหรือยาแผนโบราณที่ไม่ได้ปรับปรุงขึ้นเฉพาะสำหรับตน(ยกเว้นยาสามัญประจำบ้านแผนโบราณ)"}
                >
                    {
                        data ?
                            no5_3.answer === '1' || no5_3.answer === '0' ?
                                no5_3.answer === '1' ?
                                    `ใช่`
                                    : 'ไม่ใช่'
                                : 'ไม่มีข้อมูล'
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question */}
                <SubTopic
                    no={'5.4.'}
                    questionName={"ไม่กินผลิตภัณฑ์เสริมอาหารที่อวดอ้างสรรพคุณเกินจริง โดยแสดงสรรพคุณเป็นยาเพื่อบำบัดบรรเทา รักษาโรค ซึ่งไม่ตรงกับที่แสดงในฉลาก"}
                >
                    {
                        data ?
                            no5_4.answer === '1' || no5_4.answer === '0' ?
                                no5_4.answer === '1' ?
                                    `ใช่`
                                    : 'ไม่ใช่'
                                : 'ไม่มีข้อมูล'
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>
            </Topic>

            {/*Question 6 */}
            <Topic
                no={6}
                title={"คนอายุ 35 ปีขึ้นไป ได้รับการตรวจสุขภาพประจำปี"}
            >
                {/*Question 6.1*/}
                <SubTopic
                    no={'6.1.'}
                    questionName={"จำนวนคนในครัวเรือนที่มีประกันสุขภาพ / สิทธิรักษาพยาบาล ดังต่อไปนี้ (ตอบได้มากกว่า 1 ข้อ)"}
                >
                    {data ? answer6_1() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>

                {/*Question 6.2*/}
                <SubTopic
                    no={'6.2.'}
                    questionName={"ในรอบปีที่ผ่านมาจำนวนคนในครัวเรือนที่ใช้บริการสถานพยาบาล ดังต่อไปนี้ (ตอบได้มากกว่า 1 ข้อ)"}
                >
                    {data ? answer6_2() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>

                {/*Question 6.3*/}
                <SubTopic
                    no={'6.3.'}
                    questionName={"ครัวเรือนนี้ มีคนอายุตั้งแต่ 35 ปี ขึ้นไปหรือไม่"}
                >
                    {
                        data ?
                            no6_3.answer === '1' || no6_3.answer === '0' ?
                                no6_3.answer === '1' ?
                                    <Answer
                                        detail={`มี จำนวน`}
                                        value={no6_3.amount}
                                        unit={'คน'}/>
                                    :
                                    <Answer detail={'ไม่มี'} value={''} unit={''}/>
                                :
                                <Answer detail={'ไม่มีข้อมูล'} value={''} unit={''}/>
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 6.4*/}
                <SubTopic
                    no={'6.4.'}
                    questionName={"ในรอบปีที่ผ่านมา คนอายุตั้งแต่ 35 ปีขึ้นไปได้รับการตรวจสุขภาพประจำปี ทุกคน หรือไม่"}
                >
                    {data ? answer6_4() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>

                {/*Question 6.5*/}
                <SubTopic
                    no={'6.5.'}
                    questionName={"คนที่ไม่ได้รับการตรวจสุขภาพประจำปี ตามข้อ 6.4. ได้รับการตรวจคัดกรองความเสี่ยงต่อโรคเบาหวานและความดันโลหิตสูง ทุกคน หรือไม่"}
                >
                    {
                        data ?
                            no6_5.answer === '1' || no6_5.answer === '0' ?
                                no6_5.answer === '1' ?
                                    <Answer
                                        detail={`มี จำนวน`}
                                        value={no6_5.amount}
                                        unit={'คน'}/>
                                    :
                                    <Answer detail={'ไม่มี'} value={''} unit={''}/>
                                :
                                <Answer detail={'ไม่มีข้อมูล'} value={''} unit={''}/>
                            :
                            <div>ไม่มีข้อมูล</div>
                    }
                </SubTopic>

                {/*Question 6.6*/}
                <SubTopic
                    no={'6.6.'}
                    questionName={"ผู้หญิงอายุ 35 ปีขึ้นไปได้รับการตรวจมะเร็งเต้านมและมะเร็งปากมดลูก"}
                >
                    {data ? answer6_6() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>

                {/*Question */}
                <SubTopic
                    no={'6.7.'}
                    questionName={"คนในครัวเรือนมีความต้องการด้านสาธารณสุขเบื้องต้นอย่างไร"}
                >
                    {data ? answer6_7() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>
            </Topic>

            {/*Question 7*/}
            <Topic
                no={7}
                title={"คนอายุ 6 ปีขึ้นไป ออกกำลังกายอย่างน้อยสัปดาห์ละ 3 วัน วันละ 30 นาที"}
            >
                {/*Question 7.1*/}
                <SubTopic
                    no={'7.1.'}
                    questionName={"คนอายุตั้งแต่ 6 ปีขึ้นไป ทุกคนในครัวเรือนนี้ได้ออกกำลังกายอย่างน้อยสัปดาห์ละ 3 วัน วันละ 30 นาที หรือได้ออกแรง/ออกกำลังติดต่อกันอย่างน้อย 10 นาที ขึ้นไป รวมกันทั้งวัน 30 นาทีอย่างน้อยสัปดาห์ละ 5 วัน หรือไม่"}
                >
                    {data ? answer7_1() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>
            </Topic>
        </div>
    )

}
