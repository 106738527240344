import React, { useEffect, useState } from "react";
import './MemberListScreen.css'
import { Button, Grid, Input, Dropdown, Modal, Image, Icon, Divider, Pagination } from 'semantic-ui-react'
import CardMember from '../../../components/CardMember'
import axios from "axios";
import Config from "../../../constants/Config";
import Loader from "../../../components/RouteLoader/Loader";
import AddMember from "../AddMember";
import DetailMember from "../DetailMember";
import EditMember from '../EditMember';
import { connect } from "react-redux";
// import {withRouter} from "react-router-dom";


const MemberListScreen = (props) => {

    const keywordSearch = [
        { key: 'all', text: 'ทั้งหมด', value: 'all' },
        { key: 'name', text: 'ชื่อ-สกุล', value: 'name' },
        { key: 'id_card', text: 'เลขประจำตัวประชาชน', value: 'id_card' },

    ];
    const countRow = [
        { key: 10, text: '10', value: 10 },
        { key: 20, text: '20', value: 20 },
        { key: 50, text: '50', value: 50 },
        { key: 100, text: '100', value: 100 },
        { key: 150, text: '150', value: 150 },
        { key: 200, text: '200', value: 200 },
    ];

    const [offset, set_offset] = useState('10');
    const [current_page, set_current_page] = useState('1');
    const [from, set_from] = useState('all');
    const [keyword, set_keyword] = useState('');
    const [result, set_result] = useState([]);
    const [info, set_info] = useState([]);
    const [area, set_area] = useState([]);
    const [community, set_community] = useState([]);
    const [permission, set_permission] = useState([]);
    const [area_id, set_area_id] = useState([]);
    const [community_id, set_community_id] = useState([]);
    const [permission_id, set_permission_id] = useState([]);
    const [area_modal, set_area_modal] = useState([]);
    const [community_modal, set_community_modal] = useState([]);
    const [permission_modal, set_permission_modal] = useState([]);
    const [community_option, set_community_option] = useState([]);
    const [open_filter, set_open_filter] = useState(false);
    const [open_add_member, set_open_add_member] = useState(false);
    const [loading_status, set_loading_status] = useState(false);
    const [open_edit_member, set_open_edit_member] = useState(false);
    const [open_detail_member, set_open_detail_member] = useState(false);
    const [id_member, set_id_member] = useState('');
    const [reload_data, set_reload_data] = useState(false)

    // เปิด Modal
    const openModal = () => {
        set_open_filter(true)
    };

    // ปิด Modal
    const closeModal = () => {
        set_open_filter(false)
    };

    // คำนวณข้อมูลตัวแรกของหน้า
    const startOnPage = () => {
        return ((current_page - 1) * offset) + 1
    };

    // คำนวณข้อมูลตัวสุดท้ายของหน้า
    const endOnPage = () => {
        return current_page * offset
    };

    // กำหนดหน้าแรกที่แสดง
    const defaultPage = () => {
        set_current_page('1');
    };

    // เชื่อมข้อมูล API
    const connectApi = async () => {
        try {
            set_result([]);
            set_loading_status(true);
            let response = await axios.get(
                `${Config.url}/api/v1/all_user?page=${current_page}&offset=${offset}&keyword=${keyword}&from=${from}&permission_id=${permission_id}&community_id=${community_id}&area_id=${area_id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json',
                    }
                }
            );
            set_loading_status(false);
            set_reload_data(false)
            if (response.data.value.result) {
                set_result(response.data.value.result);
            } else {
                set_result([]);
            }
            set_info(response.data.value.info);
            return response.data

        } catch (e) {
            console.log('error is', e)
        }

    };

    // เชื่อมข้อมูล API ของตัวกรอง
    const connectFilterApi = async () => {
        try {
            let response = await axios.get(`${Config.url}/api/v1/filter_search`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json',
                    }
                }
            );
            set_area(response.data.value.area);
            set_community(response.data.value.community);
            set_permission(response.data.value.permission);
            return response.data.value

        } catch (e) {
            console.log('Fillter error is', e)
        }
    };

    const reloadData = () => {
        set_reload_data(true)
    }

    useEffect(() => {
        connectApi()
    }, [
        offset,
        current_page,
        from,
        keyword,
        permission_id,
        area_id,
        community_id,
        reload_data
    ]);

    useEffect(() => {
        connectFilterApi()
    }, []);


    return (
        <div className={'member-list-screen'}>

            <Grid>
                <Grid.Row verticalAlign={'middle'}>

                    {/*หัวข้อ ข้อมูลสมาชิกทั้งหมด*/}
                    <Grid.Column
                        mobile={8}
                        tablet={12}
                        computer={16}
                    >
                        <h1 className={'header-text'}>ข้อมูลผู้ใช้งาน</h1>
                    </Grid.Column>

                    {/*ปุ่มเพิ่มสมาชิก*/}
                    <Grid.Column
                        mobile={8}
                        tablet={4}
                        computer={5}
                        className={'align-add-button'}
                    >

                        {/*Button Add Member*/}

                        <AddMember
                            openModal={open_add_member}
                            closeModal={() => {
                                set_open_add_member(false)
                            }}
                            reloadData={() => {
                                reloadData()
                            }}
                        />
                        <button
                            className={'add-button'}
                            onClick={() => {
                                set_open_add_member(true)
                            }}
                        >
                            <Icon name={'user plus'}/>
                            เพิ่มผู้ใช้งาน
                        </button>

                    </Grid.Column>

                    {/*ช่องค้นหา*/}
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={11}
                    >
                        <div style={{ display: 'flex' }}>
                            <div className={'search-box'}>

                                {/*เลือกคำค้นหา*/}
                                <Dropdown
                                    selection
                                    defaultValue={'all'}
                                    options={keywordSearch}
                                    className={'dropdown-search'}
                                    onChange={(event, data) => {
                                        set_from(data.value);
                                        defaultPage()
                                    }}
                                />
                                <div className={'search-content'}>
                                    <input
                                        placeholder='ค้นหา...'
                                        onChange={(e) => {
                                            set_keyword(e.target.value);
                                            defaultPage();
                                        }}

                                    />
                                    <Icon name={'search'}/>
                                </div>
                            </div>


                            {/*ปุ่มกรอง*/}
                            <Modal
                                className={'filter-modal-screen'}
                                trigger={
                                    <button onClick={openModal} className={'filter-button'}>
                                        <Icon name={'filter'} size={'small'}/>
                                        <label>ตัวกรอง</label>
                                    </button>
                                }
                                open={open_filter}

                            >
                                <div className={'modal-filter'}>

                                    {/*หัวข้อตัวกรอง*/}
                                    <div className={'header-filter'}>
                                        <div>
                                            <Icon name={'filter'}/>
                                            <label>ตัวกรอง</label>
                                        </div>
                                        <button onClick={closeModal} className={'close-modal-button'}>
                                            <Icon name={'close'} size={'small'} className={'close-modal'}/>
                                        </button>
                                    </div>

                                    {/*ข้อมูลที่ต้องการกรอง*/}
                                    <div className={'modal-content'}>
                                        {/*ประเภทผู้ใช้งาน*/}
                                        <div className={'filter-detail'}>
                                            <div style={{ fontWeight: 'bold' }}>ประเภทผู้ใช้งาน</div>
                                            <Dropdown
                                                placeholder='เลือกประเภทผู้ใช้งาน'
                                                search
                                                clearable
                                                fluid
                                                selection
                                                value={permission_modal.length === 0 ? '' : permission_modal}
                                                options={
                                                    permission.map((item) => {
                                                        return (
                                                            {
                                                                key: item._id,
                                                                text: item._id === '5e71d0542e86f6aca55b90ea' ? 'ผู้ดูแลศูนย์ฯ' : 'ผู้สำรวจ',
                                                                value: item._id
                                                            }
                                                        )
                                                    })
                                                }
                                                onChange={((event, data) => {
                                                    return (
                                                        set_permission_modal(data.value)
                                                    );
                                                })

                                                }
                                            />
                                        </div>

                                        {/*ศูนย์ฯที่รับผิดชอบ*/}
                                        <div className={'filter-detail'}>
                                            <div style={{ fontWeight: 'bold' }}>ศูนย์ฯที่รับผิดชอบ</div>
                                            <Dropdown
                                                placeholder='เลือกศูนย์ฯที่รับผิดชอบ'
                                                search
                                                clearable
                                                fluid
                                                selection
                                                value={area_modal.length === 0 ? '' : area_modal}
                                                options={
                                                    area?.length > 0 ?
                                                        area.map((item) => {
                                                            return (
                                                                {
                                                                    key: item._id,
                                                                    text: item.name,
                                                                    value: item._id
                                                                }
                                                            )
                                                        })
                                                        :
                                                        []
                                                }
                                                onChange={((event, data) => {
                                                    return (
                                                        set_area_modal(data.value)
                                                    )
                                                })}
                                            />
                                        </div>

                                        {/*เขตชุมชนที่รับผิดชอบ*/}
                                        <div className={'filter-detail'}>
                                            <div style={{ fontWeight: 'bold' }}>เขตชุมชนที่รับผิดชอบ</div>
                                            <Dropdown
                                                placeholder='เลือกเขตชุมชนที่รับผิดชอบ'
                                                search
                                                fluid
                                                clearable
                                                multiple
                                                selection
                                                value={community_option}
                                                options={
                                                    community ?
                                                        community.map((item) => {
                                                            return (
                                                                {
                                                                    key: item._id,
                                                                    text: item.name,
                                                                    value: item._id
                                                                }
                                                            )
                                                        })
                                                        :
                                                        []
                                                }
                                                onChange={((event, data) => {
                                                    if (data.value.length === 0) {
                                                        set_community_option(data.value);
                                                        set_community_modal(data.value)
                                                    } else {
                                                        let communities = data.value.reduce((accumulator, current) => {
                                                            return accumulator + '.' + current
                                                        });
                                                        set_community_option(data.value);
                                                        return (
                                                            set_community_modal(communities)
                                                        )
                                                    }
                                                })}
                                            />
                                        </div>
                                    </div>

                                    {/*ปุ่มบันทึกโมดอลตัวกรอง*/}
                                    <div className={'button-filter-modal'}>
                                        <a onClick={() => {
                                            set_community_modal([]);
                                            set_community_option([]);
                                            set_permission_modal([]);
                                            set_area_modal([]);
                                        }}>ล้างข้อมูลทั้งหมด</a>
                                        <button
                                            onClick={() => {
                                                closeModal();
                                                defaultPage();
                                                return (
                                                    set_permission_id(permission_modal),
                                                        set_area_id(area_modal),
                                                        set_community_id(community_modal)

                                                )

                                            }}
                                            className={'button-save-filter-modal'}
                                        >กรองข้อมูล
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {/*หัวข้อตาราง*/}
            <div className={'header-table'}>

                {/*Block 1*/}
                <div className={'space-block1'}>
                    <label>ประเภทสมาชิก</label>
                    {props.user.permission_id === '5e4a59cec479a2e3b6190e09' &&
                    <div className={'type-user-block'}>
                        <div className={'type-symbol'}
                             style={{
                                 backgroundColor: 'rgba(237, 136, 108, 1)'
                             }}/>
                        <label>ผู้ดูแลศูนย์ฯ</label>
                    </div>
                    }
                    <div className={'type-user-block'}>
                        <div className={'type-symbol'}
                             style={{
                                 backgroundColor: 'rgba(103, 173, 25, 1)',
                             }}/>
                        <label>ผู้สำรวจ</label>
                    </div>
                </div>

                {/*Block 2*/}
                <div className={'row-content'}>
                    <div>
                        <div>ชื่อ</div>
                    </div>
                    <div className={'block2-content'}>
                        <div className={'space-content'}>เลขประจำตัวประชาชน</div>
                        <div className={'space-content'}>หมายเลขโทรศัพท์</div>
                        <div className={'space-content'}>เขตชุมชนที่รับผิดชอบ</div>
                    </div>
                    <div>
                        <div>ที่อยู่อาศัย</div>

                    </div>
                </div>

                {/*Block 3*/}
                <div className={'column-tools'}/>
            </div>

            {/*แสดงข้อมูลสมาชิก*/}
            <div className={'block-member-card'}>


                {/* open modal detail member */}
                {open_detail_member && (
                    <DetailMember
                        openDetail={open_detail_member}
                        idUser={id_member}
                        closeDetail={() => set_open_detail_member(false)}
                        openEditMember={() => {
                            set_open_detail_member(false)
                            set_open_edit_member(true)
                        }}

                    />
                )}

                {/* open modal edit member */}
                {open_edit_member && (
                    <EditMember
                        openEdit={open_edit_member}
                        idUser={id_member}
                        closeEdit={() => set_open_edit_member(false)}
                        reloadData={() => {
                            reloadData()
                        }}
                    />
                )}

                {
                    result.map((item, index) => {
                        return <CardMember
                            key={index}
                            item={item}
                            index={startOnPage() + index}
                            onClick={(keyword) => {
                                set_id_member('')
                                if (keyword === 'view_member') {
                                    set_open_detail_member(true)
                                    set_id_member(item._id)
                                } else if (keyword === 'edit_member') {
                                    set_open_edit_member(true)
                                    set_id_member(item._id)
                                }

                            }}
                            reloadData={() => {
                                reloadData()
                            }}
                        />

                    })
                }
                {loading_status ? <Loader/>
                    : result.length === 0 &&
                    <div className={'loading-block'}>
                        <span>ไม่พบข้อมูล</span>
                    </div>
                }
            </div>


            {/*แสดงจำนวนหน้า */}
            <div className={'all-page'}>
                <div className={'count-page'} style={{ flex: '1' }}>
                    <label>แสดงข้อมูลลำดับที่ {startOnPage()} ถึงลำดับที่ {endOnPage() <= info.row_all ? endOnPage() : info.row_all} จากทั้งหมด {info.row_all} ข้อมูล</label>
                </div>
                <div className={'count-page'}>
                    <label>แสดง
                        <Dropdown
                            className={'input-count'}
                            defaultValue={10}
                            selection
                            options={countRow}
                            onChange={(event, data) => {
                                set_offset(data.value)
                            }}
                        />
                        แถว</label>
                    <Pagination
                        defaultActivePage={1}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal'/>, icon: true }}
                        firstItem={{ content: <Icon name='angle double left'/>, icon: true }}
                        lastItem={{ content: <Icon name='angle double right'/>, icon: true }}
                        prevItem={{ content: <Icon name='angle left'/>, icon: true }}
                        nextItem={{ content: <Icon name='angle right'/>, icon: true }}
                        totalPages={info.end_page ? info.end_page : 1}
                        onPageChange={(event, data) => {
                            set_current_page(data.activePage);
                        }}
                    />
                </div>

            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
};

export default connect(mapStateToProps, null)(MemberListScreen)
