let url = ''
if (process.env.REACT_APP_ENVIRONMENT_DEPLOY === 'product') {
    url = 'https://local-survey.ogsdev.net'
} else if (process.env.REACT_APP_ENVIRONMENT_DEPLOY === 'test') {
    url = 'https://kkm-pop-test.ogsdev.net'
} else {
    // url = 'http://127.0.0.1:1324'
    url = 'https://kkm-pop-test.ogsdev.net'
}
export default {
    url: url,
    map_key: "AIzaSyB4woITfwUAsSMcP_MnU31CKy1c8xCHZYs"
}
