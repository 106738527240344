import React, { useState } from 'react';
import "./DataHouse.css";
import { Grid, Image } from 'semantic-ui-react';
import imageDefault from '../../../assets/img/home-default.png'
import Config from "../../../constants/Config";
import Lightbox from 'react-lightbox-component';
import "react-lightbox-component/build/css/index.css"

export default ({ item, editMarker }) => {
    const [img_err, set_img_err] = useState(false)

    let {
        house_no,
        house_code,
        ref_house_code,
        village_no,
        village_name,
        alley,
        road,
        sub_district,
        district,
        province,
        zip_code,
        house,
        image,
        jpt_survey,
        land,
    }
        = item;

    return (
        <Grid
            columns={2}
        >

            {/* image */}
            <Grid.Column
                mobile={editMarker ? 4 : 16}
                tablet={editMarker ? 4 : 16}
                computer={4}
                largeScreen={4}
            >

                {/*รูปโปรไฟล์บ้าน*/}
                <div style={{ textAlign: 'center', marginTop: 10 }}>
                    {/*<img*/}
                    {/*    style={{*/}
                    {/*        position: 'relative',*/}
                    {/*        verticalAlign: 'middle',*/}
                    {/*        maxWidth: '100%',*/}
                    {/*    }}*/}
                    {/*    onError={() => {*/}
                    {/*        set_img_err(true)*/}
                    {/*    }}*/}
                    {/*    src={img_err ? imageDefault : image ? `${Config.url}/api/v1${image.path}` : imageDefault}*/}
                    {/*/>*/}

                    <Lightbox images={
                        [
                            {
                                src: img_err ? imageDefault : image ? `${Config.url}/api/v1${image.path}` : imageDefault,
                                title: '',
                                description: 'รูปภาพบ้าน'
                            }
                        ]
                    }
                    />
                </div>

            </Grid.Column>

            {/* detail */}
            <Grid.Column
                mobile={editMarker ? 12 : 16}
                tablet={editMarker ? 12 : 16}
                computer={12}
                largeScreen={12}>

                <div className={'address-header'}>
                    {house_no && `บ้านเลขที่ ${house_no}`}
                    {ref_house_code && `บ้านเลขที่ใกล้เคียง ${ref_house_code}`}
                </div>
                <Grid
                    style={{ marginBottom: 2 }}
                >

                    {/* row 1 */}
                    <Grid.Row>
                        <Grid.Column
                            textAlign={"right"}
                            mobile={editMarker ? 7 : 8}
                            tablet={4}
                            computer={5}
                            largeScreen={5}
                            className={'address-topic'}>
                            <div>{house_code && 'รหัสประจำบ้าน'}</div>
                        </Grid.Column>
                        <Grid.Column
                            mobile={editMarker ? 9 : 8}
                            tablet={12}
                            computer={11}
                            largeScreen={11}
                            className={'address-detail'}>
                            <div>{house_code && house_code}</div>
                        </Grid.Column>

                        <Grid.Column
                            textAlign={'right'}
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={5}
                            largeScreen={5}
                            className={'address-topic'}>
                            <div>หมู่ที่</div>
                        </Grid.Column>
                        <Grid.Column
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={3}
                            largeScreen={3}
                            className={'address-detail'}>
                            <div>{village_no}</div>
                        </Grid.Column>

                        <Grid.Column
                            textAlign={'right'}
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={5}
                            largeScreen={5}
                            className={'address-topic'}>
                            <div>หมู่บ้าน</div>
                        </Grid.Column>
                        <Grid.Column
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={3}
                            largeScreen={3}
                            className={'address-detail'}>
                            <div>{village_name ? village_name : '-'}</div>
                        </Grid.Column>
                    </Grid.Row>

                    {/* row 2 */}
                    <Grid.Row className={'line-height-row sub-detail-data-house'}>
                        <Grid.Column
                            textAlign={'right'}
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={5}
                            largeScreen={5}
                            className={'address-topic'}>
                            <div>ซอย</div>
                        </Grid.Column>
                        <Grid.Column
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={3}
                            largeScreen={3}
                            className={'address-detail'}>
                            <div>{alley ? alley : '-'}</div>
                        </Grid.Column>

                        <Grid.Column
                            textAlign={'right'}
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={5}
                            largeScreen={5}
                            className={'address-topic'}>
                            <div>ถนน</div>
                        </Grid.Column>
                        <Grid.Column
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={3}
                            largeScreen={3}
                            className={'address-detail'}>
                            <div>{road ? road : '-'}</div>
                        </Grid.Column>
                    </Grid.Row>

                    {/* row 3 */}
                    <Grid.Row className={'line-height-row sub-detail-data-house'}>
                        <Grid.Column
                            textAlign={'right'}
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={5}
                            largeScreen={5}
                            className={'address-topic'}>
                            <div>ตำบล</div>
                        </Grid.Column>
                        <Grid.Column
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={3}
                            largeScreen={3}
                            className={'address-detail'}>
                            <div>{sub_district}</div>
                        </Grid.Column>

                        <Grid.Column
                            textAlign={'right'}
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={5}
                            largeScreen={5}
                            className={'address-topic'}>
                            <div>อำเภอ</div>
                        </Grid.Column>
                        <Grid.Column
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={3}
                            largeScreen={3}
                            className={'address-detail'}>
                            <div>{district}</div>
                        </Grid.Column>
                    </Grid.Row>

                    {/* row 4 */}
                    <Grid.Row className={'line-height-row sub-detail-data-house'}>
                        <Grid.Column
                            textAlign={'right'}
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={5}
                            largeScreen={5}
                            className={'address-topic'}>
                            <div>จังหวัด</div>
                        </Grid.Column>
                        <Grid.Column
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={3}
                            largeScreen={3}
                            className={'address-detail'}>
                            <div>{province}</div>
                        </Grid.Column>

                        <Grid.Column
                            textAlign={'right'}
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={5}
                            largeScreen={5}
                            className={'address-topic'}>
                            <div>รหัสไปรษณีย์</div>
                        </Grid.Column>
                        <Grid.Column
                            mobile={editMarker ? 4 : 8}
                            tablet={4}
                            computer={3}
                            largeScreen={3}
                            className={'address-detail'}>
                            <div>{zip_code}</div>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>

                <div className={'detail-data-house'}>
                    <div className={'address-header'}>ที่ตั้งที่พักอาศัย</div>
                    <Grid
                        style={{ marginBottom: 2 }}
                    >

                        {/* living location row */}
                        <Grid.Row>
                            <Grid.Column
                                textAlign={'right'}
                                mobile={8}
                                tablet={4}
                                computer={5}
                                largeScreen={5}
                                className={'address-topic'}>
                                <div>{house ? house.house_type : '-'}</div>
                            </Grid.Column>
                            <Grid.Column
                                mobile={8}
                                tablet={12}
                                computer={11}
                                largeScreen={11}
                                className={'address-detail'}>
                                <div>{house ? `${house.area.rai} ไร่ ${house.area.square_wa} ตารางวา` : '-'}</div>
                            </Grid.Column>

                            <Grid.Column
                                textAlign={'right'}
                                mobile={8}
                                tablet={4}
                                computer={5}
                                largeScreen={5}
                                className={'address-topic'}>
                                <div>ประเภทเอกสารสิทธิ์</div>
                            </Grid.Column>
                            <Grid.Column
                                mobile={8}
                                tablet={12}
                                computer={11}
                                largeScreen={11}
                                className={'address-detail'}>
                                <div>{house && house.document_type ? house.document_type : '-'}</div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <div className={'address-header'}>ที่ตั้งที่ประกอบอาชีพ</div>
                    <Grid
                        style={{ marginBottom: 2 }}
                    >
                        {/* job location row */}
                        {land && land.land_type ?
                            <Grid.Row>
                                <Grid.Column
                                    textAlign={'right'}
                                    mobile={8}
                                    tablet={4}
                                    computer={5}
                                    largeScreen={5}
                                    className={'address-topic'}>
                                    <div>{land ? land.land_type && land.land_type !== '-' ? land.land_type : 'ไม่มีที่ดินประกอบอาชีพ' : 'ไม่มีที่ดินประกอบอาชีพ'}</div>
                                </Grid.Column>
                                <Grid.Column
                                    mobile={8}
                                    tablet={12}
                                    computer={11}
                                    largeScreen={11}
                                    className={'address-detail'}>
                                    <div>{land ? `${land.area.rai ? land.area.rai : 0} ไร่ ${land.area.square_wa ? land.area.square_wa : 0} ตารางวา` : '-'}</div>
                                </Grid.Column>
                                <Grid.Column
                                    textAlign={'right'}
                                    mobile={8}
                                    tablet={4}
                                    computer={5}
                                    largeScreen={5}
                                    className={'address-topic'}>
                                    <div>ประเภทเอกสารสิทธิ์</div>
                                </Grid.Column>
                                <Grid.Column
                                    mobile={8}
                                    tablet={12}
                                    computer={11}
                                    largeScreen={11}
                                    className={'address-detail'}>
                                    <div>{land ? land.document_type : '-'}</div>
                                </Grid.Column>
                            </Grid.Row>
                            :
                            <Grid.Row>
                                <Grid.Column
                                    textAlign={'right'}
                                    mobile={8}
                                    tablet={8}
                                    computer={5}
                                    largeScreen={5}
                                    className={'address-topic'}
                                >
                                    <div>ไม่มีที่ดินประกอบอาชีพ</div>
                                </Grid.Column>
                            </Grid.Row>}
                    </Grid>
                </div>

            </Grid.Column>
        </Grid>
    )
}
