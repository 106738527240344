const INITIAL_STATE = () => ({
    _id: '',
    prefix: '',
    first_name: '',
    last_name: '',
    full_name: '',
    id_card_number: '',
    birth_date: '',
    phone_number: '',
    permission_id: '',
    permission_name: '',
    area_id: '',
    area_name: '',
    community_id: '',
    community_name: '',
    address: {},
    image: {},
    permission: {},
    area: {},
    community: {},

});

export const UserReducer = (state = INITIAL_STATE(), action) => {
    switch (action.type) {
        case 'USER_SHOW' :
            return {
                _id: action.payload._id,
                prefix: action.payload.prefix,
                first_name: action.payload.first_name,
                last_name: action.payload.last_name,
                full_name: action.payload.full_name,
                id_card_number: action.payload.id_card_number,
                birth_date: '',
                phone_number: action.payload.phone_number,
                permission_id: action.payload.permission_id,
                permission_name: action.payload.permission_name,
                area_id: action.payload.area_id,
                area: action.payload.area,
                community_id: action.payload.community_id,
                community_name: action.payload.community_name,
                address: action.payload.address,
                image: action.payload.image,
            };
        case 'USER_EDIT':
            return {
                ...state,
                full_name: action.payload.full_name,
                id_card_number: action.payload.id_card_number,
                phone_number: action.payload.phone_number,
                permission: action.payload.permission,
                area: action.payload.area,
                community: action.payload.community,
                address: action.payload.address,
                image: action.payload.image,
            };

        default:
            return state
    }
};
