import React from "react";
import './FormatContainer.css'
import {Icon, Grid} from 'semantic-ui-react'

export default (props) => {
    return (
            <div className={'format-container'} style={props.style}>
                <div className={'content-title'}>
                    <div className={'icon-title'}>
                    <Icon
                        name={props.icon}
                    />
                    </div>
                    <span className={'text-title'} >{props.title}</span>
                </div>
                <div className={'content-detail'}>
                    {props.children}
                </div>
            </div>
    )
}
