import React, {useState, useEffect} from "react";
import './EditProfile.css'
import {connect} from "react-redux";
import {User} from '../../../store/actions'
import EditDetailProfile from './EditDetailProfile'
import EditAddressProfile from './EditAddressProfile'

import {Formik} from 'formik';
import checkFormat from "../../../function/checkFormat";
import axios from "axios";
import Config from "../../../constants/Config";
import swal from "sweetalert2";
import Loader from "../../../components/RouteLoader/Loader";


const EditProfile = (props) => {

    const [detail_profile, set_detail_profile] = useState({})
    const [address_profile, set_address_profile] = useState({})
    const [loading_page, set_loading_page] = useState(false);
    const [path_image, set_path_image] = useState('');

    // ดึงข้อมูลผู้ใช้งาน
    const apiDetailProfile = () => {
        return axios
            .get(`${Config.url}/api/v1/profile`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                // console.log('response: ', response.data.value);
                return response;
            })
            .catch((error) => {
                // console.log(error.response);
                return 'DetailMember is catch';
            });
    }

    /* Loading */
    const Loading = () => {
        return Promise.all([apiDetailProfile()]).then((data) => {
            const detail_user = data[0];

            if (detail_user) {
                /* detailMember */
                set_detail_profile(detail_user.data.value);
                set_address_profile(detail_user.data.value.address);
                if (detail_user.data.value.image) {
                    set_path_image(detail_user.data.value.image.path);
                }
                set_loading_page(true);
            } else {
                set_loading_page(false);
            }
        });
    };

    useEffect(() => {
        Loading();
    }, []);

    // ฟังก์ชันบันทึกการแก้ไขข้อมูลผู้ใช้งาน
    const summitEditForm = async (values) => {
        let editForm = {
            prefix: values.prefix,
            first_name: values.first_name,
            last_name: values.last_name,
            id_card_number: values.id_card_number,
            phone_number: values.phone_number,
            address: {
                house_code: values.house_code,
                house_no: values.house_no,
                village_no: values.village_no,
                village_name: values.village_name,
                alley: values.alley,
                road: values.road,
                sub_district: values.sub_district,
                district: values.district,
                province: values.province,
                zip_code: values.zip_code,
            },
            permission_id: values.permission_id,
            area_id: values.area_id,
            community_id: values.community_id,
            // image: values.img_profile
        };

        // เรียก API บันทึกข้อมูลที่แก้ไข
        await axios
            .put(`${Config.url}/api/v1/edit_profile`,
                editForm,
                {
                    headers: {
                        'Authorization': 'Bearer ' + await localStorage.getItem('access_token'),
                        'Content-Type': 'application/json'
                    }
                })
            .then((response) => {
                if (values.img_profile !== props.user.image.path) {
                    let imageData = new FormData();
                    imageData.append('image', values.img_profile);
                    axios
                        .put(`${Config.url}/api/v1/upload_image_profile`,
                            imageData,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                        .then(function (response) {
                            //handle success
                            swal
                                .fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: 'เพิ่มข้อมูลสมาชิกเรียบร้อบแล้ว',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                .then(() => {
                                    props.history.push(props.user.permission_id === "5e4a59cec479a2e3b6190e09" ? '/administrator/profile' : '/centeradministrator/profile')
                                })

                        })
                        .catch((error) => {
                            //handle error
                            if (error.response) {
                                const message = error.response.data.message;
                                swal
                                    .fire({
                                        position: 'center',
                                        icon: 'error',
                                        title: 'เกิดข้อผิดพลาด',
                                        text: message,
                                        confirmButtonText: 'ตกลง'
                                    })
                            } else {
                                swal
                                    .fire({
                                        position: 'center',
                                        icon: 'error',
                                        title: 'เกิดข้อผิดพลาด',
                                        confirmButtonText: 'ตกลง'
                                    })
                            }
                        });
                } else {
                    swal
                        .fire({
                            position: 'center',
                            icon: 'success',
                            title: 'เพิ่มข้อมูลสมาชิกเรียบร้อบแล้ว',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    props.history.push(props.user.permission_id === "5e4a59cec479a2e3b6190e09" ? '/administrator/profile' : '/centeradministrator/profile')

                }

            })
            .catch((error) => {
                if (error.response) {
                    const message = error.response.data.message;
                    swal
                        .fire({
                            position: 'center',
                            icon: 'error',
                            title: 'เกิดข้อผิดพลาด',
                            text: message,
                            confirmButtonText: 'ตกลง'
                        })

                } else {
                    console.log('err', error)
                    swal
                        .fire({
                            position: 'center',
                            icon: 'error',
                            title: 'เกิดข้อผิดพลาด',
                            confirmButtonText: 'ตกลง'
                        })

                }
            });
    };

    if (loading_page === true) {
        return (

            <div className={'edit-profile-screen'}>

                {/*<Header className={'header-profile'} as='h1' icon='user' content='ข้อมูลผู้ใช้งาน' />*/}

                <h1 className={'header-profile'}>แก้ไขข้อมูลผู้ใช้งาน</h1>

                {/*ฟอร์ม Formik*/}
                <Formik
                    initialValues={{
                        prefix: props.user.prefix,
                        first_name: props.user.first_name,
                        last_name: props.user.last_name,
                        id_card_number: props.user.id_card_number,
                        phone_number: props.user.phone_number,
                        permission_id: props.user.permission_id,
                        permission_name: props.user.permission_name,
                        area_id: props.user.area_id,
                        area_name: props.user.area_name,
                        community_id: props.user.community_id,
                        community_name: props.user.community_name,
                        house_code: props.user.address.house_code,
                        house_no: props.user.address.house_no,
                        village_no: props.user.address.village_no,
                        village_name: props.user.address.village_name,
                        alley: props.user.address.alley,
                        road: props.user.address.road,
                        sub_district: props.user.address.sub_district,
                        district: props.user.address.district,
                        province: props.user.address.province,
                        zip_code: props.user.address.zip_code,
                        img_profile: props.user.image.path,
                    }}

                    validate={(values) => {

                        const errors = {};

                        if (!values.prefix) {
                            errors.prefix = 'กรุณาเลือกคำนำหน้า';
                        }

                        if (!values.first_name) {
                            errors.first_name = 'กรุณากรอกชื่อ';
                        } else if (!checkFormat.name.regex.test(values.first_name)) {
                            errors.first_name = 'กรุณากรอกชื่อเป็นภาษาไทย';
                        }

                        if (!values.last_name) {
                            errors.last_name = 'กรุณากรอกสกุล';
                        } else if (!checkFormat.name.regex.test(values.last_name)) {
                            errors.last_name = 'กรุณากรอกสกุลเป็นภาษาไทย';
                        }


                        if (!values.phone_number) {
                            errors.phone_number = 'กรุณากรอกหมายเลขโทรศัพท์';
                        } else if (!checkFormat.phone_number.regex.test(values.phone_number)) {
                            errors.phone_number = 'กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง เช่น XXXXXXXXXX';
                        }

                        if (!values.house_no) {
                            errors.house_no = 'กรุณากรอกบ้านเลขที่';
                        }

                        if (!values.village_no) {
                            errors.village_no = 'กรุณากรอกหมู่ที่';
                        }

                        if (!values.village_name) {
                            errors.village_name = 'กรุณากรอกหมู่บ้าน';
                        }

                        if (!values.sub_district) {
                            errors.sub_district = 'กรุณาเลือกตำบล';
                        }

                        if (!values.district) {
                            errors.district = 'กรุณาเลือกอำเภอ';
                        }

                        if (!values.province) {
                            errors.province = 'กรุณาเลือกจังหวัด';
                        }

                        if (!values.zip_code) {
                            errors.zip_code = 'กรุณากรอกรหัสไปรษณีย์';
                        } else if (!checkFormat.zip_code.regex.test(values.zip_code)) {
                            errors.zip_code = 'กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง เช่น XXXXX';
                        }

                        return errors;

                    }}

                    onSubmit={(values, {setSubmitting}) => {
                        summitEditForm(values);

                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                          /* and other goodies */
                      }) => (

                        // ฟอร์มส่งข้อมูล
                        <form onSubmit={handleSubmit}>

                            {/*Component ข้อมูลส่วนตัว*/}
                            <EditDetailProfile
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                // handleSubmit={handleSubmit}
                                isSubmitting={isSubmitting}
                            />

                            {/*ข้อมูที่ิยู่อาศัย*/}
                            <EditAddressProfile
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                isSubmitting={isSubmitting}
                            />

                            {/*ปุ่ม action*/}
                            <div className={'container-button-action'}>

                                <a
                                    onClick={() => {
                                        props.history.push(props.user.permission_id === "5e4a59cec479a2e3b6190e09" ? '/administrator/profile' : '/centeradministrator/profile')
                                    }}
                                    href={'#'}>ยกเลิก</a>

                                <button
                                    className={'button-action'}
                                    type='submit'
                                    disabled={isSubmitting}
                                    // onClick={() => {
                                    //     props.history.push('/administrator/profile')
                                    // }}
                                >
                                    <span>บันทึกข้อมูล</span>
                                </button>

                            </div>

                        </form>
                    )}


                </Formik>


            </div>
        )
    }
    else {
        return <Loader/>
    }

    };




const mapDispatchToProps = (dispatch) => {
    return {
        ...User(dispatch)
    }
};

const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)
