import {Divider, Grid, Icon, Input} from "semantic-ui-react";
import Image from "../../../../components/Image";
import Config from "../../../../constants/Config";
import React from "react";
import InputMask from 'react-input-mask';
import {Field} from 'formik';
import {DropdownDetail} from "../../../../components/Profile/Dropdown";
import UpdateImageProfile from "../../../../components/Profile/UpdateImageProfile";
import {DropdownEditMember} from "../../../MemberScreen/EditMember/DropdownEditMember";

/* Options Prefix */
const prefix = [
    {
        key: 'นาย',
        text: 'นาย',
        value: 'นาย'
    },
    {
        key: 'นาง',
        text: 'นาง',
        value: 'นาง'
    },
    {
        key: 'นางสาว',
        text: 'นางสาว',
        value: 'นางสาว'
    }
];

const EditDetailProfile = (props) => {

    return (
        <div className={'profile-user'}>

            {/*หัวข้อข้อมูลส่วนตัว*/}
            <div className={'container-subheader'}>
                <div className={'subheader'}>
                    <Icon className={'icon-header'} name='id card'/>
                    ข้อมูลส่วนตัว
                </div>
                <Divider clearing/>
            </div>

            {/*แสดงข้อมูลส่วนตัว*/}
            <div className={'container-content'}>

                {/*อัปเดตรูปโปรไฟล์*/}
                <div className={'img-profile-content'}>

                    <Field
                        name='img_profile'
                        component={UpdateImageProfile}
                        pathImage={props.values.img_profile}
                        fluid
                    />

                </div>


                {/*เส้นขั้นรูปภาพกับข้อมูล*/}
                <div className={'divider-profile'}/>

                {/*ข้อมูลส่วนตัว*/}
                <div className={'container-content-input'}>

                    {/*คำนำหน้า*/}
                    <div className={'container-input'}>
                        <label className={'label-detail'}>คำนำหน้า</label>
                        <div className={'input-status'}>
                            <Field
                                placeholder='เลือกคำนำหน้า'
                                options={prefix}
                                name='prefix'
                                component={DropdownDetail}
                                fluid
                                onBlur={props.handleBlur}
                                className={
                                    props.errors.prefix && props.touched.prefix && props.errors.prefix ? (
                                        'error'
                                    ) : (
                                        props.values.prefix && 'success'
                                    )
                                }
                            />
                            {props.errors.prefix && props.touched.prefix && props.errors.prefix && (
                                <span className='set_color_red'>{props.errors.prefix}</span>
                            )}
                        </div>
                    </div>

                    {/*ชื่อ*/}
                    <div className={'container-input'}>
                        <label className={'label-detail'}>ชื่อ</label>
                        <div className={'input-status'}>
                            <Input
                                placeholder='กรอกชื่อ'
                                values={props.values}
                                type='text'
                                name='first_name'
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.first_name}
                                className={
                                    props.errors.first_name &&
                                    props.touched.first_name &&
                                    props.errors.first_name ? (
                                        'error'
                                    ) : (
                                        props.values.first_name && 'success'
                                    )
                                }
                            />
                            {props.errors.first_name && props.touched.first_name && props.errors.first_name ? (
                                <span style={{color: 'red'}}>{props.errors.first_name}</span>
                            ) : null}
                        </div>
                    </div>

                    {/*สกุล*/}
                    <div className={'container-input'}>
                        <label className={'label-detail'}>สกุล</label>
                        <div className={'input-status'}>
                            <Input
                                placeholder='กรอกสกุล'
                                values={props.values}
                                type='text'
                                name='last_name'
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.last_name}
                                className={
                                    props.errors.last_name &&
                                    props.touched.last_name &&
                                    props.errors.last_name ? (
                                        'error'
                                    ) : (
                                        props.values.last_name && 'success'
                                    )
                                }

                            />
                            {props.errors.last_name && props.touched.last_name && props.errors.last_name ? (
                                <span style={{color: 'red'}}>{props.errors.last_name}</span>
                            ) : null}
                        </div>
                    </div>

                    {/*เลขประจำตัวประชาชน*/}
                    <div className={'container-input'}>
                        <label className={'label-detail'}>เลขประจำตัวประชาชน</label>
                        <Input
                            disabled
                            placeholder='กรอกเลขประจำตัวประชาชน'
                            value={props.values.id_card_number ? props.values.id_card_number.replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5') : "ไม่พบข้อมูล"}
                        />
                    </div>

                    {/*หมายเลขโทรศัพท์*/}
                    <div className={'container-input'}>
                        <label className={'label-detail'}>หมายเลขโทรศัพท์</label>
                        <div className={'input-status'}>
                            <Input
                                placeholder='กรอกหมายเลขโทรศัพท์'
                                value={props.values.phone_number}
                                values={props.values}
                                type='text'
                                name='phone_number'
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                className={
                                    props.errors.phone_number &&
                                    props.touched.phone_number &&
                                    props.errors.phone_number ? (
                                        'error'
                                    ) : (
                                        props.values.phone_number && 'success'
                                    )
                                }
                            />
                            {props.errors.phone_number &&
                            props.touched.phone_number &&
                            props.errors.phone_number ? (
                                <span style={{color: 'red'}}>{props.errors.phone_number}</span>
                            ) : null}
                        </div>
                    </div>

                    {/*ประเภทผู้ใช้งาน*/}
                    <div className={'container-input'}>
                        <label className={'label-detail'}>ประเภทผู้ใช้งาน</label>
                        <Input
                            disabled
                            placeholder='เลือกประเภทผู้ใช้งาน'
                            value={props.values.permission_name}
                        />
                    </div>

                    {/*ศูนยที่รับผิดชอบ*/}
                    <div className={'container-input'}>
                        <label className={'label-detail'}>ศูนยที่รับผิดชอบ</label>
                        <Input
                            disabled
                            placeholder='เลือกศูนยที่รับผิดชอบ'
                            value={props.values.area_name}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
};
export default EditDetailProfile;
