import * as React from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import 'moment/locale/th';

export const BirthDateAddMember = ({
	field: { name, value, onChange, onBlur },
	form: { touched, errors, setFieldValue },
	id,
	label,
	className,
	...props
}) => {
	return (
		<div>
			{/* DateInput */}
			<DateInput
				placeholder='กรอกวันเกิด'
				localization='Th'
				name={name}
				value={value}
				onChange={(_, { value }) => setFieldValue(name, value)}
				fluid
				className={
					errors.birth_date_format && touched.birth_date_format && errors.birth_date_format ? (
						'error'
					) : (
						value && 'success'
					)
				}
			/>
		</div>
	);
};
export default BirthDateAddMember;
