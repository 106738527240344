import React from "react";
import Topic from "./Topic";
import SubTopic from "./SubTopic";
import Answer from "./Answer";

export default ({data, ...props}) => {
    let {
        no15_1,
        no15_2,
        no16_1,
        no16_2,
        no16_3,
        no16_4,
        no16_5,
        no17_1,
        no17_2,
        no17_3,
        no17_4,
        no17_5,
        no18_1,
        no18_2,
        no18_3,
        no18_4,
        no19_1,
        no19_2,
        no19_3,
        no20_1,
        no20_2,
        no20_3,
    } = data

    const answer16_4 = () => {
        return (
            <div>
                {
                    no16_4.no16_4_1.answer === '1' &&
                    <Answer detail={`- เรียนต่อ กศน/การศึกษาผู้ใหญ่ จำนวน`} value={no16_4.no16_4_1.amount} unit={'คน'}/>
                }
                {
                    no16_4.no16_4_2.answer === '1' &&
                    <Answer detail={`- ศึกษาเองที่บ้าน จำนวน`} value={no16_4.no16_4_2.amount} unit={'คน'}/>
                }
                {
                    no16_4.no16_4_3.answer === '1' &&
                    <Answer detail={`- เรียนต่อต่างประเทศ จำนวน`} value={no16_4.no16_4_3.amount} unit={'คน'}/>
                }
                {
                    no16_4.no16_4_4.answer === '1' &&
                    <Answer detail={`- ไม่ได้เรียนต่อแต่ทำงาน จำนวน`} value={no16_4.no16_4_4.amount} unit={'คน'}/>
                }
                {
                    no16_4.no16_4_5.answer === '1' &&
                    <Answer detail={`- ไม่ได้เรียนต่อและไม่ได้ทำงาน จำนวน`} value={no16_4.no16_4_5.amount} unit={'คน'}/>
                }
                {
                    (no16_4.no16_4_1.answer !== '1' && no16_4.no16_4_2.answer !== '1' && no16_4.no16_4_3.answer !== '1' &&
                        no16_4.no16_4_4.answer !== '1' && no16_4.no16_4_5.answer !== '1') &&
                    <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                }
            </div>
        )
    }

    const answer17_4 = () => {
        return(
            <div>
                {
                    no17_4.no17_4_1.answer === '1' &&
                    <Answer detail={`- เรียนต่อ กศน/การศึกษาผู้ใหญ่ จำนวน `}
                            value={no17_4.no17_4_1.amount} unit={'คน'}/>
                }
                {
                    no17_4.no17_4_2.answer === '1' &&
                    <Answer detail={`- ศึกษาเองที่บ้าน จำนวน `} value={no17_4.no17_4_2.amount}
                            unit={'คน'}/>
                }
                {
                    no17_4.no17_4_3.answer === '1' &&
                    <Answer detail={`- เรียนต่อต่างประเทศ จำนวน `}
                            value={no17_4.no17_4_3.amount} unit={'คน'}/>
                }
                {
                    no17_4.no17_4_4.answer === '1' &&
                    <Answer detail={`- ไม่ได้เรียนต่อแต่ทำงาน จำนวน `}
                            value={no17_4.no17_4_4.amount} unit={'คน'}/>
                }
                {
                    no17_4.no17_4_5.answer === '1' &&
                    <Answer detail={`- ไม่ได้เรียนต่อและไม่ได้ทำงาน จำนวน `}
                            value={no17_4.no17_4_5.amount} unit={'คน'}/>
                }
                {
                    (no17_4.no17_4_1.answer !== '1' && no17_4.no17_4_2.answer !== '1' && no17_4.no17_4_3.answer !== '1' &&
                        no17_4.no17_4_4.answer !== '1' && no17_4.no17_4_5.answer !== '1') &&
                    <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                }
            </div>
        )
    }

    const answer18_3 = () => {
        return (
            <div>
                {
                    no18_3.no18_3_1.answer === '1' &&
                    <Answer detail={`- ผู้ที่จบ ม.3 ในรอบปีที่ผ่านมา จำนวน `}
                            value={no18_3.no18_3_1.amount} unit={'คน'}/>
                }
                {
                    no18_3.no18_3_2.answer === '1' &&
                    <Answer detail={`- ผู้ที่จบ ม.3 ในปีอื่นๆ จำนวน `}
                            value={no18_3.no18_3_2.amount} unit={'คน'}/>
                }
                {
                    (no18_3.no18_3_1.answer !== '1' && no18_3.no18_3_2.answer !== '1') &&
                    <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                }
            </div>
        )
    }

    return (
        <div>
            {/*Question 15*/}
            <Topic
                no={15}
                title={"เด็กอายุ 3 - 5 ปีได้รับการบริการเลี้ยงดูเตรียมความพร้อมก่อนวัยเรียน"}
            >
                {/*Question 15.1*/}
                <SubTopic
                    no={'15.1.'}
                    questionName={"ครัวเรือนนี้ มีเด็กอายุ 3 - 5 ปี หรือไม่"}
                >
                    {
                        (no15_1.answer === '1' || no15_1.answer === '0') ?
                            no15_1.answer === '1' ?
                                `มี จำนวน ${no15_1.amount} คน`
                                : 'ไม่มี'
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>

                {/*Question 15.2*/}
                <SubTopic
                    no={'15.2.'}
                    questionName={"เด็กอายุ 3 - 5 ปี ได้รับการบริการเลี้ยงดูเตรียมความพร้อม ก่อนวัยเรียนในศูนย์พัฒนาเด็กเล็กศูนย์พัฒนาเด็กเล็กเคลื่อนที่ ศูนย์พัฒนาเด็กเล็กก่อนเกณฑ์ขององค์กรปกครองส่วนท้องถิ่นสถาบันศาสนา อนุบาลชนบท โรงเรียนอนุบาล เป็นต้นหรือได้เข้าร่วมกิจกรรมเกี่ยวกับการเตรียมความพร้อมของเด็กก่อนวัยเรียนทุกคนหรือไม่"}
                >
                    {
                        (no15_2.answer === '1' || no15_2.answer === '0') ?
                            no15_2.answer === '1' ?
                                `ได้รับบริการทุกคน`
                                : `ไม่ได้รับบริการ จำนวน ${no15_2.amount} คน`
                            : 'ไม่มีข้อมูล'
                    }
                </SubTopic>
            </Topic>

            {/*Question 16*/}
            <Topic
                no={16}
                title={"เด็กอายุ 6 - 14 ปี ได้รับการศึกษาภาคบังคับ 9 ปี"}
            >
                {/*Question 16.1*/}
                <SubTopic
                    no={'16.1.'}
                    questionName={"ครัวเรือนนี้ มีเด็กอายุ 6 - 14 ปีหรือไม่"}
                >
                    {
                        (no16_1.answer === '1' || no16_1.answer === '0') ?
                            no16_1.answer === '1' ?
                                <Answer detail={`มี จำนวน`} value={no16_1.amount} unit={'คน'}/>
                                :
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 16.2*/}
                <SubTopic
                    no={'16.2.'}
                    questionName={"เด็กอายุ 6 - 14 ปี ได้เข้าเรียน ชั้น ป.1 - ม.3(การศึกษาภาคบังคับ 9 ปี) ทุกคน หรือไม่"}
                >
                    {
                        (no16_2.answer === '1' || no16_2.answer === '0') ?
                            no16_2.answer === '1' ?
                                <Answer detail={`ได้เรียนทุกคน`} value={''} unit={''}/>
                                :
                                <Answer detail={`ไม่ได้เรียน จำนวน`} value={no16_2.amount} unit={'คน'}/>
                            :
                            <Answer detail={` ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 16.3*/}
                <SubTopic
                    no={'16.3.'}
                    questionName={"เด็กที่ได้เรียนชั้น ป.1 - ม.3 มีการออกกลางคันหรือไม่"}
                >
                    {
                        (no16_3.answer === '1' || no16_3.answer === '0') ?
                            no16_3.answer === '1' ?
                                <Answer detail={`มี จำนวน`} value={no16_3.amount} unit={'คน'}/>
                                :
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 16.4*/}
                <SubTopic
                    no={'16.4.'}
                    questionName={"เด็กที่ออกกลางคัน ไปทำอะไร"}
                >
                    {data ? answer16_4() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>

                {/*Question 16.5*/}
                <SubTopic
                    no={'16.5.'}
                    questionName={"เด็กอายุ 6 - 14 ปี มีความสนใจในการหาความรู้จากสื่อต่าง ๆ เช่น การอ่านหนังสือ การดูข่าวสาร สารคดีการใช้อินเตอร์เน็ตเพื่อหาความรู้ เป็นต้น โดยเฉลี่ยกี่ชั่วโมงต่อสัปดาห์"}
                >
                    {
                        (no16_5.amount > 0) ?
                            <Answer detail={``} value={no16_5.amount} unit={'ชั่วโมงต่อสัปดาห์'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

            {/*Question 17*/}
            <Topic
                no={17}
                title={"เด็กจบชั้น ม.3 ได้เรียนต่อชั้น ม.4 หรือเทียบเท่า"}
            >
                {/*Question 17.1*/}
                <SubTopic
                    no={'17.1.'}
                    questionName={"ในรอบปีที่ผ่านมา ครัวเรือนนี้ มีเด็กจบชั้น ม.3 หรือไม่"}
                >
                    {
                        (no17_1.answer === '1' || no17_1.answer === '0') ?
                            no17_1.answer === '1' ?
                                <Answer detail={`มี จำนวน`}
                                        value={no17_1.amount} unit={'คน'}/>
                                :
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 17.2*/}
                <SubTopic
                    no={'17.2'}
                    questionName={"เด็กจบชั้น ม.3 ได้เรียนต่อชั้น ม.4 หรือเทียบเท่าทุกคน หรือไม่"}
                >
                    {
                        (no17_2.answer === '1' || no17_2.answer === '0') ?
                            no17_2.answer === '1' ?
                                <Answer detail={`ได้เรียนทุกคน`} value={''} unit={''}/>
                                :
                                <Answer detail={`ไม่ได้เรียน จำนวน`} value={no17_2.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 17.3*/}
                <SubTopic
                    no={'17.3.'}
                    questionName={"เด็กที่ได้เรียนต่อชั้น ม.4 หรือเทียบเท่ามีการออกกลางคัน หรือไม่"}
                >
                    {
                        (no17_3.answer === '1' || no17_3.answer === '0') ?
                            no17_3.answer === '1' ?
                                <Answer detail={`มี จำนวน`} value={no17_3.amount} unit={'คน'}/>
                                :
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 17.4*/}
                <SubTopic
                    no={'17.4.'}
                    questionName={"เด็กที่ออกกลางคัน ไปทำอะไร"}
                >
                    {data ? answer17_4() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>

                {/*Question */}
                <SubTopic
                    no={'17.5.'}
                    questionName={"เด็กอายุ 15 - 18 ปีมีความสนใจในการหาความรู้จากสื่อต่าง ๆ เช่น การอ่านหนังสือ การดูข่าวสาร สารคดีการใช้อินเตอร์เน็ตเพื่อหาความรู้ เป็นต้น โดยเฉลี่ยกี่ชั่วโมงต่อสัปดาห์"}
                >
                    {
                        (no17_5.amount > 0) ?
                            <Answer detail={``} value={no17_5.amount} unit={'ชั่วโมงต่อสัปดาห์'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

            {/*Question 18*/}
            <Topic
                no={18}
                title={"คนในครัวเรือนที่จบการศึกษาภาคบังคับ 9 ปีที่ไม่ได้เรียนต่อและยังไม่มีงานทำ ได้รับการฝึกอบรมด้านอาชีพ"}
            >
                {/*Question 18.1*/}
                <SubTopic
                    no={'18.1.'}
                    questionName={"ครัวเรือนนี้ มีคนในครัวเรือนที่จบการศึกษาภาคบังคับ 9 ปี ที่ไม่ได้เรียนต่อชั้น ม.4 หรือเทียบเท่า และยังไม่มีงานทำ หรือไม่"}
                >
                    {
                        (no18_1.answer === '1' || no18_1.answer === '0') ?
                            no18_1.answer === '1' ?
                                <Answer detail={`มี จำนวน `} value={no18_1.amount} unit={'คน'}/>
                                :
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 18.2*/}
                <SubTopic
                    no={'18.2.'}
                    questionName={"คนในครัวเรือน ตามข้อ 18.1.ได้รับการฝึกอบรมด้านอาชีพ ทุกคน หรือไม่"}
                >
                    {
                        (no18_2.answer === '1' || no18_2.answer === '0') ?
                            no18_2.answer === '1' ?
                                <Answer detail={`ได้รับการฝึกอบรมด้านอาชีพทุกคน`} value={''} unit={''}/>
                                :
                                <Answer detail={`ไม่ได้รับการฝึก จำนวน `} value={no18_2.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 18.3*/}
                <SubTopic
                    no={'18.3.'}
                    questionName={"คนในครัวเรือนที่ไม่ได้รับการฝึกอบรมด้านอาชีพตามข้อ 18.2. แบ่งเป็น"}
                >
                    {data ? answer18_3() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>

                {/*Question 18.4*/}
                <SubTopic
                    no={'18.4.'}
                    questionName={"คนในครัวเรือนที่ไม่ได้รับการฝึกอบรมด้านอาชีพตามข้อ 18.2. มีความต้องการฝึกอบรมด้านอาชีพ กี่คน"}
                >
                    {
                        (no18_4.amount > 0) ?
                            <Answer detail={``} value={no18_4.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

            {/*Question 19*/}
            <Topic
                no={19}
                title={"คนอายุ 15 - 59 ปี อ่าน เขียนภาษาไทยและคิดเลขอย่างง่ายได้"}
            >
                {/*Question 19.1*/}
                <SubTopic
                    no={'19.1.'}
                    questionName={"ครัวเรือนนี้ มีคนอายุ 15 - 59 ปี หรือไม่"}
                >
                    {
                        (no19_1.answer === '1' || no19_1.answer === '0') ?
                            no19_1.answer === '1' ?
                                <Answer detail={`มี จำนวน `}
                                        value={no19_1.amount} unit={'คน'}/>
                                :
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 19.2*/}
                <SubTopic
                    no={'19.2.'}
                    questionName={"คนอายุ 15 - 59 ปี สามารถ อ่าน เขียนภาษาไทยและคิดเลขอย่างง่ายได้ ทุกคนหรือไม่"}
                >
                    {
                        (no19_2.answer === '1' || no19_2.answer === '0') ?
                            no19_2.answer === '1' ?
                                <Answer detail={`ได้ทุกคน`} value={''} unit={''}/>
                                :
                                <Answer detail={`ไม่ได้ จำนวน `} value={no19_2.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 19.3*/}
                <SubTopic
                    no={'19.3.'}
                    questionName={"คนอายุ 15 - 59 ปี สามารถอ่าน เขียนภาษาอังกฤษได้ทุกคนหรือไม่"}
                >
                    {
                        (no19_3.answer === '1' || no19_3.answer === '0') ?
                            no19_3.answer === '1' ?
                                <Answer detail={`ได้ทุกคน`} value={''} unit={''}/>
                                :
                                <Answer detail={`ไม่ได้ จำนวน `} value={no19_2.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

        </div>
    )
}
