import React, { Component } from "react";
import { Progress, Icon } from "semantic-ui-react";
import "./styleProg.css";
export default class progressDash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allPopulate: 0,
      datasets: [],
      header: "",
      img: "",
      amount: 1000,
      numPos: 650,
      textCheck: ["มี", "ไม่มี"],
      progressCorrect: "green",
      textCorrect: "green",
      progressFalse: "red",
      textFalse: "red",
      showIcon: false,
      percPos : 0,
      percNeg :0,
      numNeg:0
    };
  }
componentDidMount() {
  const numNeg = this.props.amount - this.props.numPos;

  //find percent
  let percPos = ((this.props.numPos / this.props.amount) * 100);
  let percNeg = ((numNeg / this.props.amount) * 100);
  percPos = percPos.toFixed(2)
  percNeg = percNeg.toFixed(2)
  this.setState({
    percNeg:percNeg,
    percPos:percPos,
    numNeg:numNeg
  })
}

  render() {

    //find negative amount of data

    return (
      <div className="main-progOnce-container">
        <div className="header">
          <Icon name={this.props.img} size="big" />
          <p>{this.props.header}</p>
        </div>
        <div className="progbar-container">
          <div className="prog-top-bar">
            <div className="desc-bar">
              <p>
                {this.props.textCheck[0]} {this.props.numPos} {this.props.unit} ({this.state.percPos}%)
              </p>
              <p>
                {this.props.textCheck[1]} {this.state.numNeg} {this.props.unit} ({this.state.percNeg}%)
              </p>
            </div>
            <Progress percent={this.state.percPos} color="green" size="small" />
          </div>
        </div>
      </div>
    );
  }
}
