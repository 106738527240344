import React, { useState, useEffect } from 'react';
import { Modal, Button, Icon, Grid } from 'semantic-ui-react';
import axios from 'axios';
import Config from '../../../constants/Config';
import './DetailMember.css';
import Loader from '../../../components/RouteLoader/Loader';
import swal from 'sweetalert2';
import Image from '../../../components/Image';


const DetailMember = ({openDetail, closeDetail, idUser, openEditMember}) => {
	/*Set State*/
	const [ detailUser, setDetailUser ] = useState({});
	const [ detailAddressUser, setDetailAddress ] = useState({});
	const [ birthday, setBirthday ] = useState('');
	const [ pathImage, setPathImage ] = useState('');
	const [ loadingPage, setLoadingPage ] = useState(false);
	
		/*Set user_id*/
	const user_id = idUser;
	// const user_id = '5e9d70d72db7d36ba1401895';

	/* Type Permission */
	const adminCentral = '5e71d0542e86f6aca55b90ea';
	const surveyPerson = '5e71d1d12e86f6aca55b90eb';

	/*API แสดงข้อมูลสมาชิก*/
	const DetailMember = () => {
		return axios
			.get(`${Config.url}/api/v1/detail_user/${user_id}`, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				// console.log('response: ', response.data.value);
				return response;
			})
			.catch((error) => {
				// console.log(error.response);
			});
	};

	/* Loading */
	const Loading = () => {
		return Promise.all([ DetailMember() ]).then((data) => {
			// console.log('data: ', data);
			const detailMember = data[0];

			if (detailMember) {
				/*set detailMember */
				setDetailUser(detailMember.data.value);
				setDetailAddress(detailMember.data.value.address);
				if (detailMember.data.value.image) {
					setPathImage(detailMember.data.value.image.path);
				}

				if (detailMember.data.value.birth_date) {
					const birth = new Date(detailMember.data.value.birth_date);
					// console.log('birth:', birth);
					if (birth.getMonth() + 1 < 10) {
						setBirthday(`${birth.getDate()}-0${birth.getMonth() + 1}-${birth.getUTCFullYear()}`);
					} else {
						setBirthday(`${birth.getDate()}-${birth.getMonth() + 1}-${birth.getUTCFullYear()}`);
					}
				}

				setLoadingPage(true);
			} else {
				setLoadingPage(false);
			}
		});
	};

	useEffect(() => {
		// DetailMember();
		Loading();
	}, []);


	/* ClickResetPassword */
	const ClickResetPassword = (id) => {
		swal
			.fire({
				title: 'ยืนยันการรีเซตรหัสผ่านหรือไม่ ?',
				text: 'รหัสผ่านเริ่มต้นคือ เลข 7 ตัวของเลขบัตรประชาชน + Pka',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'ตกลง',
				cancelButtonText: 'ยกเลิก'
			})
			.then((result) => {
				console.log(result.value);

				if (result.value) {
					//ยิง API
					// /reset_password/:user_id
					axios.put(`${Config.url}/api/v1/reset_password/${id}`,{}, {
							headers: {
								Authorization: 'Bearer ' + localStorage.getItem('access_token'),
								'Content-Type': 'application/json'
							}
						})
						.then(function(response){
							swal.fire({
								title: 'สำเร็จ!',
								text: 'รีเซตรหัสผ่านสำเร็จ',
								icon: 'success',
								showConfirmButton: false,
								timer: 1500
							});
							closeDetail()
						})
						.catch((error) =>{
							swal.fire({
								position: 'center',
								icon: 'error',
								title: 'เกิดข้อผิดพลาด',
								confirmButtonText: 'ตกลง'
							})
						});
				}
			});
	};

	if (loadingPage === true) {
		return (
			<Modal
				open={openDetail}
				onClose={()=>closeDetail()}
			>
				<Modal.Content>
					<div className='set-display-margin-bottom'>
						<div style={{ flex: 1 }}>
							<h1>รายละเอียดข้อมูลผู้ใช้</h1>
						</div>
						<div>
							<Button onClick={()=>closeDetail()} className='close-button'>
								<Icon name='remove circle' color='red' size='large' className='padding-left'/>
							</Button>
						</div>
					</div>
					<Grid>
						{/* ข้อมูลผู้ใช้งาน */}
						<Grid.Row>
							<Grid.Column mobile={16} tablet={16} computer={16}>
								<div className='heading'>
									<h3>ข้อมูลผู้ใช้งาน</h3>
								</div>
							</Grid.Column>
						</Grid.Row>

						{/* pathImage */}
						<Grid.Row>
							<Grid.Column
								mobile={16}
								tablet={16}
								computer={6}
								className='set-display-center'
							>
								<div>
									<Image
										className={'pic-profile'}
										src={`${Config.url}/api/v1${pathImage}`}
										path={pathImage}
									/>
								</div>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={16} computer={9}>
								<Grid>
									{/*คำนำหน้า*/}
									<Grid.Row className='padding-bottom'>
										<Grid.Column mobile={6} tablet={4} computer={5} className='flex-end'>
											<label>คำนำหน้า</label>
										</Grid.Column>
										<Grid.Column mobile={9} tablet={11} computer={11}>
											<div className='border-bottom-solid'>{detailUser.prefix}</div>
										</Grid.Column>
									</Grid.Row>

									{/*ชื่อ*/}
									<Grid.Row className='padding-bottom'>
										<Grid.Column mobile={6} tablet={4} computer={5} className='flex-end'>
											<label>ชื่อ</label>
										</Grid.Column>
										<Grid.Column mobile={9} tablet={11} computer={11}>
											<div className='border-bottom-solid'>{detailUser.first_name}</div>
										</Grid.Column>
									</Grid.Row>

									{/*สกุล*/}
									<Grid.Row className='padding-bottom'>
										<Grid.Column mobile={6} tablet={4} computer={5} className='flex-end'>
											<label>สกุล</label>
										</Grid.Column>
										<Grid.Column mobile={9} tablet={11} computer={11}>
											<div className='border-bottom-solid'>{detailUser.last_name}</div>
										</Grid.Column>
									</Grid.Row>

									{/*วันเกิด*/}
									<Grid.Row className='padding-bottom'>
										<Grid.Column mobile={6} tablet={4} computer={5} className='flex-end'>
											<label>วันเกิด (วัน/เดือน/ปี)</label>
										</Grid.Column>
										<Grid.Column mobile={9} tablet={11} computer={11}>
											<div className='border-bottom-solid'>{birthday}</div>
										</Grid.Column>
									</Grid.Row>

									{/*เลขประจำตัวประชาชน*/}
									<Grid.Row className='padding-bottom'>
										<Grid.Column mobile={6} tablet={4} computer={5} className='flex-end'>
											<label>เลขประจำตัวประชาชน</label>
										</Grid.Column>
										<Grid.Column mobile={9} tablet={11} computer={11}>
											<div className='border-bottom-solid'>{detailUser.id_card_number}</div>
										</Grid.Column>
									</Grid.Row>

									{/*หมายเลขโทรศัพท์*/}
									<Grid.Row className='padding-bottom'>
										<Grid.Column mobile={6} tablet={4} computer={5} className='flex-end'>
											<label>หมายเลขโทรศัพท์</label>
										</Grid.Column>
										<Grid.Column mobile={9} tablet={11} computer={11}>
											<div className='border-bottom-solid'>{detailUser.phone_number}</div>
										</Grid.Column>
									</Grid.Row>

									{/* ประเภทผู้ใช้งาน */}
									<Grid.Row className='padding-bottom'>
										<Grid.Column mobile={6} tablet={4} computer={5} className='flex-end'>
											<label>ประเภทผู้ใช้งาน</label>
										</Grid.Column>
										<Grid.Column mobile={9} tablet={11} computer={11}>
											<div className='border-bottom-solid'>{detailUser.permission.name_th}</div>
										</Grid.Column>
									</Grid.Row>

									{/* เช็คสิทธิ์ว่าเป็นประเภท AdminCentral and surveyPerson */}
									{detailUser.permission._id === adminCentral ? (
										<Grid.Row className='padding-bottom'>
											<Grid.Column mobile={6} tablet={4} computer={5} className='flex-end'>
												<label>ศูนย์ที่รับผิดชอบ</label>
											</Grid.Column>
											<Grid.Column mobile={9} tablet={11} computer={11}>
												<div className='border-bottom-solid'>{detailUser.area.name}</div>
											</Grid.Column>
										</Grid.Row>
									) : detailUser.permission._id === surveyPerson ? (
										<Grid.Row className='padding-bottom'>
											<Grid.Column mobile={6} tablet={4} computer={5} className='flex-end'>
												<label>ชุมชนที่ที่รับผิดชอบ</label>
											</Grid.Column>
											<Grid.Column mobile={9} tablet={11} computer={11}>
												<div className='border-bottom-solid'>{detailUser.community.name}</div>
											</Grid.Column>
										</Grid.Row>
									) : null}
								</Grid>
							</Grid.Column>
						</Grid.Row>

						{/* ข้อมูลที่อยู่อาศัย */}
						<Grid.Row>
							<Grid.Column mobile={16} tablet={16} computer={16}>
								<div className='heading'>
									<h3>ข้อมูลที่อยู่อาศัย</h3>
								</div>
							</Grid.Column>
						</Grid.Row>

						{/* รหัสบ้าน */}
						<Grid.Row className='padding-top'>
							<Grid.Column mobile={4} tablet={3} computer={2} className='flex-end'>
									<label className='set-padding-top'>รหัสบ้าน</label>
							</Grid.Column>
							<Grid.Column mobile={11} tablet={12} computer={13} className='set-padding-top'>
								<div className='border-bottom-solid'>
									{detailAddressUser.house_code ? detailAddressUser.house_code : '-'}
								</div>
							</Grid.Column>

							{/* บ้านเลขที่ */}
							<Grid.Column mobile={4} tablet={3} computer={2} className='flex-end'>
								<label className='set-label-padding-top'>บ้านเลขที่</label>
							</Grid.Column>
							<Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top'>
								<div className='border-bottom-solid'>{detailAddressUser.house_no}</div>
							</Grid.Column>

							{/* หมู่ที่ */}
							<Grid.Column mobile={4} tablet={2} computer={2} className='flex-end'>
								<label className='set-label-padding-top'>หมู่ที่</label>
							</Grid.Column>
							<Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top'>
								<div className='border-bottom-solid'>{detailAddressUser.village_no}</div>
							</Grid.Column>

							{/* หมู่บ้าน */}
							<Grid.Column mobile={4} tablet={3} computer={2} className='flex-end'>
								<label className='set-label-padding-top'>หมู่บ้าน</label>
							</Grid.Column>
							<Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top'>
								<div className='border-bottom-solid'>{detailAddressUser.village_name ? detailAddressUser.village_name : '-'}</div>
							</Grid.Column>

							{/* ซอย */}
							<Grid.Column mobile={4} tablet={2} computer={2} className='flex-end'>
								<label className='set-label-padding-top'>ซอย</label>
							</Grid.Column>
							<Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top'>
								<div className='border-bottom-solid'>
									{detailAddressUser.alley ? detailAddressUser.alley : '-'}
								</div>
							</Grid.Column>

							{/* ถนน */}
							<Grid.Column mobile={4} tablet={3} computer={2} className='flex-end'>
								<label className='set-label-padding-top'>ถนน</label>
							</Grid.Column>
							<Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top'>
								<div className='border-bottom-solid'>
									{detailAddressUser.road ? detailAddressUser.road : '-'}
								</div>
							</Grid.Column>

							{/* ตำบล */}
							<Grid.Column mobile={4} tablet={2} computer={2} className='flex-end'>
								<label className='set-label-padding-top'>ตำบล</label>
							</Grid.Column>
							<Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top'>
								<div className='border-bottom-solid'>{detailAddressUser.sub_district}</div>
							</Grid.Column>

							{/* อำเภอ */}
							<Grid.Column mobile={4} tablet={3} computer={2} className='flex-end'>
								<label className='set-label-padding-top'>อำเภอ</label>
							</Grid.Column>
							<Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top'>
								<div className='border-bottom-solid'>{detailAddressUser.district}</div>
							</Grid.Column>

							{/* จังหวัด */}
							<Grid.Column mobile={4} tablet={2} computer={2} className='flex-end'>
								<label className='set-label-padding-top'>จังหวัด</label>
							</Grid.Column>
							<Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top'>
								<div className='border-bottom-solid'>{detailAddressUser.province}</div>
							</Grid.Column>

							{/* รหัสไปรษณีย์ */}
							<Grid.Column mobile={4} tablet={3} computer={2} className='flex-end'>
								<label className='set-label-padding-top'>รหัสไปรษณีย์</label>
							</Grid.Column>
							<Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top'>
								<div className='border-bottom-solid'>{detailAddressUser.zip_code}</div>
							</Grid.Column>
						</Grid.Row>

						{/* ปุ่ม */}
						<Grid.Row className='set-margin-bottom-top'>
							<Grid.Column mobile={16} tablet={16} computer={16} className='display-center'>
								{/* ปุ่มรีเซตรหัสผ่าน */}
								<Button
									className='button-reset-password'
									type='submit'
									onClick={() => ClickResetPassword(detailUser._id)}
								>
									<Icon circular name='key' className='set-icon-blue' />
									รีเซตรหัสผ่าน
								</Button>

								{/* ปุ่มแก้ไขข้อมูล */}
								<Button className='button-edit' onClick={()=>openEditMember()}>
									<Icon
										circular
										name='pencil alternate'
										className='set-icon-yellow'
									/>
									แก้ไขข้อมูล
								</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Modal.Content>
			</Modal>
		);
	} else {
		return <Modal
		open={openDetail}
		onClose={()=>closeDetail()}
	>
		<Modal.Content>
			<div className='set-display-margin-bottom'>
				<div style={{ flex: 1 }}>
					<h1>รายละเอียดข้อมูลผู้ใช้</h1>
				</div>
				<div>
					<Button onClick={()=>closeDetail()} className='close-button'>
						<Icon name='remove circle' color='red' size='large' className='padding-left'/>
					</Button>
				</div>
			</div>
			<Loader />
		</Modal.Content>
	</Modal>;
	}
};
export default DetailMember;
