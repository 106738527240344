import React, {useState, Fragment} from 'react';
import {Grid, Input} from 'semantic-ui-react';
import InputMask from 'react-input-mask';
import {Field} from 'formik';
import ThaiAddress from '../../../ThaiAddress.json';
import {DropdownSearch} from './DropdownSearch';
import './AddMember.css'

const UserAddress = (props) => {
    /* Set State*/
    const [thaiAddressData, setThaiAddressData] = useState(ThaiAddress);
    const [zip_code_json, set_zip_code_json] = useState([]);
    const [province_json, set_province_json] = useState([]);
    const [district_json, set_district_json] = useState([]);
    const [sub_district_json, set_sub_district_json] = useState([]);


    /* province Search*/
    const setProvinceSelect = (event, data) => {
        // console.log('event: ',event)
        // console.log('data: ',data.searchQuery)
        if (data.searchQuery) {
            let optionsP = [];
            let i = 0;
            thaiAddressData.forEach((value) => {
                //if ถ้าเกิน 20 แล้วไม่ต้องเข้า
                if (i < 20) {
                    if (value.province.includes(data.searchQuery)) {
                        let textProvince =
                            `${value.district} >> ${value.amphoe} >> ${value.province} >> ${value.zipcode}`
                        // value.district + ' >> ' + value.amphoe + ' >> ' + value.province + ' >> ' + value.zipcode;

                        optionsP.push({
                            key: textProvince,
                            text: textProvince,
                            value: textProvince
                        })

                        i++
                    }
                }
            });
            set_province_json(optionsP)
        }
    }

    /* อำเภอ Search*/
    const setDistrictSelect = (event, data) => {
        if (data.searchQuery) {
            let optionsP = [];
            let i = 0;
            thaiAddressData.forEach((value) => {
                if (i < 20) {
                    if (value.amphoe.includes(data.searchQuery)) {
                        let textProvince =
                            value.district + ' >> ' + value.amphoe + ' >> ' + value.province + ' >> ' + value.zipcode;

                        optionsP.push({
                            key: textProvince,
                            text: textProvince,
                            value: textProvince
                        })

                        i++
                    }
                }
            });
            set_district_json(optionsP)
        }
    }

    /* ตำบล Search*/
    const setSubDistrictSelect = (event, data) => {
        if (data.searchQuery) {
            let optionsP = [];
            let i = 0;
            // console.log('data.searchQuery: ',data.searchQuery);
            thaiAddressData.forEach((value) => {
                if (i < 20) {
                    if (value.district.includes(data.searchQuery)) {
                        let textProvince =
                            value.district + ' >> ' + value.amphoe + ' >> ' + value.province + ' >> ' + value.zipcode;

                        optionsP.push({
                            key: textProvince,
                            text: textProvince,
                            value: textProvince
                        })

                        i++
                    }
                }
            });
            set_sub_district_json(optionsP)
        }
    }

    /* รหัสไปรษณีย์ Search*/
    const setZipcodeSelect = (event, data) => {
        if (data.searchQuery) {
            let optionsP = [];
            let i = 0;
            thaiAddressData.forEach((value) => {
                if (i < 20) {
                    if (String(value.zipcode).includes(data.searchQuery)) {
                        let textProvince =
                            value.district + ' >> ' + value.amphoe + ' >> ' + value.province + ' >> ' + value.zipcode;

                        optionsP.push({
                            key: textProvince,
                            text: textProvince,
                            value: textProvince
                        })

                        i++
                    }
                }
            });
            set_zip_code_json(optionsP)
        }
    }

    // console.log('set_province_json: ',province_json)


    /* set options sub_district, district, province */
    const handleChangeAddress = (options) => {
        // console.log(options)
        set_sub_district_json([options[0]])
        set_district_json([options[1]])
        set_province_json([options[2]])
        set_zip_code_json([options[3]])
    }

    return (
        <Fragment>

            <Grid>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        {/* Heading */}
                        <div className='heading'>
                            <h3>ข้อมูลที่อยู่อาศัย</h3>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                {/* house_code */}
                <Grid.Row>
                    <Grid.Column mobile={4} tablet={3} computer={2} className='flexEnd'>
                        <label>รหัสบ้าน</label>
                    </Grid.Column>
                    <Grid.Column mobile={11} tablet={12} computer={13}>
                        <InputMask
                            placeholder='กรอกรหัสบ้าน'
                            fluid
                            type='text'
                            mask='9999-999999-9'
                            values={props.values}
                            name='house_code'
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.house_code}
                        >
                            {(inputProps) => <Input {...inputProps} type='tel'/>}
                        </InputMask>
                    </Grid.Column>

                    {/* house_no */}
                    <Grid.Column mobile={4} tablet={3} computer={2} className='flexEnd'>
                        <label>บ้านเลขที่<span style={{color: 'red'}}>*</span></label>
                    </Grid.Column>
                    <Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top-imp'>
                        <Input
                            placeholder='กรอกบ้านเลขที่'
                            values={props.values}
                            type='text'
                            name='house_no'
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.house_no}
                            className={
                                props.errors.house_no && props.touched.house_no && props.errors.house_no ? (
                                    'error'
                                ) : (
                                    props.values.house_no && 'success'
                                )
                            }
                            fluid
                        />
                        {props.errors.house_no && props.touched.house_no && props.errors.house_no && (
                            <span className='set-color-red'>{props.errors.house_no}</span>
                        )}
                    </Grid.Column>

                    {/* village_no */}
                    <Grid.Column mobile={4} tablet={2} computer={2} className='flexEnd'>
                        <label>หมู่ที่<span style={{color: 'red'}}>*</span></label>
                    </Grid.Column>
                    <Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top-imp'>
                        <Input
                            placeholder='กรอกหมู่ที่'
                            fluid
                            values={props.values}
                            type='text'
                            name='village_no'
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.village_no}
                            className={
                                props.errors.village_no && props.touched.village_no && props.errors.village_no ? (
                                    'error'
                                ) : (
                                    props.values.village_no && 'success'
                                )
                            }
                        />
                        {props.errors.village_no && props.touched.village_no && props.errors.village_no && (
                            <span className='set-color-red'>{props.errors.village_no}</span>
                        )}
                    </Grid.Column>

                    {/* village_name */}
                    <Grid.Column mobile={4} tablet={3} computer={2} className='flexEnd'>
                        <label>หมู่บ้าน</label>
                    </Grid.Column>
                    <Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top-imp'>
                        <Input
                            placeholder='กรอกหมู่บ้าน'
                            fluid
                            values={props.values}
                            type='text'
                            name='village_name'
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.village_name}
                        />

                    </Grid.Column>

                    {/* alley */}
                    <Grid.Column mobile={4} tablet={2} computer={2} className='flexEnd'>
                        <label>ซอย</label>
                    </Grid.Column>
                    <Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top-imp'>
                        <Input
                            placeholder='กรอกซอย'
                            fluid
                            values={props.values}
                            type='text'
                            name='alley'
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.alley}
                        />
                    </Grid.Column>

                    {/* road */}
                    <Grid.Column mobile={4} tablet={3} computer={2} className='flexEnd'>
                        <label>ถนน</label>
                    </Grid.Column>
                    <Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top-imp'>
                        <Input
                            placeholder='กรอกถนน'
                            fluid
                            values={props.values}
                            type='text'
                            name='road'
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.road}
                        />
                    </Grid.Column>

                    {/* sub_district */}
                    <Grid.Column mobile={4} tablet={2} computer={2} className='flexEnd'>
                        <label>ตำบล<span style={{color: 'red'}}>*</span></label>
                    </Grid.Column>
                    <Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top-imp'>
                        <Field
                            placeholder='ตำบล'
                            options={sub_district_json}
                            name='sub_district'
                            // value={props.values.district}
                            component={DropdownSearch}
                            fluid
                            onSearchChange={setSubDistrictSelect}
                            // optionsDistrict={amphoe_options}
                            onBlur={props.handleBlur}
                            onChangeAddress={handleChangeAddress}
                            className={
                                props.errors.sub_district && props.touched.sub_district && props.errors.sub_district ? (
                                    'error'
                                ) : (
                                    props.values.sub_district && 'success'
                                )
                            }
                        />
                        {props.errors.sub_district && props.touched.sub_district && props.errors.sub_district && (
                            <span style={{color: 'red'}}>{props.errors.sub_district}</span>
                        )}
                    </Grid.Column>

                    {/* district */}
                    <Grid.Column mobile={4} tablet={3} computer={2} className='flexEnd'>
                        <label>อำเภอ<span style={{color: 'red'}}>*</span></label>
                    </Grid.Column>
                    <Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top-imp'>
                        <Field
                            placeholder='อำเภอ'
                            options={district_json}
                            name='district'
                            component={DropdownSearch}
                            fluid
                            onSearchChange={setDistrictSelect}
                            onBlur={props.handleBlur}
                            onChangeAddress={handleChangeAddress}
                            className={
                                props.errors.district && props.touched.district && props.errors.district ? (
                                    'error'
                                ) : (
                                    props.values.district && 'success'
                                )
                            }
                        />
                        {props.errors.district && props.touched.district && props.errors.district && (
                            <span style={{color: 'red'}}>{props.errors.district}</span>
                        )}
                    </Grid.Column>

                    {/* province */}
                    <Grid.Column mobile={4} tablet={2} computer={2} className='flexEnd'>
                        <label>จังหวัด<span style={{color: 'red'}}>*</span></label>
                    </Grid.Column>
                    <Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top-imp'>
                        <Field
                            placeholder='จังหวัด'
                            options={province_json}
                            name='province'
                            component={DropdownSearch}
                            fluid
                            onSearchChange={setProvinceSelect}
                            onBlur={props.handleBlur}
                            onChangeAddress={handleChangeAddress}
                            className={
                                props.errors.province && props.touched.province && props.errors.province ? (
                                    'error'
                                ) : (
                                    props.values.province && 'success'
                                )
                            }
                        />
                        {props.errors.province && props.touched.province && props.errors.province && (
                            <span style={{color: 'red'}}>{props.errors.province}</span>
                        )}
                    </Grid.Column>

                    {/* zip_code */}
                    <Grid.Column mobile={4} tablet={3} computer={2} className='flexEnd'>
                        <label>รหัสไปรษณีย์<span style={{color: 'red'}}>*</span></label>
                    </Grid.Column>
                    <Grid.Column mobile={11} tablet={5} computer={3} className='set-padding-top-imp'>
                        <Field
                            placeholder='รหัสไปรษณีย์'
                            options={zip_code_json}
                            name='zip_code'
                            component={DropdownSearch}
                            fluid
                            onSearchChange={setZipcodeSelect}
                            onBlur={props.handleBlur}
                            onChangeAddress={handleChangeAddress}
                            className={
                                props.errors.zip_code && props.touched.zip_code && props.errors.zip_code ? (
                                    'error'
                                ) : (
                                    props.values.zip_code && 'success'
                                )
                            }
                        />
                        {props.errors.zip_code && props.touched.zip_code && props.errors.zip_code && (
                            <span style={{color: 'red'}}>{props.errors.zip_code}</span>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    );
}
export default UserAddress;
