import React from "react";
import './MemberFormat.css'
import {Grid} from 'semantic-ui-react'

const props = {
    name: 'นายสุขพัฒน์ เทพารส',
    id_card: '1234-123-12345-0',
    relation: 'หัวหน้าครัวเรือน',
    gender: 'ชาย',
    area: 'บุคคลในพื้นที่',
    birth_date: '12/03/2543',
    age: '20',
    work: 'พนักงานรัฐวิสาหกิจ',
    education: 'ปริญญาตรี',
    cult: 'พุทธ',
    body: 'ปกติ',
    help: 'ได้',
    life: 'มีชีวิตอยู่',
    underprivileged:'ไม่เป็นผู้ด้อยโอกาส',
}


export default ({item, number}) => {

    let {
        prefix,
        first_name,
        last_name,
        id_card_number,
        relation,
        gender,
        address,
        birth_date,
        occupation,
        education,
        religion,
        physical_status,
        self_help,
        alive_status,
        underprivileged,
    } = item;

    // let length_age = null


    // แปลงชื่อเพศ
    let sex = null
    switch (gender) {
        case 'ญ':
            sex = 'หญิง'
            break;
        case 'ช':
            sex = 'ชาย'
            break;
        case '':
            sex = '-'
            break;
    }

    // แปลงการช่วยเหลือตัวเอง
    let help = null
    switch (self_help) {
        case 0 :
            help = 'ไม่ได้'
            break;
        case 1 :
            help = 'ได้'
            break;
        case '' :
            help = '-'
            break;
    }

    // แปลงสภานะภาพชีวิต
    let life = null
    switch (alive_status) {
        case 0 :
            life = 'มีชีวิตอยู่'
            break;
        case 1 :
            life = 'เสียชีวิต'
            break;
        case '' :
            life = '-'
            break;
    }

    // ฟังก์ชันเช็คสถานะภาพร่างกาย
    const physicalCheck = () => {
        let physical = ''
        if (physical_status.normal === '1') {
            physical = 'ปกติ'
        }
        else if (physical_status.disabled === '1' && physical_status.chronic === '1') {
            physical = 'ผู้พิการ, ผู้ป่วยเรื้อรัง'
        } else if (physical_status.disabled === '1') {
            physical = 'ผู้พิการ'

        } else if (physical_status.chronic === '1') {
            physical = 'ผู้ป่วยเรื้อรัง'
        } else {
            physical = '-'
        }
        return physical
    }

    // ฟังก์ชันเช็ควันเกิด
    const birthDate = () => {
        let bDate = birth_date
        let date = bDate.substring(8, 10)
        let mount = bDate.substring(5, 7)
        let year = parseInt(bDate.substring(0, 4)) + 543
        return (`${date}/${mount}/${year}`)
    }

    // ฟังก์ชันเช็คอายุ
    const Age = () => {
        let today = new Date()
        let bDate = birth_date
        let nowYear = parseInt(today.getFullYear()) + 543
        let birthYear = parseInt(bDate.substring(0, 4)) + 543
        let age = nowYear - birthYear
        return age
    }

    // ฟังก์ชันเช็คช่วงอายุ
    const lengthAge = () => {
        let age = Age()
        let length_age = ''
        if (age <= 14) {
            length_age = 'เด็ก (อายุระหว่าง 0-14 ปี)'
        } else if (age <= 59 && age >= 15) {
            length_age = 'วัยแรงงาน (อายุระหว่าง 15-59 ปี)'
        } else if (age > 60) {
            length_age = 'ผู้สูงอายุ (อายุ 60 ปีขึ้นไป)'
        } else {
            length_age = '-'
        }
        return length_age
    }

    // แปลงสภานะผู้ด้อยโอกาส
    let under_privileged = null
    switch (underprivileged) {
        case "0" || 0 :
            under_privileged = 'ไม่เป็นผู้ด้อยโอกาส'
            break;
        case "1" || 1 :
            under_privileged = 'เป็นผู้ด้อยโอกาส'
            break;
        case '' :
            under_privileged = '-'
            break;
    }

    return (
        <div className={'member-format'}>
            <div>
                <span className={'number-member'}>{number}</span>
            </div>

            <div className={'detail-member'}>

                <div className={'header-member'}>{`${prefix}${first_name} ${last_name}`}</div>
                <div className={'subheader-member'}>{id_card_number ? id_card_number : 'ต่างด้าว'}</div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>ความเกี่ยวข้องหัวหน้าครัวเรือน</span>
                    <span className={'subtopic-member'}>{relation.name}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>เพศ</span>
                    <span className={'subtopic-member'}>{sex}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>ที่อยู่ตามทะเบียนบ้าน</span>
                    <span className={'subtopic-member'}>{address === null ? 'บุคคลในพื้นที่' : 'บุคคลนอกพื้นที่'}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>วัน/เดือน/ปี(พ.ศ.) เกิด</span>
                    <span className={'subtopic-member'}>{birthDate()}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>อายุ</span>
                    <span className={'subtopic-member'}>{`${Age()} ปี`}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>ช่วงอายุ</span>
                    <span className={'subtopic-member'}>{lengthAge()}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>อาชีพ</span>
                    <span className={'subtopic-member'}>{occupation ? occupation.name : '-'}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>การศึกษา</span>
                    <span className={'subtopic-member'}>{education ? education.name : '-'}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>สถานะผู้ด้อยโอกาส</span>
                    <span className={'subtopic-member'}>{under_privileged}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>ศาสนา</span>
                    <span className={'subtopic-member'}>{religion ? religion.name : '-'}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>สถานะทางร่างการ</span>
                    <span
                        className={'subtopic-member'}>{physicalCheck()}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>ช่วยเหลือตนเอง</span>
                    <span className={'subtopic-member'}>{help}</span>
                </div>

                <div className={'subdetail-member'}>
                    <span className={'topic-member'}>สถานะภาพชีวิต</span>
                    <span className={'subtopic-member'}>{life}</span>
                </div>

            </div>
        </div>
    )
}
