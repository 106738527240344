import React, { useState, useEffect } from 'react';
import { Button, Grid, Modal, Icon } from 'semantic-ui-react';
import { Formik } from 'formik';
import UserDetail from './UserDetail';
import UserAddress from './UserAddress';
import axios from 'axios';
import Config from '../../../constants/Config';
import checkFormat from '../../../function/checkFormat';
import swal from 'sweetalert2';
import './AddMember.css'

const AddMember = ({openModal, closeModal,reloadData}) => {

	/*Set State*/
	const [ choiceArea, setChoiceArea ] = useState([]);
	const [ choiceCommunity, setChoiceCommunity ] = useState([]);
	const [ choicePermission, setChoicePermission ] = useState([]);
	const [ on_submit, set_on_submit ] = useState(false);

	/*API แสดงข้อมูล area, community, permission*/
	const choiceAddMember = async () => {
		axios
			.get(`${Config.url}/api/v1/filter_search`, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				setChoiceArea(response.data.value.area);
				setChoiceCommunity(response.data.value.community);
				setChoicePermission(response.data.value.permission);
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	useEffect(() => {
		choiceAddMember();
	}, []);

	/*API add_user*/
	const saveData = async (values) => {
		set_on_submit(true)
		// console.log('saveData: ', values);

		let body = {
			prefix: values.prefix,
			first_name: values.first_name,
			last_name: values.last_name,
			id_card_number: values.id_card_number,
			birth_date: values.birth_date,
			phone_number: values.phone_number,
			address: {
				ref_house_code: values.ref_house_code,
				house_code: values.house_code,
				house_no: values.house_no,
				village_no: values.village_no,
				village_name: values.village_name,
				alley: values.alley,
				road: values.road,
				sub_district: values.sub_district,
				district: values.district,
				province: values.province,
				zip_code: values.zip_code,
				township: values.township
			},
			permission_id: values.permission_id,
			area_id: values.area_id,
			community_id: values.community_id
		};

		await axios
			.post(`${Config.url}/api/v1/add_user`
				,body
				,{
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				// console.log('response: ', response.data.value._id);
				if (values.img_profile) {
					let formData = new FormData();
					formData.append('image', values.img_profile);
					const id = response.data.value._id;
					axios
						.put(`${Config.url}/api/v1/upload_image_user/${id}`, formData, {
							headers: {
								Authorization: 'Bearer ' + localStorage.getItem('access_token'),
								'Content-Type': 'multipart/form-data'
							}
						})
						.then(function(response){
							//handle success
							// console.log('response2: ', response);
							swal
								.fire({
									position: 'center',
									icon: 'success',
									title: 'เพิ่มข้อมูลสมาชิกเรียบร้อบแล้ว',
									showConfirmButton: false,
									timer: 1500
								})
								.then(()=> {
									closeModal()
									reloadData()
								});
						})
						.catch((error) =>{
							//handle error
							// console.log(response);
							if (error.response) {
								const message = error.response.data.message;
								swal
									.fire({
										position: 'center',
										icon: 'error',
										title: 'เกิดข้อผิดพลาด',
										text: message,
										confirmButtonText: 'ตกลง'
									})
									.then(()=>{
										set_on_submit(false)
									});
							} else {
								swal
									.fire({
										position: 'center',
										icon: 'error',
										title: 'เกิดข้อผิดพลาด',
										confirmButtonText: 'ตกลง'
									})
									.then(()=>{
										set_on_submit(false)
									});
							}
						});
				} else {
					swal
						.fire({
							position: 'center',
							icon: 'success',
							title: 'เพิ่มข้อมูลสมาชิกเรียบร้อบแล้ว',
							showConfirmButton: false,
							timer: 1500
						})
						.then(()=> {
							closeModal()
							reloadData()
						});
				}
			})
			.catch((error) => {
				if (error.response) {
					const message = error.response.data.message;
					swal
						.fire({
							position: 'center',
							icon: 'error',
							title: 'เกิดข้อผิดพลาด',
							text: message,
							confirmButtonText: 'ตกลง'
						}).then(()=>{
							set_on_submit(false)
						});
				} else {
					swal
						.fire({
							position: 'center',
							icon: 'error',
							title: 'เกิดข้อผิดพลาด',
							confirmButtonText: 'ตกลง'
						}).then(()=>{
							set_on_submit(false)
						});
				}
			});
	};

	return (
		<Modal
			className={'modal-add-member'}
			open={openModal}
			// onClose={closeModal}
		>
			<Modal.Content>
				{/* Header */}
				<div className={'add-member-modal'}>
					<div style={{ flex: 1 }}>
						<h1>เพิ่มข้อมูลผู้ใช้งาน</h1>
					</div>
					<div>
						{/*Close Modal*/}
						<Button onClick={closeModal} className='close-button'>
							<Icon name='remove circle' color='red' size='large'  className='padding-left'/>
						</Button>
					</div>
				</div>

				{/* Formik */}
				<Grid>
					<Formik
						/* Formik Set State*/
						initialValues={{
							prefix: '',
							first_name: '',
							last_name: '',
							birth_date_format: '',
							birth_date: '',
							id_card: '',
							id_card_number: '',
							phone_number: '',
							permission_id: '',
							area_id: '',
							community_id: '',
							// ref_house_code: '',
							house_code: '',
							house_no: '',
							village_no: '',
							village_name: '',
							alley: '',
							road: '',
							sub_district: '',
							district: '',
							province: '',
							zip_code: '',
							img_profile: ''
						}}
						validate={(values) => {
							// console.log('values: ', values);
							const errors = {};

							/* Check values is null or not null*/
							if (!values.prefix) {
								errors.prefix = 'กรุณาเลือกคำนำหน้า';
							}

							if (!values.first_name) {
								errors.first_name = 'กรุณากรอกชื่อ';
							} else if (!checkFormat.name.regex.test(values.first_name)) {
								errors.first_name = 'กรุณากรอกชื่อเป็นภาษาไทย';
							}

							if (!values.last_name) {
								errors.last_name = 'กรุณากรอกสกุล';
							} else if (!checkFormat.name.regex.test(values.last_name)) {
								errors.last_name = 'กรุณากรอกสกุลเป็นภาษาไทย';
							}

							if (!values.birth_date_format) {
								errors.birth_date_format = 'กรุณากรอกวันเกิด';
							} else {
								const date_format = values.birth_date_format.split('-');
								const birth = new Date(`${date_format[1]}/${date_format[0]}/${date_format[2]}`);

								const BD = new Date(
									`${birth.getMonth() + 1} ${birth.getDate()} ${birth.getUTCFullYear()} 00:00 UTC`
								);
								values.birth_date = BD.toISOString();
								// console.log('BD: ', BD.toISOString());
							}

							if (!values.id_card) {
								errors.id_card = 'กรุณากรอกเลขประจำตัวประชาชน';
							} else if (values.id_card) {
								const id = values.id_card.split('-');
								let idCard = '';
								id.forEach((e) => (idCard = idCard + e));
								values.id_card_number = idCard;
							}

							if (!values.phone_number) {
								errors.phone_number = 'กรุณากรอกหมายเลขโทรศัพท์';
							} else if (!checkFormat.phone_number.regex.test(values.phone_number)) {
								errors.phone_number = 'กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง เช่น XXXXXXXXXX';
							}

							if (!values.permission_id) {
								errors.permission_id = 'กรุณาสิทธิ์ประเภทผู้ใช้งาน';
							} else if (values.permission_id === '5e71d0542e86f6aca55b90ea') {
								if (!values.area_id) {
									errors.area_id = 'กรุณาศูนย์ที่รับผิดชอบ';
								}
							} else if (values.permission_id === '5e71d1d12e86f6aca55b90eb') {
								if (!values.community_id) {
									errors.community_id = 'กรุณาชุมชนที่รับผิดชอบ';
								}
							}

							if (values.house_code) {
								const house_code = values.house_code.split('-');
								let houseCode = '';
								house_code.forEach((e)=> (houseCode = houseCode+e));
								values.house_code = houseCode
							}

							if (!values.house_no) {
								errors.house_no = 'กรุณากรอกบ้านเลขที่';
							}

							if (!values.village_no) {
								errors.village_no = 'กรุณากรอกหมู่ที่';
							}


							if (!values.sub_district) {
								errors.sub_district = 'กรุณาเลือกตำบล';
							}

							if (!values.district) {
								errors.district = 'กรุณาเลือกอำเภอ';
							}

							if (!values.province) {
								errors.province = 'กรุณาเลือกจังหวัด';
							}

							if (!values.zip_code) {
								errors.zip_code = 'กรุณากรอกรหัสไปรษณีย์';
							} else if (!checkFormat.zip_code.regex.test(values.zip_code)) {
								errors.zip_code = 'กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง เช่น XXXXX';
							}

							return errors;
						}}
						/* func onSubmit */
						onSubmit={(values, { setSubmitting }) => {
							//call func
							/* call func saveData */
							saveData(values);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting
							/* and other goodies */
						}) => (
							/* form */
							<form onSubmit={handleSubmit}>
								{/* Component UserDetail */}
								<UserDetail
									choicePermission={choicePermission}
									choiceCommunity={choiceCommunity}
									choiceArea={choiceArea}
									values={values}
									errors={errors}
									touched={touched}
									handleChange={handleChange}
									handleBlur={handleBlur}
									isSubmitting={isSubmitting}
								/>

								{/* Component UserAddress */}
								<UserAddress
									values={values}
									errors={errors}
									touched={touched}
									handleChange={handleChange}
									handleBlur={handleBlur}
									isSubmitting={isSubmitting}
								/>
								<Grid.Row style={{ marginBottom: 10, marginTop: 15 }}>
									<Grid.Column
										mobile={16}
										tablet={16}
										computer={16}
										className='display-center'
									>
										<Button className='button-cancel-cross' onClick={closeModal}>
											<u className='u-cancel'>ยกเลิก</u>
										</Button>
										<Button primary className='button-save' disabled={on_submit}>
											บันทึก
										</Button>
									</Grid.Column>
								</Grid.Row>
							</form>
						)}
					</Formik>
				</Grid>
			</Modal.Content>
		</Modal>
	);
};

export default AddMember;
