import React from "react";
import Topic from "./Topic";
import SubTopic from "./SubTopic";
import Answer from "./Answer";
import SubAnswer from "./SubAnswer";
import DetailTopic from "./DetailTopic";

export default ({data, ...props}) => {

    let {
        no24_1,
        no25_1,
        no26_1,
        no27_1,
        no27_2,
        no28_1,
        no28_2,
        no28_3,
        no29_1,
        no29_2,
        no30_1,
        no31_1,
        no31_2
    } = data;

    const answer24_1 = () => {
        return (
            <div>
                <div>
                    {
                        (no24_1.no24_1_1.no24_1_1_1.answer !== '0' && no24_1.no24_1_1.no24_1_1_2.answer !== '0' &&
                            no24_1.no24_1_1.no24_1_1_3.answer !== '0' && no24_1.no24_1_1.no24_1_1_1.answer !== '1' &&
                            no24_1.no24_1_1.no24_1_1_2.answer !== '1' && no24_1.no24_1_1.no24_1_1_3.answer !== '1') &&
                        <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                    {
                        no24_1.no24_1_1.no24_1_1_1.answer === '0' && no24_1.no24_1_1.no24_1_1_2.answer === '0' &&
                        no24_1.no24_1_1.no24_1_1_3.answer === '0' && no24_1.no24_1_2.answer === '0' &&
                        <Answer detail={`ไม่ดื่มสุราทุกคน`} value={''} unit={''}/>
                    }
                    {
                        (no24_1.no24_1_1.no24_1_1_1.answer === '1' || no24_1.no24_1_1.no24_1_1_2.answer === '1' ||
                            no24_1.no24_1_1.no24_1_1_3.answer === '1') &&

                        <div>
                            <Answer detail={`มีผู้ดื่มสุรา`} value={''} unit={''}/>
                            <div style={{marginLeft: '20px', color: "#797979"}}> 1. ช่วงอายุคนดื่มสุรา</div>
                        </div>
                    }
                    {
                        no24_1.no24_1_1.no24_1_1_1.answer === '1' &&
                        <div style={{marginLeft: '40px', color: '#555555'}}>
                            <Answer detail={`- อายุต่ำกว่า 15 ปี`} value={''} unit={''}/>
                            <Answer detail={`  จำนวน`}
                                    value={no24_1.no24_1_1.no24_1_1_1.amount} unit={'คน'}/>
                            <Answer detail={`  ความถี่`}
                                    value={no24_1.no24_1_1.no24_1_1_1.frequency}
                                    unit={'(ครั้ง / สัปดาห์)'}/>
                            <Answer detail={`  ชนิดสุรา`}
                                    value={no24_1.no24_1_1.no24_1_1_1.name} unit={''}/>
                        </div>
                    }
                    {
                        no24_1.no24_1_1.no24_1_1_2.answer === '1' &&
                        <div style={{marginLeft: '40px', color: '#555555'}}>
                            <Answer detail={`- อายุ 15 - 19 ปี`} value={''} unit={''}/>
                            <Answer detail={`จำนวน`}
                                    value={no24_1.no24_1_1.no24_1_1_2.amount} unit={'คน'}/>
                            <Answer detail={`ความถี่`}
                                    value={no24_1.no24_1_1.no24_1_1_2.frequency}
                                    unit={'(ครั้ง / สัปดาห์)'}/>
                            <Answer detail={`ชนิดสุรา`}
                                    value={no24_1.no24_1_1.no24_1_1_2.name} unit={''}/>
                        </div>
                    }
                    {
                        no24_1.no24_1_1.no24_1_1_3.answer === '1' &&
                        <div style={{marginLeft: '40px', color: '#555555'}}>
                            <Answer detail={`- อายุ 20 ปีขึ้นไป`} value={''} unit={''}/>
                            <Answer detail={`- จำนวน`}
                                    value={no24_1.no24_1_1.no24_1_1_3.amount} unit={'คน'}/>
                            <Answer detail={`- ความถี่`}
                                    value={no24_1.no24_1_1.no24_1_1_3.frequency}
                                    unit={'(ครั้ง / สัปดาห์)'}/>
                            <Answer detail={`- ชนิดสุรา`}
                                    value={no24_1.no24_1_1.no24_1_1_3.name} unit={''}/>
                        </div>
                    }
                </div>
                {
                    (no24_1.no24_1_1.no24_1_1_1.answer === '1' || no24_1.no24_1_1.no24_1_1_2.answer === '1' ||
                        no24_1.no24_1_1.no24_1_1_3.answer === '1') &&
                    <div>
                        <div style={{marginLeft: '20px', color: "#797979"}}>2.
                            หากผู้ที่ดื่มสุราเป็นผู้ที่หารายได้หลักให้กับครัวเรือน
                            ผู้ที่ดื่มสุราดื่มเฉลี่ยนเดือนละเท่าใด
                        </div>
                        <div style={{marginLeft: '40px', color: '#555555'}}>
                            <Answer detail={`- จำนวน`} value={no24_1.no24_1_2.amount}
                                    unit={'(ครั้ง/เดือน)'}/>
                        </div>
                    </div>
                }
            </div>
        )
    }

    const answer25_1 = () => {
        let i = 0;
        let result = 0;
        let status = 0;
        for (i; i < no25_1.no25_1_1.length; i++) {
            if (no25_1.no25_1_1[i].answer === '1')
                result = 1;
            if (no25_1.no25_1_1[i].answer === '0')
                status = status + 1;
        }
        if (result === 1) {
            return (
                <div>
                    {result === 1 &&
                    <div>
                        <div>1. ผู้ที่สูบบุหรี่</div>
                        {
                            no25_1.no25_1_1.map((data, index) =>
                                (
                                    <div style={{marginLeft: '20px', color: '#797979'}}>
                                        <div>- {data.prefix} {data.first_name} {data.last_name}</div>
                                        {
                                            data.answer === '1' ?
                                                <div style={{marginLeft: '40px', color: '#555555'}}>
                                                    <Answer detail={`จำนวนการสูบ `} value={data.amount}
                                                            unit={'(มวน / วัน)'}/>
                                                    <Answer detail={`ระยะเวลาการสูบ `} value={data.duration}
                                                            unit={'(ปี)'}/>
                                                </div>
                                                :
                                                <div style={{marginLeft: '40px', color: '#555555'}}>
                                                    <Answer detail={`ไม่สูบบุหรี่`} value={''} unit={''}/>
                                                </div>
                                        }
                                    </div>
                                )
                            )
                        }
                    </div>
                    }
                    {
                        result === 1 &&
                        <div>
                            <div>2. หากผู้ที่สูบบุหรี่เป็นผู้ที่หารายได้หลักให้กับ
                                ครัวเรือน ผู้ที่สูบบุหรี่สูบเฉลี่ยเดือนละเท่าใด
                            </div>
                            <div style={{marginLeft: '20px', color: '#797979'}}>
                                <Answer detail={`จำนวน`} value={no25_1.no25_1_2.amount} unit={'(ซอง/เดือน)'}/>
                            </div>
                        </div>
                    }
                </div>
            )
        } else {
            return (
                <div style={{marginBottom: 5}}>
                    {
                        (no25_1.no25_1_1.length === status) ?
                            <Answer detail={`ไม่สูบบุหรี่ทุกคน`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </div>
            )
        }
    }

    const answer31_1 = () => {
        return (
            <div>
                {/*View 1.*/}
                <SubAnswer
                    no={'1.'}
                    title={'สมาชิกในครอบครัวมีเวลาอยู่พร้อมหน้าและได้ ทำกิจกรรมร่วมกันอย่างน้อยสัปดาห์ละ 1 ครั้ง หรือ อย่างน้อยเดือนละ 4 ครั้ง'}
                >
                    {
                        no31_1.no31_1_1.answer === '1' ?
                            <Answer detail={`มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มี`} value={''} unit={''}/>
                    }
                </SubAnswer>

                {/*View 2.*/}
                <SubAnswer
                    no={'2.'}
                    title={'สมาชิกในครอบครัวมีความเคารพนับถือกันและ ไม่มีการทะเลาะเบาะแว้งรุนแรง'}
                >
                    {
                        no31_1.no31_1_2.answer === '1' ?
                            <Answer detail={`มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มี`} value={''} unit={''}/>
                    }
                </SubAnswer>

                {/*View 3.*/}
                <SubAnswer
                    no={'3.'}
                    title={'สมาชิกในครอบครัว เมื่อมีปัญหาจะปรึกษาหารือและช่วยเหลือซึ่งกันและกัน'}
                >
                    {
                        no31_1.no31_1_3.answer === '1' ?
                            <Answer detail={`มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มี`} value={''} unit={''}/>
                    }
                </SubAnswer>
            </div>
        )
    }

    const answer31_2 = () => {
        return (
            <div>
                {/*View 1.*/}
                <SubAnswer
                    no={'1.'}
                    title={'หากมีบิดา มารดา ลูกหลาน และญาติพี่น้อง ต้องมีการเดินทางเยี่ยมเยือน ระหว่างกัน อย่าง น้อยปีละ 1 ครั้ง'}

                >
                    {
                        no31_2.no31_2_1.answer === '1' ?
                            <Answer detail={`ไปเยื่ยมเยือน`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่ไปเยื่ยมเยือน`} value={''} unit={''}/>
                    }
                </SubAnswer>

                {/*View 2.*/}
                <SubAnswer
                    no={'2.'}
                    title={'หากไม่มีบิดามารดาและญาติพี่น้อง ถ้าสามารถดำรงชีวิตได้อย่างมีความสุขก็ถือว่าเป็นครอบครัว อบอุ่น'}
                >
                    {
                        no31_2.no31_2_2.answer === '1' ?
                            <Answer detail={`มีความสุข`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีความสุข`} value={''} unit={''}/>
                    }
                </SubAnswer>
            </div>
        )
    }

    return (
        <div>
            {/*Question 24*/}
            <Topic
                no={24}
                title={"คนในครัวเรือนไม่ดื่มสุรา"}
            >
                {/*Question 24.1*/}
                <SubTopic
                    no={'24.1'}
                    questionName={"ทุกคนในครัวเรือนนี้ ดื่มสุรา หรือไม่"}
                >
                    {data ? answer24_1() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>
            </Topic>

            {/*Question 25*/}
            <Topic
                no={25}
                title={"คนในครัวเรือนไม่สูบบุหรี่"}
            >
                {/*Question 25.1*/}
                <SubTopic
                    no={'25.1.'}
                    questionName={"ทุกคนในครัวเรือนนี้ สูบบุหรี่ หรือไม่ (ยาสูบ ยาเส้น หรือยามวน)"}
                >
                    {data ? answer25_1() : <div>ไม่มีข้อมูล</div>}
                </SubTopic>
            </Topic>

            {/*Question 26*/}
            <Topic
                no={26}
                title={"คนอายุ 6 ปีขึ้นไป ปฏิบัติกิจกรรมทางศาสนาอย่างน้อยสัปดาห์ละ 1 ครั้ง"}
            >
                {/*Question 26.1 */}
                <SubTopic
                    no={'26.1.'}
                    questionName={"ในรอบปีที่ผ่านมา คนในครัวเรือนที่อายุตั้งแต่ 6 ปี ขึ้นไปทุกคนได้ปฏิบัติกิจกรรมทางศาสนาอย่างใดอย่างหนึ่ง หรือหลายอย่าง อย่างน้อยสัปดาห์ละ 1 ครั้งหรือไม่ (เช่น ร่วมพิธีกรรมทางศาสนา ทำบุญ ตักบาตร ทำภาวนา/สมาธิ สวดมนต์ ฟังเทศน์ฟังธรรม หรือ การทำละหมาด และการเข้าโบสถ์คริสต์ เป็นต้น)"}
                >
                    {
                        (no26_1.answer === '1' || no26_1.answer === '0') ?
                            no26_1.answer === '1' ?
                                <Answer detail={`ปฏิบัติทุกคน`} value={''} unit={''}/>
                                :
                                <Answer detail={`ไม่ปฏิบัติ จำนวน`} value={no26_1.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

            {/*Question 27*/}
            <Topic
                no={27}
                title={"ผู้สูงอายุได้รับการดูแลจากครอบครัว ชุมชน ภาครัฐ หรือภาคเอกชน"}
            >
                {/*Question 27.1*/}
                <SubTopic
                    no={'27.1.'}
                    questionName={"ครัวเรือนนี้ มีผู้สูงอายุ หรือไม่ (อายุ 60 ปีขึ้นไป)"}
                >
                    {
                        (no27_1.answer === '1' || no27_1.answer === '0') ?
                            no27_1.answer === '1' ?
                                <Answer detail={`มี จำนวน `} value={no27_1.amount} unit={'คน'}/>
                                :
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 27.2*/}
                <SubTopic
                    no={'27.2.'}
                    questionName={"ผู้สูงอายุ ได้รับการดูแลเอาใจใส่ในชีวิตความเป็นอยู่ ด้านอาหารการกิน เสื้อผ้าเครื่องนุ่งห่มและได้รับการดูแลเมื่อยามเจ็บไข้ได้ป่วย การดูแลเอาใจใส่ด้านสภาพจิตใจจากคนในครอบครัวหมู่บ้านหรือชุมชน รวมทั้งได้รับสวัสดิการชุมชนหรือเบี้ยยังชีพ จากภาครัฐ หรือภาคเอกชนหรือไม่"}
                >
                    {
                        (no27_2.answer === '1' || no27_2.answer === '0') ?
                            no27_2.answer === '1' ?
                                <Answer detail={`ได้รับทุกคน`} value={''} unit={''}/>
                                :
                                <Answer detail={`ไม่ได้รับ จำนวน `} value={no27_2.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

            {/*Question 28*/}
            <Topic
                no={28}
                title={"ผู้พิการได้รับการดูแลจากครอบครัว ชุมชน ภาครัฐ หรือภาคเอกชน"}
            >
                {/*Question 28.1*/}
                <SubTopic
                    no={'28.1.'}
                    questionName={"ครัวเรือนนี้ มีผู้พิการ หรือไม่"}
                >
                    {
                        (no28_1.answer === '1' || no28_1.answer === '0') ?
                            no28_1.answer === '1' ?
                                <Answer detail={`มี จำนวน `}
                                        value={no28_1.amount} unit={'คน'}/>
                                :
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 28.2*/}
                <SubTopic
                    no={'28.2.'}
                    questionName={"ผู้พิการตามข้อ 28.1. มีบัตรประจำตัวคนพิการหรือไม่"}
                >
                    {
                        (no28_2.answer === '1' || no28_2.answer === '0') ?
                            no28_2.answer === '1' ?
                                <Answer detail={`มีทุกคน`} value={''} unit={''}/>
                                :
                                <Answer detail={`ไม่มี จำนวน `}
                                        value={no28_2.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 28.3*/}
                <SubTopic
                    no={'28.3.'}
                    questionName={"ผู้พิการได้รับการดูแลเอาใจใส่ในชีวิตความเป็นอยู่ด้านอาหารการกิน เสื้อผ้าเครื่องนุ่งห่ม และได้รับการดูแลเมื่อยามเจ็บไข้ได้ป่วยการดูแลเอาใจใส่ด้านสภาพจิตใจจากคนในครอบครัว หมู่บ้านหรือชุมชนรวมทั้งได้รับสวัสดิการชุมชนหรือเบี้ยยังชีพจากภาครัฐ หรือภาคเอกชน หรือไม่"}
                >
                    {
                        (no28_3.answer === '1' || no28_3.answer === '0') ?
                            no28_3.answer === '1' ?
                                <Answer detail={`ได้รับทุกคน`} value={''} unit={''}/>
                                :
                                <Answer detail={`ไม่ได้รับ จำนวน `}
                                        value={no28_3.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

            {/*Question 29*/}
            <Topic
                no={29}
                title={"ผู้ป่วยโรคเรื้อรังได้รับการดูแลจากครอบครัว ชุมชน ภาครัฐ หรือภาคเอกชน"}
            >
                {/*Question 29.1*/}
                <SubTopic
                    no={'29.1.'}
                    questionName={"ครัวเรือนนี้ มีผู้ป่วยโรคเรื้อรังหรือไม่"}
                >
                    {
                        (no29_1.answer === '1' || no29_1.answer === '0') ?
                            no29_1.answer === '1' ?
                                <Answer detail={`มี จำนวน `} value={no29_1.amount} unit={'คน'}/>
                                :
                                <Answer detail={`ไม่มี`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>

                {/*Question 29.2*/}
                <SubTopic
                    no={'29.2.'}
                    questionName={"ผู้ป่วยโรคเรื้อรัง ได้รับการดูแลเอาใจใส่ในชีวิต ความเป็นอยู่ ด้านอาหารการกิน เสื้อผ้าเครื่องนุ่งห่ม และได้รับการดูแลเมื่อยามเจ็บไข้ได้ป่วย การดูแลเอาใจใส่ด้านสภาพจิตใจ จากคนในครอบครัว หมู่บ้านหรือชุมชนรวมทั้งได้รับสวัสดิการชุมชนหรือความช่วยเหลือจากภาครัฐ หรือภาคเอกชน หรือไม่"}
                >
                    {
                        (no29_2.answer === '1' || no29_2.answer === '0') ?
                            no29_2.answer === '1' ?
                                <Answer detail={`- ได้รับทุกคน`} value={''} unit={''}/>
                                :
                                <Answer detail={`ไม่ได้รับ จำนวน`}
                                        value={no29_2.amount} unit={'คน'}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

            {/*Question 30*/}
            <Topic
                no={30}
                title={"ครัวเรือนมีส่วนร่วมทำกิจกรรมสาธารณะเพื่อประโยชน์ของชุมชนหรือท้องถิ่น"}
            >
                {/*Question 30.1*/}
                <SubTopic
                    no={'30.1.'}
                    questionName={"ในรอบปีที่ผ่านมาคนในครัวเรือนมีส่วนร่วมทำกิจกรรมสาธารณะเพื่อประโยชน์ของชุมชนหรือท้องถิ่นหรือไม่"}
                >
                    {
                        (no30_1.answer === '1' || no30_1.answer === '0') ?
                            no30_1.answer === '1' ?
                                <Answer detail={`มีส่วนร่วม`} value={''} unit={''}/>
                                :
                                <Answer detail={`ไม่มีส่วนร่วม`} value={''} unit={''}/>
                            :
                            <Answer detail={`ไม่มีข้อมูล`} value={''} unit={''}/>
                    }
                </SubTopic>
            </Topic>

            {/*Question 31*/}
            <Topic
                no={31}
                title={"ครอบครัวมีความอบอุ่น"}
            >
                {/*Question 31.1*/}
                <DetailTopic
                    no={'31.1.'}
                    questionName={"กรณีมีสมาชิก 2 คนขึ้นไปอยู่ในครัวเรือนเดียวกัน"}
                >
                    {data ? answer31_1() : <div>ไม่มีข้อมูล</div>}
                </DetailTopic>

                {/*Question 31.2.*/}
                <DetailTopic
                    no={'31.2.'}
                    questionName={"กรณีอยู่คนเดียว"}
                >
                    {data ? answer31_2() : <div>ไม่มีข้อมูล</div>}
                </DetailTopic>
            </Topic>

        </div>
    )
}
