import React, { useState, useEffect } from 'react';
import { Modal, Button, Icon, Grid } from 'semantic-ui-react';
import axios from 'axios';
import Config from '../../../constants/Config';
import { Formik } from 'formik';
import EditUserDetail from './EditUserDetail';
import EditUserAddress from './EditUserAddress';
import checkFormat from '../../../function/checkFormat';
import swal from 'sweetalert2';
import Loader from '../../../components/RouteLoader/Loader';
import './EditMember.css'

const EditMember = ({ openEdit, idUser, closeEdit, reloadData}) => {
	console.log('user id',idUser);
	/*Set State*/
	const [ detailUser, setDetailUser ] = useState({});
	const [ detailAddressUser, setDetailAddress ] = useState({});
	const [ birthday, setBirthday ] = useState('');
	const [ choiceArea, setChoiceArea ] = useState([]);
	const [ choiceCommunity, setChoiceCommunity ] = useState([]);
	const [ choicePermission, setChoicePermission ] = useState([]);
	const [ pathImage, setPathImage ] = useState('');
	const [ loadingPage, setLoadingPage ] = useState(false);

	/*Set user_id*/
	const user_id = idUser;
	// console.log('user_id: ',user_id);

	/*API detail_user เพื่อดึงรายละเอียด user */
	const DetailMember = () => {
		return axios
			.get(`${Config.url}/api/v1/detail_user/${user_id}`, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				// console.log('response: ', response.data.value);
				return response;
			})
			.catch((error) => {
				// console.log(error.response);
				return 'DetailMember is catch';
			});
	};

	/*API แสดงข้อมูล area, community, permission*/
	const ChoiceMember = () => {
		return axios
			.get(`${Config.url}/api/v1/filter_search`, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				// console.log('response: ', response.data.value);
				return response;
			})
			.catch((error) => {
				// console.log(error.response);
				return 'ChoiceMember is catch';
			});
	};

	/* Loading */
	const Loading = () => {
		return Promise.all([ DetailMember(), ChoiceMember() ]).then((data) => {
			const detailMember = data[0];
			const choiceMember = data[1];
			// console.log('detailMember: ',detailMember);
			// console.log('choiceMember: ',choiceMember);

			if (detailMember && choiceMember) {
				// console.log('1235');
				/* detailMember */
				setDetailUser(detailMember.data.value);
				setDetailAddress(detailMember.data.value.address);
				if (detailMember.data.value.image) {
					setPathImage(detailMember.data.value.image.path);
				}

				if (detailMember.data.value.birth_date) {
					const birth = new Date(detailMember.data.value.birth_date);
					if (birth.getMonth() + 1 < 10) {
						setBirthday(`${birth.getDate()}-0${birth.getMonth() + 1}-${birth.getUTCFullYear()}`);
					} else {
						setBirthday(`${birth.getDate()}-${birth.getMonth() + 1}-${birth.getUTCFullYear()}`);
					}
				}

				/* choiceMember */
				setChoiceArea(choiceMember.data.value.area);
				setChoiceCommunity(choiceMember.data.value.community);
				setChoicePermission(choiceMember.data.value.permission);

				setLoadingPage(true);
			} else {
				setLoadingPage(false);
			}
		});
	};

	useEffect(() => {
		Loading();
	}, []);

	/*API edit_user*/
	const saveEditUser = async (values) => {
		// console.log('saveEditUser: ', values);
		let body = {
			prefix: values.prefix,
			first_name: values.first_name,
			last_name: values.last_name,
			id_card_number: values.id_card_number,
			birth_date: values.birth_date,
			phone_number: values.phone_number,
			address: {
				ref_house_code: values.ref_house_code,
				house_code: values.house_code,
				house_no: values.house_no,
				village_no: values.village_no,
				village_name: values.village_name,
				alley: values.alley,
				road: values.road,
				sub_district: values.sub_district,
				district: values.district,
				province: values.province,
				zip_code: values.zip_code,
				township: values.township
			},
			permission_id: values.permission_id,
			area_id: values.area_id,
			community_id: values.community_id
		};

		await axios
			.put(`${Config.url}/api/v1/edit_user/${user_id}`, body, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				// console.log('response: ', response.data.value.user._id);
				if (values.img_profile) {
					let formData = new FormData();
					formData.append('image', values.img_profile);
					const id = response.data.value.user._id;
					axios
						.put(`${Config.url}/api/v1/upload_image_user/${id}`, formData, {
							headers: {
								Authorization: 'Bearer ' + localStorage.getItem('access_token'),
								'Content-Type': 'multipart/form-data'
							}
						})
						.then(function(response){
							//handle success
							// console.log('response2: ', response);
							swal
								.fire({
									position: 'center',
									icon: 'success',
									title: 'แก้ไขข้อมูลสมาชิกเรียบร้อบแล้ว',
									showConfirmButton: false,
									timer: 1500
								})
								.then(() => {
									closeEdit(false)
									reloadData()
								});
						})
						.catch((error) => {
							//handle error
							// console.log(response);
							if (error.response) {
								const message = error.response.data.message;
								swal
									.fire({
										position: 'center',
										icon: 'error',
										title: 'เกิดข้อผิดพลาด',
										text: message,
										confirmButtonText: 'ตกลง'
									})
									.then(() => {
										closeEdit(false)
									});
							} else {
								swal
									.fire({
										position: 'center',
										icon: 'error',
										title: 'เกิดข้อผิดพลาด',
										confirmButtonText: 'ตกลง'
									})
									.then(() => {
										closeEdit(false)
									});
							}
						});
				} else {
					swal
						.fire({
							position: 'center',
							icon: 'success',
							title: 'แก้ไขข้อมูลสมาชิกเรียบร้อบแล้ว',
							showConfirmButton: false,
							timer: 1500
						})
						.then(() => {
							closeEdit(false)
							reloadData()
						});
				}
			})
			.catch((error) => {
				if (error.response) {
					const message = error.response.data.message;
					swal
						.fire({
							position: 'center',
							icon: 'error',
							title: 'เกิดข้อผิดพลาด',
							text: message,
							confirmButtonText: 'ตกลง'
						})
						.then(() => {
							closeEdit(false)
						});
				} else {
					swal
						.fire({
							position: 'center',
							icon: 'error',
							title: 'เกิดข้อผิดพลาด',
							confirmButtonText: 'ตกลง'
						})
						.then(() => {
							closeEdit(false)
						});
				}
			});
	};

	if (loadingPage === true) {
		return (

			/* Modal */
			<Modal
				open={openEdit}
				onClose={()=>closeEdit()}
			>
				<Modal.Content>
					{/* Header */}
					<div style={{ display: 'flex', marginBottom: 20 }}>
						<div style={{ flex: 1 }}>
							<h1>แก้ไขข้อมูล{`${detailUser.first_name} ${detailUser.last_name}`}</h1>
						</div>
						<div>
							{/*Close Modal*/}
							<Button onClick={()=>closeEdit()} className='close-button'>
								<Icon name='remove circle' color='red' size='large' className='padding-left'/>
							</Button>
						</div>
					</div>

					{/* Formik */}
					<Grid>
						<Formik
							/* Formik Set State*/
							initialValues={{
								prefix: detailUser.prefix,
								first_name: detailUser.first_name,
								last_name: detailUser.last_name,
								birth_date_format: birthday,
								birth_date: '',
								id_card: detailUser.id_card_number,
								id_card_number: '',
								phone_number: detailUser.phone_number,
								permission_id: detailUser.permission_id,
								area_id: detailUser.area_id,
								community_id: detailUser.community_id,
								house_code: detailAddressUser.house_code,
								house_no: detailAddressUser.house_no,
								village_no: detailAddressUser.village_no,
								village_name: detailAddressUser.village_name,
								alley: detailAddressUser.alley,
								road: detailAddressUser.road,
								sub_district: detailAddressUser.sub_district,
								district: detailAddressUser.district,
								province: detailAddressUser.province,
								zip_code: detailAddressUser.zip_code,
								img_profile: ''
							}}
							validate={(values) => {
								// console.log('values: ', values);

								const errors = {};

								/* Check values is null or not null*/
								if (!values.prefix) {
									errors.prefix = 'กรุณาเลือกคำนำหน้า';
								}

								if (!values.first_name) {
									errors.first_name = 'กรุณากรอกชื่อ';
								} else if (!checkFormat.name.regex.test(values.first_name)) {
									errors.first_name = 'กรุณากรอกชื่อเป็นภาษาไทย';
								}

								if (!values.last_name) {
									errors.last_name = 'กรุณากรอกสกุล';
								} else if (!checkFormat.name.regex.test(values.last_name)) {
									errors.last_name = 'กรุณากรอกสกุลเป็นภาษาไทย';
								}

								if (!values.birth_date_format) {
									errors.birth_date_format = 'กรุณากรอกวันเกิด';
								} else {
									const date_format = values.birth_date_format.split('-');
									const birth = new Date(`${date_format[1]}/${date_format[0]}/${date_format[2]}`);

									const BD = new Date(
										`${birth.getMonth() + 1} ${birth.getDate()} ${birth.getUTCFullYear()} 00:00 UTC`
									);
									values.birth_date = BD.toISOString();
								}

								if (!values.id_card) {
									errors.id_card = 'กรุณากรอกเลขประจำตัวประชาชน';
								} else if (values.id_card) {
									const id = values.id_card.split('-');
									let idCard = '';
									id.forEach((e) => (idCard = idCard + e));
									values.id_card_number = idCard;
								}

								if (!values.phone_number) {
									errors.phone_number = 'กรุณากรอกหมายเลขโทรศัพท์';
								} else if (!checkFormat.phone_number.regex.test(values.phone_number)) {
									errors.phone_number = 'กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง เช่น XXXXXXXXXX';
								}

								if (!values.permission_id) {
									errors.permission_id = 'กรุณาสิทธิ์ประเภทผู้ใช้งาน';
								} else if (values.permission_id === '5e71d0542e86f6aca55b90ea') {
									if (!values.area_id) {
										errors.area_id = 'กรุณาศูนย์ที่รับผิดชอบ';
									}
								} else if (values.permission_id === '5e71d1d12e86f6aca55b90eb') {
									if (!values.community_id) {
										errors.community_id = 'กรุณาชุมชนที่รับผิดชอบ';
									}
								}

								if (values.house_code) {
									const house_code = values.house_code.split('-');
									let houseCode = '';
									house_code.forEach((e)=> (houseCode = houseCode+e));
									values.house_code = houseCode
								}

								if (!values.house_no) {
									errors.house_no = 'กรุณากรอกบ้านเลขที่';
								}

								if (!values.village_no) {
									errors.village_no = 'กรุณากรอกหมู่ที่';
								}


								if (!values.sub_district) {
									errors.sub_district = 'กรุณาเลือกตำบล';
								}

								if (!values.district) {
									errors.district = 'กรุณาเลือกอำเภอ';
								}

								if (!values.province) {
									errors.province = 'กรุณาเลือกจังหวัด';
								}

								if (!values.zip_code) {
									errors.zip_code = 'กรุณากรอกรหัสไปรษณีย์';
								} else if (!checkFormat.zip_code.regex.test(values.zip_code)) {
									errors.zip_code = 'กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง เช่น XXXXX';
								}

								return errors;
							}}
							/* func onSubmit */
							onSubmit={(values, { setSubmitting }) => {
								// console.log('object');
								//call func
								saveEditUser(values);
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting
								/* and other goodies */
							}) => (
								/* form */
								<form onSubmit={handleSubmit}>
									{/* Component EditUserDetail */}
									<EditUserDetail
										idUser={idUser}
										pathImage={pathImage}
										choicePermission={choicePermission}
										choiceCommunity={choiceCommunity}
										choiceArea={choiceArea}
										values={values}
										errors={errors}
										touched={touched}
										handleChange={handleChange}
										handleBlur={handleBlur}
										// handleSubmit={handleSubmit}
										isSubmitting={isSubmitting}
									/>

									{/* Component EditUserAddress */}
									<EditUserAddress
										values={values}
										errors={errors}
										touched={touched}
										handleChange={handleChange}
										handleBlur={handleBlur}
										// handleSubmit={handleSubmit}
										isSubmitting={isSubmitting}
									/>
									<Grid.Row style={{ marginBottom: 10, marginTop: 15 }}>
										<Grid.Column
											mobile={16}
											tablet={16}
											computer={16}
											textAlign={'center'}
											className='displayCenter'
										>
											<Button className='buttonCancelCross' onClick={()=>closeEdit()}>
												<u className='UCancel'>ยกเลิก</u>
											</Button>
											<Button
												primary
												className='buttonSave'
												type='submit'
												disabled={isSubmitting}
											>
												บันทึก
											</Button>
										</Grid.Column>
									</Grid.Row>
								</form>
							)}
						</Formik>
					</Grid>
				</Modal.Content>
			</Modal>
		);
	} else {
		return <Modal
		open={openEdit}
		onClose={()=>closeEdit()}
	>
		<Modal.Content>
			{/* Header */}
			<div style={{ display: 'flex', marginBottom: 20 }}>
				<div style={{ flex: 1 }}>
					<h1>แก้ไขข้อมูล</h1>
				</div>
				<div>
					{/*Close Modal*/}
					<Button onClick={()=>closeEdit()} className='close-button'>
						<Icon name='remove circle' color='red' size='large' className='padding-left'/>
					</Button>
				</div>
			</div>
			<Loader />
		</Modal.Content>
	</Modal>;
	}
};

export default EditMember;
