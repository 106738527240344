import React from 'react';
import "./ApproveData.css";
import { Grid, Table } from 'semantic-ui-react';

export default ({ approveDetail }) => {

    // ฟังก์ชันแปลงวัน/เดือน/ปี
    const reverseDate = (value) => {
        let dateValue = value
        let date = dateValue.substring(8, 10)
        let mount = dateValue.substring(5, 7)
        let year = parseInt(dateValue.substring(0, 4)) + 543
        return (`${date}/${mount}/${year}`)
    }

    return (
        <div className={'approve-data'}>
            <article>
                <Table striped unstackable className={'radius'}>
                    <Table.Body>

                        {/* Header Table */}
                        <Table.Row className={'tr text-header'}>
                            <Table.Cell width={1} textAlign={'center'} className={'cell-small left-cell-radius'}>
                                <div>ครั้งที่</div>
                            </Table.Cell>
                            <Table.Cell width={3} className={'cell-medium'}>
                                <div>วันที่ดำเนินการ</div>
                            </Table.Cell>
                            <Table.Cell width={3} className={'cell-medium'}>
                                <div>การดำเนินการ</div>
                            </Table.Cell>
                            <Table.Cell width={5} className={'cell-large'}>
                                <div>หมายเหตุ</div>
                            </Table.Cell>
                            <Table.Cell width={4} className={'cell-large right-cell-radius'}>
                                <div>โดย</div>
                            </Table.Cell>
                        </Table.Row>

                        {/* รายละเอียดการไม่อนุมัติข้อมูล */}
                        {approveDetail.length !== 0 ?
                            approveDetail.map((item, index) => {

                                return (
                                    <Table.Row className={'tr'}>
                                        <Table.Cell textAlign={'center'}>
                                            <div>{index + 1}</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>{reverseDate(item.created_at)}</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>{item.detail === "" ? 'อนุมัติ' : 'ไม่อนุมัติ'}</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>{item.detail === "" ? '-' : item.detail}</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>{item.created_by}</div>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })
                            :
                            <Table.Row className={'tr'}>
                                <Table.Cell colSpan={'5'} textAlign={'center'}>
                                    <div>รอดำเนินการอนุมัติ</div>
                                </Table.Cell>
                            </Table.Row>
                        }

                    </Table.Body>
                </Table>
            </article>
        </div>
    )
}
