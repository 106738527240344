import React, {useEffect, useState} from 'react'
import {
    Redirect,
    Route,
    withRouter
} from "react-router-dom";
import Loader from "./Loader";
import axios from 'axios';
import Config from "../../constants/Config";
import Page403 from "../../screen/PageError/Page403";
import {connect} from "react-redux";
import { User } from '../../store/actions';
import LoginScreen from "../../screen/LoginScreen";


const RouteLoader = ({path, component: Component,permission, ...props}) => {

    const [loading, setLoading] = useState(false);
    const [page_error, set_page_error] = useState(false);

    useEffect(() => {
        checkToken();
    }, [path]);

    const checkToken = async () => {
        try{
            let response = await axios.get(
                `${Config.url}/api/v1/verify_token/${permission}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + await localStorage.getItem('access_token')
                    }
                }
            )
            setLoading(true);
            await props.userAction.show(response.data.value);
        }catch (e) {
            if(e.status === 401){
                set_page_error(false);
                localStorage.removeItem('access_token');
                props.history.push('/login')
            }else{
                set_page_error(true);
            }
        }

    };

    return (
        <Route
            {...props}
            path={path}
            render={(props) => (
                loading ?
                    <Component {...props} />
                    :
                    page_error ?
                        <Redirect to={`/forbidden`} />
                        :
                        <Loader/>

            )}
        />
    )
};


const mapDispatchToProps = (dispatch) => {
    return {
        ...User(dispatch),
        dispatch
    }
};

export default connect(null,mapDispatchToProps )(withRouter(RouteLoader))
