import React from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link
} from "react-router-dom";
import LoginScreen from '../screen/LoginScreen'
import Admin from './admin'
import SubAdmin from './subadmin'
import Page404 from '../screen/PageError/Page404'
import Page403 from '../screen/PageError/Page403'


export default () => {
    return (
        <Router>
            <Switch>
                <Route path="/login" component={LoginScreen}/>
                <Route path="/administrator" component={Admin}/>
                <Route path="/centeradministrator" component={SubAdmin}/>
                <Route exact path="/" render={() => (
                    <Redirect to="/login"/>
                )}/>
                <Route path={`/forbidden`} render={(props) => (
                    <Page403 {...props} mainPath="/" />
                )}/>
                <Route path={`/notfound`} render={(props) => (
                    <Page404 {...props} mainPath="/"/>
                )}/>
                <Route render={(props) => (
                    <Page404 {...props} mainPath="/"/>
                )}/>
            </Switch>
        </Router>
    );
}

function Home() {
    return <h2>Home</h2>;
}

function About() {
    return <h2>About</h2>;
}

function Users() {
    return <h2>Users</h2>;
}

function ApproveScreen() {
    return <h2>Approve</h2>;
}
