import {combineReducers, createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { UserReducer } from "./reducers/UserReducer";


const reducers = combineReducers({
    userReducer : UserReducer,
});


export const store = createStore(reducers, applyMiddleware(thunk));
