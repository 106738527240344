import {Divider, Grid, Icon, Input} from "semantic-ui-react";
import React, {useState} from "react";
import InputMask from 'react-input-mask';
import {Field} from 'formik';
import {DropdownAddress} from "../../../../components/Profile/Dropdown";
import ThaiAddress from '../../../../ThaiAddress'


export default (props) => {

    /* Set State*/
    const [ thaiAddressData, setThaiAddressData ] = useState(ThaiAddress);
    const [ zip_code_json, set_zip_code_json ] = useState([{
        key: props.values.zip_code,
        text: props.values.zip_code,
        value: props.values.zip_code
    }]);
    const [ province_json, set_province_json ] = useState([{
        key: props.values.province,
        text: props.values.province,
        value: props.values.province
    }]);
    const [ district_json, set_district_json ] = useState([{
        key: props.values.district,
        text: props.values.district,
        value: props.values.district
    }]);
    const [ sub_district_json, set_sub_district_json ] = useState([{
        key: props.values.sub_district,
        text: props.values.sub_district,
        value: props.values.sub_district
    }]);

    /* province Search*/
    const setProvinceSelect = (event, data) => {
        // console.log('event: ',event)
        // console.log('data: ',data.searchQuery)
        if (data.searchQuery){
            let optionsP = [];
            let i = 0;
            thaiAddressData.forEach((value) => {
                //if ถ้าเกิน 20 แล้วไม่ต้องเข้า
                if (i < 20){
                    if (value.province.includes(data.searchQuery)){
                        let textProvince =
                            value.district + ' >> ' + value.amphoe + ' >> ' + value.province + ' >> ' + value.zipcode;

                        optionsP.push({
                            key: textProvince,
                            text: textProvince,
                            value: textProvince
                        })

                        i++
                    }
                }
            });
            set_province_json(optionsP)
        }
    }

    /* อำเภอ Search*/
    const setDistrictSelect = (event, data) => {
        if (data.searchQuery){
            let optionsP = [];
            let i = 0;
            thaiAddressData.forEach((value) => {
                if (i<20){
                    if (value.amphoe.includes(data.searchQuery)){
                        let textProvince =
                            value.district + ' >> ' + value.amphoe + ' >> ' + value.province + ' >> ' + value.zipcode;

                        optionsP.push({
                            key: textProvince,
                            text: textProvince,
                            value: textProvince
                        })

                        i++
                    }
                }
            });
            set_district_json(optionsP)
        }
    }

    /* ตำบล Search*/
    const setSubDistrictSelect = (event, data) => {
        if (data.searchQuery){
            let optionsP = [];
            let i = 0;
            thaiAddressData.forEach((value) => {
                if (i<20){
                    if (value.district.includes(data.searchQuery)){
                        let textProvince =
                            value.district + ' >> ' + value.amphoe + ' >> ' + value.province + ' >> ' + value.zipcode;

                        optionsP.push({
                            key: textProvince,
                            text: textProvince,
                            value: textProvince
                        })

                        i++
                    }
                }
            });
            set_sub_district_json(optionsP)
        }
    }

    /* รหัสไปรษณีย์ Search*/
    const setZipcodeSelect = (event, data) => {
        if (data.searchQuery){
            let optionsP = [];
            let i = 0;
            thaiAddressData.forEach((value) => {
                if (i<20){
                    if (String(value.zipcode).includes(data.searchQuery)){
                        let textProvince =
                            value.district + ' >> ' + value.amphoe + ' >> ' + value.province + ' >> ' + value.zipcode;

                        optionsP.push({
                            key: textProvince,
                            text: textProvince,
                            value: textProvince
                        })

                        i++
                    }
                }
            });
            set_zip_code_json(optionsP)
        }
    }

    /* set options sub_district, district, province */
    const handleChangeAddress = (options) => {
        set_sub_district_json([options[0]])
        set_district_json([options[1]])
        set_province_json([options[2]])
        set_zip_code_json([options[3]])
    }


    return (
        <div className={'profile-user'}>

            {/*หัวข้อข้อมูลที่อยู่อาศัย*/}
            <div className={'container-subheader'}>
                <div className={'subheader'}>
                    <Icon className={'icon-header'} name='home'/>
                    ข้อมูลที่อยู่อาศัย
                </div>
                <Divider clearing/>
            </div>

            <div className={'container-content'}>

                <div className={'container-content-input'}>
                    <Grid>
                        <Grid.Row>

                            {/*รหัสประจำบ้าน*/}
                            <Grid.Column
                                mobile={16}
                                tablet={16}
                                computer={15}
                            >
                                <div className={'container-input'}>
                                    <label className={'label-address'}>รหัสประจำบ้าน</label>
                                    <Input
                                        placeholder='กรอกรหัสประจำบ้าน'
                                        value={props.values.house_code}
                                        values={props.values}
                                        type='text'
                                        name='house_code'
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />
                                </div>
                            </Grid.Column>

                            {/*บ้านเลขที่*/}
                            <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={5}
                            >
                                <div className={'container-input'}>
                                    <label className={'label-address'}>บ้านเลขที่</label>
                                    <div className={'input-status'}>
                                        <Input
                                            placeholder='กรอกบ้านเลขที่'
                                            value={props.values.house_no}
                                            values={props.values}
                                            type='text'
                                            name='house_no'
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            className={
                                                props.errors.house_no && props.touched.house_no && props.errors.house_no ? (
                                                    'error'
                                                ) : (
                                                    props.values.house_no && 'success'
                                                )
                                            }
                                        />
                                        {props.errors.house_no && props.touched.house_no && props.errors.house_no ? (
                                            <span style={{color: 'red'}}>{props.errors.house_no}</span>
                                        ) : null}
                                    </div>
                                </div>
                            </Grid.Column>

                            {/*หมู่ที่*/}
                            <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={5}
                            >
                                <div className={'container-input'}>
                                    <label className={'label-address'}>หมู่ที่</label>
                                    <div className={'input-status'}>
                                        <Input
                                            placeholder='กรอกหมู่ที่'
                                            value={props.values.village_no}
                                            values={props.values}
                                            type='text'
                                            name='village_no'
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            className={
                                                props.errors.village_no && props.touched.village_no && props.errors.village_no ? (
                                                    'error'
                                                ) : (
                                                    props.values.village_no && 'success'
                                                )
                                            }
                                        />
                                        {props.errors.village_no && props.touched.village_no && props.errors.village_no ? (
                                            <span style={{color: 'red'}}>{props.errors.village_no}</span>
                                        ) : null}
                                    </div>
                                </div>
                            </Grid.Column>

                            {/*หมู่บ้าน*/}
                            <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={5}
                            >
                                <div className={'container-input'}>
                                    <label className={'label-address'}>หมู่บ้าน</label>
                                    <div className={'input-status'}>
                                        <Input
                                            placeholder='หมู่บ้าน'
                                            value={props.values.village_name}
                                            values={props.values}
                                            type='text'
                                            name='village_name'
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            className={
                                                props.errors.village_name && props.touched.village_name && props.errors.village_name ? (
                                                    'error'
                                                ) : (
                                                    props.values.village_name && 'success'
                                                )
                                            }
                                        />
                                        {props.errors.village_name && props.touched.village_name && props.errors.village_name ? (
                                            <span style={{color: 'red'}}>{props.errors.village_name}</span>
                                        ) : null}
                                    </div>
                                </div>
                            </Grid.Column>

                            {/*ซอย*/}
                            <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={5}
                            >
                                <div className={'container-input'}>
                                    <label className={'label-address'}>ซอย</label>
                                    <Input
                                        placeholder='กรอกซอย'
                                        values={props.values}
                                        type='text'
                                        name='alley'
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.alley}
                                    />
                                </div>
                            </Grid.Column>

                            {/*ถนน*/}
                            <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={5}
                            >
                                <div className={'container-input'}>
                                    <label className={'label-address'}>ถนน</label>
                                    <Input
                                        placeholder='กรอกถนน'
                                        values={props.values}
                                        type='text'
                                        name='road'
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.road}
                                    />
                                </div>
                            </Grid.Column>

                            {/*ตำบล*/}
                            <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={5}
                            >
                                <div className={'container-input'}>
                                    <label className={'label-address'}>ตำบล</label>
                                    <div className={'input-status'}>
                                        <Field
                                            placeholder='ตำบล'
                                            options={sub_district_json}
                                            name='sub_district'
                                            // value={props.values.district}
                                            component={DropdownAddress}
                                            fluid
                                            onSearchChange={setSubDistrictSelect}
                                            // optionsDistrict={amphoe_options}
                                            onBlur={props.handleBlur}
                                            onChangeAddress={handleChangeAddress}
                                            className={
                                                props.errors.sub_district && props.touched.sub_district && props.errors.sub_district ? (
                                                    'error'
                                                ) : (
                                                    props.values.sub_district && 'success'
                                                )
                                            }
                                        />
                                        {props.errors.sub_district && props.touched.sub_district && props.errors.sub_district && (
                                            <span style={{ color: 'red' }}>{props.errors.sub_district}</span>
                                        )}
                                    </div>
                                </div>
                            </Grid.Column>


                            {/*อำเภอ*/}
                            <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={5}
                            >
                                <div className={'container-input'}>
                                    <label className={'label-address'}>อำเภอ</label>
                                    <div className={'input-status'}>
                                        <Field
                                            placeholder='อำเภอ'
                                            options={district_json}
                                            name='district'
                                            component={DropdownAddress}
                                            fluid
                                            onSearchChange={setDistrictSelect}
                                            onBlur={props.handleBlur}
                                            onChangeAddress={handleChangeAddress}
                                            className={
                                                props.errors.district && props.touched.district && props.errors.district ? (
                                                    'error'
                                                ) : (
                                                    props.values.district && 'success'
                                                )
                                            }
                                        />
                                        {props.errors.district && props.touched.district && props.errors.district && (
                                            <span style={{ color: 'red' }}>{props.errors.district}</span>
                                        )}
                                    </div>
                                </div>
                            </Grid.Column>

                            {/*จังหวัด*/}
                            <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={5}
                            >
                                <div className={'container-input'}>
                                    <label className={'label-address'}>จังหวัด</label>
                                    <div className={'input-status'}>
                                        <Field
                                            placeholder='จังหวัด'
                                            options={province_json}
                                            name='province'
                                            component={DropdownAddress}
                                            fluid
                                            onSearchChange={setProvinceSelect}
                                            onBlur={props.handleBlur}
                                            onChangeAddress={handleChangeAddress}
                                            className={
                                                props.errors.province && props.touched.province && props.errors.province ? (
                                                    'error'
                                                ) : (
                                                    props.values.province && 'success'
                                                )
                                            }
                                        />
                                        {props.errors.province && props.touched.province && props.errors.province && (
                                            <span style={{ color: 'red' }}>{props.errors.province}</span>
                                        )}
                                </div>
                                </div>
                            </Grid.Column>

                            {/*รหัสไปรษณีย์*/}
                            <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={5}
                            >
                                <div className={'container-input'}>
                                    <label className={'label-address'}>รหัสไปรษณีย์</label>
                                    <div className={'input-status'}>
                                    <Field
                                        placeholder='รหัสไปรษณีย์'
                                        options={zip_code_json}
                                        name='zip_code'
                                        component={DropdownAddress}
                                        fluid
                                        onSearchChange={setZipcodeSelect}
                                        onBlur={props.handleBlur}
                                        onChangeAddress={handleChangeAddress}
                                        className={
                                            props.errors.zip_code && props.touched.zip_code && props.errors.zip_code ? (
                                                'error'
                                            ) : (
                                                props.values.zip_code && 'success'
                                            )
                                        }
                                    />
                                    {props.errors.zip_code && props.touched.zip_code && props.errors.zip_code && (
                                        <span style={{ color: 'red' }}>{props.errors.zip_code}</span>
                                    )}
                                    </div>
                                </div>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                </div>


            </div>
        </div>
    )
}
