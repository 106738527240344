import React, {useState} from 'react'
import {Icon,} from "semantic-ui-react";
import './CardHouseList.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import ShowDetailHouseHold from '../../../components/ShowDetailHouseHold'
import DefaultImg from '../../../assets/img/default-user-image.png'
import NoanyImg from '../../../assets/img/Maker/NoanyHouse.png'
import IncompleteImg from '../../../assets/img/Maker/IncompleteHouse.png'
import CompleteImg from '../../../assets/img/Maker/CompleteHouse.png'
import ApproveImg from '../../../assets/img/Maker/approvehouse.png'
import RejectImg from '../../../assets/img/Maker/rejecthouse.png'


const CardHouse = ({item, page, index, onClick, reloadData, ...props}) => {

    const [open_detail_house, set_open_detail_house] = useState(false)

    // Set image status house
    const imgStatushouse = (value) => {
        let src = ''
        if (value === 'new') {
            src = NoanyImg
        } else if (value === 'incomplete') {
            src = IncompleteImg

        } else if (value === 'complete') {
            src = CompleteImg

        } else if (value === 'reject') {
            src = RejectImg

        } else if (value === 'approve') {
            src = ApproveImg
        } else {
            src = DefaultImg
        }
        return src
    }

    // Set text status house
    const statushouse = (value) => {
        let status = ''
        if (value === 'new') {
            status = 'ยังไม่เชื่อมข้อมูล'
        } else if (value === 'incomplete') {
            status = 'กำลังดำเนินการ'

        } else if (value === 'complete') {
            status = 'ดำเนินการเสร็จสิ้น'

        } else if (value === 'reject') {
            status = 'ปฏิเสธข้อมูล'

        } else if (value === 'approve') {
            status = 'อนุมัติข้อมูล'
        } else {
            status = '-'
        }
        return status
    }

    // Set text status house
    const styleStatushouse = (value) => {
        let style = ''
        if (value === 'new') {
            style = 'text-detail-member-error'
        } else if (value === 'incomplete') {
            style = 'text-detail-member-warning'

        } else if (value === 'complete') {
            style = 'text-detail-member-success'

        } else if (value === 'approve') {
            style = 'text-detail-member-success'

        } else if (value === 'reject') {
            style = 'text-detail-member-error'
        } else {
            style = 'text-detail-member'
        }
        return style
    }

// Reverse date to TH date
    const reverseDate = (value) => {
        // 2015-o4-15Tooooo
        if (value) {
            let [year , month , day] = value.split("T")[0].split("-")
            const date = new Date(parseInt(year), parseInt(month) -1 , parseInt(day))
            let dateTH = date.toLocaleDateString("th-TH", {
                year: `numeric`,
                month: `long`,
                day: `numeric`
            })

            return dateTH
        } else {
            return 'กำลังโหลด...'
        }
    }

    return (

        <div className={'card-house-list'}>

            <div style={{display: 'flex', flexDirection: 'column', flex: '1'}}>

                {/*ข้อมูลสมาชิก*/}
                <div className={'container-border-card'}>
                    <div className={'members'}>

                        {/*ประเภทผู้ใช้งาน*/}
                        <div className={'type-user-symbol'}
                             style={{backgroundImage: 'linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%)', marginRight: '10px'}}
                        >
                            <div className={'background-house-no'}>
                                <span className={'house-no'}
                                      style={{color: '#1967AD'}}>{page}</span>
                            </div>
                        </div>

                        <div className={'container-card'}>

                            <div className={'container-detail-house'}>

                                {/*ที่อยู่อาศัย*/}
                                <div className={'detail-table-block'} style={{flex: 2}}>

                                    {/*House No*/}
                                    <div className={'header-detail-member'}>
                                        {
                                            item.house_no ?
                                                `บ้านเลขที่ ${item.house_no}`
                                                :
                                                `บ้านเลขที่ใกล้เคียง ${item.ref_house_code}`
                                        }
                                    </div>

                                    {/*House ID*/}
                                    <div className={'subdetail-table-block'}>
                                        รหัสบ้าน
                                        <div className={'text-detail-member'}> : {item.house_code}</div>
                                    </div>

                                    {/*House Detail*/}
                                    <div className={'subdetail-table-block housedetail'} style={{paddingRight: '20px'}}>
                                        <Icon name={'home'} style={{color: '#17436b'}}/>
                                        <div className={'text-detail-member'}>
                                            <span style={{color: '#2d9034'}}>หมู่ที่</span><span
                                            style={{fontSize: '16px'}}> {item.village_no} </span>
                                            <span style={{color: '#2d9034'}}>ซอย</span><span
                                            style={{fontSize: '16px'}}> {item.alley} </span>
                                            <span style={{color: '#2d9034'}}>ถนน</span><span
                                            style={{fontSize: '16px'}}> {item.road} </span>
                                            <br/>
                                            <span style={{color: '#2d9034'}}>ต.</span><span
                                            style={{fontSize: '16px'}}> {item.sub_district} </span>
                                            <span style={{color: '#2d9034'}}>อ.</span><span
                                            style={{fontSize: '16px'}}> {item.district} </span>
                                            <span style={{color: '#2d9034'}}>จ.</span><span
                                            style={{fontSize: '16px'}}> {item.province} </span>
                                            <span style={{fontSize: '16px'}}> {item.zip_code} </span>
                                        </div>
                                    </div>
                                </div>

                                {/*เขตพื้นที่รับผิดชอบ*/}
                                <div className={'detail-table-block'}>

                                    {/*Medical Center Area*/}
                                    <div>
                                        <div className={'subdetail-name'}>
                                            <Icon name={'building'} style={{color: '#17436b'}}/>
                                            <div className={'text'}>ศูนย์รับผิดชอบ</div>
                                        </div>
                                        <div
                                            className={'text-detail-member'}>{item.area ? item.area.name : '-'}</div>
                                    </div>

                                    {/*Community Area*/}
                                    <div>
                                        <div className={'subdetail-name'}>
                                            <Icon name={'home'} style={{color: '#17436b'}}/>
                                            <div className={'text'}>เขตพื้นที่รับผิดชอบ</div>
                                        </div>
                                        <div
                                            className={'text-detail-member'}>{item.community ? item.community.name : '-'}</div>
                                    </div>
                                </div>

                                {/*ข้อมูลการสำรวจ*/}
                                <div className={'detail-table-block'}>

                                    {/*Survey Person*/}
                                    <div>
                                        <div className={'subdetail-name'}>
                                            <Icon name={'user'} style={{color: '#17436b'}}/>
                                            <div className={'text'}>ผู้สำรวจ</div>
                                        </div>
                                        <div
                                            className={'text-detail-member'}>{item.survey_by ? item.survey_by : '-'}</div>
                                        {!!item.survey_tel && <div className={'text-detail-member'}>{`(${item.survey_tel})`}</div>}
                                    </div>

                                    {/*Survey Date*/}
                                    <div>
                                        <div className={'subdetail-name'}>
                                            <Icon name={'calendar alternate'} style={{color: '#17436b'}}/>
                                            <div className={'text'}>วันที่สำรวจ</div>
                                        </div>
                                        <div
                                            className={'text-detail-member'}>{item.survey_at ? reverseDate(item.survey_at) : '-'}</div>
                                    </div>

                                </div>

                                {/*Status Operation*/}
                                <div className={'detail-table-block'}>
                                    <div className={'subdetail-name'} style={{alignItems: 'center'}}>
                                        <img src={imgStatushouse(item.status)} style={{maxWidth: '35px'}}/>
                                        <div className={styleStatushouse(item.status)}>
                                            {statushouse(item.status)}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/*ปุ่มแก้ไข/ลบ*/}
                        <div className={'layout-button-action'}>
                            <div className={'button-action-modal'}>
                                <button
                                    onClick={() => {
                                        if(item.status === 'new'){
                                            Swal.fire({
                                                title: 'แจ้งเตือน',
                                                text: 'ไม่มีข้อมูลบ้าน',
                                                icon: 'warning',
                                                confirmButtonText: 'ตกลง',
                                            })
                                        }
                                        else {
                                            set_open_detail_house(true)
                                        }
                                    }}
                                    className={'button-info-member'}
                                    style={{
                                        padding: '5px',
                                        backgroundColor: 'rgb(242, 201, 76)',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Icon name={'eye'} size='large'/>
                                </button>

                            </div>
                        </div>

                        {
                            open_detail_house &&
                            <ShowDetailHouseHold
                                houseId={item._id}
                                visible={open_detail_house}
                                onClose={() => {
                                    set_open_detail_house(false);
                                }}
                            />
                        }

                    </div>


                </div>


            </div>


        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
};

export default connect(mapStateToProps, null)(CardHouse)
