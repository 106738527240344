import React, { useEffect, useState } from "react";
import './MapScreen.css';
import mapStyles from "../../components/Maps/mapStyles"
import { Grid, Icon } from 'semantic-ui-react'
import Config from "../../constants/Config";
import GoogleMapReact from 'google-map-react';
import 'sweetalert2/src/sweetalert2.scss'
import Marker from "../../components/Maps/Marker";
import axios from "axios";
import DataHouse from "../ApproveDataScreen/DataHouse";
import FormatContainer from "../../components/FormatContainer";
import Swal from 'sweetalert2';

const MapScreen = (props) => {

    const [map_api, set_map_api] = useState(null)

    const [loading, set_loading] = useState(true)

    const [markers, set_markers] = useState(null)
    const [marker_map, set_marker_map] = useState(null)
    const [temp_position, set_temp_position] = useState(null)
    const [new_position, set_new_position] = useState(null)

    const [reset_status, set_reset_status] = useState(false)
    const [save_status, set_save_status] = useState(false)

    const [type_map, set_type_map] = useState('SATELLITE')

    useEffect(() => {

            const fetchHouseData = async () => {

                let id = props.location.state.household_id

                let response = await axios.get(
                    `${Config.url}/api/v1/house_data/${id}`,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                            'Content-Type': 'application/json',
                        }
                    }
                )

                set_markers(response.data.value)

                set_loading(false)

                return {
                    position: {
                        lat: parseFloat(response.data.value.location.latitude),
                        lng: parseFloat(response.data.value.location.longitude)
                    },
                    data: response.data.value.data,
                    status: response.data.value.status,
                }
            }


            if (!!map_api) {
                fetchHouseData().then(({ position, data, status }) => {
                    let source = null
                    switch (status) {
                        case 'new':
                            source = require('../../assets/img/Maker/NoanyHouse.png');
                            break;
                        case 'incomplete':
                            source = require('../../assets/img/Maker/IncompleteHouse.png');
                            break;
                        case 'complete':
                            source = require('../../assets/img/Maker/CompleteHouse.png');
                            break;
                        case 'approve':
                            source = require('../../assets/img/Maker/approvehouse.png');
                            break;
                        case 'reject':
                            source = require('../../assets/img/Maker/rejecthouse.png');
                            break;
                    }

                    set_temp_position(position)
                    map_api.map.setZoom(18);
                    map_api.map.panTo(position);

                    const { innerWidth: width, innerHeight: height } = window;

                    if (width <= 1200) {
                        map_api.map.panBy(0, 100);
                    } else {
                        map_api.map.panBy(-200, 0);
                    }

                    var icon = {
                        url: source, // url
                        scaledSize: new map_api.maps.Size(80, 80), // scaled size
                        origin: new map_api.maps.Point(0, 0), // origin
                        anchor: new map_api.maps.Point(40, 80) // anchor
                    };

                    let marker = new map_api.maps.Marker({
                        position: position,
                        map: map_api.map,
                        icon: icon,
                        draggable: true,
                        label: {
                            fontSize: '10px',
                            text: data.house_no || "ยังไม่มีข้อมูล",
                            className: 'marker-text',
                        }
                    });

                    marker.addListener("dragend", () => {
                        let position = marker.getPosition();
                        set_new_position({
                            latitude: position.lat(),
                            longitude: position.lng()
                        })
                        set_save_status(true)
                        console.log({
                            latitude: position.lat(),
                            longitude: position.lng()
                        })
                        set_reset_status(true)
                    });

                    set_marker_map(marker)

                })
            }

        }, [map_api]
    )

    const resetMarkerPosition = () => {
        set_reset_status(false)
        set_save_status(false)
        marker_map.setPosition(temp_position);
        set_new_position(temp_position)
        map_api.map.panTo(temp_position);

        const { innerWidth: width, innerHeight: height } = window;

        if (width <= 1200) {
            map_api.map.panBy(0, 100);
        } else {
            map_api.map.panBy(-200, 0);
        }
    }

    const submitPosition = () => {
        Swal.fire({
            title: 'แจ้งเตือน',
            text: "ยืนยันการแก้ไขข้อมูล?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#c7c7c7',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if (result.value) {
                set_loading(true)
                let response = await axios.put(
                    `${Config.url}/api/v1/change_marker_position`,
                    {
                        ...new_position,
                        "id": props.location.state.household_id
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                            'Content-Type': 'application/json'
                        }
                    }
                )
                    .then((response) => {
                        set_loading(false)
                        Swal.fire(
                            'ทำรายการสำเร็จ!',
                            'คุณได้เปลี่ยนตำแน่งพิกัดเรียบร้อยแล้ว',
                            'success'
                        )

                    })
                    .catch((error) => {
                        console.log('err', error)
                        set_loading(false)
                        Swal.fire(
                            'พบข้อผิดพลาด!',
                            'ไม่สามรถเปลี่ยนตำแน่งพิกัดได้ กรุณาลองใหม่อีกครั้ง',
                            'error'
                        )
                    })

            }
        })
    }

    return (
        <div className={'edit-map-screen'}>
            {
                loading &&
                <div className={'backdrop'}>
                    <img src={require("../../assets/img/OGS-logo-white.png")}
                         style={{ width: '150px', height: '120px', marginBottom: '20px' }}/>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h1>กำลังโหลด</h1>
                        <img src={require("../../assets/loading.gif")}
                             style={{ width: '50px', height: '50px', marginBottom: '-16px' }}/>
                    </div>
                </div>
            }

            <div className={'header-content'}>
                <Icon
                    name={"arrow circle left"}
                    onClick={() => {
                        props.history.goBack()
                    }}
                />
                <div>
                    <div className={'header-text'}>แก้ไขตำแหน่งพิกัดบ้าน</div>
                    <div className={'subheader-text'}>บ้านเลขที่ : {markers?.data.house_no}</div>
                </div>
            </div>

            {
                !!markers &&
                <div className={'detail-content'}>
                    <FormatContainer
                        icon={'home'}
                        title={'ข้อมูลบ้าน'}
                        style={{ height: '100%' }}
                    >

                        <div className={'content-marker-detail'}>
                            {/*Component ข้อมูลบ้าน*/}
                            <DataHouse
                                item={markers.data}
                                editMarker={true}
                            />
                        </div>

                        <div className={'content-action'}>

                            <button
                                className={`edit-location ${!reset_status && "disabled"}`}
                                disabled={!reset_status}
                                onClick={resetMarkerPosition}
                            >
                                <Icon
                                    name={"redo"}
                                />
                                รีเซ็ตตำแหน่ง
                            </button>

                            <button
                                className={`edit-location active ${!save_status && "disabled"}`}
                                onClick={submitPosition}
                                disabled={!save_status}
                            >
                                <Icon
                                    name={"save"}
                                />
                                บันทึกตำแหน่ง
                            </button>

                        </div>

                    </FormatContainer>
                </div>
            }

            <div className={'map-content'}>

                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals
                    bootstrapURLKeys={{ key: Config.map_key }}
                    defaultCenter={{ lat: 13.917882, lng: 100 }}
                    onGoogleApiLoaded={({ map, maps }) => {
                        set_map_api({ map, maps })
                    }}
                    options={(maps) => {
                        return {
                            mapTypeId: maps.MapTypeId[type_map],
                            maxZoom: 22,
                        }
                    }}
                    defaultZoom={9}
                >
                </GoogleMapReact>

                <div className={'filter-content'}>

                    {/*Type Map*/}
                    <div className={"type-map"}>
                        <img
                            onClick={() => {
                                if (type_map === 'ROADMAP') {
                                    set_type_map('SATELLITE')
                                } else {
                                    set_type_map('ROADMAP')
                                }
                            }}
                            src={type_map === 'ROADMAP' ? require('../../assets/img/satellite.png') : require('../../assets/img/roadmap.png')}
                        />
                    </div>
                </div>

            </div>


        </div>
    )
}

export default MapScreen;


