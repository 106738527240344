import React from "react";
import './PageError.css';


export default (props) => {
    return (
        <div className={'page-error'}>
            <div className={'container-text'}>
                <h1>Access Denied!</h1>
                <div className={'detail'}>
                    <div>เดี๋ยวก่อน! คุณไม่ได้รับอนุญาตให้เข้าถึงหน้านี้</div>
                </div>
                <div className={'ex-detail'}>
                    <div>หากต้องการกลับสู่หน้าเข้าสู่ระบบ</div>
                    <button onClick={()=>{
                        props.history.replace(props.mainPath)
                    }}>กลับสู่หน้าเข้าสู่ระบบ</button>
                </div>
            </div>
            <div className={'container-img'}>
                <img src={require('../../assets/img/403.png')}/>
            </div>
        </div>
    )
}
