import React from 'react'

export default ({item,onClick}) => {
    let source
    switch (item.status) {
        case 'new':
            source = require('../../assets/img/Maker/NoanyHouse.png');
            break;
        case 'incomplete':
            source = require('../../assets/img/Maker/IncompleteHouse.png');
            break;
        case 'complete':
            source = require('../../assets/img/Maker/CompleteHouse.png');
            break;
        case 'approve':
            source = require('../../assets/img/Maker/approvehouse.png');
            break;
        case 'reject':
            source = require('../../assets/img/Maker/rejecthouse.png');
            break;
    }

    return (
        <div onClick={()=>{
            onClick()
        }}>
            <div className={'address-no'}>{item.data ? item.data.house_no ? item.data.house_no : item.data.ref_house_code : "ไม่พบข้อมูล"}</div>
            <img className={'icon-marker'} src={source}/>
        </div>
    );
}
