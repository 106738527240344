import React, {useEffect, useState} from "react";
import './SettingTable.css'
import FormatContiner from "../../../components/FormatContainer";
import {Breadcrumb, Icon, Table, Input, Button} from "semantic-ui-react";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import DetailTable from "./DetailTable";
import Config from "../../../constants/Config";
import axios from "axios"
import Loader from "../../../components/RouteLoader/Loader";


export default ({name, reloadData, data, loader, ...props}) => {

    const [new_data, set_new_data] = useState([])

    const setData = async () => {
        set_new_data(data)
    }

    const handleSearch = (event) => {
        let keyword = event.target.value
        if (keyword) {
            let searchData = data.filter((item) => {
                return item.name.search(keyword) !== -1
                // return Object.keys(item).some(key =>
                //     item[key].includes(event.target.value)
                // );
            })
            set_new_data(searchData)
        } else {
            set_new_data(data)

        }
    }

    const addData = async (value) => {
        let data = {
            name: value,
            detail: value
        }
        try {
            if (name === 'อาชีพ') {
                let response = await axios.post(
                    `${Config.url}/api/v1/add_occupation`,
                    data,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                            'Content-Type': 'application/json'
                        }
                    }
                )
            } else if (name === 'วุฒิการศึกษา') {
                let response = await axios.post(
                    `${Config.url}/api/v1/add_education`,
                    data,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                            'Content-Type': 'application/json'
                        }
                    }
                )
            } else if (name === 'ศาสนา') {
                let response = await axios.post(
                    `${Config.url}/api/v1/add_religion`,
                    data,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                            'Content-Type': 'application/json'
                        }
                    }
                )
            }
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ!',
                text: 'เพิ่มรายการสำเร็จ',
                confirmButtonText: 'ตกลง',
            }).then(() => {
                reloadData()
            })
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด!',
                text: 'ไม่สามารถเพิ่มรายการได้ กรุณาทำรายการภายหลัง',
                confirmButtonText: 'ตกลง',
            }).then(() => {
                reloadData()
            })
        }
    }

    const editData = async (detail_text, data) => {
        let id = data
        let info = {
            name: detail_text,
            detail: detail_text
        }
        try {
            if (name === 'อาชีพ') {
                let response = await axios.put(
                    `${Config.url}/api/v1/edit_occupation/${id}`,
                    info,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                            'Content-Type': 'application/json'
                        }
                    }
                )
            }
            if (name === 'วุฒิการศึกษา') {
                let response = await axios.put(
                    `${Config.url}/api/v1/edit_education/${id}`,
                    info,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                            'Content-Type': 'application/json'
                        }
                    }
                )
            }
            if (name === 'ศาสนา') {
                let response = await axios.put(
                    `${Config.url}/api/v1/edit_religion/${id}`,
                    info,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                            'Content-Type': 'application/json'
                        }
                    }
                )
            }

        } catch (e) {
            console.log('error is :', e)
        }
    }

    const handleSummit = (detail_text, data) => {
        console.log(data)
        editData(detail_text, data)
        Swal.fire({
            icon: 'success',
            title: 'สำเร็จ',
            text: 'แก้ไขรายการสำเร็จ',
            confirmButtonText: 'ตกลง',
        }).then(() => {
            reloadData()
        })
    }

    const deleteData = async (data) => {
        let id = data;
        if (name === 'อาชีพ') {
            let response = await axios.delete(
                `${Config.url}/api/v1/remove_occupation/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json'
                    }
                }
            )
        }
        if (name === 'วุฒิการศึกษา') {
            let response = await axios.delete(
                `${Config.url}/api/v1/remove_education/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json'
                    }
                }
            )
        }
        if (name === 'ศาสนา') {
            let response = await axios.delete(
                `${Config.url}/api/v1/remove_religion/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json'
                    }
                }
            )
        }
    }

    const handleDelete = (data, value) => {
        Swal.fire({
            title: 'ยืนยันการลบ',
            text: "ยืนยันการลบรายการ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก'
        }).then((result) => {
            if (result.value) {
                deleteData(data)
                set_new_data(value)
                Swal.fire(
                    {
                        icon: 'success',
                        title: 'ลบ!',
                        text: 'ลบรายการสำเร็จ',
                        confirmButtonText: 'ตกลง',
                    }
                ).then(() => {
                    reloadData()
                })
            }
        })
    }

    useEffect(() => {
        setData()
    }, [data])

    return (
        <div className={'setting-table-screen'}>
            <Table color={'blue'}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell className={'table-name-header'}>
                            <div className={'block-table'}>
                                <div className={'detail-table'}>{`ชื่อ${name}`}</div>
                                <div className={'add-input'}>
                                    <button
                                        className={'add-button'}
                                        onClick={() => {
                                            Swal.fire({
                                                title: `เพิ่ม${name}`,
                                                text: 'กรุณากรอกข้อมูลให้ถูกต้องก่อนยืนยัน',
                                                input: 'text',
                                                showCancelButton: true,
                                                confirmButtonText: 'เพิ่มข้อมูล',
                                                cancelButtonText: 'ยกเลิก',
                                                showLoaderOnConfirm: true,
                                                preConfirm: (data) => {
                                                    if (!data) {
                                                        Swal.showValidationMessage('กรุณากรอกข้อมูล')
                                                    }
                                                },
                                                allowOutsideClick: () => !Swal.isLoading()
                                            }).then((result) => {
                                                if (result.value) {
                                                    addData(result.value)
                                                } else {

                                                }
                                            })
                                        }}
                                    >
                                        <Icon name='plus circle'/>
                                        <span>เพิ่ม{name}</span>
                                    </button>
                                </div>
                            </div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    <Table.Row>
                        <Table.Cell colSpan='2'>
                            <div className={'search-container'}>
                                <input
                                    placeholder='ค้นหา...'
                                    onChange={(event) => {
                                        handleSearch(event)
                                    }
                                    }
                                />
                                <Icon name={'search'}/>
                            </div>


                        </Table.Cell>

                    </Table.Row>
                    {
                        loader ?
                            <Table.Row>
                                <Table.Cell colSpan={'3'}>
                                    <Loader/>
                                </Table.Cell>
                            </Table.Row>
                            :
                            new_data.length > 0 ?
                                new_data.map((item, index) => {
                                        return (
                                            <DetailTable
                                                key={index.toString()}
                                                index={index}
                                                item={item}
                                                data={data}
                                                handleDelete={handleDelete}
                                                handleSummit={handleSummit}
                                            />

                                        )
                                    }
                                )
                                :

                                <Table.Row>
                                    <Table.Cell colSpan={'3'}>
                                        <div style={{textAlign: 'center'}}>ไม่พบข้อมูล</div>
                                    </Table.Cell>
                                </Table.Row>

                    }

                </Table.Body>
            </Table>
        </div>
    )
}
