import React from "react";
import './SurveyData.css'

export default ({item}) => {

    // Reverse date to TH date
    const reverseDate = (value) => {
        if (value) {
            let [year, month, day] = value.split("T")[0].split("-")
            const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
            let dateTH = date.toLocaleDateString("th-TH", {
                year: `numeric`,
                month: `long`,
                day: `numeric`
            })
            return dateTH
        } else {
            return 'กำลังโหลด...'
        }
    }

    return (
        <div className={'survey-data-screen'}>
            <div className={'survey-container'}>
                <div style={{display: 'flex'}}>
                    <div className={'survey-topic'}>ผู้สำรวจ</div>
                    <span className={'survey-desc'}>{item.survey_by}</span>
                </div>
                <div style={{display: 'flex'}}>
                    <div className={'survey-topic'}>วันที่สำรวจ</div>
                    <span className={'survey-desc'}>{reverseDate(item.survey_at)}</span>
                </div>
                <div style={{display: 'flex'}}>
                    <div className={'survey-topic'}>เบอร์ติดต่อ</div>
                    <span className={'survey-desc'}>{item.survey_tel}</span>
                </div>
            </div>
        </div>
    )
}
