import React from 'react'
import {Icon,} from "semantic-ui-react";
import './CardHouse.css'
import Image from "../../../components/Image"
import 'sweetalert2/src/sweetalert2.scss'
import {Link, withRouter} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";


const CardHouse = ({item, page, index, onClick, reloadData, ...props}) => {

// Reverse date to TH date
    const reverseDate = (value) => {
        if (value) {
            let [year, month, day] = value.split("T")[0].split("-")
            const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
            let dateTH = date.toLocaleDateString("th-TH", {
                year: `numeric`,
                month: `long`,
                day: `numeric`
            })
            return dateTH
        } else {
            return 'กำลังโหลด...'
        }
    }

    return (

        <div className={'card-house'}>

            <div style={{display: 'flex', flexDirection: 'column', flex: '1'}}>

                {/*ข้อมูลสมาชิก*/}
                <div className={'container-border-card'}>
                    <div className={'members'}>

                        {/*ประเภทผู้ใช้งาน*/}
                        <div className={'type-user-symbol'}
                             style={{backgroundImage: 'linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%)', marginRight: '10px'}}
                        >
                            <div className={'background-house-no'}>
                                <span className={'house-no'}
                                      style={{color: '#1967AD'}}>{page}</span>
                            </div>
                        </div>

                        <div className={'container-card'}>

                            <div className={'container-detail-house'}>

                                {/*ที่อยู่อาศัย*/}
                                <div className={'detail-table-block'} style={{flex: 2}}>

                                    {/*House No*/}
                                    <div className={'header-detail-member'}>
                                        {
                                            item.house_no ?
                                                `บ้านเลขที่ ${item.house_no}`
                                                :
                                                `บ้านเลขที่ใกล้เคียง ${item.ref_house_code}`
                                        }
                                    </div>

                                    {/*House ID*/}
                                    <div className={'subdetail-table-block'}>
                                        รหัสบ้าน
                                        <div className={'text-detail-member'}> : {item.house_code}</div>
                                    </div>

                                    {/*House Detail*/}
                                    <div className={'subdetail-table-block housedetail'} style={{paddingRight: '20px'}}>
                                        <Icon name={'home'} style={{color: '#17436b'}}/>
                                        <div className={'text-detail-member'}>
                                            <span style={{color: '#2d9034'}}>หมู่ที่</span><span
                                            style={{fontSize: '16px'}}> {item.village_no} </span>
                                            <span style={{color: '#2d9034'}}>ซอย</span><span
                                            style={{fontSize: '16px'}}> {item.alley} </span>
                                            <span style={{color: '#2d9034'}}>ถนน</span><span
                                            style={{fontSize: '16px'}}> {item.road} </span>
                                            <br/>
                                            <span style={{color: '#2d9034'}}>ต.</span><span
                                            style={{fontSize: '16px'}}> {item.sub_district} </span>
                                            <span style={{color: '#2d9034'}}>อ.</span><span
                                            style={{fontSize: '16px'}}> {item.district} </span>
                                            <span style={{color: '#2d9034'}}>จ.</span><span
                                            style={{fontSize: '16px'}}> {item.province} </span>
                                            <span style={{fontSize: '16px'}}> {item.zip_code} </span>
                                        </div>
                                    </div>
                                </div>

                                {/*เขตพื้นที่รับผิดชอบ*/}
                                <div className={'detail-table-block'}>

                                    {/*Medical Center Area*/}
                                    <div>
                                        <div className={'subdetail-name'}>
                                            <Icon name={'building'} style={{color: '#17436b'}}/>
                                            <div className={'text'}>ศูนย์รับผิดชอบ</div>
                                        </div>
                                        <div
                                            className={'text-detail-member'}>{item.area ? item.area.name : 'ไม่พบข้อมูล'}</div>
                                    </div>

                                    {/*Community Area*/}
                                    <div>
                                        <div className={'subdetail-name'}>
                                            <Icon name={'home'} style={{color: '#17436b'}}/>
                                            <div className={'text'}>เขตพื้นที่รับผิดชอบ</div>
                                        </div>
                                        <div
                                            className={'text-detail-member'}>{item.community ? item.community.name : 'ไม่พบข้อมูล'}</div>
                                    </div>
                                </div>

                                {/*หัวหน้าครัวเรือน*/}
                                <div className={'detail-table-block'}>
                                    <div>
                                        <div className={'subdetail-name'}>
                                            <Icon name={'user'} style={{color: '#17436b'}}/>
                                            <div className={'text'}>หัวหน้าครัวเรือน</div>
                                        </div>
                                        <div className={'text-detail-member'}>{item.head_member}</div>
                                    </div>
                                </div>

                                {/*ข้อมูลการสำรวจ*/}
                                <div className={'detail-table-block'}>

                                    {/*Survey Person*/}
                                    <div>
                                        <div className={'subdetail-name'}>
                                            <Icon name={'user'} style={{color: '#17436b'}}/>
                                            <div className={'text'}>ผู้สำรวจ</div>
                                        </div>
                                        <div
                                            className={'text-detail-member'}>{item.survey_by ? item.survey_by : 'ไม่พบข้อมูล'}
                                        </div>
                                        <div
                                            className={'text-detail-member'}>{`(${item.survey_tel})`}
                                        </div>
                                    </div>

                                    {/*Survey Date*/}
                                    <div>
                                        <div className={'subdetail-name'}>
                                            <Icon name={'calendar alternate'} style={{color: '#17436b'}}/>
                                            <div className={'text'}>วันที่สำรวจ</div>
                                        </div>
                                        <div
                                            className={'text-detail-member'}>{item.survey_at ? reverseDate(item.survey_at) : 'ไม่พบข้อมูล'}</div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        {/*ปุ่มแก้ไข/ลบ*/}
                        <div className={'layout-button-action'}>
                            <div className={'button-action-modal'}>
                                <Link
                                    to={{

                                        pathname: props.user.permission_id === "5e4a59cec479a2e3b6190e09" ? '/administrator/approve/detail' : '/centeradministrator/approve/detail',
                                        hash: "#the-hash",
                                        state: {mark_id: item._id}
                                    }}
                                    className={'button-info-member'}
                                    type="button"
                                    style={{
                                        padding: '5px',
                                        backgroundColor: 'rgb(242, 201, 76)',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Icon name={'eye'} size='large'/>
                                </Link>

                            </div>

                        </div>

                    </div>


                </div>


            </div>


        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
};

export default connect(mapStateToProps, null)(CardHouse)
