import React, {useEffect, useState} from "react";
import './SettingApp.css'
import SettingTable from '../SettingTable'
import {Breadcrumb, Icon, Table} from "semantic-ui-react";
import FormatContiner from "../../../components/FormatContainer";
import Config from "../../../constants/Config";
import axios from "axios";


export default (props) => {
    // console.log(props)

    const [detail_data, set_detail_data] = useState([])
    const [reload_api, set_reload_api] = useState(false)
    const [loader_status, set_loader_status] = useState(false)


    const connectApi = async () => {
        set_loader_status(true)
        try {
            let response = await axios.get(
                `${Config.url}/api/v1/filter_user_all`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json'
                    }
                }
            ).then ((response)=>{
                if(props.location.state.topic==='อาชีพ'){
                    set_detail_data(response.data.value?.occupations || [])
                }
                if(props.location.state.topic==='วุฒิการศึกษา'){
                    set_detail_data(response.data.value?.education || [])
                }
                if(props.location.state.topic==='ศาสนา'){
                    set_detail_data(response.data.value?.religion || [])
                }
                set_reload_api(false)
                set_loader_status(false)
            })


        } catch (e) {
            console.log('error : ', e)
        }

    }

    const reloadApi = () => {
        set_reload_api(true)
    }


    useEffect(() => {
        connectApi()
    }, [reload_api])



    return (
        <div className={'setting-app-screen'}>
            <div className={'header-container'}>
                <button className={'button-back'} onClick={props.history.goBack}>
                    <Icon name={'chevron circle left'}/>
                </button>
                <div className={'header-taxt-breadcumb'}>
                    <div className={'header-text'}>{props.location.state.title}</div>
                    <Breadcrumb>
                        <Breadcrumb.Section href={'/administrator/setting'}>ตั้งค่าระบบ</Breadcrumb.Section>
                        <Breadcrumb.Divider/>
                        <Breadcrumb.Section active>{props.location.state.title}</Breadcrumb.Section>
                    </Breadcrumb>
                </div>
            </div>

            <FormatContiner
                icon={props.location.state.icon}
                title={props.location.state.title}
            >

                    <SettingTable
                        name={props.location.state.topic}
                        data={detail_data}
                        reloadData={reloadApi}
                        loader={loader_status}
                    />


            </FormatContiner>

        </div>
    )
}
