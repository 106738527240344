import React, {useState, useEffect} from 'react'
import {Icon, Dropdown} from "semantic-ui-react";
import './CardMember.css'
// import imgProfile from '../../assets/img/girl_profile.png'
import Image from "../Image"
import axios from "axios";
import Config from "../../constants/Config";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'


export default ({item, index, onClick,reloadData}) => {

    const deleteInfo = () => {
        Swal.fire({
            title: 'ยืนยันการลบข้อมูล',
            text: "คุณต้องการลบข้อมูลนี้หรือไม่?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ยืนยันการลบ',
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if (result.value) {
                let response = await axios.delete(
                    `${Config.url}/api/v1/remove_user/${_id}`,
                    {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                            'Content-Type': 'application/json'
                        }
                    }
                )
                    .then((response)=>{
                    Swal.fire(
                        'ลบสำเร็จ!',
                        'คุณได้ลบข้อมูลเรียบร้อยแล้ว',
                        'success'
                    )
                        reloadData()

                })
                    .catch((error)=>{
                        console.log('err',error)
                        Swal.fire(
                            'พบข้อผิดพลาด!',
                            'ไม่สามรถลบข้อมูลได้ กรุณาลองใหม่อีกครั้ง',
                            'error'
                        )
                    })

            }
        })
    };


    let {
        _id,
        fullName,
        id_card_number,
        phone_number,
        image: {
            path
        },
        area,
        community,
        address: {
            house_no,
            village_name,
            village_no,
            sub_district,
            district,
            province,
            zip_code
        },
        permission,
    } = item;

    const setEmptyInfo = (value) => {
        return value ? value : '-'
    };

    return (

        <div className={'card-member'}>

            <div style={{display: 'flex', flexDirection: 'column', flex: '1'}}>

                {/*ข้อมูลสมาชิก*/}
                <div className={'container-border-card'}>
                    <div className={'members'}>

                        {/*ประเภทผู้ใช้งาน*/}
                        <div className={'type-user-symbol'}
                             style={permission._id === "5e71d0542e86f6aca55b90ea" ? {backgroundImage: 'linear-gradient(#ED8A6E, #E96E4C)'} : {backgroundImage: 'linear-gradient(#85bd46, #67ad19)'} }
                        >
                            <div className={'background-member-no'}>
                                <span className={'member-no'}
                                      style={permission._id === "5e71d0542e86f6aca55b90ea" ? {color: '#E96E4C'} : {color: '#67ad19'}}>{index}</span>
                            </div>
                        </div>
                        <div className={'container-card'}>

                            {/*รูปโปรไฟล์*/}
                            <div className={'layout-pic-profile'}>
                                <Image
                                    className={'pic-profile'}
                                    src={`${Config.url}/api/v1${path}`}
                                    path={path}

                                />

                            </div>
                            <div
                                className={'container-detail-user'}
                            >

                                {/*Block1*/}
                                {/*ชื่อสกุล*/}
                                <div className={'header-detail-member'}>{fullName}</div>

                                {/*Block2*/}
                                <div
                                    className={'container-text-detail'}
                                    style={permission._id === "5e71d0542e86f6aca55b90ea" ? {color: '#E96E4C'} : {color: '#67ad19'}}
                                >

                                    {/*เลขประจำตัวประชาชน*/}
                                    <div className={'space-block'}>
                                        <Icon name={'address card'}/>
                                        <div className={'text-detail-member'}>{id_card_number}</div>
                                    </div>

                                    {/*หมายเลขโทรศัพท์*/}
                                    <div className={'space-block'}>
                                        <Icon name={'phone'}/>
                                        <div className={'text-detail-member'}>{phone_number}</div>
                                    </div>

                                    {/*เชตชุมชนที่รับผิดชอบ*/}
                                    <div className={'space-block'}>
                                        <Icon name={'map marker alternate'}/>
                                        <div className={'text-detail-member'}>
                                            {permission._id === "5e71d0542e86f6aca55b90ea" ? area.name : community.name}
                                        </div>
                                    </div>

                                </div>


                                {/*Block3*/}
                                {/*ที่อยู่*/}
                                <div
                                    className={'text-detail-member-address '}
                                    style={permission._id === "5e71d0542e86f6aca55b90ea" ? {color: '#E96E4C'} : {color: '#67ad19'}}
                                >
                                    <Icon name={'home'}/>
                                    <div className={'text-detail-member'}>{`
                                    บ้านเลขที่ ${setEmptyInfo(house_no)} 
                                    หมู่บ้าน${setEmptyInfo(village_name)} 
                                    หมู่ที่ ${setEmptyInfo(village_no)} 
                                    ต.${setEmptyInfo(sub_district)} 
                                    อ.${setEmptyInfo(district)} 
                                    จ.${setEmptyInfo(province)} 
                                    ${setEmptyInfo(zip_code)} 
                                    `}</div>
                                </div>

                            </div>


                        </div>

                        {/*ปุ่มแก้ไข/ลบ*/}
                        <div className={'layout-button-action'}>

                            <button
                                className={'button-info-member'}
                                type="button"
                                style={{backgroundColor: '#F2C94C'}}
                                onClick={() => onClick('view_member')}
                            >
                                <Icon name={'eye'} size='large'/>
                            </button>

                            <div className={'button-action-modal'}>

                                <button
                                    className={'button-edit-member'}
                                    type="button"
                                    style={{backgroundColor: '#F2C94C'}}
                                    onClick={() => onClick('edit_member')}
                                >
                                    <Icon name={'pencil alternate'} size='large'/>
                                </button>

                                <button
                                    className={'button-action'}
                                    type="button"
                                    style={{backgroundColor: '#EB5757'}}
                                    onClick={deleteInfo}
                                >
                                    <Icon name={'trash alternate'} size='large'/>
                                </button>

                            </div>


                        </div>

                        {/*ปุ่มแสดง แก้ไข/ลบ บนจอเล็ก*/}
                        <Dropdown
                            className={'button-detail'}
                            direction={'left'}
                            floating
                            labeled
                            icon={'ellipsis vertical'}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => onClick('view_member')} >
                                    <Icon size={'small'} name={'eye'}/>ดูข้อมูล
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => onClick('edit_member')}>
                                    <Icon size={'small'} name={'pencil'}/>แก้ไข
                                </Dropdown.Item>
                                <Dropdown.Item onClick={deleteInfo} >
                                    <Icon size={'small'} name={'trash'}/>
                                    ลบ
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </div>


                </div>


            </div>


        </div>
    )
}
