import React, {useEffect, useState} from "react";
import './ApproveDataScreen.css';
import {Grid, Icon, Breadcrumb} from 'semantic-ui-react';
import FormatContainer from '../../components/FormatContainer'
import MemberHouse from "./MemberHouse";
import Question from "./Question"
import DataHouse from "./DataHouse";
import LocationHouse from "./LocationHouse";
import ApproveData from "./ApproveData";
import SurveyData from "./SurveyData"
import axios from "axios";
import Config from "../../constants/Config";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";


const ApproveDataScreen = (props) => {

    const id = props.location.state.mark_id
    // const id = "5e82e92eb945c66c54365f11"
    const [loading, set_loading] = useState(false)
    const [household, set_household] = useState({})
    const [house_data, set_house_data] = useState({})
    const [map_data, set_map_data] = useState({})
    const [member_data, set_member_data] = useState([])
    const [question_data, set_question_data] = useState({})
    const [approve_data, set_approve_data] = useState([])

    const connectApi = async () => {
        try {
            set_loading(true)
            let response = await axios.get(
                `${Config.url}/api/v1/house_data/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json',
                    }
                }
            )

            set_loading(false)
            if (response.data.value) {
                set_household(response.data.value)
                set_house_data(response.data.value.data)
                set_map_data(response.data.value.location)
                set_member_data(response.data.value.data.members)
                set_approve_data(response.data.value.approve_and_reject ? response.data.value.approve_and_reject : [])
            } else {
                set_house_data({})
            }

        } catch (e) {

            Swal.fire({
                title: 'แจ้งเตือน',
                text: 'พบข้อผิดพลาดในการดำเนินการ กรุณาลองใหม่อีกครั้ง',
                icon: 'warning',
                confirmButtonText: 'ตกลง',
            }).then(() => {
                props.history.goBack()
            })
            console.log('err',e)

        }
    }

    const handleApprove = async () => {
        Swal.fire({
            icon: 'warning',
            title: 'อนุมัติ',
            text: 'กรุณาตรวจสอบข้อมูลก่อนอนุมัติ',
            footer: `<span style="padding-right: 50px">โดย คุณ${props.user.full_name}</span> วันที่ ${dateFormat()}`,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#BFC3C8',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then((async (result) => {
            if (result.value) {
                try {
                    let response = await axios.put(
                        `${Config.url}/api/v1/access_approval/${id}`,
                        {}, {
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                                'Content-Type': 'application/json',
                            }
                        }
                    )
                    Swal.fire(
                        'ทำรายการสำเร็จ',
                        'ข้อมูลครัวเรือนนี้ได้รับการอนุมัติ',
                        'success'
                    ).then(() => props.history.goBack())
                } catch (error) {
                    Swal.fire({
                        title: 'ขออภัย',
                        text: 'พบข้อผิดพลาดในการดำเนินการ กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง',
                    })
                }
            }
        }))
    }

    const handleReject = async () => {

        Swal.fire({
            icon: 'warning',
            title: 'ไม่อนุมัติ',
            text: 'กรุณากรอกหมายเหตุ',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            footer: `<span style="padding-right: 50px">โดย คุณ${props.user.full_name}</span> วันที่ ${dateFormat()}`,
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            showLoaderOnConfirm: true,
            preConfirm: (value, item) => {
                if (value) {
                    let response = axios.put(
                        `${Config.url}/api/v1/access_reject/${id}`,
                        {
                            detail: value
                        },
                        {
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                                'Content-Type': 'application/json',
                            }
                        }
                    )
                        .then((response) => {
                            Swal.fire(
                                'ทำรายการสำเร็จ',
                                'ข้อมูลครัวเรือนนี้ไม่ได้รับการอนุมัติ',
                                'success'
                            ).then(() => props.history.goBack())
                            return response.data
                        })
                        .catch((e) => {
                            Swal.fire({
                                title: 'ขออภัย',
                                text: 'พบข้อผิดพลาดในการดำเนินการ กรุณาลองใหม่อีกครั้ง',
                                icon: 'warning',
                                confirmButtonText: 'ตกลง',
                            })
                        })
                } else {
                    Swal.showValidationMessage(
                        `กรุณากรอกหมายเหตุ`
                    )
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {
                Swal.fire(
                    'ทำรายการสำเร็จ',
                    'ข้อมูลครัวเรือนนี้ไม่ได้รับการอนุมัติ',
                    'success'
                )
            }
        })

    }

    // ฟังก์ชันแปลงวัน/เดือน/ปี ปัจจุบัน
    const dateFormat = () => {
        let today = new Date();
        let years = today.getFullYear()
        const date = new Date(years, today.getMonth(), today.getDate())
        let dateTH = date.toLocaleDateString("th-TH", {
            year: `numeric`,
            month: `long`,
            day: `numeric`
        })
        return dateTH

    }

    // Reverse date to TH date
    const reverseDate = (value) => {
        if (value) {
            let [year, month, day] = value.split("T")[0].split("-")
            const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
            let dateTH = date.toLocaleDateString("th-TH", {
                year: `numeric`,
                month: `long`,
                day: `numeric`
            })
            return dateTH
        } else {
            return 'กำลังโหลด...'
        }
    }

    useEffect(() => {
        connectApi()
    }, [])

    return (
        <div className={'approve-data-screen'}>

            {/*หัวข้อการอนุมัตข้อมูล*/}
            <div className={'header-container'}>
                <button className={'button-back'} onClick={props.history.goBack}>
                    <Icon name={'chevron circle left'}/>
                </button>
                <div className={'header-taxt-breadcumb'}>
                    <div className={'header-text'}>ข้อมูลครัวเรือน</div>
                    <Breadcrumb>
                        <Breadcrumb.Section link
                                            onClick={() => props.history.goBack()}>อนุมัติข้อมูลครัวเรือน</Breadcrumb.Section>
                        <Breadcrumb.Divider/>
                        <Breadcrumb.Section active>ข้อมูลครัวเรือน</Breadcrumb.Section>
                    </Breadcrumb>
                </div>

            </div>


            {/*เริ่ม Grid*/}
            <Grid className={'space-top'}>

                {/*ข้อมูลบ้าน*/}
                <Grid.Row>

                    {/*ข้อมูลบ้าน*/}
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={10}
                        largeScreen={10}
                    >

                        {/*ใส่ Component ข้อมูลบ้าน ตรงนี้*/}
                        <FormatContainer
                            icon={'home'}
                            title={'ข้อมูลบ้าน'}
                            style={{height: '100%'}}
                        >

                            {/*Component ข้อมูลบ้าน*/}
                            <DataHouse
                                item={house_data}
                            />

                        </FormatContainer>

                    </Grid.Column>

                    {/*ข้อมูลแผนที่บ้าน*/}
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={6}
                        largeScreen={6}
                    >
                        {/*Survey Detail*/}
                        <div className={'survey-person-detail'}>
                            <FormatContainer
                                icon={'user'}
                                title={'ข้อมูลผู้สำรวจ'}
                            >
                                <SurveyData
                                    item={household}
                                />
                            </FormatContainer>
                        </div>

                        {/*ใส่ Component แผนที่ ตรงนี้*/}
                        <LocationHouse
                            item={map_data}
                            status={household.status}
                            id={household._id}
                        />

                    </Grid.Column>

                </Grid.Row>

                {/*ข้อมูลสมาชิก*/}
                <Grid.Row>
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={16}
                        largeScreen={16}
                    >

                        {/*Component container หัวข้อ*/}
                        <FormatContainer
                            icon={'users'}
                            title={'ข้อมูลสมาชิก'}
                        >

                            {/*Component ข้อมูลสมาชิก*/}
                            <MemberHouse
                                members={member_data}
                            />

                        </FormatContainer>

                    </Grid.Column>
                </Grid.Row>

                {/*ข้อมูลแบบสอบถาม*/}
                <Grid.Row>
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={16}
                        largeScreen={16}
                    >

                        {/*Component container หัวข้อ*/}
                        <FormatContainer
                            icon={'file alternate'}
                            title={'ข้อมูลแบบสอบถาม'}
                        >

                            {/*Component ข้อมูลแบบสอบถาม*/}
                            <Question data={!!household.data && household.data.jpt_survey}/>
                        </FormatContainer>

                    </Grid.Column>
                </Grid.Row>

                {/*ข้อมูลการอนุมัติ*/}
                <Grid.Row>
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={16}
                        largeScreen={16}
                    >

                        {/*ใส่ Component ข้อมูลการอนุมัติ ตรงนี้*/}
                        {/*Component container หัวข้อ*/}
                        <FormatContainer
                            icon={'check circle'}
                            title={'ข้อมูลแบบสอบถาม'}
                        >
                            {/*Component ข้อมูลการอนุมัติ*/}
                            {
                                approve_data ?

                                    <ApproveData
                                        approveDetail={approve_data}
                                    />
                                    :
                                    <div style={{textAlign: 'center'}}>รอการตรวจสอบ</div>
                            }

                        </FormatContainer>

                    </Grid.Column>
                </Grid.Row>

                {/*ปุ่ม*/}
                <Grid.Row>
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={16}
                        largeScreen={16}
                    >

                        {/*ปุ่มอนุมัติ/ไม่อนุมัติ*/}
                        <div className={'block-button'}>

                            {/*ปุ่มอนุมัติ*/}
                            <button
                                className={'approve-button'}
                                onClick={handleApprove}
                            >
                                <span className={'icon-button'}>
                                <Icon name={'check'}/>
                                </span>
                                อนุมัติ
                            </button>

                            {/*ปุ่มไม่อนุมัติ*/}
                            <button
                                className={'reject-button'}
                                onClick={handleReject}
                            >
                                <span className={'icon-button'}>
                                <Icon name={'times'}/>
                                </span>
                                ไม่อนุมัติ
                            </button>

                        </div>

                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    }
};

export default connect(mapStateToProps, null)(withRouter(ApproveDataScreen));


