import React, {useEffect, useState} from "react";
import './Question.css'
import {Menu, Segment} from 'semantic-ui-react'
import {Tab} from 'semantic-ui-react'
import Group1 from "./Group/Group1";
import Group2 from "./Group/Group2";
import Group3 from "./Group/Group3";
import Group4 from "./Group/Group4";
import Group5 from "./Group/Group5";


const Question = (props) => {


    const panes = [
        {menuItem: 'หมวดที่ 1', render: () => <Tab.Pane className={'question-container'}><Group1 data={props.data} /></Tab.Pane>},
        {menuItem: 'หมวดที่ 2', render: () => <Tab.Pane className={'question-container'}><Group2 data={props.data} /></Tab.Pane>},
        {menuItem: 'หมวดที่ 3', render: () => <Tab.Pane className={'question-container'}><Group3 data={props.data} /></Tab.Pane>},
        {menuItem: 'หมวดที่ 4', render: () => <Tab.Pane className={'question-container'}><Group4 data={props.data} /></Tab.Pane>},
        {menuItem: 'หมวดที่ 5', render: () => <Tab.Pane className={'question-container'}><Group5 data={props.data} /></Tab.Pane>},
    ]

    return (
        <div className={'quetion-content'}>
            {/*ข้อมูลแบบสอบถาม*/}

            <div style={{width: '100%',overflow: 'auto'}}>
                <Tab panes={panes} />
            </div>
        </div>
    )
}

export default Question;
