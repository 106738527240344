import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Modal } from 'semantic-ui-react';
import Config from '../../../constants/Config';
import Image from '../../../components/Image';
import '../AddMember/AddMember.css';

export const UploadImgAndCrop = ({ field: { name }, form: { setFieldValue }, ...props }) => {
	/* Set State */
	const [ imgSrc, setImgSrc ] = useState('');
	const [ crop, setCrop ] = useState({
		unit: '%',
		width: 30,
		aspect: 9 / 9
	});
	const [ croppedImageUrl, setCroppedImageUrl ] = useState('');
	const [ modalImg, setModalImg ] = useState(false);
	const [ imgRef, setImgRef ] = useState('');
	const [ fileUrl, setFileUrl ] = useState('');
	const [ fileName, setFileName ] = useState('');

	/* func onSelectFile */
	const onSelectFile = (e) => {
		setCroppedImageUrl('');
		if (e.target.files && e.target.files.length > 0) {
			setFileName(e.target.files[0].name);
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				setImgSrc(reader.result);
				setModalImg(true);
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	/* func onImageLoaded */
	const onImageLoaded = (image) => {
		setImgRef(image);
	};

	/* func onCropComplete */
	const onCropComplete = (crop) => {
		makeClientCrop(crop);
	};

	/* func onCropChange */
	const onCropChange = (crop, percentCrop) => {
		setCrop(crop);
	};

	/* func makeClientCrop */
	const makeClientCrop = async (crop) => {
		if (imgRef && crop.width && crop.height) {
			const { fileUrl, blob } = await getCroppedImg(imgRef, crop, fileName);
			setCroppedImageUrl({ croppedImageUrl: fileUrl, blob });
		}
	};

	/* func getCroppedImg */
	const getCroppedImg = (image, crop, fileName) => {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(fileUrl);
				setFileUrl(window.URL.createObjectURL(blob));
				// console.log('fileUrl: ', fileUrl);
				// console.log('blob: ', blob.name);
				resolve({ fileUrl, blob });
			}, 'image/jpeg');
		});
	};

	/* func onClickCancel */
	const onClickCancel = () => {
		setModalImg(false);
		setImgSrc('');
		setCroppedImageUrl('');
		document.getElementById('upload').value = null;
		setCrop({
			unit: '%',
			width: 30,
			aspect: 9 / 9
		});
		setFileUrl('');
		setImgRef('');
	};

	/* func onClickChoose */
	const onClickChoose = () => {
		setModalImg(false);
		setImgSrc(fileUrl);
		// console.log('fileUrl: ',fileUrl.);
		var file = new File([ croppedImageUrl.blob ], fileName, { type: croppedImageUrl.blob.type });
		// console.log('file: ',file);
		setFieldValue(name, file);
	};

	return (
		<div>

			
			{/* image */}
			<div className='set-margin-bottom'>
				<Image
					className={'pic-profile'}
					src={imgSrc ? imgSrc : `${Config.url}/api/v1${props.pathImage}`}
					path={!props.pathImage ? imgSrc : `${Config.url}/api/v1${props.pathImage}`}
				/>
			</div>

			{/* ปุ่มของรูปภาพ */}
			<div className='display-center'>

				{/* ปุ่มล้างรูปภาพ */}
				{
					imgSrc && ( 
						<Button className='button-cancel-cross' onClick={()=>setImgSrc('')}>
							<u className='u-cancel'>ล้างรูป</u>
						</Button>
					)
				}

				{/* ปุ่มแก้ไขรูปภาพ */}
				<label>
					<div className='btn-upload-img'> แก้ไขรูปภาพ </div>
					<input
						type='file'
						id='upload'
						accept='image/*'
						onChange={onSelectFile}
						style={{ display: 'none' }}
					/>
				</label>
			</div>

			{/* Modal Crop Img */}
			<Modal open={modalImg}>
				<Modal.Header>
					<label>สร้างรูปโปร์ไฟล์</label>
				</Modal.Header>

				{/* Modal Content */}
				<Modal.Content className='display-center'>
					<div>
						<div className='display-center'>
							<ReactCrop
								src={imgSrc}
								crop={crop}
								ruleOfThirds
								onImageLoaded={onImageLoaded}
								onComplete={onCropComplete}
								onChange={onCropChange}
							/>
						</div>
						{croppedImageUrl && (
						<div className='set-margin-top'>
							<Button basic color='red' content='ยกเลิก' onClick={onClickCancel} />
							<Button primary className='buttonSave' content=' เลือก ' onClick={onClickChoose} />
						</div>
					)}
					</div>
				</Modal.Content>

				{/* Modal Actions
				<Modal.Actions>
					
				</Modal.Actions> */}
			</Modal>
		</div>
	);
};
export default UploadImgAndCrop;
